.swiper-pagination-bullet {
	background-color: #fff;
	opacity: 0.6;
	text-indent: -9999em;
	overflow: hidden;
	width: 5px;
	height: 5px;
}
.swiper-pagination-bullet-active {
	background-color: #ff7300;
	opacity: 1;
}

#sme-livestudio {
}
#sme-livestudio .page-component {
}
#sme-livestudio .livestudio-banner {
	position: relative;
}
#sme-livestudio .livestudio-banner .banner-content {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 40px 20px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 35%, rgba(0, 0, 0, 0) 100%);
}
#sme-livestudio .livestudio-banner .banner-title {
	font-size: 30px;
	font-weight: bold;
	color: #fff;
	line-height: 40px;
	margin-left: -20px;
}
#sme-livestudio .livestudio-banner .banner-image {
	line-height: 0;
	margin-top: 55px;
}
#sme-livestudio .livestudio-banner .banner-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
#sme-livestudio .studio-list-wrapper {
}

/* #sme-livestudio .section-notice-arrow {position: absolute;right: 15px;top: 91px;display: flex;z-index: 100;}
#sme-livestudio .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
#sme-livestudio .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-livestudio .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-livestudio .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
#sme-livestudio .disabled {opacity:.3;}
#sme-livestudio .btn-arrow:not(:first-child) {margin-left:5px;}
#sme-livestudio [class^="icon-arrow"] {width:15px;height:15px;}
#sme-livestudio .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
#sme-livestudio .icon-arrow-next {background-image:url(../img/main/notice_next.svg)} */

.livestudio-intro {
	position: relative;
	margin: 0 -20px;
}
.livestudio-intro .studio-introduce {
	padding: 20px;
	background-color: #fff;
}
.livestudio-intro .studio-introduce-title {
	font-size: 20px;
	font-weight: 900;
	margin-bottom: 20px;
	line-height: 30px;
	width: 70%;
	word-break: keep-all;
}
.livestudio-intro .studio-introduce-text {
	word-break: keep-all;
	font-size: 16px;
	line-height: 22px;
	color: #555;
}
.livestudio-intro .studio-data {
	padding: 20px 20px 30px;
}
.livestudio-intro .studio-data-selector {
	margin-bottom: 20px;
}
.livestudio-intro .studio-data-selector .form-select {
	box-shadow: 0 0 0 1px #ff7300 inset;
	color: #ff7300;
}

.livestudio-intro [class^='icon-'] {
	width: 24px;
	min-height: 24px;
	position: absolute;
	left: 0;
	top: -2px;
}
.livestudio-intro .icon-time {
	background-image: url(../img/livestudio/icon_time.svg);
}
.livestudio-intro .icon-parking {
	background-image: url(../img/livestudio/icon_parking.svg);
}
.livestudio-intro .icon-location {
	background-image: url(../img/livestudio/icon_location.svg);
}
.livestudio-intro .icon-phone {
	background-image: url(../img/livestudio/icon_phone.svg);
}
.livestudio-intro .studio-data-description .text {
	font-size: 15px;
	line-height: 16px;
	color: #555;
	word-break: keep-all;
}
.livestudio-intro .description-item {
	position: relative;
	padding-left: 30px;
}
.livestudio-intro .description-item ~ .description-item {
	margin-top: 20px;
}

.studio-list {
}
.studio-list-item {
}
.studio-list-item ~ .studio-list-item {
	margin-top: 20px;
}
.studio-card {
	position: relative;
	background-color: #fff;
	border-radius: 2px;
}
.studio-card .studio-images {
	overflow: hidden;
	position: relative;
}
.studio-card .studio-image-wrapper {
}
.studio-card .studio-image {
	width: 100%;
	height: 0;
	padding-top: 51.8%;
}
.studio-card .studio-image img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.studio-card .studio-description {
	position: relative;
	padding: 20px;
}
.studio-card .studio-description .subtitle {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 10px;
	color: #999;
}
.studio-card .studio-description .title {
	font-size: 20px;
	font-weight: bold;
}

.livestudio-section.section-list {
	background-color: #f3f5f7;
	padding-bottom: 40px;
}
.livestudio-section.section-rating {
	padding-top: 30px;
}

.livestudio-rating-title {
	font-size: 16px;
	font-weight: 900;
	margin-bottom: 20px;
}

.livestudio-review {
}
.livestudio-review .rating-status {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 20px;
	max-width: 505px;
	margin: auto;
}
.livestudio-review .rating-status .status-list {
	flex: 1 1 auto;
}
.livestudio-review .rating-status .status-list {
	padding-left: 60px;
}
.livestudio-review .rating-status .status-item {
	display: flex;
	align-items: center;
}
.livestudio-review .rating-status .status-item ~ .status-item {
	margin-top: 15px;
}
.livestudio-review .rating-status .status-bar {
	position: relative;
	flex: 1 1 auto;
	height: 4px;
	border-radius: 0.3125rem;
	background-color: #ebebeb;
	max-width: 300px;
	width: 100%;
}
.livestudio-review .rating-status .status-bar .value {
	z-index: 30;
	position: absolute;
	left: 0;
	height: 4px;
	border-radius: 0.3125rem;
	background-color: #ff7300;
}
.livestudio-review .rating-status .status-text {
	flex: 0 0 40px;
	margin-left: 20px;
}
.livestudio-review .rating-status .status-text .text {
	font-size: 13px;
	color: #999;
	font-weight: bold;
}
.livestudio-review .review-item ~ .review-item {
	border-top: 1px solid #ebebeb;
}

.is-selected .tab-count {
	color: #bbb;
}

/* review-list-top */
.review-list-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 15px;
	border-bottom: 1px solid #ebebeb;
}
.review-list-top .top-header {
	display: flex;
	align-items: center;
}
.review-list-top .top-header .title {
	font-size: 1rem;
	color: #111;
	margin-right: 5px;
	font-weight: 800;
}
.review-list-top .top-header .count {
	font-size: 1rem;
	color: #555;
	font-weight: normal;
}
.review-list-top .top-options {
}
.review-list-top .top-options .option-list {
	display: flex;
}
.review-list-top .top-options .option-item {
	margin-left: clamp(7px, 1.4vw, 15px);
}
.review-list-top .top-options .option-link {
}
.review-list-top .top-options .option-text {
	font-size: clamp(13px, 1.4vw, 15px);
	color: #bbb;
	font-weight: 800;
}
.review-list-top .top-options .is-selected {
}
.review-list-top .top-options .is-selected .option-text {
	color: #242424;
}
.review-list-top.is-borderless {
	border-bottom: none;
}
.review-list-top .top-column {
	display: flex;
	align-items: center;
}
.review-list-top .top-date {
}
.review-list-top .top-date .text {
	font-weight: 800;
}
.review-list-top .top-actions {
}
.review-list-top .top-actions .detailed-link {
	display: inline-flex;
	align-items: center;
}
.review-list-top .top-actions .detailed-link .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/mypage/icon_arrow_right.svg);
}
.review-list-top .top-actions .text {
	font-weight: 800;
}
.review-list-top .top-order .text {
	font-weight: 700 !important;
}
.review-list-top .subtext {
	display: flex;
	align-items: center;
}
.review-list-top .subtext.dot .icon-dot {
	display: inline-block;
	width: 3px;
	height: 3px;
	border-radius: 3px;
	background-color: #999;
	margin: 0 10px;
}
.review-list-top .subtext.notice {
	padding-left: 23px;
	font-size: 13px;
	font-weight: 800;
}
.review-list-top .subtext.notice .icon-warning {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	background-image: url(../img/mypage/icon_notice.svg);
	background-size: 20px auto;
}
.review-list-top .number {
	display: inline-block;
	margin-right: 10px;
}
.review-list-top .top-column {
}
.review-list-top .top-column .header-link {
	display: inline-flex;
	align-items: center;
}
.review-list-top .top-column .text {
	font-weight: 800;
}
.review-list-top .top-column .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/mypage/icon_arrow_right.svg);
}

.review-wrapper {
	padding-bottom: 20px;
}
.review-card {
	position: relative;
	padding: 20px 0;
}
.review-card .review-row {
	display: flex;
}
.review-card .review-inner {
	flex: 1 1 auto;
}
.review-card .review-top {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.review-card .top-rating {
	display: flex;
	align-items: center;
}
.review-card .top-option {
	position: relative;
	margin-left: 20px;
}
.review-card .top-option .text {
	font-size: 13px;
	color: #999;
	line-height: 16px;
}
.review-card .review-body {
}
.review-card .review-body .text {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}
.review-card .review-bottom {
	display: flex;
	padding-top: 10px;
}
.review-card .review-bottom .bottom-reviewer {
}
.review-card .review-bottom .bottom-reviewer .text {
	font-size: 13px;
}
.review-card .review-bottom .bottom-date {
	margin-left: 10px;
}
.review-card .review-bottom .bottom-date .text {
	font-size: 13px;
}
.review-card .review-image {
	position: relative;
	padding-top: 20px;
}
.review-card .review-image .image-list {
	display: flex;
}
.review-card .review-image .image-item {
	width: 160px;
	height: 160px;
	border-radius: 0.3125rem;
}
.review-card .review-image .image-item ~ .image-item {
	margin-left: 10px;
}
.review-card .review-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.review-card .review-like {
	flex: 0 0 62px;
	width: 62px;
}
.review-card .review-like .btn-like {
	display: block;
}
.review-card .review-like .icon-like {
	width: 62px;
	height: 62px;
	background-image: url(../img/common/icon_review_like.svg);
	margin-bottom: 5px;
}
.review-card .review-like .text {
	display: block;
	font-size: 15px;
	font-weight: 700;
}
.review-card .review-like.is-active {
}
.review-card .review-like.is-active .btn-like {
}
.review-card .review-like.is-active .icon-like {
	background-image: url(../img/common/icon_review_like_active.svg);
}
.review-card .review-like.is-active .text {
	color: #ff7300;
}

.rating-wrapper {
	display: flex;
	align-items: center;
}
.rating-wrapper .rating {
	display: flex;
}
.rating-wrapper .star {
	display: block;
	background-image: url(../img/common/icon_star_default.svg);
	background-position: 0 0;
	background-repeat: no-repeat;
}
.rating-wrapper .star.is-active {
	background-image: url(../img/common/icon_star.svg);
}
.rating-wrapper .rating-text {
	display: block;
	margin-left: 10px;
	transform: translateY(-1px);
}
.rating-wrapper .rating-text .text {
	font-size: 15px;
}
.rating-sm {
}
.rating-sm .star {
	position: relative;
	top: -1px;
	width: 16px;
	height: 16px;
	background-size: 16px auto;
}
.rating-sm .rating-text .text {
	font-weight: bold;
	font-size: 13px;
	vertical-align: middle;
	line-height: 16px;
}
.rating-lg {
}
.rating-lg .star {
	position: relative;
	top: -3px;
	width: 32px;
	height: 32px;
	background-size: 32px auto;
}
.rating-lg .rating-text .text {
	font-size: 30px;
	font-weight: 800;
}

.livestudio-sub-title {
	position: relative;
	padding-top: 85px;
	padding-bottom: 40px;
}
.livestudio-sub-title .badge {
	margin-bottom: 25px;
}
.livestudio-sub-title .title {
	font-size: 50px;
	font-weight: bold;
	line-height: 65px;
}
.livestudio-sub-body {
}
.livestudio-sub-content {
}
.livestudio-reservator {
}

.livestudio-gallery {
	position: relative;
	width: 100%;
}
.livestudio-gallery .gallery-images {
	overflow: hidden;
}
.livestudio-gallery img {
	object-fit: cover;
}

.livestudio-gallery .swiper-pagination-bullet {
	display: none;
	background-color: #fff;
	opacity: 0.6;
	text-indent: -9999em;
	overflow: hidden;
	width: 10px;
	height: 10px;
	margin: 0 5px;
}
.livestudio-gallery .swiper-pagination-bullet-active {
	background-color: #ff7300;
	opacity: 1;
}
.livestudio-gallery .swiper-counter {
	position: absolute;
	z-index: 1;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}
.livestudio-gallery .swiper-counter .text {
	color: #fff;
	font-size: bold;
	font-size: 13px;
	opacity: 0.6;
}
.livestudio-gallery .swiper-counter .text.count {
	opacity: 1;
}
.livestudio-gallery .swiper-counter .text.total {
	display: inline-block;
	position: relative;
	margin-left: 5px;
	padding-left: 5px;
}
.livestudio-gallery .swiper-counter .text.total:before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -4px;
	width: 1px;
	height: 8px;
	background-color: #fff;
}
.livestudio-gallery .gallery-button-next,
.livestudio-gallery .gallery-button-prev {
	position: absolute;
	z-index: 1;
	bottom: 30px;
	width: 40px;
	height: 40px;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(2px);
}
.livestudio-gallery .gallery-button-next {
	right: 20px;
}
.livestudio-gallery .gallery-button-prev {
	left: 20px;
}
.livestudio-gallery .gallery-button-prev .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/common/list_arrow_white.svg);
	background-position: 50%;
	background-size: 20px 20px;
	transform: rotate(-180deg);
}
.livestudio-gallery .gallery-button-next .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/common/list_arrow_white.svg);
	background-position: 50%;
	background-size: 20px 20px;
}

.livestudio-actions {
	position: sticky;
	bottom: 0;
}
.livestudio-actions .btn {
	width: 100%;
	height: 80px;
}
.livestudio-actions .btn .text {
	font-size: 20px;
}

.livestudio-description {
	position: relative;
	padding: 25px 20px 0;
}
.livestudio-description-title {
	font-size: 20px;
	font-weight: bold;
	line-height: 30px;
	margin-bottom: 25px;
}
.livestudio-description-contents {
	overflow: hidden;
	max-height: 50px;
	transition: max-height 0.4s;
}
.livestudio-description-text {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}

.livestudio-description-morebutton {
	position: relative;
	z-index: 1;
	border: solid #ebebeb;
	border-width: 1px 0;
	margin: 0 -20px;
}
.livestudio-description-morebutton .btn-more {
	width: 100%;
	background-color: #fff;
}
.livestudio-description-morebutton .icon-arrow {
	width: 10px;
	height: 6px;
	background-image: url(../img/livestudio/description_arrow.svg);
	margin-left: 10px;
}

.livestudio-description.is-visible {
}
.livestudio-description.is-visible .livestudio-description-contents {
	max-height: 700px;
	padding-bottom: 40px;
}
.livestudio-description.is-visible .livestudio-description-morebutton .icon-arrow {
	transform: rotate(180deg);
	margin-top: -2px;
}

.livestudio-warning {
	position: relative;
	padding: 20px;
	margin: 20px;
	background-color: #fff;
}
.livestudio-warning:before {
	content: '';
	position: absolute;
	right: 0;
	top: -20px;
	left: 0;
	height: 1px;
	background-color: #ebebeb;
}
.livestudio-warning-title {
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: bold;
	color: #ff7300;
	margin-bottom: 20px;
}
.livestudio-warning-title .icon-warning {
	width: 24px;
	height: 24px;
	background-image: url(../img/common/warning_alarm.svg);
	margin-right: 5px;
}
.livestudio-warning-description {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}

.livestudio-reservator {
	position: relative;
	background-color: #f3f5f7;
}
.livestudio-reservator .livestudio-reservator-actions {
	position: sticky;
	top: 0;
	z-index: 101;
}
.livestudio-reservator .livestudio-reservator-actions .btn {
	width: 100%;
}
.livestudio-reservator .livestudio-reservator-actions .icon-arrow {
	margin-left: 5px;
	width: 14px;
	height: 14px;
	background-image: url(../img/livestudio/icon_collapse_arrow.svg);
}
.livestudio-reservator .month-selector {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}
.livestudio-reservator .month-selector .btn-selector .icon-arrow {
	width: 25px;
	height: 25px;
	background-image: url(../img/livestudio/month_arrow.svg);
	background-size: 25px auto;
}
.livestudio-reservator .month-selector .btn-selector-prev .icon-arrow {
	transform: rotate(-180deg);
}
.livestudio-reservator .month-selector .calendar-title {
	width: 160px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
}
.livestudio-reservator .livestudio-calendar {
	padding: 30px 0 10px;
}
.livestudio-reservator .ls-calendar {
	width: 100%;
}
.livestudio-reservator .ls-calendar th,
.livestudio-reservator .ls-calendar td {
	width: 65px;
	height: 65px;
	text-align: center;
}
.livestudio-reservator .ls-calendar .date {
	position: relative;
	display: flex;
	width: 48px;
	height: 48px;
	margin: auto;
	justify-content: center;
	padding-top: 10px;
}
.livestudio-reservator .calendar-date .num {
}
.livestudio-reservator .calendar-date .text {
	position: absolute;
	left: 50%;
	font-size: 10px;
	white-space: nowrap;
	bottom: 5px;
	transform: translateX(-50%);
}
.livestudio-reservator .calendar-date-disabled * {
	color: #999;
}
.livestudio-reservator .calendar-date:not(.calendar-date-disabled):not(.calendar-date-selected) .date:hover {
	background-color: #ebebeb;
}
.livestudio-reservator .calendar-date-selected .date {
	background-color: #ff7300;
}
.livestudio-reservator .calendar-date-selected .date * {
	color: #fff;
}
.livestudio-reservator th.calendar-sun,
.livestudio-reservator td.calendar-sun * {
	color: #999;
}
.livestudio-reservator th.calendar-sat,
.livestudio-reservator td.calendar-sat * {
	color: #999;
}

.livestudio-reservator .livestudio-buttons {
	margin: 20px 20px 0;
	padding-bottom: 60px;
}
.livestudio-reservator .livestudio-buttons .btn {
	width: 100%;
}
.livestudio-reservator .livestudio-buttons .btn ~ .btn {
	margin-top: 10px;
}

.livestudio-time-selector {
	position: relative;
	border-top: 1px solid #ebebeb;
	padding: 20px 20px;
}

.time-selector-list {
}
.time-selector-item:not(:first-child) {
	margin-top: 10px;
}
.time-selector-check {
	position: relative;
}
.time-selector-input {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
}
.time-selector-label {
	display: block;
	width: 100%;
	padding: 20px 20px 30px;
	background-color: #fff;
	cursor: pointer;
	height: 100px;
	transition: background-color 0.4s;
}
.time-selector-label .time {
	display: block;
	font-size: 20px;
	color: #111;
	font-weight: bold;
	margin-bottom: 22px;
}
.time-selector-label .time-status {
	font-size: 13px;
	color: #ff7300;
	font-weight: bold;
}
.time-selector-input:checked ~ .time-selector-label {
	background-color: #ff7300;
	background-image: url(../img/livestudio/icon_timecheck.svg);
	background-repeat: no-repeat;
	background-position: calc(100% - 35px) 50%;
}
.time-selector-input:checked ~ .time-selector-label * {
	color: #fff;
}
.time-selector-input:disabled ~ .time-selector-label * {
	color: #999;
}
.time-selector-input:focus ~ .time-selector-label {
	outline: 2px solid #111;
}

/* 셀렉트 추가 S (20220721 - hib) */
#sme-livestudio .box_select {
	width: 205px;
	position: absolute;
	top: 8px;
	right: 5px;
	z-index: 9;
}
#sme-livestudio .box_select button {
	width: 100%;
	padding: 8px 12px;
	text-align: left;
	background: #999999;
	color: #fff;
	border-radius: 5px;
	position: relative;
	top: 3px;
}
#sme-livestudio .box_select button::after {
	display: inline-block;
	width: 28px;
	height: 26px;
	content: '';
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	position: absolute;
	top: 3px;
	right: 2px;
}
#sme-livestudio .box_select button.active::after {
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	transform: rotate(180deg);
}
#sme-livestudio .box_select ul {
	display: none;
	width: 100%;
	padding: 12px 10px 8px;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #999999;
}
#sme-livestudio .box_select ul li {
	font-size: 14px;
	font-weight: 900;
}
#sme-livestudio .box_select ul li + li {
	margin-top: 8px;
}
#sme-livestudio .box_select .show_list {
	display: block;
	background: #fff;
}
/* 셀렉트 추가 E (20220721 - hib) */
