.gachi-sm-loader {
	position: absolute;
	border: 3px solid #f8f9fa;
	border-radius: 50%;
	/*border-top: 5px solid #3498db;*/
	border-top: 3px solid #626365;
	width: 25px;
	height: 25px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	/*left: 80%*/
}
.mt1 {
	margin-top: 10px;
}
.mb1 {
	margin-bottom: 10px;
}
.gachi-loader {
	position: absolute;
	/*border: 5px solid #f3f3f3;*/
	border: 5px solid #f8f9fa;
	border-radius: 50%;
	/*border-top: 5px solid #3498db;*/
	border-top: 5px solid #626365;
	width: 40px;
	height: 40px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	left: 80%;
}

.gachi-login-error {
	text-align: center;
	color: #ff0000ff;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.gachi-fade-enter-active,
.gachi-fade-leave-active {
	transition: opacity 0.3s;
}

.gachi-fade-enter,
.gachi-fade-leave-to {
	opacity: 0;
}

.gachi-select-placeholder {
	color: #999;
}

.campaign-content .badge-text font {
	color: #111 !important;
}

#gachi.gachi-main-background {
	padding-top: 0;
	min-width: inherit;
	background-color: #f3f5f7;
}

.btn-white .text font {
	text-decoration: none;
	text-decoration-color: transparent;
}
.btn-white:focus .text font,
.btn-white:hover .text font {
	text-decoration-color: inherit;
}

.title-link[data-anim] font {
	text-decoration: underline;
	text-decoration-color: transparent;
	transition: text-decoration-color 0.2s ease;
}
.title-link[data-anim]:focus font,
.title-link[data-anim]:hover font {
	text-decoration-color: inherit;
}

/*퍼블리셔님한테 물어보자*/
.visually-hidden {
	display: none;
}

.editor-area {
	overflow-y: auto;
}

.comment-item.gachi-comment-list {
	padding-bottom: 20px !important;
}

.page-buttons.gachi-comment {
	padding-top: 10px !important;
	top: -1px;
}

#sme-community-sub .community-sub-header.gachi-editor-header {
	min-height: 350px !important;
}

.gachi-layer-popup-container {
	z-index: 1800 !important;
}
.gachi-layer-popup-container .form-control {
	width: 100%;
}
.gachi-layer-popup-container.gachi-loading-layer {
	z-index: 2500 !important;
}
.gachi-cursor-pointer {
	cursor: pointer;
}

.gachi-cursor-none {
	cursor: auto !important;
}

/* 태그를 위한 css 추가 */
/*.form-celltd.is-column {display:flex;width:560px;}*/
.form-celltd.is-column {
	display: flex;
	width: 560px;
}
.form-celltd.is-column .form-control,
.form-celltd.is-column .form-select {
	width: 100%;
}
.form-celltd.is-column .form-column {
	width: 35%;
}
.form-celltd.is-column .form-column ~ .form-column {
	margin-left: 10px;
}

/* 추후에 추가되면 삭제 */
.ie #popup-community-contact {
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
}

#popup-mypage-creator-foothold {
}
#popup-mypage-creator-foothold .popup-body {
	flex: auto;
	max-height: 500px;
	padding-right: 100px;
	padding-left: 100px;
	overflow-y: auto;
}
#popup-mypage-creator-foothold .popup-body2 {
	flex: auto;
	max-height: 500px;
	padding-right: 100px;
	padding-left: 30px;
	overflow-y: auto;
}
#popup-mypage-creator-foothold .popup-body form,
#popup-mypage-creator-foothold .popup-body textarea {
	height: 100%;
}
#popup-mypage-creator-foothold .popup-body textarea {
	resize: none;
}
#popup-mypage-creator-foothold .write-editor {
	height: 346px;
	box-shadow: 0 0 0 1px #ebebeb inset;
}
#popup-mypage-creator-foothold .profile-image-wrapper .profile-image {
	width: 120px;
	height: 120px;
	border-radius: 2px;
	margin: auto;
}
#popup-mypage-creator-foothold .profile-image-wrapper .profile-image img {
	width: 120px;
	height: 120px;
}

.ie #popup-mypage-creator {
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
}
.ie #popup-livestudio-resve .popup-body {
	flex: auto;
	max-height: 450px;
	padding-right: 100px;
	padding-left: 100px;
	overflow-y: auto;
}

pre.gachi-pre {
	/*width: 660px;*/
	width: 100%;
	white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
	white-space: -o-pre-wrap; /* Opera */
	white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
	word-wrap: break-word; /* IE 5.5+ */
	font-family: 'Malgun Gothic', '맑은 고딕', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', 'Apple SD Gothic Neo', 'Arial', 'Dotum', '돋움', sans-serif;
}

.gachi-up-arrow-icon {
	width: 10px;
	height: 6px;
	background-image: url(~@/assets/img/livestudio/description_arrow.svg);
	transform: rotate(-180deg);
	margin-left: 10px;
}

.livestudio-description-morebutton .btn-more:hover .gachi-up-arrow-icon {
	background-image: url(~@/assets/img/livestudio/description_arrow_active.svg);
	transform: rotate(180deg);
}

.calendar-date-disabled {
	cursor: auto;
}

.gachi-header .gachi-auth {
	color: #fff;
}

.gachi-header.is-active .gachi-auth {
	color: #111;
}

.gachi-header[data-header='dark'] .gachi-auth {
	color: #111;
}

.gachi-header[data-header='sticky'] .gachi-auth {
	color: #111;
}

.gachi-unread-text {
	color: #111 !important;
}

.gachi-read-text {
	color: #999 !important;
}

#popup-mypage-creator {
	overflow-y: auto;
}

.gachi-mobile-popup {
	overflow-y: auto;
}

.gachi-mobile-popup .popup-header {
	position: inherit;
}

.gachi-login .gachi-footer .footer-family-list {
	display: none !important;
}
.gachi-login .gachi-footer .footer-row {
	background-color: transparent !important;
}

#sme-noticeinfo .page-header {
	padding: 55px 0 5px;
}
#sme-noticeinfo .tag-search {
	width: 800px;
	margin: 25px auto 20px;
}
#sme-noticeinfo .tag-search .form-control {
	font-size: 18px;
	padding-left: 65px;
	background: url(../img/connect/tag_search_icon.svg) no-repeat 20px 50%;
}
#sme-noticeinfo .tag-search .form-control:focus {
	box-shadow: 0 0 0 2px #015aab inset;
}

.form-row .form-cellth.is-required .text:after {
	background-color: #ff0000 !important;
}

.gachi-datepicker-width {
	width: 100% !important;
	max-width: 560px;
}

.layer-popup.layer-md-popup {
	max-width: 800px !important;
}
.layer-popup.layer-md-popup .popup-body {
	padding: 0 40px !important;
	min-height: 400px;
	max-height: 450px;
	overflow-y: auto;
}
.layer-popup-alert .popup-body.gachi-popup-body {
	height: inherit;
	margin-bottom: 10px;
}

/*.btn {*/
/*    border-radius: 10px !important;*/
/*}*/

.gachi-popup-md-body {
	padding: 0 100px !important;
}

.gachi-modal-loader {
	position: absolute;
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #aaa;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

#popup-survey-preview {
}

#popup-survey-preview .btn-popup-close {
	right: 30px;
	top: 30px;
}
#popup-survey-preview .layer-popup {
	width: 1170px;
}
#popup-survey-preview .popup-body {
	padding: 30px 40px 40px;
	text-align: left;
}
#popup-survey-preview .popup-body.gachi-mobile-view {
	padding: 30px 20px 20px;
	text-align: left;
}
#popup-survey-preview .preview-header {
	margin-bottom: 50px;
}
#popup-survey-preview .preview-header .btn-close {
	position: absolute;
	left: 20px;
	top: 23px;
}
#popup-survey-preview .preview-header .icon-close {
	width: 30px;
	height: 30px;
	background-image: url(../img/common/icon_popup_close.svg);
}

#popup-survey-preview .preview-header .header-title {
	font-weight: 800;
	font-size: 25px;
}
#popup-survey-preview .preview-header .header-subtext {
	font-size: 16px;
	color: #999;
	line-height: 22px;
	padding-top: 20px;
}
/*#popup-survey-preview .preview-body {max-height:60vh;overflow-y:auto}*/

#popup-survey-preview .preview-body {
	max-height: 65vh;
	overflow-y: auto;
}
#popup-survey-preview .gachi-mobile-view .preview-body {
	max-height: 70vh;
	overflow-y: auto;
}

#popup-survey-preview .survey-item ~ .survey-item {
	margin-top: 40px;
}
#popup-survey-preview .survey-option-title {
	position: relative;
	padding-left: 35px;
	font-size: 20px;
	font-weight: 800;
	margin-bottom: 30px;
}
#popup-survey-preview .survey-option-title .order {
	position: absolute;
	top: -1px;
	left: 0;
	display: inline-flex;
	width: 22px;
	height: 22px;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 700;
	background-color: #121212;
	margin-right: 15px;
	font-size: 16px;
}
#popup-survey-preview .survey-option-list {
	position: relative;
}
#popup-survey-preview .survey-option-item {
	position: relative;
	font-size: 20px;
	color: #555;
	padding-left: 32px;
}
#popup-survey-preview .survey-option-item ~ .survey-option-item {
	margin-top: 22px;
}
#popup-survey-preview .survey-option-item:before {
	content: attr(data-order);
}

#popup-survey-preview .rating-wrapper {
	padding-left: 32px;
}
#popup-survey-preview .star {
	width: 24px;
	height: 24px;
	background-size: 24px;
	cursor: pointer;
}
#popup-survey-preview .gachi-survey-buttons {
	padding-bottom: 0;
	padding-top: 10px;
}

#popup-mypage-profile .popup-body.is-pc {
	flex: auto;
	max-height: 500px;
	padding-right: 100px;
	padding-left: 100px;
	overflow-y: auto;
}

.gachi-active-border {
	border: 2px #921919 dashed;
	padding: 5px;
}

#sme-signup .signup-auth .btn-verify .icon-check {
	position: absolute;
	top: 30px;
	left: 35px;
	width: 24px;
	height: 20px;
	background-image: url(../img/common/icon_form_checkbox_active.svg);
}

.gachi-auth-table {
	border: solid 1px #cbcbcb;
	border-collapse: collapse;
}
.gachi-auth-table th {
	border-left: 1px solid #cbcbcb;
}
.gachi-auth-table tr {
	border: solid 1px #cbcbcb;
}
.gachi-auth-table td {
	border-left: 1px solid #cbcbcb;
}

.gachi-auth-logout {
	font-size: 15px;
}

.swiper-container-vertical > .swiper-wrapper {
	top: -10px;
}

/* table responsive */
.table-responsive {
	overflow-y: auto;
	width: 100%;
	margin-bottom: 15px;
	padding-bottom: 5px;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	background: -webkit-radial-gradient(0% 50%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -webkit-radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 100% 0;
	background: radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 100% 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 10px 100%;
	&.is-overflow-none {
		overflow-y: unset;
	}
	@include mobile {
		max-height: 330px;
	}

	> .table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		background: -webkit-linear-gradient(left, white 30%, rgba(255, 255, 255, 0)), -webkit-linear-gradient(right, white 30%, rgba(255, 255, 255, 0)) 100% 0;
		background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to left, white 30%, rgba(255, 255, 255, 0)) 100% 0;
		background-size: 50px 100%;
		background-repeat: no-repeat;
		max-width: none;
		margin-bottom: 0;
		@include mobile {
			width: auto;
			max-width: 1300px;
			min-width: 1100px;
			&.is-short {
				max-width: 1000px;
				min-width: 900px;
				thead th,
				tbody td {
					padding: 10px;
				}
			}
		}
		> thead > tr > th {
			position: sticky;
			top: 0;
			outline: 1px solid #bdbdbd;
		}
	}

	&::-webkit-scrollbar {
		appearance: none;
		width: 14px;
		height: 14px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 3px solid #fff;
		background-color: rgba(0, 0, 0, 0.3);
	}
}

.table {
	&.is-bordered {
		th,
		td {
			height: auto;
			padding: 12px;
			border: 1px solid #bdbdbd;
			text-align: center;
		}
		thead {
			th,
			td {
				background-color: #fefaf3;
				border-bottom: none;
				font-size: 14px;
				font-weight: 700;
			}
		}
		tbody {
			th,
			td {
				background-color: #fff;
				font-size: 16px;
				font-weight: 400;
				@include mobile {
					font-size: 14px;
				}
			}
		}
	}
	&.is-styled {
		border-radius: 15px;
		overflow: hidden;
		box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
		th,
		td {
			height: auto;
			padding: 12px;
			text-align: center;
		}
		thead {
			th,
			td {
				position: relative;
				border: none;
				background-color: #645f5b;
				color: #fff;
				font-size: 14px;
				font-weight: 700;
				&:not(:first-child) {
					&::before {
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						width: 1px;
						height: 18px;
						border-left: 1px solid #bbb;
						transform: translateY(-50%);
					}
				}
			}
		}
		.th {
			background-color: #645f5b;
			color: #fff;
			font-size: 14px;
			font-weight: 700;
		}
		tbody {
			th,
			td {
				background-color: #fff;
				font-size: 14px;
				font-weight: 400;
				@include mobile {
					font-size: 13px;
				}
				word-break: break-all;
				.form-checkbox label,
				.form-radio label {
					font-size: 14px;
				}
				.input {
					&.is-small {
						height: 30px;
						padding: 0 0.5em;
						border: 1px solid #bdbdbd;
						background-position: calc(100% - 12px) 50%;
						border-radius: 5px;
						font-size: 14px;
						font-weight: 400;
						color: #424242;
					}
					&.width-full {
						width: 100%;
					}
				}
			}
			tr:not(:last-child) {
				th,
				td {
					border-bottom: 1px solid #eee;
				}
			}
		}
	}
}
.dot-list {
	margin: 0.5em 0;
	li {
		position: relative;
		margin: 0.3em 0;
		padding-left: 0.5em;
		line-height: 1.3;
		&::before {
			content: '\00b7';
			font-size: 1.2em;
			font-weight: 700;
			display: inline-block;
			position: absolute;
			width: 0.5em;
			top: -0.1em;
			left: 0;
		}
	}
}

.table-responsive > .table.is-styled {
	> thead > tr {
		> th {
			outline: none;
		}
	}
}
