html {
	&.__fullpage {
		overflow-y: hidden;
		#gachi {
			.fullpage-container {
				overflow: hidden;
			}
		}
		&.__mobile {
			overflow-y: auto;
		}
		@include tablet {
			overflow-y: auto;
		}
		.sns-quick__one {
			display: none;
			@include tablet {
				display: flex;
			}
		}
		.fullpage-btn-top {
			display: flex;
			@include tablet {
				display: none;
			}
		}
		body {
			min-width: inherit;
		}
	}
}
.pointer {
	cursor: pointer;
}
#gachi {
	padding-top: 80px;
	.fullpage-container {
		overflow: initial;
	}
	@include tablet {
		padding-top: 60px;
		// min-width: inherit !important;
		// padding-bottom: 0;
		// height: auto;
		// min-height: auto;
	}
}
.gachi-header {
	height: 80px;
	min-height: 80px;
	position: fixed;
	// background-color: rgba(0, 0, 0, 0.4);
	// overflow-x: auto;
	// overflow-y: hidden;
	@include scrollbar('', 'h');

	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	@include tablet {
		&[data-header='sticky'] {
			position: fixed;
		}
		height: 60px;
		min-height: 60px;
		max-height: 60px;
	}
	.header-logo {
		.logo-gachi {
			background-image: url(~@/assets/img/renew/logo.svg);
		}
		.logo-onfan {
			background-image: url(~@/assets/img/common/onfan.svg);
		}
		&-link {
			li {
				&:not(:first-child) {
					&::before {
						border-color: rgba(150, 150, 150, 0.5);
					}
				}
			}
			&__item {
				color: rgba(150, 150, 150, 0.7);
				&.is-active {
					color: #645f5b;
				}
				&:hover,
				&:focus {
					color: #645f5b;
					text-decoration: underline;
				}
			}
		}
	}
	// .icon-header {
	// 	&-search {
	// 		background-image: url(~@/assets/img/renew/icon_header_search.svg);
	// 	}
	// 	&-news {
	// 		background-image: url(~@/assets/img/renew/icon_header_news.svg);
	// 	}
	// 	&-mypage {
	// 		background-image: url(~@/assets/img/renew/icon_header_user.svg);
	// 	}
	// 	&-logout {
	// 		background-image: url(~@/assets/img/renew/icon_header_logout.svg);
	// 	}
	// 	&-join {
	// 		background-image: url(~@/assets/img/renew/icon_header_join.svg);
	// 	}
	// 	&-login {
	// 		background-image: url(~@/assets/img/renew/icon_header_login.svg);
	// 	}
	// }

	// &.is-active,
	// &[data-header='sticky'] {
	// 	position: fixed;
	// 	height: 80px;
	// 	max-height: 80px;
	// 	background-color: rgba(255, 255, 255, 1);
	// 	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	// 	@include tablet {
	// 		height: 60px;
	// 		min-height: 60px;
	// 		max-height: 60px;
	// 	}
	// 	.header-logo {
	// 		.logo-gachi {
	// 			background-image: url(~@/assets/img/renew/logo.svg);
	// 		}
	// 		&-link {
	// 			li {
	// 				&:not(:first-child) {
	// 					&::before {
	// 						border-color: rgba(150, 150, 150, 0.5);
	// 					}
	// 				}
	// 			}
	// 			&__item {
	// 				color: rgba(150, 150, 150, 0.7);
	// 				&.is-active {
	// 					color: #645f5b;
	// 				}
	// 				&:hover,
	// 				&:focus {
	// 					color: #645f5b;
	// 					text-decoration: underline;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	.icon-header {
	// 		&-search {
	// 			background-image: url(~@/assets/img/renew/icon_header_search.svg);
	// 		}
	// 		&-news {
	// 			background-image: url(~@/assets/img/renew/icon_header_news.svg);
	// 		}
	// 		&-mypage {
	// 			background-image: url(~@/assets/img/renew/icon_header_user.svg);
	// 		}
	// 		&-logout {
	// 			background-image: url(~@/assets/img/renew/icon_header_logout.svg);
	// 		}
	// 		&-join {
	// 			background-image: url(~@/assets/img/renew/icon_header_join.svg);
	// 		}
	// 		&-login {
	// 			background-image: url(~@/assets/img/renew/icon_header_login.svg);
	// 		}
	// 	}
	// }

	.header-component {
		height: 100%;
		@include tablet {
			padding: 0 25px;
		}
		@include mobile {
			padding: 0 15px;
		}
		&.header-component-sub {
			@include until(345px) {
				padding: 0 15px;
			}
		}
		.header-menu {
			@include until(820px) {
				display: none;
			}
		}
	}
	.header-gnb {
		height: 100%;
		// @include tablet {
		// 	height: 60px;
		// 	min-height: 60px;
		// 	max-height: 60px;
		// }
	}
	.header-logo {
		display: flex;
		flex: 0 0 120px;
		@include tablet {
			flex: 0 0 95px;
		}
		.logo-gachi {
			width: 159px;
			height: 36px;
			@include icon;
			// background-image: url(~@/assets/img/renew/logo_w.svg);
			&::after {
				display: none;
			}
			@include tablet {
				width: 99px;
				height: 23px;
				background-size: contain;
				// background-image: url(~@/assets/img/renew/logo_w.svg);
			}
		}
		.logo-onfan {
			width: 120px;
			height: 45px;
			font-size: 0;
			@include icon;
			// background-image: url(~@/assets/img/renew/logo_w.svg);
			&::after {
				display: none;
			}
			@include tablet {
				width: 91px;
				height: 35px;
				background-size: contain;
				// background-image: url(~@/assets/img/renew/logo_w.svg);
			}
		}
		&-link {
			display: flex;
			margin-top: 0.5em;
			margin-left: 10px;
			align-items: center;
			justify-content: flex-end;
			@include tablet {
				margin-top: 0.3em;
			}
			@include mobile {
				margin-top: 0.5em;
			}
			li {
				&:not(:first-child) {
					&::before {
						content: '';
						display: inline-block;
						width: 1px;
						height: 12px;
						margin: 0 6px 0 8px;
						// border-left: 1px solid rgba(255, 255, 255, 0.5);
						border-left: 1px solid rgba(150, 150, 150, 0.5);
						@include tablet {
							margin: 0 5px 0 8px;
						}
					}
				}
			}
			&__item {
				display: inline-flex;
				font-size: 14px;
				font-weight: 400;
				// color: rgba(255, 255, 255, 0.7);
				@include tablet {
					font-size: 13px;
				}
				@include transition(all, 0.5s);
				&.is-active {
					// color: #fff;
					font-weight: 700;
				}
				&:hover,
				&:focus {
					// color: #fff;
					text-decoration: underline;
				}
			}
		}
	}
	.header-menu-text {
		position: relative;
		display: inline-block;
		font-size: 19px;
		white-space: nowrap;
		@include until(990px) {
			font-size: 16px;
		}
	}
	.header-util-item {
		display: flex;
		align-items: center;
		justify-content: center;
		.icon {
			display: flex;
			margin: 0 auto;
			align-items: center;
			justify-content: center;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.header-util-aside {
		// @include from(1327px) {
		@include from(820px) {
			display: none;
		}
		display: block;
		// @include tablet {
		// }
	}
	.header-util-list {
		gap: 20px;
		@include tablet {
			margin-left: 0;
			gap: 10px;
		}
		@include mobile {
			gap: 6px;
		}
		@include until(360px) {
			gap: 2px;
		}
	}
	.header-util-link {
		display: block;
		text-align: center;
		.icon {
			svg {
				width: 100%;
				height: 100%;
			}
		}

		@include tablet {
			display: inline-flex;
			width: auto;
			align-items: center;
			justify-content: center;
		}
		.icon-util-menu {
			width: 25px;
			height: 25px;
			// background-image: url(~@/assets/img/common/icon_menu.svg);
			@include tablet {
				width: 23px;
				background-position: 50% 50%;
			}
		}
	}
	.header-util-item {
		&:not(:first-child) {
			margin: 0;
		}
	}
	.header-util-text {
		display: block;
		margin-top: 5px;
		text-align: center;
		font-size: 12px;
		line-height: 1.2;
		color: #645f5b;
		white-space: nowrap;
		@include tablet {
			display: none;
		}
	}
	.header-menu {
		&-list {
			// width: 80%;
			width: 70%;
			max-width: 560px;
			// max-width: 800px;
			gap: 12px;
			justify-content: space-between;
			// @include until(990px) {
			// 	// width: 90%;
			// }
		}
	}
	.header-menu-item {
		// .header-sub {
		// 	display: none !important;
		// }
		&:not(:first-child) {
			.header-menu-link {
				margin: 0;
			}
		}
		&.is-active {
			.header-menu-link {
				&:after {
					width: 100%;
				}
			}
		}
		.header-menu-link {
			height: 80px;
			@include tablet {
				height: 60px;
			}
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				display: block;
				width: 0;
				height: 3px;
				background-color: #ff7300;
				transform: translateX(-50%);
				@include transition(all, 0.3s);
			}
			&:hover,
			&:focus {
				&:after {
					width: 100%;
				}
			}
		}
	}
	.header-sub-overlay {
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		background-color: rgba(0, 0, 0, 0.6);
	}
	.header-util {
		.header-market-link {
			@include mobile {
				display: none;
			}
		}
	}
	@include tablet {
		height: 60px;
		min-height: 60px;
		max-height: 60px;
		&[data-header='sticky'] {
			.header-logo {
				.logo-gachi {
					background-image: url(~@/assets/img/renew/logo.svg);
				}
			}

			.header-logo-link {
				li {
					&:not(:first-child) {
						&::before {
							border-color: rgba(150, 150, 150, 0.7);
						}
					}
				}
				&__item {
					color: rgba(150, 150, 150, 0.7);
					&.is-active {
						color: #645f5b;
					}
					&:hover,
					&:focus {
						color: #645f5b;
						text-decoration: underline;
					}
				}
			}
			.icon-header {
				&-search {
					background-image: url(~@/assets/img/renew/icon_header_search.svg);
				}
				&-news {
					background-image: url(~@/assets/img/renew/icon_header_news.svg);
				}
				&-mypage {
					background-image: url(~@/assets/img/renew/icon_header_user.svg);
				}
				&-logout {
					background-image: url(~@/assets/img/renew/icon_header_logout.svg);
				}
				&-join {
					background-image: url(~@/assets/img/renew/icon_header_join.svg);
				}
				&-login {
					background-image: url(~@/assets/img/renew/icon_header_login.svg);
				}
			}
			// .icon-util-menu {
			// 	background-image: url(~@/assets/img/renew/icon_header_menu.svg);
			// }
		}
	}
}

.gachi-aside {
	display: flex;
	position: fixed;
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	left: -150%;
	top: 0;
	z-index: -1;
	opacity: 0;
	background-color: rgba(255, 255, 255, 1);
	// backdrop-filter: blur(10px);
	flex-direction: column;
	box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
	@include transition(all, 0.3s);
	&.is-active {
		z-index: 1001;
		left: 0;
		opacity: 1;
	}
	.aside-header {
		display: flex;
		width: inherit;
		margin: 0;
		padding: 0 25px;
		background-color: #f4f4f4;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		.logo-gachi {
			display: block;
			// width: 87px;
			// height: 20px;
			// background: url(../img/common/logo_white.svg);
			// background-image: url(~@/assets/img/renew/logo.svg);
			width: 91px;
			height: 35px;
			background-image: url(~@/assets/img/common/onfan.svg);
			background-repeat: no-repeat;
			background-size: contain;
			color: transparent;
		}
		.btn-aside-close {
			display: flex;
			margin-right: -6px;
			align-items: center;
			justify-content: center;
		}
		.icon-close {
			display: block;
			position: relative;
			width: 23px;
			height: 23px;
			font-size: 0;
			// &::before,
			// &::after {
			// 	position: absolute;
			// 	content: '';
			// 	display: block;
			// 	width: 25px;
			// 	height: 1px;
			// 	top: 50%;
			// 	left: 50%;
			// 	background-color: #424242;
			// }
			// &::before {
			// 	transform: translate(-50%, -50%) rotate(-45deg);
			// }
			// &::after {
			// 	transform: translate(-50%, -50%) rotate(45deg);
			// }
		}
	}
	.aside-body {
		width: inherit;
		padding: 40px 25px;
		flex: 1 1 auto;
		overflow-y: auto;
	}
	.aside-nav {
		.nav-list {
		}
		.nav-item {
			> a {
				position: relative;
				display: flex;
				align-items: center;
			}
			~ .nav-item {
				margin-top: 40px;
			}
			&.has-child {
				> a {
					&:after {
						position: absolute;
						content: '';
						width: 12px;
						height: 8px;
						right: 0;
						background: url(~@/assets/img/common/icon_aside_arrow.svg);
						transform: rotate(0);
						@include transition(transform, 0.3s);
					}
				}
			}
			&.is-active {
				.nav-text {
					text-decoration: none;
				}
				&.has-child {
					> a {
						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		.nav-link {
			display: block;
		}
		.nav-text {
			position: relative;
			font-size: 21px;
			line-height: 1.2;
			font-weight: bold;
			color: #424242;
		}

		.icon-link {
			width: 24px;
			height: 24px;
			background-image: url(~@/assets/img/common/icon_aside_link.svg);
		}

		.nav-submenu-list {
			overflow: hidden;
			transition: all 0.4s;
			opacity: 0;
		}
		.nav-submenu-item {
			padding-top: 20px;
			&.is-active {
				.nav-submenu-text {
					text-decoration: underline;
				}
			}
		}
		.nav-submenu-link {
		}
		.nav-submenu-text {
			margin-left: 1em;
			font-size: 16px;
			line-height: 18px;
			color: #9a918b;
			letter-spacing: 0;
		}

		.is-active .nav-submenu-list {
			max-height: 400px;
			opacity: 1;
		}
	}

	.aside-footer {
		background-color: #f9f9f9;
		width: inherit;
		padding: 20px 25px;
		flex: 0 0 80px;
		.footer-links {
			display: flex;
		}
		.link-item {
			~ .link-item {
				position: relative;
				margin-left: 21px;
				&:before {
					content: '';
					width: 1px;
					height: 10px;
					background-color: #ccc;
					position: absolute;
					left: -10px;
					top: 2px;
				}
			}
		}
		.link {
		}
		.link-text {
			font-size: 12px;
			font-weight: bold;
			color: #666;
		}
		.footer-copyright {
			padding-top: 15px;
			font-size: 13px;
			color: #999;
		}
	}
}

// header
.icon-header {
	&-search {
		@include icon;
		width: 26px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_header_search_w.svg);
		@include tablet {
			width: 23px;
			height: 24px;
		}
	}
	&-news {
		@include icon;
		width: 26px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_header_news_w.svg);
		@include tablet {
			width: 24px;
			height: 25px;
		}
	}
	&-mypage {
		@include icon;
		width: 24px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_header_user_w.svg);
		@include tablet {
			width: 21px;
			height: 25px;
		}
	}
	&-logout {
		@include icon;
		width: 21px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_header_logout_w.svg);
		@include tablet {
			width: 20px;
			height: 25px;
		}
	}
	&-join {
		@include icon;
		width: 36px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_header_join_w.svg);
		@include tablet {
			width: 30px;
			height: 25px;
		}
	}
	&-login {
		@include icon;
		width: 21px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_header_login_w.svg);
		@include tablet {
			width: 20px;
			height: 25px;
		}
	}
	&-fanfan {
		@include icon;
		width: 37px;
		height: 27px;
		// background-image: url(~@/assets/img/renew/icon_fanfan_logo.svg);
		@include tablet {
			width: 32px;
			height: 25px;
		}
	}
	&-onfan {
		@include icon;
		width: 20px;
		height: 28px;
		margin-top: -1px;
		@include tablet {
			width: 20px;
			height: 25px;
		}
	}
	&-dhfesta {
		@include icon;
		width: 61px;
		height: 28px;
		@include tablet {
			width: 51px;
			height: 24px;
		}
	}
}
.fullpage {
	display: flex;
	position: relative;
	width: 100%;
	// height: 100vh;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	&-wp {
		@at-root .__height-auto & {
			transform: none !important;
		}
	}
	&.page {
		display: flex;
		@at-root .__fullpage & {
			.fp-content {
				max-height: 635px;
			}
		}
		@at-root .__height-auto & {
			height: auto !important;
			.fp-content {
				max-height: initial;
			}
		}
		overflow: initial;
	}
	@include tablet {
		height: auto;
		padding-top: 100px;
		padding-bottom: 100px;
	}
	@include mobile {
		height: auto;
		padding-top: 60px;
		padding-bottom: 75px;
	}
	.swiper-buttons-container {
		@include mobile {
			.swiper-button-prev,
			.swiper-button-next {
				transform: scale(0.5);
			}
		}
	}
	&-footer {
		justify-content: flex-end;
		@include tablet {
			padding-bottom: 0;
		}
		.fp-content {
			display: flex;
			height: 250px;
			padding-bottom: 30px;
			flex: 1 1;
			align-items: center;
			@include tablet {
				height: auto;
				padding-bottom: 60px;
			}
		}
		.gachi-footer {
			width: 100%;
			overflow: hidden;
			flex: 0 0 260px;
			@include tablet {
				flex: 0 0 280px;
			}
		}
		.fp-content {
			.display-flex {
				width: 100%;
				gap: 40px;
				align-items: flex-start;
			}
		}
		.simple-notice-list {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid #757575;
		}
		.display-flex {
			@include mobile {
				flex-wrap: wrap;
			}
			.fp-footer {
				&-article {
					&:first-child {
						flex: 1 1;
						width: calc(100% - 350px);
						@include mobile {
							width: 100%;
						}
					}
					&:last-child {
						width: 310px;
						flex: 0 0 310px;
						@include mobile {
							flex: none;
						}
					}
				}
			}
		}
		.fp-footer {
			&-article {
				&__title {
					font-size: 21px;
					font-weight: 700;
					a {
						display: block;
						position: relative;
						&::after {
							content: '';
							position: absolute;
							display: block;
							top: 50%;
							right: 0;
							width: 8.5px;
							height: 8.5px;
							border-top: 2px solid #757575;
							border-right: 2px solid #757575;
							transform: translateY(-50%) rotate(45deg);
						}
					}
				}
			}

			&-sns {
				display: flex;
				margin-top: 10px;
				align-items: center;
				gap: 15px;
				@include mobile {
					gap: 10px;
				}
			}
		}
		.icon-footer-sns {
			@include icon;
			width: 50px;
			height: 50px;
		}
		.icon-fp {
			&-blog {
				background-image: url(~@/assets/img/renew/icon_fp_blog.svg);
			}
			&-insta {
				background-image: url(~@/assets/img/renew/icon_fp_insta.svg);
			}
			&-youtube {
				background-image: url(~@/assets/img/renew/icon_fp_youtube.svg);
			}
			&-facebook {
				background-image: url(~@/assets/img/renew/icon_fp_facebook.svg);
			}
			&-kakao {
				background-image: url(~@/assets/img/renew/icon_fp_kakao.svg);
			}
		}
	}
}
.fp-fade-down {
	opacity: 0;
	transform: translateY(-100%);
	@include transition(all, 2s);
	@at-root .__fullpage-partner & {
		opacity: 1;
		transform: translateY(0);
		transition-delay: 0.3s;
	}
	@at-root .__fullpage-partner--visible & {
		opacity: 1;
		transform: translateY(0);
		transition-delay: 0;
	}
}
.fp-content {
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 635px;
	margin-top: 50px;
	// @at-root .fp-index & {
	// 	margin-top: 0;
	// }
	z-index: 1;
	@include tablet {
		// max-height: none;
		margin-top: 20px;
	}
	&.container {
		display: flex;
		max-width: 1280px;
		flex-direction: column;
		justify-content: center;
		@include mobile {
			// display: block;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.display-flex {
		display: flex;
		align-items: center;
	}
	.fp-title {
		margin-bottom: 10px;
		font-size: 26px;
		font-weight: 700;
		text-align: center;
		color: #ff7300;
		line-height: 1.1;
		@include word-wrap;
		@include mobile {
			font-size: 21px;
		}
		&-wrap {
			position: relative;
			max-width: 1280px;
			margin: 0 auto;
			.fp-title-right {
				display: flex;
				position: absolute;
				top: 50%;
				right: 10px;
				gap: 15px;
				transform: translateY(-50%);
				align-items: center;
				justify-content: flex-end;
				.boradcast-button {
					display: flex;
					align-items: center;
					gap: 7px;
					span {
						font-size: 14px;
					}
				}
			}
		}
	}
	.fp-subtitle {
		margin-bottom: 30px;
		padding: 0 30px;
		font-size: 40px;
		font-weight: 700;
		text-align: center;
		color: #000;
		line-height: 1.15;
		@at-root .__fullpage-partner & {
			margin-bottom: 10px;
		}
		@at-root .__fullpage-partner--visible & {
			margin-bottom: 10px;
		}
		@include word-wrap;
		// + div {
		// 	display: flex;
		// }
		@include mobile {
			font-size: 27px;
			.hidden-mobile {
				display: none;
			}
		}
		.visible-mobile {
			display: none;
			margin-top: 10px;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #7a7a7a;
			@include mobile {
				display: block;
			}
		}
	}
	.fp-desc {
		margin-bottom: 50px;
		font-size: 23px;
		text-align: center;
		font-weight: 400;
		line-height: 1.3;
		@include word-wrap;
		@include mobile {
			margin-bottom: 40px;
			font-size: 14px;
		}
	}
}

// 풀페이지 탭 컨트롤
%fp-tablist {
	display: flex;
	position: relative;
	width: 130px;
	margin-right: -10px;
	padding-top: 10px;
	padding-bottom: 10px;
	flex: 0 0 130px;
	flex-direction: column;
	gap: 5px;
	@include mobile {
		// display: none;
	}
}
%fp-tab {
	display: flex;
	width: 100%;
	height: 100px;
	padding: 15px;
	border-radius: 15px 0px 0px 15px;
	background: #f4f4f4;
	color: #645f5b;
	text-align: left;
	font-size: 19px;
	font-weight: 700;
	align-items: center;
	justify-content: flex-start;
	line-height: 1.3;
	@include transition(background, 0.3s);
	@include word-wrap;
	&:hover,
	&:focus {
		background-color: #eaeaea;
	}
}
%fp-tab-active {
	background: #645f5b;
	color: #fff;
	&:hover,
	&:focus {
		background-color: #444;
	}
}
%fp-tab-panels {
	overflow: hidden;
	width: 100%;
	flex: 1 1;
}
%fp-tab-panel {
	position: relative;
	overflow: hidden;
	width: inherit;
	height: 500px;
	padding: 30px;
	z-index: 1;
	border: 6px solid #645f5b;
	border-radius: 30px;
	background-color: #fff;
	@include tablet {
		height: auto;
	}
	@include mobile {
		padding: 15px;
	}
}
.fp-content {
	[role='tablist'] {
		@extend %fp-tablist;
		[role='tab'] {
			@extend %fp-tab;
			@include desktop {
				br {
					display: none;
				}
			}
		}
		[aria-selected='true'] {
			@extend %fp-tab-active;
		}
		+ div {
			@extend %fp-tab-panels;
		}
	}
	[role='tabpanel'] {
		@extend %fp-tab-panel;
	}

	.page-nav.style-orange {
		height: 59px;
		margin-bottom: 0;
		background-color: #f5f5f5;
		.container {
			overflow-x: auto;
			.nav-list {
				min-width: initial;
			}
		}
		.nav-item {
			.button {
				display: inline-flex;
				width: 100%;
				height: 60px;
				min-width: 50px;
				opacity: 1;
				height: 60px;
				padding: 0px;
				border-color: transparent;
				background-color: transparent;
				color: #757575;
				text-indent: inherit;
				align-items: center;
				font-size: 19px;
				white-space: nowrap;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				@include transition(all, 0.3s);
				.nav-text {
					font-size: 21px;
					@include tablet {
						font-size: 19px;
					}
				}
				&:hover {
					.nav-text {
						color: #ff7300;
					}
				}
			}

			&.is-active {
				.button {
					border-radius: 0;
					.nav-text {
						color: #ff7300;
					}
					&:after {
						content: '';
						display: block;
						position: absolute;
						bottom: 0;
						left: 50%;
						z-index: 1;
						width: 100%;
						height: 3px;
						background-color: #ff7300;
						transform: translateX(-50%);
					}
					&:hover {
						.nav-text {
							color: #ff7300;
						}
					}
				}
			}
		}
	}
}
.fp {
	&-tab-container {
		display: flex;
	}
	&-tablist {
		@extend %fp-tablist;
	}
	&-tab {
		@extend %fp-tab;
		&.is-active {
			@extend %fp-tab-active;
		}
	}
	&-tabpanels {
		@extend %fp-tab-panels;
	}
	&-tabpanel {
		@extend %fp-tab-panel;
	}
}

.fp {
	&-video-control {
		position: absolute;
		right: calc(1% + 50px);
		bottom: 4%;
		z-index: 10;
		.swiper-button-stop,
		.swiper-button-play {
			width: 40px;
			height: 40px;
			background-color: #645f5b;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
			&:after {
				transform: scale(1.2);
			}
		}
	}
	&-index {
		background-color: transparent;
		@include mobile {
			padding-top: 100px;
		}
		&-slogan {
			margin-bottom: clamp(20px, 4vh, 60px);
			color: #fff;
			text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
			text-align: center;
			&__head {
				margin-bottom: 18px;
				font-size: 32px;
				font-weight: 400;
				@include mobile {
					font-size: 16px;
				}
			}
			&__title {
				margin-bottom: 10px;
				font-size: 80px;
				font-weight: 700;
				@include mobile {
					font-size: 44px;
				}
			}
			&__desc {
				font-size: 21px;
				font-weight: 400;
				@include mobile {
					font-size: 13px;
				}
			}
		}
		&-search {
			margin-bottom: 20px;
			position: relative;
			.input {
				width: 100%;
				height: 50px;
				padding: 10px 30px;
				border-radius: 50px;
				border: none;
				background-color: #fff;
				font-weight: 400;
				box-shadow: none;
				@include placeholder {
					color: #bbb;
				}
			}
			&__submit {
				position: absolute;
				display: flex;
				width: 58px;
				height: 50px;
				top: 0;
				right: 10px;
				align-items: center;
				justify-content: center;
			}
		}
		&-keywords {
			display: flex;
			margin-bottom: clamp(10px, 4vh, 60px);
			align-items: center;
			justify-content: center;
			gap: 20px;
			flex-wrap: wrap;
			@include tablet {
				gap: 5px;
			}
			> a {
				display: inline-flex;
				height: 28px;
				padding: 0 20px;
				border-radius: 30px;
				background: rgba(255, 255, 255, 0.5);
				line-height: 1;
				font-size: 14px;
				color: #333;
				white-space: nowrap;
				align-items: center;
				justify-content: center;
				@include transition(background, 0.3s);
				&:hover,
				&:focus {
					background: rgba(255, 255, 255, 1);
				}
			}
		}
		&-links {
			position: relative;
			padding: 25px 6vw;
			border-radius: 15px;
			background: rgba(100, 95, 91, 0.5);
			@include mobile {
				padding: 10px 30px;
			}
			.swiper-slide {
				> a {
					display: flex;
					color: #fff;
					padding: 20px 0;
					font-size: 14px;
					flex-direction: column;
					align-items: center;
					@include mobile {
						transform: scale(0.8);
					}
					&:hover,
					&:focus {
						.icon-fp {
							transform: translate(0, -10%);
							box-shadow: 0 11px 8px rgba(0, 0, 0, 0.2);
						}
					}
					.text {
						display: block;
						margin-top: 10px;
						text-align: center;
						white-space: nowrap;
					}
					.icon-fp {
						box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
						@include transition(all, 0.3s);
					}
				}
			}
			.swiper-buttons-container {
				.swiper-button {
					&-prev,
					&-next {
						@include icon;
						z-index: 1;
						width: 25px;
						height: 50px;
						&.swiper-button-disabled {
							opacity: 0;
						}
						&:after {
							border-color: #fff;
						}
					}
					&-prev {
						left: 25px;
						background-image: url(~@/assets/img/renew/icon_arrow_l.svg);
						@include tablet {
							left: 15px;
						}
						@include mobile {
							left: 5px;
						}
					}
					&-next {
						right: 25px;
						background-image: url(~@/assets/img/renew/icon_arrow_r.svg);
						@include tablet {
							right: 15px;
						}
						@include mobile {
							right: 5px;
						}
					}
				}
			}
		}
	}
	&-festa {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: #000;
		z-index: 1;
		@include transition(background, 1s);
		@include tablet {
			padding-top: 9em;
		}
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 1;
		}
		&.fp-swiper-fade {
			@include from(801px) {
				.fp-title,
				.fp-subtitle {
					opacity: 0;
					@include transition(opacity, 0.8s);
				}
				&::before {
					opacity: 0;
					@include transition(opacity, 0.8s);
				}
				&.__active-fp {
					.fp-title,
					.fp-subtitle {
						opacity: 1;
					}
					&::before {
						opacity: 1;
						transition-delay: 0.1s;
					}
				}
				.swiper-wrapper {
					transition-duration: 10ms !important;
				}
				.swiper-slide {
					opacity: 0;
					transform: translateY(10%);
					@include transition(all, 1.2s);
					&-custom-active {
						opacity: 1;
						transform: translateY(0);
						// transition-delay: 0.8s;
					}
				}
			}
		}
		.fp-subtitle {
			color: #fff;
		}
		.fp-festa-item {
			display: flex;
			position: relative;
			overflow: hidden;
			width: 80%;
			max-width: 1050px;
			margin: 0 auto;
			padding: 25px 0;
			border-radius: 34px;
			background-color: #fff;
			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
			@include mobile {
				width: 100%;
			}
			.button-default {
				&.is-secondary.is-rounded.is-large {
					height: 38px;
					margin: 30px auto 0;
					flex: 0 0 38px;
					@include mobile {
						min-width: 113px;
						height: 30px;
						margin: 20px auto 0;
						font-size: 13px;
					}
				}
			}
			@include mobile {
				height: 330px;
				padding: 0;
				border-radius: 15px;
			}
		}

		.swiper-buttons-container {
			.swiper-button {
				&-prev,
				&-next {
					display: none;
					width: 70px;
					height: 70px;
					border-radius: 70px;
					border: 1px solid #d5d5d5;
					background-color: #fff;
					align-items: center;
					justify-content: center;
					box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
					@include mobile {
						display: flex;
					}
					&::after {
						position: static;
						width: 16px;
						height: 16px;
					}
				}
				&-prev {
					top: 42%;
					left: 5vw;
					@include until(630px) {
						left: 4vw;
					}
					@include until(530px) {
						left: 3vw;
					}
					@include until(450px) {
						left: 1.5vw;
					}
					@include until(360px) {
						left: 1vw;
					}
					&::after {
						margin-left: 7px;
					}
				}
				&-next {
					top: 42%;
					right: 5vw;
					@include until(630px) {
						right: 4vw;
					}
					@include until(530px) {
						right: 3vw;
					}
					@include until(450px) {
						right: 1.5vw;
					}
					@include until(360px) {
						right: 1vw;
					}
					&::after {
						margin-right: 7px;
					}
				}
			}
		}
		.swiper-controls {
			position: static;
			padding-top: 70px;
			transform: none;

			.swiper-button {
				&-stop,
				&-play {
					position: absolute;
					right: 60px;
					bottom: 74px;
					z-index: 10;
					@include mobile {
						right: 20px;
					}
				}
			}
			.swiper-pagination-container {
				width: 100%;
				background: transparent;
				@include mobile {
					display: none;
				}
				.swiper-pagination {
					display: flex;
					width: 100%;
					justify-content: center;
					gap: 5px;
					&-bullet {
						width: 50%;
						max-width: 300px;
						min-width: 130px;
						height: auto;
						padding: 20px 10px 0;
						opacity: 0.5;
						border-top: 4px solid #fff;
						text-indent: initial;
						border-radius: initial;
						background: transparent;
						transform: translateY(0);
						@include transition(all, 0.5s);
						em {
							display: block;
							font-size: 17px;
							font-weight: 400;
							line-height: 1.2;
							@include word-wrap;
						}
						&:hover,
						&:focus,
						&.swiper-pagination-bullet-active {
							border-color: #ff7300;
							opacity: 1;
							transform: translateY(-20px);
							@include transition(all, 0.5s);
						}
					}
				}
			}
		}
		&-content {
			&__img {
				display: flex;
				position: relative;
				width: 50%;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #d9d9d9;
				img {
					position: absolute;
					width: 85%;
					height: 85%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					object-fit: contain;
				}
				@include mobile {
					width: 100%;
					img {
						width: 60%;
						height: 60%;
					}
				}
			}
			&__right {
				width: 50%;
				padding: 45px 67px;
				@include tablet {
					padding: 25px 35px;
					br {
						display: none;
					}
				}
				@include mobile {
					position: absolute;
					width: 100%;
					height: 100%;
					padding: 40px 35px;
					left: 0;
					top: 0;
					bottom: 0;
					z-index: 1;
					background-color: rgba(255, 255, 255, 0.9);
				}
			}
			&__text {
				display: flex;
				height: 260px;
				flex-direction: column;
				justify-content: space-between;
				@include mobile {
					display: block;
					height: auto;
					text-align: center;
				}
			}
			&__title {
				display: block;
				margin-bottom: 0.5em;
				font-size: 27px;
				font-weight: 800;
				line-height: 1.2;
				@include mobile {
					font-size: 19px;
				}
			}
			&__desc {
				display: block;
				margin-bottom: 30px;
				// height: 150px;
				text-align: left;
				font-size: 20px;
				font-weight: 600;
				color: #3b3b3b;
				line-height: 1.5;
				@include word-wrap;
				@include mobile {
					font-size: 15px;
					font-weight: 400;
					line-height: 1.4;
					word-break: break-all;
				}
			}
			&__slogan {
				display: flex;
				position: relative;
				width: 100%;
				height: auto;
				min-height: 80px;
				margin-bottom: 10px;
				padding: 10px 30px;
				// padding: 10px 70px;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				line-height: 1.4;
				color: #000;
				flex: 0 0 80px;
				align-items: center;
				justify-content: center;
				@include word-wrap;
				@include tablet {
					padding: 0 20px;
				}
				@include mobile {
					padding: 0 16px;
					font-size: 15px;
					font-weight: 800;
					min-height: 40px;
				}
				&::before,
				&::after {
					position: absolute;
					content: '';
					display: block;
					top: 0;
					width: 16px;
					height: 100%;
					border: 5px solid #ff7300;
					@include mobile {
						width: 10px;
						border: 6px solid #ff7300;
					}
				}
				&::before {
					left: 0;
					border-right: none;
				}
				&::after {
					right: 0;
					border-left: none;
				}
			}
		}
		&-mobile {
			display: none;
			width: 80%;
			margin: 30px auto 0;
			border-top: 4px solid #fff;
			padding-top: 14px;
			@include transition(border, 0.5s);
			@at-root .swiper-slide-active & {
				border-top: 4px solid #ff7300;
			}
			@include mobile {
				display: block;
				width: 100%;
			}
			&__date,
			&__title {
				display: block;
				line-height: 1.3;
				text-align: center;
				font-size: 17px;
				font-weight: 400;
				color: #fff;
			}
		}
	}
	&-panro {
		// display: block;
		overflow-x: hidden;
		.fp-content {
			margin-top: -50px;
			@include tablet {
				margin-top: 0;
			}
		}
		.broadcast-banner {
			.swiper-controls {
				bottom: -50px;
				.swiper-button-prev {
					left: 5%;
				}
				.swiper-button-next {
					right: 5%;
				}
			}
			&-wrap {
				position: relative;
				margin-top: -4px;
				@include mobile {
					margin-top: 0;
				}
			}
			&-control {
				display: block;
				position: relative;
				width: 100%;
				height: 59px;
				background-color: #f5f5f5;
				margin: -6px auto 0;
				padding: 0 25px;
				gap: 5px;
				min-width: 300px;
				@include mobile {
				}
				.swiper-container {
					max-width: 700px;
					margin: 0 auto;
					padding: 0;
				}
				.swiper-wrapper {
					justify-content: center;
					gap: 36px;
				}
				.swiper-slide {
					width: auto !important;
					padding: 0px 4px;
					.button {
						display: inline-flex;
						width: 100%;
						height: 60px;
						min-width: 50px;
						opacity: 1;
						height: 60px;
						padding: 0px;
						border-color: transparent;
						background-color: transparent;
						color: #757575;
						text-indent: inherit;
						align-items: center;
						font-size: 19px;
						white-space: nowrap;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						@include transition(all, 0.3s);
						&:hover {
							color: #ff7300;
						}
					}

					&-active {
						.button {
							border-radius: 0;
							color: #ff7300;
							&:after {
								content: '';
								display: block;
								position: absolute;
								bottom: 0;
								left: 50%;
								z-index: 1;
								width: 100%;
								height: 3px;
								background-color: #ff7300;
								transform: translateX(-50%);
							}
							&:hover {
								color: #ff7300;
							}
						}
					}
				}
			}
		}
	}
	&-mypage {
		.fp-content.container {
			align-items: center;
			@include mobile {
				text-align: center;
			}
		}
		// .fp-title {
		// 	text-align: left;
		// 	@include mobile {
		// 		text-align: center;
		// 	}
		// }
		// .fp-desc {
		// 	text-align: left;
		// 	@include mobile {
		// 		margin-bottom: 35px;
		// 		text-align: center;
		// 		font-size: 14px;
		// 	}
		// }
		.fp-subtitle {
			margin-bottom: 10px;
		}
		&-list {
			display: flex;
			overflow-x: auto;
			width: 100%;
			max-width: 1050px;
			margin-bottom: 50px;
			gap: 15px;
			justify-content: space-between;
			@include mobile {
				width: calc(100% + 30px);
				margin-left: -15px;
				margin-right: -15px;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 8px;
				gap: 10px;
			}
			> li {
				flex: 0 0 calc(25% - 12px);
			}
		}
		&-item {
			position: relative;
			display: block;
			min-width: 160px;
			max-width: 250px;
			overflow: hidden;
			border-radius: 15px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 50%;
				z-index: 1;
				background: rgb(0, 0, 0);
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			}
			&__text {
				display: block;
				position: absolute;
				color: #fff;
				top: 15px;
				left: 20px;
				z-index: 5;
				font-size: 22px;
				font-weight: 700;
				line-height: 1.2;
				@include mobile {
					font-size: 16px;
				}
				em {
					display: block;
					font-size: 0.8em;
					font-weight: normal;
					vertical-align: bottom;
				}
			}
			&__img {
				display: flex;
				position: relative;
				padding-top: 100%;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1.01);
					@include transition(transform, 0.5s);
				}
			}
			&:hover {
				.fp-mypage-item__img {
					img {
						transform: scale(1.2);
					}
				}
			}
		}
		.button-default {
			&.is-large {
				display: inline-flex;
				height: 56px;
				min-width: inherit;
				padding: 20px;
				font-size: 21px;
				font-weight: 700;
				color: #fff;
				border-radius: 56px;
				width: auto;
				&:hover,
				&:focus {
					color: #424242;
					background-color: #e6e6e6;
				}
			}
		}
	}
	&-partner {
		&-scroll {
			position: relative;
			width: 100%;
			max-width: 1120px;
			margin: 0 auto;
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 50px;
				left: 0;
				bottom: 0;
				z-index: 10;
				background-color: #fff;
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
			}
			&__inner {
				position: relative;
				display: flex;
				height: 400px;
				padding-bottom: 50px;
				// overflow: hidden;
				overflow-x: hidden;
				overflow-y: auto;
				justify-content: center;
				@include mobile {
					height: 500px;
				}
				@include until(430px) {
					height: 0;
					padding-top: 117%;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
			&__img {
				&-handle {
					display: block;
					position: absolute;
					top: 300%;
					left: 50%;
					z-index: 1;
					transform: translateX(-50%);
					transition-delay: 0s;
					@include transition(all, 1.5s);
					@at-root .__fullpage-partner & {
						top: 0;
						transition-delay: 3.5s;
					}
					@at-root .__fullpage-partner--visible & {
						top: 0;
						transition: none;
					}
					@include until(430px) {
						width: 100%;
					}
					img {
						max-width: inherit;
						@include until(430px) {
							width: 100%;
						}
					}
					.pc {
						@include until(1020px) {
							display: none;
						}
					}
					.mo {
						display: none;
						@include until(1020px) {
							display: block;
						}
					}
				}
			}
			&__video {
				display: flex;
				position: absolute;
				height: 400px;
				overflow: hidden;
				opacity: 1;
				bottom: 0;
				left: 50%;
				z-index: 2;
				visibility: visible;
				transform: translateX(-50%);
				align-items: center;
				justify-content: center;
				@include transition(all, 2s);
				@at-root .__fullpage-partner & {
					visibility: hidden;
					opacity: 0;
					transition-delay: 4.5s;
				}
				@at-root .__fullpage-partner--visible & {
					display: none;
					transition: none;
				}
				@include mobile {
					height: 500px;
				}
				@include until(430px) {
					width: 100%;
					height: 0;
					padding-top: 117%;
				}
				video {
					max-width: inherit;
				}
				.pc {
					width: 1000px;
					@include until(1020px) {
						display: none;
					}
				}
				.mo {
					display: none;
					width: 448px;
					@include until(1020px) {
						display: block;
					}
					@include until(430px) {
						width: 100%;
					}
				}
			}
			&__bar {
				position: absolute;
				bottom: -20px;
				left: 50%;
				width: 0;
				height: 6px;
				z-index: 10;
				transform: translateX(-50%);
				@include transition(width, 1s);
				@at-root .__fullpage-partner & {
					width: 100%;
					transition-delay: 4.5s;
				}
				@at-root .__fullpage-partner--visible & {
					width: 100%;
					transition: none;
				}
				&:after {
					display: block;
					position: absolute;
					content: '';
					width: 100%;
					height: 6px;
					top: 50%;
					left: 0;
					border-radius: 6px;
					background-color: #ddd;
					transform: translateY(-50%);
				}
				.slider {
					visibility: hidden;
					opacity: 0;
					@include transition(all, 0.5s);
					@at-root .__fullpage-partner & {
						visibility: visible;
						opacity: 1;
						transition-delay: 6s;
					}
					@at-root .__fullpage-partner--visible & {
						visibility: visible;
						opacity: 1;
						transition: none;
					}
				}
			}
			&__handle {
				position: absolute;
				top: 50%;
				left: 0;
				display: flex;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background-color: #ddd;
				transform: translateY(-50%);
				cursor: pointer;
				z-index: 10;
			}
			.slider {
				position: absolute;
				top: 50%;
				left: 0;
				-webkit-appearance: none;
				overflow: hidden;
				width: 100%;
				height: 6px;
				border-radius: 6px;
				background: transparent;
				outline: none;
				transform: translateY(-50%);
				z-index: 10;
				&::-ms-track {
					width: 100%;
					cursor: pointer;
					background: transparent;
					border-color: transparent;
					color: transparent;
				}
				&::-webkit-slider-runnable-track {
					border-radius: 6px;
					background: #ddd;
				}
				&::-moz-slider-runnable-track {
					border-radius: 6px;
					background: #ddd;
				}
				&::-webkit-slider-thumb {
					appearance: none;
					width: 6px;
					height: 6px;
					border-radius: 6px;
					background-color: #ff7300;
					box-shadow: -100vw 0 0 100vw #ff7300;
					cursor: pointer;
				}
				&::-moz-range-thumb {
					appearance: none;
					width: 6px;
					height: 6px;
					border-radius: 6px;
					background-color: #ff7300;
					box-shadow: -100vw 0 0 100vw #ff7300;
					cursor: pointer;
				}
			}
		}
	}
	&-facility {
		@include tablet {
			padding-bottom: 120px;
		}
		&::before {
			position: absolute;
			content: '';
			display: block;
			width: 100%;
			top: 50%;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #e6e6e6;
		}
	}
	&-tabs-container {
		> div {
			display: flex;
		}
	}
	&-sodam-wrap {
		border-radius: 30px;
		border: 1px solid #e0e0e0;
		background-color: #fff;
		.page-nav.style-orange {
			border-radius: 30px 30px 0 0;
		}
		.sodam-map-wrap {
			position: relative;
			.fp-map-wrap {
				height: 455px;
				@include tablet {
					// height: 800px;
					height: auto;
					flex-wrap: nowrap;
					flex-direction: column;
				}
				.flex-l {
					flex: 0 0 400px;
					@include tablet {
						flex: 0 0 350px;
					}
					@include mobile {
						margin-bottom: 0;
					}
				}
				.flex-r {
					width: 100%;
					flex: 1 1;
				}
				.fp-map {
					overflow: hidden;
					height: 440px;
					margin-top: -20px;
					margin-bottom: -20px;
					@include tablet {
						height: 400px;
					}
					@include mobile {
						margin-top: -40px;
						margin-left: -20px;
						margin-right: -20px;
					}
					&-pin {
						width: 45px;
						height: 61px;
						.icon {
							svg {
								width: 100%;
								height: 100%;
								&:last-child {
									display: none;
								}
							}
						}
						&.is-active {
							.icon {
								svg {
									&:first-child {
										display: none;
									}
									&:last-child {
										display: block;
									}
								}
							}
						}
					}
					&-bg {
						position: absolute;
						width: 340px;
						height: 625px;
						left: 0;
						top: -57px;
						right: 0;
						svg {
							width: 100%;
							height: 100%;
						}
						&::before,
						&::after {
							content: '';
							display: block;
							position: absolute;
							height: 70px;
							width: 100%;
							left: 0;
							z-index: 1;
							background-color: rgba(255, 255, 255, 0.5);
						}
						&::before {
							top: 35px;
							background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
						}
						&::after {
							bottom: 120px;
							background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
						}
					}
				}
			}
		}
	}
	&-map {
		position: relative;
		width: 340px;
		min-width: 300px;
		height: 100%;
		min-height: 420px;
		// background-image: url(~@/assets/img/renew/map.svg);
		background-size: 300px 420px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		@include mobile {
			transform: scale(0.9);
		}
		&-wrap {
			display: flex;
			height: 100%;
			padding: 20px;
			align-items: center;
			@include tablet {
				flex-wrap: wrap;
			}
			@include mobile {
				padding: 15px;
			}
			.flex-l {
				display: flex;
				width: 350px;
				height: 100%;
				flex: 0 0 350px;
				align-items: center;
				justify-content: center;
				@include tablet {
					width: 100%;
					margin-bottom: 30px;
					flex: none;
				}
			}
			.flex-r {
				display: flex;
				height: 100%;
				padding-left: 30px;
				flex: 1 1;
				flex-direction: column;
				@include tablet {
					padding-left: 0;
				}
				.fp-map-content {
					border: 1px solid #bdbdbd;
					border-bottom: none;
					&:first-child {
						border-top-left-radius: 15px;
						border-top-right-radius: 15px;
					}
					&:last-child {
						border-bottom-left-radius: 15px;
						border-bottom-right-radius: 15px;
						border-bottom: 1px solid #bdbdbd;
					}
					// &:not(:last-child) {
					// 	border: 1px solid #bdbdbd;
					// 	border-bottom: none;
					// 	border-radius: 0 0 0 0;
					// }
				}
			}
		}
		&-loc {
			&__seoul {
				top: 20px;
				left: 66px;
			}
			&__gangwon {
				top: 11px;
				left: 191px;
			}
			&__jeonju {
				top: 220px;
				left: 72px;
			}
			&__gwangju {
				top: 310px;
				left: 53px;
			}
			&__daegu {
				top: 235px;
				left: 218px;
			}
			&__busan {
				top: 306px;
				left: 269px;
			}
		}
		&-panel {
			display: none;
			height: 100%;
			&.is-active {
				display: flex;
			}
		}
		&-pin {
			position: absolute;
			width: 38px;
			height: 70px;
			z-index: 1;
			.icon {
				display: inline;
			}
			.icon-map-pin {
				@include icon;
				width: 38px;
				height: 52px;
				background-image: url(~@/assets/img/renew/icon_pin_off.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: contain;
			}
			&.is-active {
				.icon-map-pin {
					background-image: url(~@/assets/img/renew/icon_pin_on.svg);
				}
				.fp-map-pin__num {
					color: #ff7300;
				}
			}
			&__text {
				display: inline-flex;
				height: 22px;
				padding: 0 4px;
				border-radius: 8px;
				background: rgba(220, 220, 220, 0.8);
				color: #000;
				font-size: 12px;
				align-items: center;
				justify-content: center;
			}
			&__num {
				position: absolute;
				display: block;
				top: 13px;
				left: 50%;
				z-index: 1;
				color: #575756;
				font-size: 19px;
				font-weight: 500;
				transform: translateX(-50%);
			}
		}
		&-content {
			display: flex;
			height: 100%;
			overflow: hidden;

			&__l {
				display: flex;
				position: relative;
				overflow: hidden;
				max-width: 300px;
				flex: 0 0 40%;
				align-items: center;
				justify-content: center;
				img {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					right: 0;
					z-index: 1;
					object-fit: cover;
					@include transition(transform, 0.5s);
				}
			}
			&__r {
				padding: 20px 20px 20px 30px;
				flex: 1 1;
				display: flex;
				flex-direction: column;
				@include mobile {
					padding: 15px;
				}
			}
			@include mobile {
				flex-direction: column;
				.fp-map-content {
					&__l {
						flex: none;
						max-width: 100%;
						width: 100%;
						min-height: clamp(200px, 40vw, 300px);
					}
					&__r {
						width: 100%;
					}
				}
			}
			&__title {
				display: block;
				margin-bottom: 1em;
				font-size: 21px;
				font-weight: 700;
				line-height: 1.2;
				&.is-big {
					font-size: 26px;
					@include mobile {
						font-size: 21px;
					}
				}
			}
			&__desc {
				margin-bottom: 1em;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.5;
				&.is-narrow {
					line-height: 1.3;
				}
			}
			&__dl {
				color: #2e5190;
				dt {
					font-weight: 700;
				}
				dd {
					ol {
						display: block;
						list-style-type: decimal;
						line-height: 1.2;
						margin: 1em 0;
						padding-inline-start: 1em;
						li {
							display: list-item;
							margin: 0.5em 0;
							list-style: decimal;
							&::marker {
								font-size: 0.9em;
							}
						}
					}
				}
			}
			&__body {
				flex: 1 1;
			}
			&__footer {
				flex: 0 0;
				.buttons {
					display: flex;
					gap: 10px;
					white-space: nowrap;
					.button-default {
						display: inline-flex;
					}
					@include mobile {
						gap: 5px;
					}
				}
			}
			&--small {
				.fp-map-content {
					&__l {
						max-width: 233px;
						flex: 0 0 40%;
						@include mobile {
							max-width: 100%;
							flex: 0 0 100%;
						}
					}
					&__r {
						justify-content: center;
						.fp-map-content {
							&__title {
								margin-bottom: 0;
							}
							&__body {
								flex: initial;
								img {
									max-width: 448px;
									width: 100%;
								}
							}
							&__footer {
								margin-top: 1em;
								flex: initial;
							}
						}
					}
				}
				.button-default {
					&.is-rounded {
						&.is-primary,
						&.is-light {
							height: 28px;
							padding: 0 15px;
							font-size: 14px;
							@include mobile {
								padding: 0 10px;
							}
						}
					}
				}
			}
		}
	}
	&-biz {
		background-color: #fafafa;
		&-wrap {
			overflow: hidden;
			border-radius: 30px;
			border: 1px solid #e0e0e0;
			background-color: #fff;
			.fp-tab-container {
				display: block;
				.fp-tabpanel {
					border: none;
				}
				.fp-tab {
					display: inline-flex;
					width: auto;
					height: 30px;
					padding: 5px 20px;
					border-radius: 30px;
					border: 1px solid #645f5b;
					background-color: #fff;
					text-align: center;
					line-height: 1;
					font-size: 16px;
					font-weight: 400;
					color: #757575;
					&.is-active {
						color: #fff;
						border-color: #ff7300;
						background: #ff7300;
					}
				}
			}
			.fp-tablist {
				display: block;
				overflow-x: auto;
				width: auto;
				// height: 45px;
				margin: 20px -15px -14px;
				// padding: 0 20px 37px;
				padding: 0 20px 18px;
				text-align: center;
				// @include scrollbar('', 'h');
				white-space: nowrap;
				z-index: 2;
				.fp-tab {
					margin: 0 8px;
				}
			}
			.sptpgm-article-footer {
				.button-default {
					min-width: 68px;
					margin-top: 0;
					padding: 0 10px;
					font-weight: 400;
				}
			}
		}
		.fp-tab {
			height: 56px;
			// height: 68px;
		}
		// .fp-tabpanels {
		// 	width: 100%;
		// 	overflow: hidden;
		// }
		.fp-tabpanels {
			&.no-border {
				overflow: inherit;
				.fp-tabpanel {
					overflow: inherit;
					padding: 20px 0;
					border: none;
					@include mobile {
						padding: 0;
					}
				}
				.swiper {
					@include mobile {
						padding-left: 15px;
						padding-right: 15px;
						margin-left: -15px;
						margin-right: -15px;
					}
				}

				.swiper-button-prev {
					left: -40px;
					@include tablet {
						margin-top: -50px;
						left: -30px;
					}
					@include mobile {
						left: -20px;
					}
				}
				.swiper-button-next {
					right: -40px;
					@include tablet {
						margin-top: -50px;
						right: -30px;
					}
					@include mobile {
						right: -20px;
					}
				}
			}
		}
		.fp-tabpanel {
			height: 328px;
			padding: 20px;
			@include tablet {
				height: 336px;
			}
			@include mobile {
				height: 321px;
			}
			.swiper {
				padding-left: 0px;
				padding-right: 0px;
				@include mobile {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
			.swiper-button-prev,
			.swiper-button-next {
				&.swiper-button-disabled {
					opacity: 0;
				}
				@include tablet {
					transform: scale(0.5);
				}
			}

			@include mobile {
				padding: 15px 0;
			}
			&-buttons {
				display: flex;
				margin-top: 20px;
				align-items: center;
				justify-content: center;
				.button-default {
					&.is-rounded {
						width: auto;
						min-width: 103px;
						height: 30px;
						font-size: 16px;
						border: 1px solid #e0e0e0;
						gap: 8px;
						.icon {
							width: 14px;
							height: 14px;
							margin-top: -4px;
							svg {
								width: 100%;
								height: 100%;
							}
						}
						@include transition(all, 0.3s);
						@include desktop {
							&:hover,
							&:focus {
								border-color: #ff7300;
								background-color: #ff7300;
								color: #fff;
							}
						}
						@include tablet {
							&:active {
								border-color: #ff7300;
								background-color: #ff7300;
								color: #fff;
							}
						}
					}
				}
			}
			.no-results-found {
				padding: 16px 0;
				border-radius: 15px;
				// background: #fbfbfc;
				@include tablet {
					padding: 19px 0;
				}

				.text {
					font-size: 19px;
					color: #bdbdbd;
					@include mobile {
						font-size: 16px;
					}
				}
				&::before {
					content: '';
					display: flex;
					width: 160px;
					height: 160px;
					margin: 0 auto 20px;
					background-image: url(~@/assets/img/common/noresult.svg);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: 50% 50%;
				}
			}
		}
		&-buttons {
			display: flex;
			margin-bottom: 20px;
			.fp-biz-button {
				display: flex;
				width: 100%;
				height: 55px;
				padding: 15px;
				border: 1px solid #645f5b;
				border-radius: 15px 0px 0px 15px;
				background-color: transparent;
				font-size: 26px;
				font-weight: 400;
				color: #645f5b;
				align-items: center;
				justify-content: center;
				@include word-wrap;
				@include mobile {
					height: auto;
					font-size: 16px;
					text-align: left;
				}
				&:hover,
				&:focus {
					background-color: #f4f4f4;
					&.is-active {
						background-color: #444;
					}
				}
				&.is-active {
					color: #fff;
					background: #645f5b;
				}
				&:first-child {
					border-radius: 15px 0px 0px 15px;
				}
				&:last-child {
					border-radius: 0 15px 15px 0;
				}
			}
		}
		.sptpgm-article {
			width: 100%;
			height: 238px;
			border-radius: 20px;
			border-color: #e0e0e0;
			background-color: #fff;
			@include tablet {
				height: auto;
			}
		}
	}
	&-digital {
		background-color: #f4f4f4;
		@include tablet {
			padding-bottom: 150px;
		}
		.fp-digital-card {
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 80%;
				top: 0;
				left: 0;
				z-index: 1;
				background: rgba(0, 0, 0, 0.3);
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
				@include mobile {
					top: inherit;
					bottom: 0;
					transform: rotate(180deg);
				}
			}
		}
		// &-tabs {
		// 	display: block !important;
		// 	overflow-y: hidden;
		// 	overflow-x: auto;
		// 	height: 65px;
		// 	margin: 0 100px 30px;
		// 	padding: 0 30px;
		// 	border: 1px solid #bdbdbd;
		// 	border-radius: 15px;
		// 	background: #ffffff;
		// 	text-align: center;
		// 	white-space: nowrap;
		// 	flex: 0 0 65px;
		// 	@include mobile {
		// 		display: none !important;
		// 	}
		// }
		&-panels {
			position: relative;
			max-width: 1320px;
			margin: 0 auto;
			padding: 0 100px;
			@include mobile {
				padding: 0 35px;
			}
			&-wrap {
				display: flex;
				gap: 20px;
				justify-content: space-between;
				@include tablet {
					gap: 15px;
				}
				@include mobile {
					flex-wrap: wrap;
					gap: 10px;
					.fp-digital-card {
						width: calc(50% - 5px);
					}
				}
			}
			.swiper-controls {
				bottom: -55px;
				@include mobile {
					bottom: -43px;
				}
			}
		}
		&-tab {
			display: inline-block;
			margin: 0 5px;
			padding: 22px 0.3em;
			border: none;
			background-color: transparent;
			font-size: 19px;
			font-weight: 400;
			color: #645f5b;
			white-space: nowrap;
			@include transition(all, 0.3s);
			&.is-active,
			&:hover,
			&:focus {
				font-weight: 700;
				color: #ff7300;
				&:after {
					content: '';
					display: block;
					width: 100%;
					margin-top: 3px;
					border-bottom: 2px solid #ff7300;
				}
			}
		}
		&-card {
			width: 25%;
			display: flex;
			position: relative;
			overflow: hidden;
			padding: 0;
			border-radius: 20px;
			@include mobile {
				border-radius: 10px;
			}
			&:hover,
			&:focus {
				.fp-digital-card__img img {
					transform: scale(1.2);
				}
			}
			&__title {
				display: block;
				position: absolute;
				width: 100%;
				top: 0;
				z-index: 1;
				padding: 30px;
				font-size: 23px;
				font-weight: 400;
				color: #fff;
				line-height: 1.1;
				text-align: left;
				@include word-wrap;
				@include tablet {
					padding: 15px;
					font-size: 18px;
				}
				@include mobile {
					top: inherit;
					bottom: 0;
					padding: 15px 10px;
					font-size: 16px;
					text-align: center;
				}
			}
			&__img {
				display: flex;
				position: relative;
				width: 100%;
				padding-top: 168%;
				overflow: hidden;
				align-items: center;
				justify-content: center;
				flex: 1 1;
				@include mobile {
					padding-top: 100%;
				}
				img {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					object-fit: cover;
					transform: scale(1.01);
					@include transition(transform, 0.5s);
				}
			}
			&__tags {
				position: absolute;
				display: flex;
				width: 100%;
				bottom: 0;
				padding: 30px;
				flex-direction: column;
				align-items: flex-start;
				@include tablet {
					padding: 15px;
				}
				@include mobile {
					display: none;
				}
				span {
					display: inline-flex;
					overflow: hidden;
					height: 30px;
					margin-top: 10px;
					padding: 0 15px;
					border-radius: 30px;
					background: rgba(0, 0, 0, 0.25);
					line-height: 1;
					font-size: 15px;
					font-weight: 400;
					color: #fff;
					align-items: center;
					white-space: nowrap;
					@include tablet {
						max-width: 100%;
						height: 25px;
						margin-top: 5px;
						padding: 0 5px;
						font-size: 13px;
					}
					&::before {
						content: '#';
						display: inline;
						margin-right: 0.2em;
					}
					em {
						@include text-ellipsis;
					}
				}
			}

			&__company {
				display: flex;
				width: 100%;
				font-size: 16px;
				text-align: right;
				font-weight: 400;
				justify-content: flex-end;
				flex: 0 0;
			}
		}
		.swiper-buttons-container {
			.swiper-button-prev {
				left: 7px;
			}
			.swiper-button-next {
				right: 7px;
			}
		}
	}
	&-mobile-control {
		display: none;
		width: 100%;
		margin: 0 0 20px;
		.dropdown {
			width: 100%;
			.dropdown-trigger {
				width: 100%;
				.button {
					width: 100%;
					height: 40px;
					padding: 0 20px;
					border-radius: 15px;
					background-color: #fff;
					font-size: 19px;
					font-weight: 700;
					color: #645f5b;
				}
			}
			.dropdown-item {
				padding: 0;
				button {
					width: 100%;
					padding: 10px 20px;
					text-align: left;
					font-size: 19px;
					font-weight: 400;
					color: #645f5b;
					flex: 1 1;
				}
				&.is-active {
					button {
						font-weight: 700;
						background-color: #f4f4f4;
					}
				}
				&:hover,
				&:focus {
					background-color: #f4f4f4;
				}
			}
		}
		.dropdown-content {
			overflow: hidden;
			border-radius: 15px;
		}
		@include mobile {
			display: block;
		}
	}
	&-selfdx {
		background-image: url('~@/assets/img/main/bg_selfdx.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		&-content {
			display: flex;
			gap: 16px;
			justify-content: space-between;
			@include until(1160px) {
				justify-content: center;
			}
		}
		&-item {
			min-height: 332px;
			padding: 24px 20px;
			border-radius: 30px;
			background-color: #fff;
			text-align: center;
			border: 1px solid #e0e0e0;
			box-shadow: 0px 0px 10px 0px rgba(141, 85, 49, 0.25);
			&.flex-l {
				.fp-selfdx-item__title {
					display: flex;
					gap: 8px;
					justify-content: center;
					.icon {
						margin-top: -3px;
					}
				}
				@include until(1160px) {
					display: none;
				}
			}
			&.flex-r {
				display: flex;
				max-width: 420px;
				width: 80%;
				min-width: 300px;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				// flex: 0 0 30%;
				.fp-selfdx-item__title {
					margin-bottom: 0;
				}
				.button-default.is-secondary.is-rounded {
					font-size: 14px;
				}
			}
			&.bg-grey {
				background-color: #eee;
			}
			&__title {
				margin-bottom: 25px;
				line-height: 1.2;
				font-size: 24px;
				font-weight: 700;
				@include mobile {
					font-size: 21px;
				}
			}
		}
		.selfdx {
			&-msection {
				&-sub {
					&__header {
						margin-bottom: 13px;
						gap: 6px;
					}
					&__body {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					&__title {
						font-size: 19px;
						font-weight: 700;
					}
					&__img {
						width: 185px;
						height: 185px;
						margin-bottom: 12px;
						border: 1px solid rgba(255, 255, 255, 0.8);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
						background: rgb(255, 255, 255, 0.4);
					}
					&__info {
						display: flex;
						margin-top: 10px;
						flex-wrap: wrap;
						gap: 4px;
						dl {
							font-size: 13px;
							text-align: left;
							dt {
								flex: 0 0 30px;
							}
							&:nth-child(1),
							&:nth-child(3) {
								width: calc(51% - 4px);
							}
							&:nth-child(2),
							&:nth-child(4) {
								width: calc(51% - 4px);
							}
						}
					}
					&__graph {
						height: 185px;
						margin-bottom: 20px;
						padding: 1em 1.5em;
						border-radius: 185px;
						border: 1px solid rgba(255, 255, 255, 0.8);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
						background: rgb(153, 153, 153, 0);
						justify-content: space-around;

						@include until(1050px) {
							height: auto;
							padding: 50px 20px;
							flex-wrap: wrap;
							gap: 20px 5px;
						}
						@include mobile {
							padding: 30px 20px;
							border-radius: 100px;
						}
						@include until(400px) {
							padding: 40px 10px;
							border-radius: 80px;
						}
						dl {
							display: flex;
							width: 100px;
							flex-direction: column;
							align-items: center;
							@include until(780px) {
								width: calc((100% - 10px) / 2);
							}
						}
						dt {
							font-size: 14px;
							@include mobile {
								font-size: 14px;
							}
						}
						dd {
							height: 125px;
							order: 1;
							transform: scale(0.8);
						}
					}
					&__desc {
						padding-left: 35px;
						padding-right: 35px;
						font-size: 14px;
						text-align: left;
						@include mobile {
							margin-top: 80px;
							padding-left: 0;
							padding-right: 0;
						}
						.selfdx-voice {
							top: -30px;
							left: 24px;
							transform: scale(0.7);
							@include mobile {
								top: -59px;
								left: -30px;
							}
							@include until(400px) {
								left: 0;
							}
						}
						.typing {
							padding-left: 45px;
							padding-right: 24px;
							line-height: 1.3;
							@include word-wrap;
							@include mobile {
								padding-left: 0;
								padding-right: 0;
								font-size: 14px;
							}
						}
					}
				}
			}
		}
		.icon-selfdx-main {
			&-1 {
				width: 29px;
				height: 32px;
				background-image: url('~@/assets/img/selfdx/icon_main_1_b.svg');
			}
			&-11 {
				width: 21px;
				height: 22px;
				background-image: url('~@/assets/img/selfdx/icon_main_11_b.svg');
			}
			&-12 {
				width: 21px;
				height: 22px;
				background-image: url('~@/assets/img/selfdx/icon_main_12_b.svg');
			}
		}
		.selfdx-item {
			display: flex;
			gap: 18px;
			justify-content: space-between;
			@include until(1160px) {
				display: block;
			}

			.flex {
				&-l {
					flex: 0 0 185px;
					@include until(1160px) {
						margin-bottom: 2em;
					}
				}
				&-r {
					flex: 1 1;
				}
			}
		}
	}
	&-more-link {
		display: inline-flex;
		font-size: 14px;
		font-weight: 300;
		gap: 4px;
		align-items: center;
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
	&-sbdcnews {
		.fp-title-right {
			display: flex;
			margin-bottom: 24px;
			justify-content: flex-end;
			@include mobile {
				position: relative;
				margin-bottom: -26px;
			}
		}
		&-list {
			display: flex;
			padding: 20px;
			background-color: #f5f5f5;
			border-radius: 30px;
			gap: 16px;
			@include mobile {
				margin-left: -15px;
				margin-right: -15px;
				margin-bottom: -100px;
				padding: 40px 15px 100px;
				border-radius: 0;
				flex-direction: column;
				gap: 30px;
			}
		}
		&-item {
			width: 50%;
			@include mobile {
				width: 100%;
			}
			&__title {
				margin-bottom: 8px;
				line-height: 1.2;
				font-size: 24px;
				font-weight: 700;
			}
		}
		.fp-mphoto {
			&-item {
				max-width: initial;
				&__img {
					border: 1px solid #eee;
				}
				&__text {
					position: static;
					margin-top: 8px;
					font-size: 19px;
					font-weight: 700;
					color: #424242;
					line-height: 1.3;
					@include text-ellipsis-2;
					@include mobile {
						font-size: 16px;
					}
				}
			}
			&-list {
				@include mobile {
					gap: 16px;
				}
				> li {
					width: calc(50% - 8px);
					flex: none;
				}
			}
		}
	}
}
.fp-mphoto {
	&-list {
		display: flex;
		gap: 16px;
	}
	&-item {
		position: relative;
		display: block;

		&__text {
			position: absolute;
			display: block;
			margin-top: 10px;
			color: #fff;
			top: 15px;
			left: 20px;
			z-index: 5;
			font-size: 22px;
			font-weight: 700;
			line-height: 1.2;
			@include mobile {
				font-size: 16px;
			}
			em {
				display: block;
				font-size: 0.8em;
				font-weight: normal;
			}
		}
		&__img {
			position: relative;
			display: flex;

			overflow: hidden;
			border-radius: 15px;
			padding-top: 100%;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: scale(1.01);
				@include transition(transform, 0.5s);
			}
		}
		&:hover {
			.fp-mphoto-item__img {
				img {
					transform: scale(1.2);
				}
			}
		}
	}
}
.fp-live {
	@include tablet {
		padding-bottom: 130px;
	}
	.fp-subtitle {
		margin-bottom: 10px;
	}
	.live-card {
		.live-title {
			margin-top: clamp(10px, 1.57vw, 15px);
		}
		.live-bottom {
			padding-top: 0;
		}
	}
	&-panel {
		position: relative;
		max-width: 1320px;
		margin: 0 auto;
		padding: 0 80px;
		@include mobile {
			padding: 0 35px;
		}
		.swiper-slide {
			padding: 0 15px;
		}
	}
	.swiper-controls {
		bottom: -31px;
	}
}

// 지원사업 아티클
.sptpgm-article {
	display: flex;
	padding: 20px;
	border: 1px solid #eeeeee;
	border-radius: 15px;
	background: #fbfbfc;
	flex-direction: column;
	@include mobile {
		padding: 15px;
	}
	&-header {
		display: flex;
		margin-bottom: 10px;
		align-items: center;
		font-size: 14px;
		font-weight: 700;
		color: #8c837e;
		gap: 10px;
		flex: 0 0;
		flex-wrap: wrap;
		@include tablet {
			gap: 5px;
		}
		&__tag {
			display: inline-flex;
			height: 25px;
			padding: 5px;
			border-radius: 4px;
			border: 1px solid #8c837e;
			white-space: nowrap;
			align-items: center;
			&.is-gray {
				background-color: #eee;
				border-color: transparent;
			}
		}
	}
	&-title {
		height: 43px;
		font-size: 19px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 10px;
		@include text-ellipsis-2;
	}
	&-desc {
		height: 42px;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.3;
		color: #757575;
		@include text-ellipsis-2;
	}
	&-dl {
		display: flex;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #757575;
		line-height: 1.1;
		align-items: center;
		dt {
			display: none;
			padding-right: 10px;
			flex: 0 0 32px;
		}
		dd {
			flex: 1 1;
			@include text-ellipsis;
		}
	}
	&-body {
		flex: 1 1;
	}
	&-footer {
		margin-top: 20px;
		flex: 0 0;
		.button-default {
			margin-top: 10px;
		}
	}
}
.fullpage-pagination {
	display: none;
	position: fixed;
	top: 50%;
	right: 1rem;
	z-index: 10;
	transform: translateY(-50%);
	@at-root .__fullpage & {
		display: block;
	}
	.fp-pagination {
		&-item {
			position: relative;
			display: block;
			margin: 20px 0;
			cursor: pointer;
			i {
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				border: 2px solid #fff;
				background-color: transparent;
				@include transition(all, 0.3s);
			}
			@at-root .__fullpage-light & {
				i {
					border-color: #645f5b;
				}
				// &.is-active,
				&:hover {
					i {
						border-color: #645f5b;
						background-color: #645f5b;
					}
				}
			}
			// &.is-active {
			// 	span {
			// 		background-color: #ff7300;
			// 		&::after {
			// 			border-color: transparent transparent transparent #ff7300;
			// 		}
			// 	}
			// }
			&.is-active,
			&:hover {
				i {
					transform: scale(1.8);
				}
			}
			&.is-active {
				i {
					border-color: #ff7300;
					background-color: #ff7300;
				}
			}
			&:hover {
				i {
					border-color: #fff;
					background-color: #fff;
				}
			}
			&.is-active:hover {
				i {
					border-color: #ff7300;
					background-color: #ff7300;
				}
				span {
					background-color: #ff7300;
					&:after {
						border-color: transparent transparent transparent #ff7300;
					}
				}
			}
			&:hover {
				span {
					opacity: 1;
					visibility: visible;
				}
			}
			span {
				display: inline-flex;
				position: absolute;
				opacity: 0;
				visibility: hidden;
				right: 25px;
				top: 50%;
				padding: 6px 12px;
				border-radius: 9px;
				background-color: #645f5b;
				color: #fff;
				font-size: 14px;
				transform: translateY(-50%);
				white-space: nowrap;
				@include transition(all, 0.3s);
				&:after {
					content: '';
					position: absolute;
					display: block;
					right: -6px;
					top: 50%;
					width: 0px;
					height: 0px;
					border-style: solid;
					border-width: 6px 0 6px 7px;
					border-color: transparent transparent transparent #645f5b;
					transform: translateY(-50%);
					@include transition(all, 0.3s);
				}
			}
		}
	}
}
.fullpage-btn-top {
	display: flex;
	position: fixed;
	width: 60px;
	height: 60px;
	margin-right: 9px;
	right: 7.5%;
	bottom: 4%;
	z-index: 999;
	border-radius: 8px;
	@include until(1800px) {
		right: 15px;
		bottom: 10px;
	}
	@include tablet {
		display: none;
	}
}
.collapse-content {
	height: 100%;
	flex-direction: column;
	.button-default.is-primary.is-rounded {
		height: 30px;
		font-size: 16px;
	}
	.fp-map-content {
		cursor: pointer;
		&:hover,
		&:focus {
			.fp-map-content__l {
				img {
					transform: scale(1.1);
				}
			}
		}
		@include mobile {
			flex-direction: row;
			height: auto;
			.fp-map-content {
				&__l {
					max-width: 233px;
					flex: 0 0 40%;
					width: 100%;
					height: inherit;
					min-height: inherit;
				}
				&__r {
					width: 100%;
				}
			}
		}
		&.is-collapse {
			flex: 1 0 70%;
			.fp-map-content__body,
			.fp-map-content__footer {
				display: block;
			}
			@include mobile {
				flex-direction: column;
				.fp-map-content {
					&__l {
						flex: none;
						max-width: 100%;
						width: 100%;
						min-height: clamp(200px, 40vw, 300px);
					}
					&__r {
						width: 100%;
					}
				}
			}
		}
	}
	.fp-map-content__header {
	}
	.fp-map-content__body {
		// overflow-y: auto;
		// max-height: calc(100% - 80px);
		margin-top: 1em;
		@include scrollbar('', 'v');
	}
	.fp-map-content__body,
	.fp-map-content__footer {
		display: none;
	}
}
/* video full background */
#myVideo {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 0;
	min-width: 100%;
	min-height: 100%;
	// filter: grayscale(1);
}

.icon {
	&-fp {
		display: inline-flex;
		width: 80px;
		height: 80px;
		border-radius: 15px;
		background-color: #ff7300;
		align-items: center;
		justify-content: center;
		> i {
			@include icon;
		}
		&-link {
			&1 {
				width: 53px;
				height: 60px;
				background-image: url(~@/assets/img/renew/icon_fp_link1.svg);
			}
			&2 {
				width: 62px;
				height: 53px;
				background-image: url(~@/assets/img/renew/icon_fp_link2.svg);
			}
			&3 {
				width: 55px;
				height: 50px;
				background-image: url(~@/assets/img/renew/icon_fp_link3.svg);
			}
			&4 {
				width: 50px;
				height: 50px;
				@include icon;
				background-image: url(~@/assets/img/renew/icon_fp_link4.svg);
			}
			&5 {
				width: 51px;
				height: 50px;

				background-image: url(~@/assets/img/renew/icon_fp_link5.svg);
			}
			&6 {
				width: 53px;
				height: 53px;

				background-image: url(~@/assets/img/renew/icon_fp_link6.svg);
			}
		}
		&-search {
			@include icon;
			width: 25px;
			height: 25px;
			background-image: url(~@/assets/img/renew/icon_search.svg);
		}
		&-date {
			@include icon;
			width: 20px;
			height: 20px;
			background-image: url(~@/assets/img/renew/icon_fp_calendar.svg);
		}
		&-phone {
			@include icon;
			width: 20px;
			height: 20px;
			background-image: url(~@/assets/img/renew/icon_fp_phone.svg);
		}
		&-bldg {
			@include icon;
			width: 22px;
			height: 22px;
			background-image: url(~@/assets/img/renew/icon_fp_bldg.svg);
		}
	}
}

.mouse-scroll {
	position: fixed;
	opacity: 1;
	visibility: visible;
	width: 30px;
	height: 50px;
	left: 50%;
	bottom: 30px;
	z-index: 10;
	margin-top: -35px;
	margin-left: -15px;
	border: 2px solid #fff;
	border-radius: 25px;
	@include tablet {
		display: none;
	}
	@include transition(opacity, 0.3s);
	span {
		display: block;
		margin-top: 52px;
		opacity: 0.7;
		color: #fff;
		font-size: 11px;
		font-weight: 400;
		font-family: arial, consolas, serif;
		@include transition(all, 0.3s);
	}
	@include transition(all, 0.3s);
	@at-root .__fullpage-light & {
		border-color: rgba(0, 0, 0, 0.25);
		span {
			color: rgba(0, 0, 0, 0.25);
		}
	}
	@at-root .__fullpage-footer & {
		opacity: 0;
		visibility: hidden;
	}
	&:before {
		position: absolute;
		left: 50%;
		content: '';
		width: 8px;
		height: 8px;
		background-color: #fff;
		margin-left: -4px;
		top: 8px;
		border-radius: 4px;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-name: scroll;
		@include transition(all, 0.3s);
		@at-root .__fullpage-light & {
			background-color: rgba(0, 0, 0, 0.25);
		}
	}
	@include mobile {
		position: relative;
	}
}

@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(26px);
	}
}
.sns-quick,
.sns-mobile-quick {
	right: 1%;
	&__one {
		width: 40px;
		height: 40px;
	}
	a,
	button {
		display: flex;
		width: 40px;
		height: 40px;
		align-items: center;
		justify-content: center;
		i {
			overflow: hidden;
			width: 40px;
			height: 40px;
			border-radius: 8px;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
		}
		&.is-hidden {
			position: absolute;
			visibility: hidden;
			opacity: 0;
		}
	}
}
.sns-mobile-quick {
	position: fixed;
	right: 10px;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	gap: 0;
	a,
	button {
		width: 46px;
		height: 46px;
	}
	&__hidden {
		position: absolute;
		width: 46px;
		height: 230px;
		bottom: 94px;
		visibility: hidden;
		// opacity: 0;
		border-radius: 8px;
		overflow: hidden;
		// @include transition(all, 0.5s);
		> div {
			position: relative;
			// opacity: 0;
			display: flex;
			flex-direction: column;
			gap: 0;
			bottom: -235px;
			z-index: 0;
			@include transition(all, 0.5s);
		}
		&.is-active {
			// opacity: 1;
			visibility: visible;
			> div {
				opacity: 1;
				bottom: 0;
			}
		}
	}
	.button-sns-close {
		position: relative;
		margin-bottom: 1px;
		z-index: 10;
	}
	.button-sns-open {
		position: absolute;
		bottom: 47px;
		left: 0;
	}
}
.icon-quick {
	&-blog {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_blog.svg);
	}
	&-insta {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_insta.svg);
	}
	&-youtube {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_youtube.svg);
	}
	&-facebook {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_facebook.svg);
	}
	&-kakao {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_kakao.svg);
	}
	&-sns {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_sns.svg);
	}
	&-close {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_close.svg);
	}
	&-top {
		@include icon;
		background-image: url(~@/assets/img/renew/icon_quick_top.svg);
	}
}

.simple-notice-list {
	li {
		display: flex;
		padding: 10px 0;
		align-items: center;
		justify-content: space-between;
	}
	&__title {
		font-size: 16px;
		max-width: calc(100% - 100px);
		@include text-ellipsis;
		flex: 1 1 calc(100% - 100px);
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	&__date {
		flex: 0 0;
		font-size: 14px;
		color: #757575;
	}
}

.ai-anim {
	&-1 {
		i {
			position: absolute;
			display: block;
			@include icon;
			@include tablet {
				transform: scale(0.8);
			}
			@include until(990px) {
			}
			&:nth-child(1) {
				top: -14px;
				left: -17px;
				width: 20px;
				height: 20px;
				background-image: url(~@/assets/img/renew/icon-ai-star.svg);
				animation: blink-effect 3.1s ease-in-out infinite;
			}
			&:nth-child(2) {
				top: 0;
				left: -17px;
				width: 9px;
				height: 9px;
				background-image: url(~@/assets/img/renew/icon-ai-star.svg);
				animation: blink-effect 2.6s ease-in-out infinite;
			}
			&:nth-child(3) {
				top: -8px;
				left: 4px;
				width: 8px;
				height: 7px;
				background-image: url(~@/assets/img/renew/icon-ai-star1.svg);
				animation: blink-effect 2.1s ease-in-out infinite;
			}
		}
	}
	&-2 {
		position: absolute;
		right: -12px;
		bottom: 1px;
		display: block;
		width: 11px;
		height: 11px;
		@include icon;
		background-image: url(~@/assets/img/renew/icon-ai-star.svg);
		animation: blink-effect 2.4s ease-in-out infinite;
		@include tablet {
			transform: scale(0.8);
		}
		@include until(990px) {
		}
	}
}
@keyframes blink-effect {
	50% {
		opacity: 0;
	}
}
