.dxloader {
	@include icon;
	width: 500px;
	height: 500px;
	position: relative;
	background-position: 100% 100%;
	box-sizing: border-box;
	&-inner {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include until(600px) {
			transform: translate(-50%, -50%) scale(0.6);
		}
		i {
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			border-radius: 50%;
			box-sizing: border-box;
			transform-origin: center center;
			&::after,
			&::before {
				@include icon;
				content: '';
				box-sizing: border-box;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				border-radius: 50%;
				box-sizing: border-box;
				transform-origin: center center;
			}
			&:nth-child(1) {
				width: 452px;
				height: 452px;
				background-image: url('~@/assets/img/selfdx/loader2.svg');
				animation: rotationBack 7s linear infinite;
			}
			&:nth-child(2) {
				&::after {
					width: 426px;
					height: 426px;
					border-width: 48px;
					border-style: solid;
					border-color: transparent transparent rgba(255, 115, 0, 0.1) rgba(255, 115, 0, 0.1);
					// animation: rotation 0.4s linear infinite;
					animation: rotation 4s linear infinite;
				}
				&::before {
					width: 329px;
					height: 329px;
					border-width: 1px;
					border-style: dashed;
					border-color: transparent transparent rgba(255, 115, 0, 0.9) rgba(255, 115, 0, 0.9);
					animation: rotationBack 1s linear infinite;
				}
			}
			&:nth-child(3) {
				width: 293px;
				height: 293px;
				border-width: 2px;
				border-style: dashed;
				border-color: rgba(255, 115, 0, 0.5);
				animation: rotation 7s linear infinite;
				&::after {
					width: 277px;
					height: 277px;
					border-width: 2px;
					border-style: dotted;
					border-color: transparent transparent rgba(255, 115, 0, 0.3) rgba(255, 115, 0, 0.3);
					animation: rotation 9s linear infinite;
				}
				&::before {
					width: 213px;
					height: 213px;
					border-width: 10px;
					border-style: solid;
					border-color: transparent transparent rgba(255, 115, 0, 0.7) rgba(255, 115, 0, 0.7);
					// animation: rotationBack 0.4s linear infinite;
					animation: rotationBack 1s linear infinite;
				}
			}
			&:nth-child(4) {
				width: 124px;
				height: 124px;
				border-width: 2px;
				border-style: dotted;
				border-color: rgba(255, 115, 0, 0.8);
				animation: rotationBack 10s linear infinite;
				&::after {
					width: 97px;
					height: 97px;
					border-width: 2px;
					border-style: dotted;
					border-color: transparent transparent rgba(255, 115, 0, 0.3) rgba(255, 115, 0, 0.3);
					animation: rotationBack 6s linear infinite;
				}
				&::before {
					width: 43px;
					height: 43px;
					background-color: #ff7300;
				}
			}
		}
	}
	&-text {
		margin-top: 30px;
		text-align: center;
		font-size: 21px;
		font-weight: 600;
		line-height: 1.1;
	}
	&-container {
		display: flex;
		opacity: 0;
		visibility: hidden;
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		min-height: 100vh;
		padding: 3em 0;
		background-color: rgba(255, 255, 255, 0.8);
		align-items: center;
		justify-content: center;
		@include transition(all, 0.5s);
		&.is-active {
			opacity: 1;
			visibility: visible;
		}
	}
	&::after,
	&::before {
		@include icon;
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		border-radius: 50%;
	}
	&::after {
		width: 500px;
		height: 500px;
		background-image: url('~@/assets/img/selfdx/loader11.svg');
		opacity: 0.1;
		// border-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5) transparent transparent;
		animation: rotation 30s linear infinite;
	}
	&::before {
		width: 495px;
		height: 495px;
		border: 17px solid;
		border-color: rgba(255, 115, 0, 0.08) rgba(255, 115, 0, 0.01) transparent transparent;
		// animation: rotation 0.6s linear infinite;
		animation: rotation 6s linear infinite;
	}
}

.dxloader-small {
	display: inline-block;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 6px solid;
	border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.3) transparent transparent;
	box-sizing: border-box;
	transform-origin: center center;
	transform: translate(-50%, -50%);
	animation: rotation 1s linear infinite;
	&-container {
		display: flex;
		width: 140px;
		height: 140px;
		position: relative;
		align-items: center;
		justify-content: center;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 75px;
		height: 75px;
		margin: auto;
		border-radius: 50%;
		border: 5px solid;
		border-color: transparent transparent rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1);
		box-sizing: border-box;
		box-sizing: border-box;
		animation: rotationBack 0.5s linear infinite;
		transform-origin: center center;
		transform: translate(-50%, -50%);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
#selfdx-result {
	padding-bottom: 0;
	background-image: url('~@/assets/img/selfdx/bg_main.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	.container {
		position: relative;
		z-index: 1;
		max-width: 1400px;
		@include mobile {
			padding: 0 16px;
		}
	}
	// @include tablet {
	// 	overflow-x: hidden;
	// }
}
.animation-typing {
	display: block;
	overflow: hidden;
	width: 0;
	white-space: nowrap;
	animation: typing 1.2s steps(18) forwards;
}
.animation-fade-up {
	opacity: 0;
	transform: translateY(50%);
	animation: aniFadeUp 3s forwards;
}
.selfdx {
	&-msection {
		display: flex;
		min-height: 100vh;
		padding: 5em 0;
		text-align: center;
		color: #fff;
		align-items: center;
		justify-content: center;
		line-height: 1.3;

		.container {
		}
		&-header {
			margin-bottom: 60px;
			@include mobile {
				margin-bottom: 32px;
			}
			&__title {
				font-size: 50px;
				font-weight: 700;
				letter-spacing: -1px;
				animation-delay: 0s;
				animation-duration: 0s;
				@include word-wrap;
				@include mobile {
					font-size: 26px;
				}
			}
			&__desc {
				width: 424px;
				margin: 16px auto 0;
				font-size: 26px;
				font-weight: 400;
				span {
					animation-delay: 0.5s;
				}
				@include mobile {
					width: 259px;
					font-size: 16px;
				}
			}
		}
		&-content {
		}
		&-subcontainer {
			text-align: left;
			&__header {
				display: flex;
				opacity: 0;
				margin-bottom: 24px;
				align-items: center;
				gap: 16px;
				animation-delay: 1.8s;
				animation-duration: 0s;
				@include mobile {
					justify-content: center;
				}
			}
			&__title {
				font-size: 40px;
				font-weight: 700;
				@include mobile {
					font-size: 24px;
				}
			}
			.display-flex {
				padding-left: 25px;
				padding-right: 25px;
				align-items: flex-start;
				justify-content: space-between;
				gap: 45px;
				@include tablet {
					gap: 30px;
				}
				@include until(1050px) {
					display: block;
				}
				@include until(400px) {
					padding-left: 0;
					padding-right: 0;
				}
				.flex-l {
					flex: 0 0 345px;
					animation-delay: 1.8s;
					animation-duration: 0s;
					@include until(1050px) {
						width: 100%;
						margin: 0 0 80px;
						.selfdx-msection-sub__body {
							width: 345px;
							margin: 0 auto;
						}
					}
					@include mobile {
						margin-bottom: 32px;
						.selfdx-msection-sub__body {
							width: auto;
						}
					}
				}
				.flex-r {
					flex: 1 1;
					animation-delay: 0.5s;
				}
			}
		}
		&-sub {
			&__header {
				display: flex;
				margin-bottom: 20px;
				align-items: center;
				gap: 6px;
			}
			&__body {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			&__title {
				font-size: 32px;
				font-weight: 700;
				@include mobile {
					font-size: 19px;
				}
			}
			&__img {
				display: flex;
				overflow: hidden;
				position: relative;
				width: 268px;
				height: 268px;
				margin-bottom: 16px;
				border: 1px solid rgba(255, 255, 255, 0.8);
				border-radius: 50%;
				background: rgb(255, 255, 255);
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
				align-items: center;
				justify-content: center;
				@include mobile {
					width: 215px;
					height: 215px;
					margin-left: auto;
					margin-right: auto;
				}
				img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(1.03);
				}
			}
			&__info {
				display: flex;
				margin-top: 10px;
				flex-wrap: wrap;
				gap: 4px;
				dl {
					display: flex;
					// width: calc((100% - 4px) / 2);
					font-size: 18px;
					&:nth-child(1),
					&:nth-child(3) {
						width: calc(45% - 4px);
					}
					&:nth-child(2),
					&:nth-child(4) {
						width: calc(55% - 4px);
					}
					dt {
						font-weight: 700;
						flex: 0 0 40px;
					}
					dd {
						font-weight: 300;
					}
				}
			}
			&__graph {
				display: flex;
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 269px;
				margin-bottom: 24px;
				padding: 1em 3em;
				border: 1px solid rgba(255, 255, 255, 0.8);
				border-radius: 269px;
				background-color: rgba(255, 255, 255, 0.15);
				box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.2);
				align-items: center;
				justify-content: space-around;
				backdrop-filter: blur(18px);
				@include until(1050px) {
					height: auto;
					padding: 50px 20px;
					flex-wrap: wrap;
					gap: 20px 5px;
				}
				@include mobile {
					padding: 30px 20px;
					border-radius: 100px;
				}
				@include until(400px) {
					padding: 40px 10px;
					border-radius: 80px;
				}
				dl {
					display: flex;
					flex-direction: column;
					align-items: center;
					@include until(780px) {
						width: calc((100% - 10px) / 2);
					}
				}
				dt {
					font-size: 19px;
					font-weight: 500;
					order: 2;
					white-space: nowrap;
					@include mobile {
						font-size: 16px;
					}
				}
				dd {
					order: 1;
					height: 138px;
				}
			}
			&__desc {
				position: relative;
				width: 100%;
				padding-left: 35px;
				padding-right: 35px;
				font-size: 22px;
				font-weight: 400;
				line-height: 1.4;
				@include mobile {
					margin-top: 80px;
					padding-left: 0;
					padding-right: 0;
				}
				.selfdx-voice {
					position: absolute;
					top: -30px;
					left: 29px;
					@include mobile {
						top: -59px;
						left: -30px;
					}
					@include until(400px) {
						left: 0;
					}
				}
				.typing {
					padding-left: 60px;
					padding-right: 60px;
					@include word-wrap;
					@include mobile {
						padding-left: 0;
						padding-right: 0;
						font-size: 16px;
					}
				}
			}
		}
	}
	&-bg {
		.icon {
			position: absolute;
			z-index: 0;
			animation: updownAni 1.2s infinite ease-in-out alternate;
			&-selfdx {
				&-bg1 {
					top: -175px;
					right: 25%;
					width: 271px;
					height: 264px;
					animation-duration: 1.9s;
					background-image: url('~@/assets/img/selfdx/bg1.svg');
					@include mobile {
						top: 13px;
						right: -8%;
						width: 135px;
						height: 132px;
					}
				}
				&-bg2 {
					top: -142px;
					left: -71px;
					width: 345px;
					height: 324px;
					background-image: url('~@/assets/img/selfdx/bg2.svg');
					@include tablet {
						left: -15%;
					}
					@include mobile {
						top: 50px;
						left: initial;
						right: -11%;
						width: 185px;
						height: 165px;
					}
				}
				&-bg3 {
					bottom: 115px;
					right: 125px;
					opacity: 0.6;
					width: 293px;
					height: 289px;
					animation-duration: 2.5s;
					background-image: url('~@/assets/img/selfdx/bg3.svg');
					@include tablet {
						right: -10%;
					}
					@include mobile {
						right: -150%;
					}
				}
				&-bg4 {
					top: -31px;
					left: -178px;
					z-index: 0;
					opacity: 0.6;
					width: 485px;
					height: 466px;
					animation-duration: 1s;
					background-image: url('~@/assets/img/selfdx/bg4.svg');
					@include tablet {
						left: -25%;
					}
					@include mobile {
						top: -300px;
						left: -158px;
						z-index: -1;
						width: 265px;
						height: 255px;
					}
				}
				&-bg5 {
					bottom: 175px;
					right: 50px;
					z-index: 0;
					opacity: 0.4;
					width: 570px;
					height: 570px;
					animation-duration: 1.5s;
					background-image: url('~@/assets/img/selfdx/bg5.svg');
					@include tablet {
						right: -30%;
					}
					@include mobile {
						right: -180px;
						bottom: 190%;
						z-index: -1;
						width: 286px;
						height: 286px;
					}
				}
			}
		}
	}
	&-section {
		position: relative;
		margin-bottom: 160px;

		&.is-active {
			.selfdx-section-header {
				animation-delay: 0s;
				animation: aniFadeUp1 1.6s forwards;
			}
			.selfdx-section-content {
				animation-delay: 2s;
				animation: aniFadeUp1 1.6s forwards;
			}
		}
		&-header {
			opacity: 0;
			transform: translateY(50%);
			margin-bottom: 30px;
			@include word-wrap;
			&__title {
				position: relative;
				padding-left: 16px;
				font-size: 32px;
				font-weight: 700;
				line-height: 1.2;
				text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
				&::before {
					content: '';
					position: absolute;
					top: 4px;
					left: 0;
					display: block;
					width: 8px;
					height: 30px;
					border-radius: 8px;
					border-left: 8px solid #ff7300;
					@include mobile {
						top: 1px;
						width: 7px;
						height: 25px;
						border-left: 7px solid #ff7300;
					}
				}
				@include mobile {
					font-size: 24px;
				}
			}
			&__desc {
				margin-top: 0.8em;
				font-size: 21px;
				font-weight: 300;
				line-height: 1.5;
				@include mobile {
					font-size: 19px;
				}
			}
			&__buttons {
				display: flex;
				width: 100%;
				margin-top: 16px;
				.button-default {
					&.is-secondary.is-rounded {
						width: 100%;
						max-width: 380px;
					}
				}
			}
		}
		&-content {
			opacity: 0;
			transform: translateY(50%);
		}
		&.display-flex {
			> .container {
				gap: 24px;
				display: flex;
				@include mobile {
					display: block;
				}
			}
			.selfdx-section {
				&-header {
					flex: 0 0 380px;
					@include until(1100px) {
						flex: 0 0 300px;
					}
				}
				&-content {
					flex: 1 1;
				}
			}
		}
		&.is-box {
			margin-top: 30px;
			@include mobile {
				margin-bottom: 80px;
				padding: 28px 0 24px;
			}
			.selfdx-voice {
				position: absolute;
				top: -7px;
				left: -38px;
				@include tablet {
					top: -18px;
					left: -12px;
				}
			}
			.selfdx-section {
				&-header {
					position: relative;
					margin-bottom: 16px;
					@include tablet {
						margin-bottom: 20px;
					}
					// &__title {
					// 	color: #424242;
					// }
				}
				&-content {
					position: relative;
					min-height: 6em;
					padding: 30px;
					border-radius: 30px;
					border: 1px solid rgba(255, 255, 255, 0.5);
					background-color: rgba(255, 255, 255, 0.2);
					color: #424242;
					font-size: 26px;
					font-weight: 300;
					line-height: 1.5;
					@include mobile {
						padding: 35px 16px;
						font-size: 16px;
						.dot-list {
							font-size: 15px;
						}
					}
					.dot-list {
						margin-bottom: 2em;
					}
				}
			}
		}
	}
	&-section1 {
		// position: relative;
		z-index: 1;
		&-header {
			&__title {
				background: linear-gradient(#e21e2c 0%, #e19400 100%);
				background-clip: text;
				-webkit-background-clip: text;
				color: transparent;
				font-size: 40px;
				font-weight: 700;
				@include word-wrap;
				@include mobile {
					font-size: 24px;
					text-align: center;
				}
			}
			&__desc {
				font-size: 26px;
				font-weight: 500;
				color: #645f5b;
				@include mobile {
					margin-top: 0.5em;
					font-size: 16px;
					text-align: center;
				}
			}
		}
		&-content {
		}
	}
	&-content {
		padding-top: 120px;
		padding-bottom: 120px;
		background-image: url('~@/assets/img/selfdx/bg_content.jpg');
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-size: cover;
		color: #fff;
		line-height: 1.3;
		@include mobile {
			padding-top: 60px;
		}
		> .container {
			position: relative;
		}
		.buttons {
			width: 100%;
			text-align: center;
			justify-content: center;
		}
	}
	&-result {
		width: 100%;
		gap: 16px;
		&:not(:last-child) {
			margin-bottom: 40px;
		}
		&.display-flex {
			display: flex;
			// align-items: center;
			justify-content: center;
			opacity: 0;
			transform: translateY(50%);
			&.is-active {
				animation-delay: 0.5s;
				animation: aniFadeUp1 1.6s forwards;
			}
			@include until(1200px) {
				display: block;
			}
			.flex-left {
				flex: 0 0 380px;
				@include until(1200px) {
					width: 50%;
					height: 235px;
					margin-bottom: 16px;
					border-radius: 15px;
				}
				@include until(550px) {
					width: 100%;
				}
			}
			.flex-right {
				flex: 1 1;
				.card {
					&-list {
						flex-wrap: nowrap;
						@include until(1200px) {
							overflow-x: auto;
							margin-left: -16px;
							margin-right: -16px;
							padding-left: 16px;
							padding-right: 16px;
						}
						gap: 16px;
						&-title {
							margin-bottom: 8px;
							font-size: 19px;
						}
					}
					&-item {
						width: calc((100% - 32px) / 3);
						@include mobile {
							min-width: 260px;
						}
						&__link {
							&:hover,
							&:focus {
								.card-item__image {
									outline: 5px solid #ff7300;
								}
							}
						}
						&__image {
							// height: 180px;
							// padding-bottom: 0;
							border-radius: 30px;
							border: 2px solid #ff7300;
							outline: 0px solid transparent;
							@include transition(outline, 0.2s);
							@include until(1200px) {
								border-radius: 15px;
							}
						}
						&__text {
							margin-top: 8px;
							font-size: 16px;
							font-weight: 400;
							color: #fff;
							@include text-ellipsis;
						}
					}
				}
			}
		}
		&-header {
			position: relative;
			height: initial;
			padding: 20px;
			border-radius: 30px;
			border: 2px solid #ff7300;
			background-color: #fff;
			color: #424242;
			.buttons {
				position: absolute;
				bottom: 25px;
				left: 0;
				padding: 0 20px;
				gap: 8px;
				.button-default {
					width: 100%;
					padding: 0 !important ;
					@include word-wrap;
					&[disabled] {
						background-color: #777;
						opacity: 0.5;
						pointer-events: none;
						cursor: not-allowed;
					}
				}
			}
			&__top {
				display: flex;
				margin-bottom: 8px;
				align-items: center;
				justify-content: space-between;
			}
			&__tag {
				display: inline-block;
				padding: 4px 8px;
				border-radius: 4px;
				background-color: #eee;
				font-size: 14px;
				font-weight: 700;
				color: #757575;
				&.is-round {
					border-radius: 25px;
					background-color: #c5dcfa;
					font-weight: 400;
					color: #17205b;
					&.is-disabled {
						background-color: #e0e0e0;
					}
				}
			}
			&__title {
				margin-bottom: 8px;
				font-size: 21px;
				font-weight: 700;
				line-height: 1.2;
				@include text-ellipsis-2;
				-webkit-line-clamp: 1;
			}
			&__desc {
				height: 60px;
				margin-bottom: 1em;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;
				@include word-wrap;
				@include text-ellipsis-3;
			}
			&__date {
				display: block;
				margin-bottom: 8px;
				font-size: 14px;
				font-weight: 400;
				color: #757575;
			}
		}
	}
	&-footer {
		gap: 16px;
		.button-default {
			&.is-primary.is-rounded,
			&.is-secondary.is-rounded {
				min-width: 130px;
			}
		}
	}
}
.icon {
	@include icon;
	&-selfdx {
		&-main-1 {
			width: 60px;
			height: 65px;
			background-image: url('~@/assets/img/selfdx/icon_main_1.svg');
			@include mobile {
				width: 32px;
				height: 34px;
			}
		}
		&-main-11 {
			width: 32px;
			height: 34px;
			background-image: url('~@/assets/img/selfdx/icon_main_11.svg');
			@include mobile {
				width: 24px;
				height: 25px;
			}
		}
		&-main-12 {
			width: 32px;
			height: 38px;
			background-image: url('~@/assets/img/selfdx/icon_main_12.svg');
			@include mobile {
				width: 24px;
				height: 29px;
			}
		}
	}
}
.icon-ai-beta {
	position: absolute;
	top: -15px;
	right: -30px;
	@include icon;
	width: 29px;
	height: 21px;
	background-image: url('~@/assets/img/common/icon_beta.svg');
	@include until(990px) {
		right: -24px;
		width: 24px;
		height: 18px;
	}
	@include mobile {
		top: -15px;
		right: -30px;
		width: 29px;
		height: 21px;
	}
}
.dot-list {
	margin: 0.5em 0;
	li {
		position: relative;
		padding-left: 1.2em;
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0.5em;
			left: 0.5em;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #666;
		}
		+ li {
			margin-top: 0.3em;
		}
	}
	&.font-24 {
		font-size: 24px;
	}
}
.selfdx-voice {
	display: flex;
	position: absolute;
	width: 56px;
	height: 51px;
	padding-left: 14px;
	background-image: url('~@/assets/img/selfdx/icon_voice.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	font-size: 0;
	color: #fff;
	align-items: center;
	justify-content: center;
	gap: 0;
	em {
		display: flex;
		position: relative;
		width: 7px;
		height: 7px;
		flex: 0 0 14px;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			font-size: 0;
			top: 50%;
			left: 3.5px;
			width: 7px;
			height: 7px;
			border-radius: 60%;
			background: #ff7300;
			transform: translateY(-50%);
			animation: escaleY 1s infinite ease-in-out;
		}
		&:before {
			left: -3.5px;
			animation-delay: 0s;
		}
		&:after {
			animation-delay: 0.16s;
		}
		&:first-child {
			&:before {
				animation-delay: 0.32s;
			}
			&:after {
				animation-delay: 0.16s;
			}
		}
		&:last-child {
			&:before {
				animation-delay: 0.32s;
			}
			&:after {
				display: none;
			}
		}
	}

	@keyframes escaleY {
		0%,
		80%,
		100% {
			box-shadow: 0 0;
			height: 10px;
		}
		40% {
			box-shadow: 0 -10px;
			height: 20px;
		}
	}
}
#selfdxProduct {
	.card {
		&-list {
			width: 100%;
			gap: 24px;
			flex-wrap: nowrap;
			@include mobile {
				overflow-x: auto;
				width: initial;
				margin-left: -16px;
				margin-right: -16px;
				padding-left: 16px;
				padding-right: 16px;
			}
		}
		&-item {
			width: calc((100% - 48px) / 3);
			@include mobile {
				min-width: 260px;
			}
			&__link {
				display: flex;
				width: 100%;
				height: 270px;
				overflow: hidden;
				border-radius: 30px;
				border: 1px solid rgba(255, 255, 255, 0.5);
				background-color: #fff;
				color: #424242;
				flex-direction: column;
			}
			&__image {
				width: 100%;
				height: 188px;
				padding-bottom: 0;
				border-radius: 0;
				border: none;
				flex: 0 0 188px;
			}
			&-body {
				display: flex;
				padding: 12px 24px;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				flex: 1 1;
			}
			&__company {
				margin-bottom: 6px;
				font-size: 12px;
				font-weight: 400;
				color: #757575;
				@include text-ellipsis;
			}
			&__title {
				margin-top: 0;
				font-size: 16px;
				font-weight: 700;
				@include word-wrap;
				@include text-ellipsis-2;
			}
		}
	}
	.layer-popup {
		max-width: 800px;
		color: #424242;
		.popup-body {
			padding: 2em;
		}
	}
}
#selfdxPanroTv {
	.selfdx-section-header {
		flex: 0 0 600px;
		@include until(1300px) {
			flex: 0 0 380px;
		}
		@include until(1100px) {
			flex: 0 0 300px;
		}
	}
	.card-list {
		gap: 24px;
		@include until(400px) {
			padding-left: 30px;
			padding-right: 30px;
		}
		> li {
			width: calc((100% - 24px) / 2);
			@include until(400px) {
				width: 100%;
			}
			.live-card {
				max-width: 300px;
				@include until(400px) {
					max-width: 100%;
				}
			}
		}
	}
}
#selfdxNews {
	.card-list {
		gap: 16px;
		flex-wrap: nowrap;
		@include mobile {
			overflow-x: auto;
			width: initial;
			margin-left: -16px;
			margin-right: -16px;
			padding-left: 16px;
			padding-right: 16px;
		}
		.card-item {
			width: calc((100% - 32px) / 3);
			@include mobile {
				min-width: 260px;
			}
			&__category {
				margin-top: 10px;
				color: #fff;
				font-size: 16px;
				font-weight: 400;
			}
			&__title {
				margin-top: 8px;
				color: #fff;
			}
		}
	}
}
#selfdxElearning {
	.card {
		&-list {
			width: 100%;
			gap: 24px;
		}
		&-item {
			width: calc((100% - 24px) / 2);
			display: flex;
			height: 320px;
			overflow: hidden;
			border-radius: 30px;
			border: none;
			background-color: #f5f5f5;
			color: #424242;
			flex-direction: column;
			@include until(530px) {
				width: 100%;
			}

			&__image {
				width: 100%;
				height: 180px;
				padding-bottom: 0;
				border-radius: 0;
				border: none;
				flex: 0 0 180px;
			}
			&-body {
				display: block;
				padding: 16px;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				flex: 1 1;
			}
			&__title {
				margin-top: 0;
				margin-bottom: 8px;
				font-size: 19px;
				font-weight: 700;
				@include text-ellipsis;
			}
			&__desc {
				margin-bottom: 20px;
				font-size: 14px;
				font-weight: 400;
				@include text-ellipsis;
			}
			.buttons {
				.button-default {
					font-weight: 400;
				}
			}
		}
	}
}
/* 애니메이션 */
@keyframes updownAni {
	from {
		transform: translateY(0px);
	}
	to {
		transform: translateY(-20px);
	}
}

.selfdx-reserv {
	&-list {
		display: flex;
		width: 100%;
		gap: 24px;
		> li {
			width: 50%;
			@include mobile {
				width: 100%;
			}
		}
		@include mobile {
			flex-direction: column;
		}
	}
	&-item {
		display: flex;
		width: 100%;
		padding: 20px;
		border-radius: 20px;
		background-color: #fff;
		align-items: center;
		justify-content: space-between;
		.buttons {
			width: auto;
			.button-default {
				padding: 0 25px;
				font-weight: 400;
			}
		}
		&:hover {
			.reserv-item-thumb {
				img {
					transform: translate(-50%, -50%) scale(1.15);
				}
			}
		}
		.fl {
			flex: 0 0 115px;
			@include mobile {
				flex: 0 0 110px;
			}
		}
		.fr {
			width: calc(100% - 115px);
			padding-left: 15px;
			flex: 1 1;
			@include mobile {
				padding-bottom: 12px;
			}
		}
		&-thumb {
			display: flex;
			position: relative;
			overflow: hidden;
			padding-top: 100%;
			border-radius: 15px;
			align-items: center;
			justify-content: center;
			@include mobile {
				border-radius: 12px;
			}
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 50%;
				left: 50%;
				object-fit: cover;
				transform: translate(-50%, -50%) scale(1.01);
				@include transition(transform, 0.5s);
			}
		}
		&-text {
			margin-bottom: 10px;
			@include mobile {
				margin-bottom: 12px;
			}
			&__title {
				display: block;
				margin-bottom: 10px;
				font-size: 19px;
				// font-size: 17px;
				font-weight: 700;
				line-height: 1.2;
				@include text-ellipsis;
				@include word-wrap;
				@include mobile {
					margin-bottom: 6px;
					font-size: 16px;
				}
			}
			&__desc {
				display: block;
				height: 34px;
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: 400;
				color: #757575;
				line-height: 1.2;
				@include text-ellipsis-2;
				@include mobile {
					font-size: 14px;
					&.mobile-hidden {
						display: none;
					}
				}
			}
		}
	}
}

.popup-product {
	&-detail {
		gap: 20px;
		.thumb {
			display: flex;
			overflow: hidden;
			position: relative;
			width: 250px;
			height: 250px;
			border-radius: 16px;
			border: 1px solid #e0e0e0;
			flex: 0 0 250px;
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	&-info {
		text-align: left;
		line-height: 1.2;
		&__brand {
			margin-bottom: 16px;
			font-size: 16px;
			font-weight: 400;
			color: #757575;
		}
		&__title {
			margin-bottom: 16px;
			font-size: 24px;
			font-weight: 700;
			@include text-ellipsis-2;
		}
		&__price {
			font-size: 19px;
			font-weight: 700;
		}
	}
	&-desc {
		text-align: left;
		margin-top: 24px;
		line-height: 1.5;
		font-size: 16px;
	}
}
#modalProducts {
	.popup-body {
		padding-top: 1em !important;
	}
}

.mypage2 {
	&-selfdx {
		width: 100%;
		.mypage2-sub-title {
			margin-bottom: 26px;
			font-size: 21px;
			font-weight: 700;
			line-height: 1.2;
		}
		.list-item {
			display: block;
			overflow: initial;
			width: 100%;
			height: auto;
			padding: 0;
			border-radius: 0;
			background-color: transparent;
			box-shadow: none;
			&:not(:last-child) {
				margin-bottom: 30px;
			}
			&-date {
				display: block;
				margin-top: 18px;
				text-align: right;
				font-size: 16px;
				color: #757575;
			}
			&-content {
				display: block;
				position: relative;
				width: 100%;
				height: auto;
				padding: 20px;
				border: 1px solid #e0e0e0;
				border-radius: 30px;
				background-color: #f1f1f1;
				overflow: hidden;
				box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
				@include until(600px) {
					padding: 16px;
				}
			}
		}
		.icon-selfdx-main {
			&-11 {
				width: 24px;
				height: 25px;
				background-image: url('~@/assets/img/selfdx/icon_main_11_b.svg');
			}
			&-12 {
				width: 24px;
				height: 28px;
				background-image: url('~@/assets/img/selfdx/icon_main_12_b.svg');
			}
		}
		.selfdx-item {
			display: flex;
			gap: 12px;
			justify-content: space-between;
			@include until(1160px) {
				display: block;
			}
			.flex {
				&-l {
					flex: 0 0 240px;
					@include until(1160px) {
						margin-bottom: 2em;
					}
				}
				&-r {
				}
			}
		}
		.selfdx-voice {
			transform: scale(0.75);
			@include mobile {
				top: -50px;
				left: -20px;
			}
		}
		.selfdx-msection {
			&-sub {
				&__header {
					margin-bottom: 12px;
				}

				&__title {
					font-size: 21px;
					font-weight: 700;
				}
				&__img {
					width: 205px;
					height: 205px;
					margin: 0 auto 12px;
					border: 1px solid #fff;
				}
				&__info {
					gap: 8px 4px;
					@include until(1160px) {
						width: 300px;
						margin: 0 auto;
					}
					dl {
						width: calc((100% - 4px) / 2);
						font-size: 14px;
						dt {
							flex: 0 0 30px;
							line-height: 1.2;
						}
						dd {
							line-height: 1.2;
						}
					}
				}
				&__graph {
					width: 100%;
					height: 202px;
					margin-bottom: 20px;
					padding: 1em;
					border: 1px solid #fff;
					border-radius: 269px;
					background-color: rgba(0, 0, 0, 0.025);
					box-shadow: none;
					@include until(600px) {
						height: auto;
						padding: 2em 0.5em;
						border-radius: 60px;
					}

					dl {
						width: calc((100% - 15px) / 4);
						margin-top: -20px;
						@include until(600px) {
							width: calc((100% - 10px) / 2);
						}
					}
					dt {
						font-size: 16px;
					}
					dd {
						height: 123px;
						transform: scale(0.8);
					}
				}
				&__desc {
					font-size: 16px;
					@include mobile {
						margin-top: 55px;
					}
					.typing {
						padding-left: 50px;
						padding-right: 50px;
						@include mobile {
							padding-left: 0;
							padding-right: 0;
						}
					}
				}
			}
		}
	}
}

@keyframes aniFadeUp1 {
	0% {
		opacity: 0;
		transform: translateY(100%) scaleY(1.5);
	}
	100% {
		opacity: 1;
		transform: translateY(0) scaleY(1);
	}
}

// print
@page {
	size: A4;
	margin: 0.5cm;
	margin-top: 1.5cm;
	margin-bottom: 1.5cm;
}
@page :first {
	margin-top: 0;
	// margin-bottom: 1in;
	@top-center {
		content: '소상공인 온라인 진출지원 플랫폼(온판)의 "자가진단 서비스"를 통한 결과 화면으로 출력 되었습니다.';
	}
	@bottom-center {
		content: '온판';
	}
}

.selfdx-print-header {
	display: none;
}
@media print {
	body {
		min-width: initial;
		background-color: #fff;
	}
	* {
		color: #000;
	}
	nav,
	.no-print {
		display: none;
	}
	#gachi {
		min-width: initial;
	}
	.selfdx-print-header {
		display: flex;
		margin-bottom: 4em;
		padding-bottom: 0.5em;
		border-bottom: 1px solid #ddd;
		font-size: 12px;
		font-weight: 300;
		color: #000;
		gap: 20px;
		.fl {
			display: flex;
			align-items: center;
			gap: 10px;
			.icon {
				width: 70px;
				img {
					width: 100%;
				}
				&.icon-selfdx-gachi {
					width: 50px;
				}
			}
		}
	}
	.selfdx-content {
		.icon {
			display: none;
		}
	}

	.gachi-header,
	.gachi-footer {
		display: none;
	}
	.selfdx-msection {
		display: block;
		min-height: initial;
		padding: 1em 0;
		// break-after: page;
		&-header {
			&__title,
			&__desc {
				color: #000;
			}
			&__title {
				font-size: 42px;
				letter-spacing: -2px;
			}
		}
		&-subcontainer {
			padding-bottom: 1em;
			border-bottom: 2px solid #ccc;
			&__title {
				font-size: 30px;
			}
			&__header {
				.icon {
					display: none;
				}
			}
			.display-flex {
				padding-left: 0;
				padding-right: 0;
				gap: 10px;
				transform: scale(0.8) translate(-8%, -10%);
				.flex-l {
					flex: 0 0 270px;
				}
			}
		}
		&-sub {
			&__header {
				.icon {
					display: none;
				}
			}
			&__img {
				border-color: #ddd;
			}
			&__graph {
				padding: 1em 0.5em;
				border-radius: 50px;
				border-color: #ddd;
				box-shadow: none;

				dt {
					font-size: 17px;
				}
				.circular-progress {
					svg {
						border-radius: 50%;
						overflow: hidden;
						border: 1px solid #ddd;
					}
				}
			}
			&__title {
				font-size: 26px;
			}
			&__info {
				dl {
					font-size: 14px;
					&:nth-child(1),
					&:nth-child(3) {
						width: calc(42% - 4px);
					}
					&:nth-child(2),
					&:nth-child(4) {
						width: calc(58% - 4px);
					}
				}
			}
			&__desc {
				.selfdx-voice {
					display: none;
				}
				.typing {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}
	.selfdx-result {
		&.display-flex {
			.flex-left {
				flex: 0 0 200px;
			}
			.flex-right {
				.card-item__image {
					border: 1px solid #ddd;
				}
			}
		}
		&-header {
			padding: 10px;
			border-radius: 15px;
			border: 1px solid #ddd;
			&__tag {
				border: 1px solid #eee;
				font-size: 12px;
			}
			&__title {
				font-size: 16px;
			}
			&__desc {
				height: 45px;
				font-size: 12px;
			}
			.buttons {
				position: static;
				margin-top: 0.5em;
				.button-default {
					height: 30px;
					border: 1px solid #645f5b;
					background-color: #fff;
					font-size: 14px;
					color: #645f5b;
				}
			}
		}
	}
	.selfdx-content {
		padding-top: 0;
		padding-bottom: 0;
	}
	.selfdx-section1-header__title {
		margin-top: 2em;
		font-size: 34px;
		color: #000;
		background: transparent;
	}
	.selfdx-section {
		&:last-child {
			margin-bottom: 0;
		}
		&.is-box {
			.selfdx-section-content {
				border-color: #ddd;
				font-size: 22px;
			}
			.selfdx-voice {
				display: none;
			}
		}
		&-header {
			&__title {
				text-shadow: none;
			}
			&__buttons {
				.button-default.is-medium.is-rounded {
					height: 45px;
					border-radius: 45px;
					border: 1px solid #645f5b;
					background-color: #fff;
					color: #645f5b;
				}
			}
		}
	}
	.selfdx-reserv {
		&-list {
			gap: 10px;
		}
		&-item {
			padding: 0.5em;
			border: 1px solid #ddd;
			.fr {
				padding-left: 10px;
			}
			&-text {
				&__title {
					font-size: 16px;
					letter-spacing: -1px;
				}
				&__desc {
					height: 28px;
					font-size: 12px;
				}
			}
			.buttons {
				.button-default {
					height: 30px;
				}
			}
		}
	}
	.selfdx-footer {
		display: none;
	}
	#selfdxElearning {
		.card-item {
			border: 1px solid #ddd;
			&-body {
				padding: 10px;
			}
			&__title {
				font-size: 16px;
			}
			&__desc {
				font-size: 12px;
			}
			.buttons {
				.button-default {
					height: 30px;
				}
			}
		}
	}
	#selfdxPanroTv {
		.selfdx-section-header {
			flex: 0 0 380px;
		}
		.live-card {
			.live-video-link {
				border: 1px solid #ddd;
			}
			.video-content-title {
				color: #000;
			}
		}
	}
	#selfdxNews {
		.card-list {
			.card-item {
				&__image {
					border: 1px solid #ddd;
				}
				&__category,
				&__title {
					color: #000;
				}
				&__title {
					font-size: 16px;
				}
			}
		}
	}
}
