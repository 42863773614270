/*#sme-edu {}*/
/*#sme-edu .page-component {padding:0 0 20px;}*/
/*#sme-edu .list-header {padding:40px 20px 20px}*/
/*#sme-edu .list-header .title {font-size:20px;}*/

/*#sme-edu .card-image {}*/
/*#sme-edu .card-content {padding:20px;background:white;margin-bottom:20px;}*/
/*#sme-edu .card-content .tags .tag {font-size:16px; font-weight: bold; line-height: 22px;}*/
/*#sme-edu .card-content .info {margin-top:10px;}*/
/*#sme-edu .card-content .info span {font-size:14px; color:#999;}*/
/*#sme-edu .card-content .info .text:before {content:"|"; margin:0 10px;}*/
/*#sme-edu .card-content .actions {margin-top:20px;}*/
/*#sme-edu .card-content .actions .btn-outline-white {width:90px;margin-right:10px;}*/
/*#sme-edu .card-content .actions .btn-outline-white .text {color:#015AA8;}*/
/*#sme-edu .card-content .actions .btn-primary {width:90px;background:white;border-color:#ebebeb;}*/
/*#sme-edu .card-content .actions .btn-primary .text {color:#FF7300;}*/

/*#sme-edu .edu-banner {position:relative;}*/
/*#sme-edu .edu-banner .banner-content  {z-index:1;position:absolute;left:0;bottom:40px;width:100%;}*/
/*#sme-edu .edu-banner .banner-title {font-size:30px;font-weight:bold;color:#fff;line-height:40px;padding-left:20px;}*/
/*#sme-edu .edu-banner .banner-subtitle {font-size:1rem;color:#fff;line-height:22px;margin-bottom:8px}*/
/*#sme-edu .edu-banner .banner-image {position:relative;height:0;padding-top:100%;line-height:0;}*/
/*#sme-edu .edu-banner .banner-image img {position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover;}*/

[class*='-component'] {
	padding: 0 15px;
}

[id^='sme-edu'] .page-header .page-header-title .title {
	text-align: left;
}
[id^='sme-edu'] .list-header {
	margin: 0;
	border: 0;
	padding-top: 40px;
}
[id^='sme-edu'] .edu-banner {
	position: relative;
	height: 375px;
}
[id^='sme-edu'] .edu-banner .banner-content {
	z-index: 1;
	position: absolute;
	left: 0;
	top: 40px;
	width: 100%;
	padding: 30px 20px 30px;
}
[id^='sme-edu'] .edu-banner .banner-title {
	font-size: 1.5rem;
	font-weight: 700;
	color: #fff;
	line-height: 54px;
	font-family: var(--font-family-nanumsquare);
}
[id^='sme-edu'] .edu-banner .banner-title img {
	width: 34px;
	height: 32px;
}
[id^='sme-edu'] .edu-banner .banner-text {
	margin-bottom: 10px;
	font-size: 1.3rem;
	line-height: 26px;
	color: #fff;
}
[id^='sme-edu'] .edu-banner .banner-image {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	line-height: 0;
	text-align: center;
}
[id^='sme-edu'] .edu-banner .banner-image img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}
[id^='sme-edu'] .edu-contents .line-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
[id^='sme-edu'] .edu-contents .line {
	width: 250px;
}
[id^='sme-edu'] .edu-contents .line-box {
	width: 900px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 18px;
}
#sme-edu .page-component .line-component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

[id^='sme-edu'] .edu-swiper-container {
	overflow: hidden;
	position: relative;
	padding-top: 60px;
	margin-top: -45px;
	padding-bottom: 15px;
}
[id^='sme-edu'] .edu-slide-item {
	width: 100%;
	height: 100%;
}
[id^='sme-edu'] .edu-swiper-buttons {
	position: absolute;
	right: 0;
	top: 5%;
	display: flex;
}
[id^='sme-edu'] .edu-swiper-buttons .btn {
	width: 30px;
	height: 30px;
	border: 1px solid #ebebeb;
	background-color: #f3f5f7;
	padding: 0;
	margin-left: 5px;
}
[id^='sme-edu'] .edu-swiper-buttons .icon-arrow {
	width: 6px;
	height: 10px;
	background-image: url(~@/assets/img/edu/icon_swiper_arrow.svg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev1 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev2 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev3 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev4 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .swiper-button-disabled .icon-arrow {
	opacity: 0.5;
}
[id^='sme-edu'] .lecture-list-container {
	position: relative;
}
[id^='sme-edu'] .lecture-article {
	background-color: #fff;
	padding: 0;
}
[id^='sme-edu'] .lecture-article .article-header {
	margin-bottom: 0;
}
[id^='sme-edu'] .lecture-article .article-header .title {
	font-size: 0.85rem;
	line-height: 20px;
	font-weight: 700;
	font-family: var(--font-family-nanumsquare);
}
[id^='sme-edu'] .lecture-article .article-header .subtext {
	display: block;
	color: #999;
}
[id^='sme-edu'] .edu-item {
	padding: 20px 0;
}
[id^='sme-edu'] .edu-box {
	position: relative;
	display: flex;
	flex-direction: column;
}
[id^='sme-edu'] .edu-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
[id^='sme-edu'] .edu-thumbnail {
	width: 100%;
	height: 84%;
}
[id^='sme-edu'] .edu-thumbnail .thumbnail-link {
	width: 100%;
	height: 100%;
}
[id^='sme-edu'] .edu-thumbnail .thumbnail-link img {
	width: 100%;
	height: 100%;
}
[id^='sme-edu'] .edu-contents {
	line-height: 15px;
	padding-right: 0px;
	padding-top: 10%;
}
[id^='sme-edu'] .edu-contents .tags {
	margin-top: 0.4rem;
	font-size: 0.8rem;
	display: inline-block;
	text-overflow: clip;
	overflow: hidden;
	white-space: nowrap;
	width: 90%;
	text-overflow: ellipsis;
}
[id^='sme-edu'] .edu-contents .description {
	color: #999;
	font-weight: bold;
	font-size: 0.6rem;
}
[id^='sme-edu'] .edu-contents .title {
	width: 100%;
	font-weight: 900;
	font-size: 1.7rem;
	line-height: 34px;
}
[id^='sme-edu'] .edu-contents .text {
	font-weight: bold;
	font-size: 14px;
	line-height: 1.5;
}
[id^='sme-edu'] .edu-contents .subtitle {
	text-align: left;
	margin-bottom: 6px;
	font-size: 1.06rem;
	line-height: 15px;
	font-weight: bold;
}
[id^='sme-edu'] .edu-buttons .btn {
	width: 100%;
	height: 60px;
}
[id^='sme-edu'] .edu-buttons .btn .text {
	font-weight: 700;
}

[id^='sme-edu'] .edu-actions {
	margin-top: 10px;
}
[id^='sme-edu'] .edu-actions .btn {
	display: none;
	width: 95px;
	height: 50px;
}
[id^='sme-edu'] .edu-actions .text {
	font-size: 15px;
}
[id^='sme-edu'] .edu-actions .btn-outline-white .text {
	font-size: 17px;
	color: #015aa8;
}
[id^='sme-edu'] .edu-actions .btn-outline-white:hover {
	background-color: #015aa8;
}
[id^='sme-edu'] .edu-actions .btn-outline-white:hover .text {
	color: white;
}

#sme-edu .edu-banner {
	background-color: rgba(0, 0, 0, 0.5);
}
#sme-edu-lectures .edu-banner {
	background-color: #999;
}
#sme-edu-apply .edu-banner {
	background-color: #77c2c2;
}
#sme-edu-apply .edu-section-text {
	background-color: #fff;
}

[id^='sme-edu'] .edu-section {
	position: relative;
	padding-top: 10px;
	padding-bottom: 40px;
	background-color: #deede2;
}

.edu-section {
	padding: 30px 0 40px;
}
.edu-section-text {
	background-color: #f3f5f7;
}
.edu-section-text .section-header {
	margin-bottom: 0;
	text-align: center;
}
.edu-section-text .section-header .title {
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 30px;
	font-family: var(--font-family-nanumsquare);
	margin-bottom: 20px;
}
.edu-section-text .section-header .subtext {
	font-size: 16px;
	line-height: 22px;
	color: #555;
}
.edu-section-text .section-header .subtext a {
	color: #555;
}
.edu-section-text .text-container {
	text-align: center;
}
.edu-section-text .text-container .text {
	word-break: keep-all;
	font-size: 16px;
	line-height: 30px;
	min-width: 300px;
}
.edu-section-text .text-container .left-text {
	text-indent: 0 !important;
	line-height: 30px;
}
.edu-section-text .text-container .text .text-link {
	font-weight: bold;
	color: #015aa8;
	text-decoration: underline;
	font-size: 1.1rem;
}
.edu-section-text .text-container .text .text-link ~ span {
	font-size: 12px;
}
.edu-section-text .text-container .left-text .text-link {
	font-weight: bold;
	color: #015aa8;
	text-decoration: underline;
}
.edu-section-text .btn-container {
	margin-top: 30px;
}
.edu-section-text .btn-container .btn {
	width: 100%;
	height: 60px;
}
.edu-section-text .btn-container .text {
	font-weight: bold;
}

.edu-section-course .section-header img {
	width: 30px;
}

.edu-section-banner {
	padding: 20px 0 !important;
}
.edu-section-banner .banner-container {
}
.edu-section-banner .banner {
	position: relative;
	width: 100%;
}
.edu-section-banner .banner-01 {
	background-color: #999;
}
.edu-section-banner .banner-02 {
	background-color: #6eb4b4;
	margin-top: 20px;
}
.edu-section-banner .banner-image {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.edu-section-banner .banner-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: right;
}
.edu-section-banner .banner-content {
	z-index: 1;
	position: relative;
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	min-height: 250px;
}
.edu-section-banner .banner-content .subtitle {
	font-size: 1.2rem;
	line-height: 15px;
	margin-bottom: 12.5rem;
	font-weight: bold;
	color: #fff;
}
.edu-section-banner .banner-content .title {
	font-size: 1.4rem;
	line-height: 32px;
	font-weight: 700;
	margin-bottom: 16px;
	color: #fff;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-banner .banner-content .description {
	font-size: 1.1rem;
	color: #fff;
	line-height: 22px;
}
.edu-section-banner .banner-content .buttons {
	width: 130px;
	flex: 1 1 auto;
	display: flex;
	align-items: flex-end;
	padding-top: 10px;
}
.edu-section-banner .banner-content .buttons .btn {
	width: 160px;
}
.edu-section-banner .banner-content .buttons .text {
	color: #015aa8;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 20px;
}
.edu-section-banner .banner-content .banner-preview {
	background-color: #999;
}
.edu-section-banner .banner-content .buttons .subtext {
	text-align: center;
	color: #015aa8;
	font-size: 0.95rem;
	font-weight: bold;
}

.edu-section-course {
	text-align: center;
}
.edu-section-course .section-header {
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.edu-section-course .course-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.edu-section-course .course-header {
	margin-bottom: 30px;
}
.edu-section-course .course-header .title {
	font-size: 20px;
	line-height: 26px;
	font-weight: 700;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-course .course-thumbnail {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 59.7%;
	border-radius: 1px;
	overflow: hidden;
	isolation: isolate;
	margin-bottom: 20px;
}
.edu-section-course .course-thumbnail img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.edu-section-course .course-content .text {
	word-break: keep-all;
	text-indent: 3px;
	font-size: 1.06rem;
	text-align: left;
	margin: 5px 7px 5px 7px;
}
.edu-section-course .course-content .title .text-link {
	text-align: center;
	margin-top: 5px;
	font-size: 0.6rem;
	line-height: 18px;
	font-weight: bold;
	color: #ff7300;
}
.edu-section-course .course-content .subtext {
	margin-top: 8px;
	font-size: 13px;
	line-height: 18px;
	font-weight: bold;
	color: #ff7300;
}
.edu-section-course .course-title {
	text-align: center;
	margin-top: 25px;
}
.edu-section-login {
	background-color: #f3f5f7;
	text-align: center;
}
.edu-section-login .section-header {
	margin-bottom: 20px;
}
.edu-section-login .section-header .title {
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	font-family: var(--font-family-nanumsquare);
	color: #222;
}
.edu-section-login .section-header .subtext {
	font-size: 16px;
	line-height: 24px;
	color: #555;
}
.edu-section-login .login-container {
	width: 100%;
	margin: auto;
}
.edu-section-login .login-container .form-row ~ .form-row {
	padding-top: 10px;
}
.edu-section-login .login-container .btn-login {
	margin-top: 20px;
	width: 100%;
	height: 60px;
}

/* 디자인 수정 */
// @font-face {
// 	font-family: 'TmonMonsori';
// 	font-display: swap;
// 	src: url('./font/TmonMonsori.ttf');
// }
.edu-banner .banner-image.corner-image::before {
	display: block;
	width: 270px;
	height: 180px;
	content: '';
	clear: both;
	position: absolute;
	bottom: -3%;
	right: 0;
	background: url(../img/edu/banner_cont.png) no-repeat bottom right / contain;
	z-index: 2;
}
.edu-contents .edu-inner {
	display: flex;
	max-width: 1280px;
	min-height: 1100px;
	margin: 0 auto;
	flex-direction: column;
	align-items: center;
	z-index: -1;
}
.edu-contents .edu-inner .edu-inner_tit {
	position: relative;
	margin-left: 140px;
	margin-right: 140px;
}
#sme-edu .page-component .line-component {
	padding-left: 0;
	padding-right: 0;
}
.section-container.page-component .text {
	margin-top: 15px;
	font-weight: 600;
}
.green {
	color: #1c6961;
	font-weight: 900;
}
.green_bold {
	color: #263d2c;
	font-weight: 900;
}
.red {
	color: red;
	font-weight: 600;
}
.edu-section-course .course-title .course-title-line {
	padding: 0 10px;
	font-size: 24px;
	font-weight: 900;
}
.edu-contents .edu-inner .course-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.edu-section-course .course-content {
	margin: 40px 0 20px;
	padding: 30px;
	position: relative;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.edu-section-course .course-content .ico_01 {
	width: 90px;
	height: 90px;
	position: absolute;
	top: -12%;
	right: 5%;
	background: url(~@/assets/img/edu/con_icon_01.png) no-repeat top left / contain;
}
.edu-section-course .course-content .ico_02 {
	width: 90px;
	height: 90px;
	position: absolute;
	top: -12%;
	right: 5%;
	background: url(~@/assets/img/edu/con_icon_02.png) no-repeat top left / contain;
}
.edu-section-course .course-content .title {
	font-size: 26px;
	line-height: 24px;
	font-weight: 900;
	font-family: var(--font-family-nanumsquare);
	text-align: left;
}
.edu-section-course .course-content .text {
	margin: 15px 0;
}
.edu-section-course .course-content .text > p {
	margin-bottom: 10px;
	font-size: 17px;
	font-weight: 600;
	line-height: 30px;
	color: #333;
}
.edu-section-course .course-content .text > span {
	font-size: 13px;
	color: #575757;
	word-break: keep-all;
}
.edu-section-course.section01 .course-content .btn_more {
	text-align: left;
}
.edu-section-course .course-content .btn_more .text-link {
	display: inline-block;
	padding: 15px 60px 15px 30px;
	background: #f64081 url(~@/assets/img/edu/ico_more_w.png) no-repeat top 50% right 15%;
	border-radius: 20px;
	font-size: 24px;
	color: #fff;
}
.edu-section.section02 {
	padding: 50px 0;
	background: #fff;
	color: #1c6961;
}
.edu-section.section02 .section-header .title {
	font-size: 28px;
	word-break: keep-all;
}
.box_step {
	width: 100%;
	padding: 15px 5px;
	border: 10px dashed #bfdec7;
	text-align: center;
}
.box_step .text-container .text {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}
.box_step .text-container .text .step {
	width: 20%;
	text-align: right;
	color: #1c6961;
}
.box_step .text-container .text .step_content {
	width: 75%;
	text-align: left;
}
.box_step .text:last-child p {
	margin-right: 7px;
}
.box_step .text p {
	display: inline-block;
	color: #1c6961;
	font-size: 16px;
	font-weight: 600;
}
.box_step .text .text-link {
	font-size: 22px;
	font-weight: 600;
}
.box_step .green {
	color: #1c6961;
	text-decoration: underline;
	font-weight: 600;
}
.box_step .btn_step {
	display: inline-block;
	padding: 0px 5px;
	background: #deede2;
	border: 3px dashed #bfdec7;
	border-radius: 20px;
	margin-bottom: 5px;
}
.box_step .btn_step span {
	font-weight: 900;
	font-size: 11px;
}
.edu-section.section03 {
	padding: 50px 0;
	background: #f3f3f3;
}
.edu-section.section03 .banner {
	display: flex;
	padding: 15px 5px;
	justify-content: space-between;
	align-items: center;
	border-radius: 15px;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.edu-section.section03 .banner.left {
	margin-bottom: 20px;
	background: #1b5e57;
	border: 10px solid #103c37;
}
.edu-section.section03 .banner.right {
	background: #bfdec7;
	border: 10px solid #7fb18c;
}
.edu-section.section03 .banner-image {
	width: 33%;
	height: inherit;
	position: inherit;
}
.edu-section.section03 .banner-image img {
	width: 100px;
	height: 100px;
}
.edu-section.section03 .banner-content {
	width: 60%;
}
.edu-section.section03 span {
	display: inline-block;
}
.edu-section.section03 .font44 {
	font-size: 44px;
	margin-bottom: 10px;
	line-height: 56px;
	font-family: 'Pretendard';
}
.edu-section.section03 .font30 {
	font-size: 30px;
}
.edu-section.section03 .banner-content {
	padding: 0;
}
.edu-section.section03 .banner-content .title {
	font-size: 1.4em;
	font-weight: 900;
	font-family: 'TmonMonsori';
	line-height: 40px;
	word-break: keep-all;
}
.edu-section.section03 .banner-content .description {
	color: #f6dc8a;
	font-size: 0.9em;
	line-height: 26px;
}
.edu-section.section03 .banner.right .title {
	color: #1b5e57;
}
.edu-section.section03 .banner.right .description {
	width: 100%;
	background: #284340;
	color: #fff;
	font-size: 16px;
	text-align: center;
	line-height: 40px;
}
.edu-section.section03 .btn-banner {
	display: inline-block;
	margin-top: 15px;
	padding: 10px 45px 10px 20px;
	background: #f64081 url(~@/assets/img/edu/ico_more_w.png) no-repeat top 50% right 10%;
	border-radius: 20px;
	font-size: 18px;
	color: #fff;
}
.edu-section.section03 .btn-banner .text {
	font-size: 14px;
	word-break: keep-all;
}

@media (max-width: 365px) {
	.box_step .btn_step {
		padding: 0px 2px;
		border: 2px dashed #bfdec7;
	}
	.box_step .btn_step span {
		font-size: 10px;
	}
}

@media (min-width: 450px) and (max-width: 1300px) {
	.edu-section-course .course-content {
		width: 450px;
	}
}
