/* #sme-connect {background-color:#f3f5f7;} */
#sme-connect {}
#sme-connect .page-header {padding:40px 0 40px;}
#sme-connect .tag-search {width:100%;margin:20px auto 20px}
#sme-connect .tag-search .form-control {padding-left:65px;background:url(../img/connect/tag_search_icon.svg) no-repeat 20px 50%}
#sme-connect .tag-area {display:flex;justify-content:flex-start;margin:-5px 0;flex-wrap:wrap}
#sme-connect .tag-area .tag-item {margin:5px;}
#sme-connect .tag-area .tag-item button {height:40px;}
#sme-connect .tag-area .tag-item button .text {height:40px;font-size:16px;line-height: 40px;;}
#sme-connect .tag-area .text {position:relative;}
#sme-connect .tag-area .text:before {content:'# ';font-weight:bold;color:#999}
#sme-connect .tag-area .btn-primary .text:before {content:'#';font-weight:bold;color:#fff}
/* #sme-connect .page-body {padding-top:20px;} */
#sme-connect .page-body {padding-top:20px; background-color:#f3f5f7}  /* 해당 영역에만 백그라운드 컬러 추가 (20220802 hib)*/
#sme-connect .page-body .connect-wrap {}
#sme-connect .page-body .creator-list {margin-bottom:40px;}
#sme-connect .page-body .creator-item {}
#sme-connect .filter-selector {position:relative;}
#sme-connect .filter-selector .form-select {width:160%}
#sme-connect .page-buttons {justify-content:center;}
#sme-connect .page-buttons .btn {width:250px;}

#sme-connect .community-title {display:flex;justify-content:center;padding:10px;}
#sme-connect .community-title .community-title-desc {word-break:keep-all;padding:10px;font-weight:700;border-radius:2px;background-color:white;color:#111;text-align: center;font-size:1.1rem;line-height: 1.5;}
#sme-connect .community-title .community-title-desc img {width:100%;margin-top: 10px}


#sme-connect .community-banner {position:relative;}
#sme-connect .community-banner .banner-content {z-index:1;position:absolute;left:0;bottom:40px;width:100%;}
#sme-connect .community-banner .banner-title {padding-left:20px;font-size:20px;font-weight:bold;color:#fff;line-height:30px}
#sme-connect .community-banner .banner-subtitle {font-size:1rem;color:#fff;line-height:22px;margin-bottom:8px}
#sme-connect .community-banner .banner-image {margin-top:55px; position:relative;height:0;padding-top:100%;line-height:0;}
#sme-connect .community-banner .banner-image img {position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover;}
#sme-connect .community-banner .deem-banner {position:absolute; top:0; left:0; width:100%; height:100%; background:linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.35));}

/* #sme-connect .section-notice-arrow {position: absolute;right: 15px;top: 91px;display: flex;z-index: 100;}
#sme-connect .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
#sme-connect .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-connect .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-connect .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
#sme-connect .disabled {opacity:.3;}
#sme-connect .btn-arrow:not(:first-child) {margin-left:5px;}
#sme-connect [class^="icon-arrow"] {width:15px;height:15px;}
#sme-connect .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
#sme-connect .icon-arrow-next {background-image:url(../img/main/notice_next.svg)} */

#sme-connect .community-list {}
#sme-connect .community-list-item {width:100%;}
#sme-connect .community-list-item:not(:last-child) {margin-bottom:20px}
#sme-connect .community-list-item:last-child {margin-bottom:40px;}


#sme-connect .creator-card {position:relative;padding:20px;background-color:#fff;border-radius:1px;width:100%;}

#sme-connect .creator-card .community-content {padding-bottom:15px}
#sme-connect .creator-card .community-content .badge {margin-bottom:12px}
#sme-connect .creator-card .community-content .text {line-height:22px;font-size:1rem;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;max-height:66px;}
#sme-connect .creator-card .community-images {overflow:hidden;position:relative;}
#sme-connect .creator-card .community-image-wrapper {}
#sme-connect .creator-card .community-image {position:relative;width:100%;height:0;padding-top:61.5%}
#sme-connect .creator-card .community-image img {position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover;}
#sme-connect .creator-card .community-info {padding:20px 0}
#sme-connect .creator-card .community-info .info-row {display:flex;min-width:0}
#sme-connect .creator-card .community-info .info-item {position:relative;font-size:13px;color:#555;}
#sme-connect .creator-card .community-info .info-item.writer {overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
#sme-connect .creator-card .community-info .info-item:not(.writer) {flex-shrink:0;}
#sme-connect .creator-card .community-info .info-item:not(:first-child):before {content:'·';display:inline-block;margin-right:2px;margin-left:4px;}
#sme-connect .creator-card .community-actions {position:relative;border-top:1px solid #ebebeb;display:flex;padding-top:20px}
#sme-connect .creator-card .community-actions .actions-btn {display:inline-flex;align-items:center;}
#sme-connect .creator-card .community-actions .actions-btn:not(:first-child) {margin-left:15px}
#sme-connect .creator-card .community-actions .icon-reply {width:20px;height:20px;background-image:url(../img/community/reply_icon.svg);margin-right:10px;}
#sme-connect .creator-card .community-actions .icon-message {width:20px;height:20px;background-image:url(../img/community/message_icon.svg);margin-right:10px;}
#sme-connect .creator-card .community-actions .actions-text {font-size:13px;font-weight:900;color:#015aa8}
#sme-connect .creator-item {flex:0 0 100%;padding:20px 0}
#sme-connect .creator-list {display: inline }


#sme-connect-sub {}
#sme-connect-sub .connect-sub-header {position:relative;height:550px;}
#sme-connect-sub .connect-sub-cover {position:relative;}
#sme-connect-sub .connect-sub-cover .creator-cover{position:relative;height:0;padding-top:100%}
#sme-connect-sub .connect-sub-cover .creator-cover .image{position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover;}
#sme-connect-sub .creator-avatar {position:relative;width:140px;height:140px}
#sme-connect-sub .creator-avatar img {width:100%;height:100%;object-fit:cover;border-radius:70px}
#sme-connect-sub .creator-avatar .icon-authentication {width:32px;height:32px;background-size:32px auto;position:absolute;right:8px;bottom:0}
#sme-connect-sub .creator-content {z-index:1;position:absolute;left:0;bottom:0;width:100%;background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.35) 35%, rgba(0,0,0,0) 100%);padding-bottom:40px;padding-top:60px}
#sme-connect-sub .creator-content *{color:#fff;}
#sme-connect-sub .creator-content .creator {display:flex;align-items:center;justify-content:space-between;}
#sme-connect-sub .creator-content .creator-name {font-size:30px;font-weight:bold;}
#sme-connect-sub .creator-content .creator-title {font-size:40px;font-weight:bold;padding-top:10px;}
#sme-connect-sub .creator-content .creator-tag {margin-bottom:10px;}
#sme-connect-sub .creator-content .icon-authentication {width:32px;height:32px;background-image:url(../img/common/icon_authentication_check.svg);}
#sme-connect-sub .connect-sub-body {position:relative;padding:50px 20px 0;background-color:#fff;}
#sme-connect-sub .connect-sub-body .connect-body-title {display:flex;justify-content:space-between;margin-bottom:30px;}
#sme-connect-sub .connect-sub-body .connect-body-title .title {font-size:20px;line-height:26px;font-weight:900}
#sme-connect-sub .connect-sub-body .sns-buttons {display:flex;justify-content:center;padding-top:40px;}
#sme-connect-sub .connect-sub-body .sns-buttons [class^="btn-"] {position:relative;text-align:center;width:106px}
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:before,
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:after {content:'';position:absolute;top:50%;transform:translateY(-50%); width:1px;height:43px;background-color:#ebebeb}
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:before {left:0}
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:after {right:0}
#sme-connect-sub .connect-sub-body .sns-buttons [class^="icon"] {width:24px;height:24px;background-position:50%}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-youtube {background-image:url(../img/connect/icon_youtube.svg)}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-insta {background-image:url(../img/connect/icon_insta.svg)}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-facebook {background-image:url(../img/connect/icon_facebook.svg)}
#sme-connect-sub .connect-sub-body .sns-buttons .text {display:block;padding-top:15px;font-weight:bold;font-size:13px;color:#999}

.ie #sme-connect .page-body .connect-wrap {}
.ie #sme-connect .page-body .connect-wrap .creator-item {flex:0 0 285px}

/* 셀렉트 추가 S (20220721 - hib) */
#sme-connect .box_select {width: 205px;position: absolute;top: 8px;right: 5px;z-index: 999;}
#sme-connect .box_select button {width: 100%;padding: 8px 12px;text-align: left;background: #999999;color: #fff;border-radius: 5px;position: relative;top: 3px;}
#sme-connect .box_select button::after {display: inline-block;width: 28px;height: 26px;content: '';background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;position: absolute;top: 3px;right: 2px;}
#sme-connect .box_select button.active::after {background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;transform: rotate(180deg);}
#sme-connect .box_select ul {display: none;width: 100%;padding: 12px 10px 8px;border-width: 0 1px 1px 1px;border-style: solid;border-color: #999999;}
#sme-connect .box_select ul li {font-size: 14px;font-weight: 900;}
#sme-connect .box_select ul li + li {margin-top: 8px;}
#sme-connect .box_select .show_list {display: block; background: #fff;}
/* 셀렉트 추가 E (20220721 - hib) */