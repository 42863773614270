/* 게시판 반응형 스타일 : 2024 */

.board-list-wrapper {
	margin-top: 30px;
	border-top: 1px solid #ebebeb;
}
.board-list-search {
	display: flex;
	padding: 20px 0;
	margin: 20px 0 25px;
	border: 1px solid #ebebeb;
	background: #f9f9f9;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	@include mobile {
		padding: 10px;
		gap: 5px;
		.form-select {
			height: 50px;
			padding: 10px 25px 10px 10px;
			background-position: calc(100% - 5px) 50%;
		}
	}
	.form-select {
		font-weight: 400;
	}

	input {
		margin-right: 10px;
		padding: 11px 30px;
		color: #111;
		border: 0;
		box-shadow: 0 0 0 2px #ebebeb inset;
		border-radius: 2px;
		&:focus {
			box-shadow: 0 0 0 2px #015aa8 inset;
		}
		@include mobile {
			height: 50px;
			padding: 10px 10px;
		}
	}

	.box-calendar {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	&__box {
		@include mobile {
			display: flex;
			gap: 0;
			input {
				width: calc(100% - 51px);
				margin: 0;
			}
		}
	}
	.calendar {
		display: inline-block;
		position: relative;
		.calendar {
			.mx-icon-calendar {
				display: inline-block;
				width: 40px;
				height: 100%;
				position: absolute;
				right: 1px;
				background: #015aa8;
				border-radius: 5px;
				> svg {
					width: 50%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					fill: white;
					height: 2em;
				}
			}
			input {
				width: 150px;
				height: auto;
				padding: 10px 15px;
				border: 0;
				box-shadow: 0 0 0 2px #ebebeb inset;
				border-radius: 2px;
			}
			span {
				display: inline-block;
				width: 40px;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				background: #015aa8;
				border-radius: 5px;
			}
			img {
				width: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		input:focus {
			box-shadow: 0 0 0 2px #015aa8 inset;
		}
	}

	.button-search {
		background-color: #015aa8;
		&:after {
			content: '';
			display: flex;
			width: 19px;
			height: 20px;
			margin-left: 5px;
			background-image: url(~@/assets/img/common/search_w.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			align-items: center;
			justify-content: center;
			@include mobile {
				margin-left: 0;
				width: 27px;
				height: 28px;
			}
		}
		@include mobile {
			height: 50px;
			padding: 0 10px;
			font-size: 0;
			.text {
				display: none;
			}
		}
	}
}

.board-list-header {
	display: flex;
	margin-bottom: 10px;
	justify-content: space-between;
	align-items: center;
	.header-left {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.all-page {
			margin-right: 10px;
			padding-left: 15px;
			background: url(~@/assets/img/notice/ico_list.png) no-repeat top 2px left / 13px;
		}
		.current-page {
			padding-left: 15px;
			background: url(~@/assets/img/notice/ico_current.png) no-repeat top 2px left / 12px;
		}
		span {
			color: #e24d51;
		}
	}
	.header-right .btn-cur {
		margin-right: 10px;
		background: #424242;
		&:hover {
			background: #5b5b5b;
		}
		.text {
			color: #fff;
		}
	}
}

.board-list-body {
	table {
		width: 100%;
		text-align: center;

		tr {
			height: 50px;
			border-bottom: 1px solid #dce2e8;
		}
		th {
			position: relative;
			&::after {
				display: block;
				width: 1px;
				height: 20px;
				position: absolute;
				top: 0;
				right: -2px;
				background: #94a3f9;
				content: '';
				clear: both;
			}
			&:last-child::after {
				display: none;
			}
		}

		td {
			position: relative;

			&::after {
				display: block;
				width: 1px;
				height: 20px;
				position: absolute;
				top: 0;
				right: -2px;
				background: #dce2e8;
				content: '';
				clear: both;
				@include mobile {
					display: none;
				}
			}
			&:last-child::after {
				display: none;
			}
			.button-default.is-rounded {
				padding: 0 8px;
				white-space: nowrap;
			}
		}
	}
	colgroup {
		@include mobile {
			display: none;
		}
	}
	thead {
		@include mobile {
			display: none;
		}
		tr {
			background: #f3f9ff;
			border-top: 2px solid #94a3f9;
			box-shadow: 0 2px 5px rgb(184 196 251 / 100%);
		}
	}
	tbody {
		tr {
			@include mobile {
				display: flex;
				height: auto;
				padding: 10px 0;
				align-items: center;
				justify-content: space-between;
			}
		}
		td {
			padding: 0.5em 1em;
			line-height: 1.3;
			@include mobile {
				padding: 0;
			}
		}
		.icon img {
			width: 25px;
		}
		.attach img {
			width: 20px;
		}
	}
}

.blind {
	position: absolute !important;
	top: -9999rem !important;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
}

@include mobile {
	.hidden-mobile {
		display: none;
	}
}

#page-privacy {
	@include mobile {
		.board-list-search {
			.board-list-search__item {
				flex: 0 0 75px;
				&.header-column {
					flex: 1 1 auto;
				}
			}
		}
	}
}
