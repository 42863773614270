:root {
	/* Color system */
	/* gray-color-variables */

	--white: #fff;
	--gray-100: #f3f5f7;
	--gray-200: #ebebeb;
	--gray-300: #ececec;
	--gray-400: #ced4da;
	--gray-500: #bbb;
	--gray-600: #999;
	--gray-700: #555;
	--gray-800: #444;
	--gray-900: #111;
	--black: #000;

	/* color-variables */
	/* https://maketintsandshades.com/#0dcaf0 */
	/* https://noeldelgado.github.io/shadowlord/#ec663b */

	--blue: #015aa8; /* 13, 110, 253 */
	--indigo: #6610f2; /* 102, 16, 242 */
	--purple: #4834ff; /* 6, 42, 1 */
	--pink: #d63384; /* 214, 51, 132 */
	--red: #f14e6d; /* 220, 53, 69 */
	--orange: #ff7300; /* 253, 126, 20 */
	--brown: #bf6b00; /* 253, 126, 20 */
	--yellow: #ffc107; /* 255, 193, 7 */
	--green: #198754; /* 25, 135, 84 */
	--teal: #20c997; /* 32, 201, 151 */
	--cyan: #00babf; /* 13, 202, 240 */

	--gray: #999;
	--gray-dark: #111;
	--rgb-blue: 11, 95, 169;
	--rgb-indigo: 102, 16, 242;
	--rgb-purple: 6, 42, 1;
	--rgb-pink: 214, 51, 132;
	--rgb-red: 220, 53, 69;
	--rgb-orange: 253, 126, 20;
	--rgb-yellow: 255, 193, 7;
	--rgb-green: 25, 135, 84;
	--rgb-teal: 32, 201, 151;
	--rgb-cyan: 13, 202, 240;

	/* theme-color-variables */
	--primary: #015aa8;
	--secondary: #ff7300;
	--success: var(--green);
	--info: #00babf;
	--warning: var(--yellow);
	--danger: #f14e6d;
	--light: #ebebeb;
	--dark: #111;

	--spacer: 1.25rem;
	--spacer-0: 0;
	--spacer-1: calc(20px / 4); /* 5 */
	--spacer-2: calc(20px / 2); /* 10 */
	--spacer-3: 20px; /* 20 */
	--spacer-4: calc(20px * 1.5); /* 30 */
	--spacer-5: calc(20px * 2); /* 40 */
	--spacer-6: calc(20px * 2.5); /* 50 */
	--spacer-7: calc(20px * 3); /* 60 */

	--grid-columns: 12;
	--grid-gutter-width: 1.5rem;
	--grid-row-columns: 6;

	--gutter: 1.25rem;
	--gutter-0: 0;
	--gutter-1: calc(var(--gutter) / 4);
	--gutter-2: calc(var(--gutter) / 2);
	--gutter-3: var(--gutter);
	--gutter-4: calc(var(--gutter) * 1.5);
	--gutter-5: calc(var(--gutter) * 2);
	--gutter-6: calc(var(--gutter) * 2.5);
	--gutter-7: calc(var(--gutter) * 3);

	--container-padding-x: calc(20px * 1.25);

	/* Body */
	--body-bg: #ffffff;
	--body-color: #111;
	--body-text-align: null;

	/* links */
	--link-color: #015aa8;
	--link-decoration: underline;
	--link-shade-percentage: 20%;
	/*--link-hover-color:    shift-color(--link-color, --link-shade-percentage);*/
	--link-hover-decoration: null;
	--stretched-link-pseudo-element: after;
	--stretched-link-z-index: 1;

	/* border-variables */
	--border: #ebebeb;
	--border-width: 2px;
	--border-radius: 0.3125rem; /* 5 */
	--border-radius-xs: 0.15rem;
	--border-radius-sm: calc(0.3125rem / 2);
	--border-radius-lg: calc(0.3125rem * 2); /* 10 */
	--border-radius-pill: 50rem;

	--border-color: #ebebeb;

	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;

	/* box-shadow-variables */
	--box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	--box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--box-shadow-lg: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.05);
	--box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);

	/* font-variables */
	--font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	--font-family-notosans: 'Noto Sans KR', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	/* --font-family-blacksans:   'Black Han Sans', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
	--font-family-pretendard: 'Pretendard', 'Malgun Gothic', '맑은 고딕', Dotum, '돋움', system-ui, -apple-system, 'Segoe UI', 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	--font-family-nanumsquare: 'NanumSquare', system-ui, -apple-system, 'Segoe UI', 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// --font-family-base: var(--font-family-nanumsquare);
	--font-family-base: var(--font-family-pretendard);

	--font-size-base: 1rem;
	--font-size-xs: calc(1rem * 0.75); /* 12px */
	--font-size-sm: calc(1rem * 0.8125); /* 13px */
	--font-size-md: calc(1rem * 0.9375); /* 15px */
	--font-size-lg: calc(1rem * 1.25); /* 20px */

	--web-font-size: 15px;
	--tablet-font-size: 14px;
	--mobile-font-size: 13px;

	--font-weight-lighter: lighter;
	--font-weight-light: 300;
	--font-weight-normal: 400;
	--font-weight-bold: 700;
	--font-weight-bolder: bolder;

	--font-weight-base: var(--font-weight-normal);

	--line-height-base: 1.5;
	--line-height-sm: 1.25;
	--line-height-lg: 2;

	--h1-font-size: calc(1rem * 2.5); /* 40px */
	--h2-font-size: calc(1rem * 2); /* 32px */
	--h3-font-size: calc(1rem * 1.75); /* 28px */
	--h4-font-size: calc(1rem * 1.5); /* 24px */
	--h5-font-size: calc(1rem * 1.25); /* 20px */
	--h6-font-size: 1rem;

	--small-font-size: 0.75rem;

	--sub-sup-font-size: 0.75rem;

	--text-muted: #999;

	--initialism-font-size: var(--small-font-size);

	--gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--zindex-dropdown: 1000;
	--zindex-sticky: 1020;
	--zindex-fixed: 1030;
	--zindex-offcanvas: 1040;
	--zindex-modal-backdrop: 1050;
	--zindex-modal: 1060;
	--zindex-popover: 1070;
	--zindex-tooltip: 1080;

	/* table-variables*/
	--table-cell-padding-y: 0.5rem;
	--table-cell-padding-x: 0.5rem;
	--table-cell-padding-y-sm: 0.25rem;
	--table-cell-padding-x-sm: 0.25rem;

	--table-cell-vertical-align: top;

	--table-color: #111;
	--table-bg: transparent;

	--table-th-font-weight: 700;
	--table-th-bg-factor: 0.05;
	--table-th-bg: rgba(0, 0, 0, var(--table-th-bg-factor));

	--table-striped-color: var(--table-color);
	--table-striped-bg-factor: 0.05;
	--table-striped-bg: rgba(0, 0, 0, var(--table-striped-bg-factor));

	--table-active-color: --table-color;
	--table-active-bg-factor: 0.1;
	--table-active-bg: rgba(0, 0, 0, var(--table-active-bg-factor));

	--table-hover-color: --table-color;
	--table-hover-bg-factor: 0.075;
	--table-hover-bg: rgba(0, 0, 0, var(--table-hover-bg-factor));

	--table-border-factor: 0.1;
	--table-border-width: var(--border-width);
	--table-border-color: #ebebeb;

	--table-striped-order: odd;

	--table-group-separator-color: #000;

	--table-caption-color: var(--text-muted);

	--table-bg-scale: -80%;
	/* Buttons + Forms */
	--input-btn-padding-y: calc(0.9375rem - 3px);
	--input-btn-padding-x: 10px;
	--input-btn-font-family: 'NanumSquare', system-ui, -apple-system, 'Segoe UI', 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	--input-btn-font-size: 0.9375rem;
	--input-btn-line-height: var(--line-height-base);

	--input-btn-focus-width: 0.25rem;
	--input-btn-focus-color-opacity: 0.25;
	/*--input-btn-focus-color:        rgba(--component-active-bg, --input-btn-focus-color-opacity);*/
	--input-btn-focus-blur: 0;
	--input-btn-focus-box-shadow: 0 0 --input-btn-focus-blur --input-btn-focus-width --input-btn-focus-color;

	--input-btn-padding-y-xs: 0.125rem;
	--input-btn-padding-x-xs: 5px;
	--input-btn-font-size-xs: calc(1rem * 0.75);

	--input-btn-padding-y-sm: 0.4375rem;
	--input-btn-padding-x-sm: calc(10px + 5px);
	--input-btn-font-size-sm: calc(1rem * 0.8125);

	--input-btn-padding-y-lg: 0.5rem;
	--input-btn-padding-x-lg: 30px;
	--input-btn-font-size-lg: calc(1rem * 1.25);

	--input-btn-border-width: var(--border-width);

	/* btn-variables */
	--btn-padding-y: var(--input-btn-padding-y);
	--btn-padding-x: var(--input-btn-padding-x);
	--btn-font-family: var(--input-btn-font-family);
	--btn-font-size: var(--input-btn-font-size);
	--btn-line-height: var(--input-btn-line-height);
	--btn-white-space: null;

	--btn-padding-y-xs: var(--input-btn-padding-y-xs);
	--btn-padding-x-xs: var(--input-btn-padding-x-xs);
	--btn-font-size-xs: var(--input-btn-font-size-xs);

	--btn-padding-y-sm: var(--input-btn-padding-y-sm);
	--btn-padding-x-sm: var(--input-btn-padding-x-sm);
	--btn-font-size-sm: var(--input-btn-font-size-sm);

	--btn-padding-y-lg: var(--input-btn-padding-y-lg);
	--btn-padding-x-lg: 30px;
	--btn-font-size-lg: var(--input-btn-font-size-lg);

	--btn-border-width: var(--input-btn-border-width);

	--btn-font-weight: var(--font-weight-normal);
	--btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
	--btn-focus-width: var(--input-btn-focus-width);
	--btn-focus-box-shadow: var(--input-btn-focus-box-shadow);
	--btn-disabled-opacity: 0.65;
	--btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

	--btn-link-color: var(--link-color);
	--btn-link-hover-color: var(--link-hover-color);
	--btn-link-disabled-color: #999;

	--btn-border-radius: 0.3125rem;
	--btn-border-radius-xs: var(--border-radius-xs);
	--btn-border-radius-sm: calc(0.3125rem / 2);
	--btn-border-radius-lg: calc(0.3125rem * 2);

	--btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	--btn-hover-bg-shade-amount: 15%;
	--btn-hover-bg-tint-amount: 15%;
	--btn-hover-border-shade-amount: 20%;
	--btn-hover-border-tint-amount: 10%;
	--btn-active-bg-shade-amount: 20%;
	--btn-active-bg-tint-amount: 20%;
	--btn-active-border-shade-amount: 25%;
	--btn-active-border-tint-amount: 10%;

	/* Forms */
	--form-text-margin-top: 0.25rem;
	--form-text-font-size: var(--small-font-size);
	--form-text-font-style: normal;
	--form-text-font-weight: 400;
	--form-text-color: var(--text-muted);

	--form-label-margin-bottom: 0.5rem;
	--form-label-font-size: null;
	--form-label-font-style: null;
	--form-label-font-weight: null;
	--form-label-color: null;

	--input-padding-y: var(--input-btn-padding-y);
	--input-padding-x: 20px;
	--input-font-family: var(--input-btn-font-family);
	--input-font-size: var(--input-btn-font-size);
	--input-font-weight: var(--font-weight-base);
	--input-line-height: var(--input-btn-line-height);

	--input-padding-y-sm: var(--input-btn-padding-y-sm);
	--input-padding-x-sm: var(--input-btn-padding-x-sm);
	--input-font-size-sm: var(--input-btn-font-size-sm);

	--input-padding-y-lg: var(--input-btn-padding-y-lg);
	--input-padding-x-lg: 30px;
	--input-font-size-lg: var(--input-btn-font-size-lg);

	--input-bg: #ffffff;
	--input-disabled-bg: #ebebeb;
	--input-disabled-border-color: #bbb;

	--input-color: #111;
	--input-border-color: #ebebeb;
	--input-border-width: var(--input-btn-border-width);
	--input-box-shadow: var(--box-shadow-inset);

	--input-border-radius: 0.3125rem;
	--input-border-radius-sm: calc(0.3125rem / 2);
	--input-border-radius-lg: calc(0.3125rem * 2);

	--input-focus-bg: var(--input-bg);
	/*--input-focus-border-color:             tint-color(--component-active-bg, 50%);*/
	--input-focus-color: var(--input-color);
	--input-focus-width: var(--input-btn-focus-width);
	--input-focus-box-shadow: var(--input-btn-focus-box-shadow);

	--input-placeholder-color: #999;
	--input-plaintext-color: #111;

	/*--input-height-border:                  var(--input-border-width * 2);*/

	/*
    calc(calc(var(--input-line-height) * 1em) + calc(var(--input-padding-y) * 2) + var(--input-height-border))
    */
	--input-height: calc(calc(var(--input-line-height) * 1em) + calc(var(--input-padding-y) * 2) + var(--input-height-border));
	--input-height-sm: calc(calc(var(--input-line-height) * 1em) + calc(var(--input-padding-y-sm) * 2) + var(--input-height-border));
	--input-height-lg: calc(calc(var(--input-line-height) * 1em) + calc(var(--input-padding-y-lg) * 2) + var(--input-height-border));

	--input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	/* form-check */
	--form-check-input-width: 1em;
	--form-check-min-height: calc(1rem * var(--line-height-base));
	--form-check-padding-start: calc(var(--form-check-input-width) + 0.5em);
	--form-check-margin-bottom: 0.125rem;
	--form-check-label-color: null;
	--form-check-label-cursor: null;
	--form-check-transition: null;

	--form-check-input-active-filter: brightness(90%);

	--form-check-input-bg: --input-bg;
	--form-check-input-border: 1px solid rgba(0, 0, 0, 0.25);
	--form-check-input-border-radius: 0.25em;
	--form-check-radio-border-radius: 50%;
	--form-check-input-focus-border: --input-focus-border-color;
	--form-check-input-focus-box-shadow: --input-btn-focus-box-shadow;

	--form-check-input-checked-color: --component-active-color;
	--form-check-input-checked-bg-color: --component-active-bg;
	--form-check-input-checked-border-color: --form-check-input-checked-bg-color;
	--form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{--form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
	--form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{--form-check-input-checked-color}'/></svg>");

	--form-check-input-indeterminate-color: --component-active-color;
	--form-check-input-indeterminate-bg-color: --component-active-bg;
	--form-check-input-indeterminate-border-color: --form-check-input-indeterminate-bg-color;
	--form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{--form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

	--form-check-input-disabled-opacity: 0.5;
	--form-check-label-disabled-opacity: --form-check-input-disabled-opacity;
	--form-check-btn-check-disabled-opacity: --btn-disabled-opacity;

	--form-check-inline-margin-end: 1rem;

	/*form-switch-variables*/
	--form-switch-color: rgba(0, 0, 0, 0.25);
	--form-switch-width: 2em;
	--form-switch-padding-start: --form-switch-width + 0.5em;
	--form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{--form-switch-color}'/></svg>");
	--form-switch-border-radius: --form-switch-width;
	--form-switch-transition: background-position 0.15s ease-in-out;

	--form-switch-focus-color: --input-focus-border-color;
	--form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{--form-switch-focus-color}'/></svg>");

	--form-switch-checked-color: --component-active-color;
	--form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{--form-switch-checked-color}'/></svg>");
	--form-switch-checked-bg-position: right center;

	/*input-group-variables*/
	--input-group-addon-padding-y: var(--input-padding-y);
	--input-group-addon-padding-x: var(--input-padding-x);
	--input-group-addon-font-weight: var(--input-font-weight);
	--input-group-addon-color: var(--input-color);
	--input-group-addon-bg: #ebebeb;
	--input-group-addon-border-color: var(--input-border-color);

	/*form-select-variables*/
	--form-select-padding-y: var(--input-padding-y);
	--form-select-padding-x: var(--input-padding-x);
	--form-select-font-family: var(--input-font-family);
	--form-select-font-size: var(--input-font-size);
	--form-select-indicator-padding: calc(var(--form-select-padding-x) * 3);
	--form-select-font-weight: var(--input-font-weight);
	--form-select-line-height: var(--input-line-height);
	--form-select-color: var(--input-color);
	--form-select-bg: var(--input-bg);
	--form-select-disabled-color: null;
	--form-select-disabled-bg: #ebebeb;
	--form-select-disabled-border-color: var(--input-disabled-border-color);
	--form-select-bg-position: right var(--form-select-padding-x) center;
	--form-select-bg-size: 16px 12px;
	--form-select-indicator-color: #444;
	--form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{--form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

	--form-select-feedback-icon-padding-end: calc(calc(var(--form-select-padding-x) * 2.5) + var(--form-select-indicator-padding));
	--form-select-feedback-icon-position: center right var(--form-select-indicator-padding);
	/*--form-select-feedback-icon-size:       var(--input-height-inner-half) var(--input-height-inner-half);*/

	--form-select-border-width: var(--input-border-width);
	--form-select-border-color: var(--input-border-color);
	--form-select-border-radius: 0.3125rem;
	--form-select-box-shadow: var(--box-shadow-inset);

	--form-select-focus-border-color: var(--input-focus-border-color);
	--form-select-focus-width: var(--input-focus-width);
	--form-select-focus-box-shadow: 0 0 0 var(--form-select-focus-width) var(--input-btn-focus-color);

	--form-select-padding-y-sm: var(--input-padding-y-sm);
	--form-select-padding-x-sm: var(--input-padding-x-sm);
	--form-select-font-size-sm: var(--input-font-size-sm);

	--form-select-padding-y-lg: var(--input-padding-y-lg);
	--form-select-padding-x-lg: var(--input-padding-x-lg);
	--form-select-font-size-lg: var(--input-font-size-lg);

	/*form-file-variables*/
	--form-file-button-color: var(--input-color);
	--form-file-button-bg: var(--input-group-addon-bg);
	/*--form-file-button-hover-bg:      shade-color(--form-file-button-bg, 5%);*/

	/**Breadcrumbs**/
	/*breadcrumb-variables*/
	--breadcrumb-font-size: null;
	--breadcrumb-padding-y: 0;
	--breadcrumb-padding-x: 0;
	--breadcrumb-item-padding-x: 0.5rem;
	--breadcrumb-margin-bottom: 1rem;
	--breadcrumb-bg: null;
	--breadcrumb-column-color: #999;
	--breadcrumb-active-color: #999;
	/*--breadcrumb-column:               quote("/");*/
	--breadcrumb-column-flipped: var(--breadcrumb-column);
	--breadcrumb-border-radius: null;

	/* Badges*/
	/*badge-variables*/
	--badge-font-size: 0.75em;
	--badge-font-weight: var(--font-weight-bold);
	--badge-color: #ffffff;
	--badge-padding-y: 0.35em;
	--badge-padding-x: 0.65em;
	--badge-border-radius: calc(0.3125rem / 2);

	--ratio-1x1: 100%;
	--ratio-2x1: calc(1 / 2 * 100%);
	--ratio-4x3: calc(3 / 4 * 100%);
	--ratio-16x9: calc(9 / 16 * 100%);
	--ratio-21x9: calc(9 / 21 * 100%);
}
