/* 소담상회 수정 */
.title.title_sub img {
	width: 130px;
}
.sodam_wrap section {
	margin-bottom: 50px;
}
.sodam_wrap .business_introduce {
	padding-top: 29px;
}
.sodam_wrap .subtit {
	display: inline-block;
	margin-bottom: 15px;
	font-size: 21px;
	font-family: 'NanumSquare-otfeb';
	color: #333;
}
.sodam_wrap .dot {
	margin: 15px 0 5px;
	font-family: 'NanumSquare-otfeb';
}
.sodam_wrap p {
	line-height: 25px;
	font-family: 'NanumSquare-otfr';
	color: #575757;
}
/* .sodam_wrap .sodam_title{padding-top: 60px; text-align: center;}
.sodam_wrap .sodam_title .title{margin-bottom: 20px;}
.sodam_wrap .sodam_title .title img{width: 220px;height: 65px;} 
.sodam_wrap .sodam_title p{font-size: 18px;}*/
.sodam_wrap .apply .asterisk {
	margin-top: 5px;
	color: #777777;
}
.sodam_wrap .apply .box_text span {
	display: block;
	line-height: 22px;
}
.sodam_wrap .apply > div {
	margin-bottom: 50px;
}
.sodam_wrap .apply .space_introduce .space_content {
	display: flex;
	flex-wrap: wrap;
}
.sodam_wrap .apply .space_introduce .space_content .left,
.sodam_wrap .space_introduce .space_content .right {
	width: 100%;
}
.sodam_wrap .apply .space_introduce .space_content .left .img_list {
	width: 100%;
}
/* .sodam_wrap .apply .space_introduce .space_content .left .img_list li{width: 48%;height: 110px; margin-bottom: 20px;} */
.sodam_wrap .apply .space_introduce .space_content .left .img_list img {
	width: 100%;
	height: 100%;
}
.sodam_wrap .apply .space_introduce .space_content .box_text {
	margin-top: 23px;
}
.sodam_wrap .apply .space_introduce .space_content .box_text.ideas {
	padding-bottom: 22px;
	margin-bottom: 15px;
	border-bottom: 2px dashed #cccccc;
}
.sodam_wrap .apply .space_introduce .space_content .box_text h4 {
	font-size: 22px;
	font-family: 'NanumSquare-otfeb';
	color: #0457a5;
}
.sodam_wrap .apply .space_introduce .space_content .box_text .text {
	margin-top: 26px;
}
.sodam_wrap .apply .space_introduce .space_content .box_text .text span:nth-child(2),
.sodam_wrap .apply .space_introduce .space_content .box_text .text span:nth-child(5) {
	font-family: 'NanumSquare-otfb';
}
.sodam_wrap .apply .space_introduce .space_content .box_text .text .address {
	font-size: 14px;
	font-family: 'NanumSquare-otfr';
	color: #575757;
}
/* .sodam_wrap .apply .space_introduce .space_content .right .box_text .text p{margin: 20px 0 5px; font-size: 16px;} */
.sodam_wrap .apply .apply_step .step_way {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.sodam_wrap .apply .apply_step .step_way li {
	width: 100%;
}
.sodam_wrap .apply .apply_step .step_way .box_way {
	margin-bottom: 15px;
	border: 1px solid #015aa8;
	text-align: center;
}
.sodam_wrap .apply .apply_step .step_way .box_way p {
	width: 100%;
	height: 120px;
	background: #015aa8;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	line-height: 120px;
}
.sodam_wrap .apply .apply_step .step_way .box_way span {
	display: inline-block;
	padding: 10px 0;
	font-size: 18px;
}
.sodam_wrap .apply .apply_step .step_way .step_arrow {
	width: 45px;
	height: 35px;
	margin: 15px 0;
	background: url(~@/assets/img/apply/apply_arrow.svg) no-repeat top left / 100%;
	transform: rotate(90deg);
}
.sodam_wrap .apply .apply_step .link {
	color: #015aa8;
	text-decoration: underline;
}
.sodam_wrap .apply .apply_step .apply_box_step {
	display: flex;
	margin-bottom: 10px;
}
.sodam_wrap .apply .apply_step .apply_box_step li {
	display: flex;
	align-items: center;
	width: 100%;
}
.sodam_wrap .apply .apply_step .apply_box_step li + li {
	margin-left: 10px;
}
.sodam_wrap .apply .apply_step .apply_box_step li + li:before {
	display: inline-block;
	margin-right: 10px;
	content: '>';
}
.sodam_wrap .apply .apply_step .apply_box_step li:first-child {
	width: 85%;
}
.sodam_wrap .apply .apply_step .apply_box_step li p {
	width: 100%;
	padding: 10px 0;
	border-radius: 19px;
	text-align: center;
	background-color: #f3f5f7;
	line-height: 20px;
	color: #333;
}
.sodam_wrap .apply .apply_step .apply_box_step li span {
	display: block;
}

.sodam_wrap .thumbnail_wrap {
	padding: 25px 15px 50px;
	margin: 0;
}
.sodam_wrap .thumbnail_wrap .thumbnail_list {
	display: flex;
	flex-wrap: wrap;
}
.sodam_wrap .thumbnail_wrap .thumbnail_list li {
	width: 100%;
	position: relative;
	margin-bottom: 30px;
	border: 1px solid #ddd;
	border-radius: 15px;
	background-color: #fff;
	text-align: center;
}
.sodam_wrap .thumbnail_wrap .thumbnail_content {
	padding: 20px;
	text-align-last: left;
}
.sodam_wrap .thumbnail_wrap .thumbnail_store {
	margin: 10px 0;
	position: relative;
	color: #333;
	font-size: 16px;
	font-weight: 600;
}
/* .sodam_wrap .thumbnail_wrap .thumbnail_store::after{display: block;content: "";clear: both; width: 20%;height: 8px; position: absolute;bottom: 0;left: 40%;background: #c3cdfb;border-radius: 20px; z-index: -1;} */
.sodam_wrap .thumbnail_wrap .thumbnail_text {
	color: #999;
	font-size: 14px;
	font-family: 'NanumSquare-otfr';
}
.sodam_wrap .thumbnail_wrap .thumbnail_img {
	width: 100%;
	height: 200px;
}
.sodam_wrap .thumbnail_wrap .thumbnail_img img {
	width: 100%;
	height: 100%;
	border-radius: 15px 15px 0 0;
}
.sodam_wrap .thumbnail_wrap .btn_group {
	padding: 0 0 10px 20px;
	text-align-last: left;
}
.sodam_wrap .thumbnail_wrap .btn_group .btn-detail {
	margin-right: 10px;
	padding: 8px 30px;
	border-radius: 10px;
}

/* 이미지용 퍼블 css */
.sodam_wrap .thumbnail_wrap .apply_img {
	height: 400px;
}
.sodam_wrap .thumbnail_wrap .apply_img .thumbnail_img {
	height: 100%;
}
.sodam_wrap .thumbnail_wrap .btn_group img {
	width: 81px;
}
.sodam_wrap .thumbnail_wrap .btn_group a:first-child img {
	margin-right: 10px;
}
.sodam_wrap .thumbnail_wrap .btn_group a:last-child img {
	margin-left: 10px;
}

/* swiper-slide */
.sodam_wrap .swiper-pagination-bullet-active {
	background-color: #fff;
}
.sodam_wrap .swiper-pagination-bullet {
	width: 8px;
	height: 8px;
}

/* .page-component .section-notice-arrow {position:absolute;right:0;bottom:calc(90% - 5px);display:flex; top: -35%}
.page-component .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
.page-component .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
.page-component .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
.page-component .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
.page-component .disabled {opacity:.3;}
.page-component .btn-arrow:not(:first-child) {margin-left:5px;}
.page-component [class^="icon-arrow"] {width:15px;height:15px;}
.page-component .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
.page-component .icon-arrow-next {background-image:url(../img/main/notice_next.svg)} */

/* 셀렉트 추가 S (20220721 - hib) */
.page-header-sub .box_select {
	width: 205px;
	position: absolute;
	top: -6px;
	right: 5px;
}
.page-header-sub .box_select button {
	width: 100%;
	padding: 8px 12px;
	text-align: left;
	background: #999999;
	color: #fff;
	border-radius: 5px;
	position: relative;
	top: 3px;
}
.page-header-sub .box_select button::after {
	display: inline-block;
	width: 28px;
	height: 26px;
	content: '';
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	position: absolute;
	top: 3px;
	right: 2px;
}
.page-header-sub .box_select button.active::after {
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	transform: rotate(180deg);
}
.page-header-sub .box_select ul {
	display: none;
	width: 100%;
	padding: 12px 10px 8px;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #999999;
}
.page-header-sub .box_select ul li {
	font-size: 14px;
	font-weight: 900;
}
.page-header-sub .box_select ul li + li {
	margin-top: 8px;
}
.page-header-sub .box_select .show_list {
	display: block;
	background: #fff;
}
/* 셀렉트 추가 E (20220721 - hib) */
