@use 'sass:list';
@use 'sass:math';

$button-family: false !default;
$button-height: 40px !default;
$button-color: $primary !default;
$button-background-color: #fff !default;

$button-color: $primary !default;
$button-background-color: #fff !default;
$button-border-color: transparent !default;
$button-border-width: 1px !default;

$button-padding-vertical: 0 !default;
$button-padding-horizontal: 0 !default;

$button-hover-color: $primary-light !default;
$button-hover-border-color: $primary-light !default;

$button-focus-color: #333 !default;
$button-focus-border-color: #000 !default;

$button-focus-box-shadow-size: 0 0 0 0.125em !default;
$button-focus-box-shadow-color: bulmaRgba($link, 0.25) !default;

$button-active-color: $primary-dark !default;
$button-active-border-color: $primary-dark !default;
$button-active-background-color: rgba(0, 133, 255, 0.2);

$button-text-color: #1a233a !default;
$button-text-decoration: underline !default;
$button-text-hover-background-color: #fff !default;
$button-text-hover-color: #333 !default;

$button-disabled-color: #828282;
$button-disabled-background-color: #f2f2f2 !default;
$button-disabled-border-color: #bdbdbd !default;
$button-disabled-shadow: none !default;
$button-disabled-opacity: 0.5 !default;

$button-static-color: $text-light !default;
$button-static-background-color: $scheme-main-ter !default;
$button-static-border-color: $border !default;

$tag-error-background-color: $error-light;
$tag-error-color: $error;
$tag-warning-background-color: $warning-light;
$tag-warning-color: $warning;
$tag-success-background-color: $success-light;
$tag-success-color: $success-dark;
$tag-link-background-color: $link-light;
$tag-link-color: $link-dark;

$button-point-color: $point-color !default;
$button-point-border-color: $point-color !default;

@mixin control {
	display: inline-flex;
	position: relative;
	overflow: hidden;
	appearance: none;
	align-items: center;
	background-color: transparent;
	// border: $control-border-width solid transparent;
	border-radius: 4px;
	box-shadow: none;
	// font-size: $size-normal;
	height: $button-height;
	justify-content: flex-start;
	// line-height: $control-line-height;
	// padding-bottom: $control-padding-vertical;
	// padding-left: $control-padding-horizontal;
	// padding-right: $control-padding-horizontal;
	// padding-top: $control-padding-vertical;
	vertical-align: top;
	border-width: 1px;
	// border-color: transparent;
	border-style: solid;

	// States
	// &:focus,
	// &.is-focused,
	// &:active,
	// &.is-active {
	// 	outline: none;
	// }

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		pointer-events: none;
	}
}

%control {
	@include control;
}

// The button sizes use mixins so they can be used at different breakpoints
@mixin button-small {
	//min-width: 85px;
	min-width: auto;
	height: 26px;
	padding-top: 0;
	padding-left: 15px;
	padding-bottom: 0;
	padding-right: 15px;
	font-size: 14px;
	@include mobile {
		height: 26px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 12px;
	}
}

@mixin button-normal {
	font-size: 14px;
}

@mixin button-medium {
	font-size: 14px;
}

@mixin button-large {
	font-size: 16px;
	min-width: 140px;
	height: 48px;
	padding-left: 40px;
	padding-right: 40px;
}

.button {
	@extend %control;
	min-width: 30px;
	@extend %unselectable;
	border-radius: 4px;
	// border-color: $button-border-color;
	// border-width: $button-border-width;
	background-color: $button-background-color;
	// color: $button-color;
	cursor: pointer;
	line-height: 1;
	vertical-align: middle;
	// font-size: 14px;
	// font-weight: 400;
	@if $button-family {
		font-family: $button-family;
	}
	justify-content: center;
	padding-bottom: $button-padding-vertical;
	padding-left: $button-padding-horizontal;
	padding-right: $button-padding-horizontal;
	padding-top: $button-padding-vertical;
	text-align: center;
	white-space: nowrap;

	strong {
		color: inherit;
	}

	.icon {
		// &,
		// &.is-small,
		// &.is-medium,
		// &.is-large {
		// 	height: 1.5em;
		// 	width: 1.5em;
		// }

		// &:first-child:not(:last-child) {
		// 	// margin-left: calc(#{math.div(-1, 2) * $button-padding-horizontal} - #{$button-border-width});
		// 	margin-right: math.div($button-padding-horizontal, 4);
		// }

		// &:last-child:not(:first-child) {
		// 	margin-left: math.div($button-padding-horizontal, 4);
		// 	// margin-right: calc(#{math.div(-1, 2) * $button-padding-horizontal} - #{$button-border-width});
		// }

		// &:first-child:last-child {
		// 	margin-left: calc(#{math.div(-1, 2) * $button-padding-horizontal} - #{$button-border-width});
		// 	margin-right: calc(#{math.div(-1, 2) * $button-padding-horizontal} - #{$button-border-width});
		// }
		// &::before {
		// 	display: none;
		// }
	}

	// States
	&:hover,
	&.is-hovered {
		// border-color: $button-hover-border-color;
		background-color: $button-background-color;
		// color: $button-hover-color;
	}

	&:focus,
	&.is-focused {
		// border-color: $button-focus-border-color;
		background-color: #f2f6f9;
		// color: $button-focus-color;

		// &:not(:active) {
		// 	box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color;
		// }
	}

	&:active,
	&.is-active {
		// border-color: $button-active-border-color;
		// color: $button-active-color;
		background-color: $button-active-background-color;
	}

	// Colors
	&.is-text {
		min-width: initial;
		height: auto;
		padding: 0;
		border-radius: 0;
		border-color: transparent;
		background-color: transparent;
		line-height: inherit;
		// color: $button-text-color;
		// text-decoration: none;

		&:hover,
		&.is-hovered,
		&:focus,
		&.is-focused {
			// background-color: $button-background-color;
			color: $primary;
			&:after {
				transform: scale3d(0, 0, 1);
				transform-origin: 0 50%;
			}
		}

		transition: all 0.3s;

		&:after {
			opacity: 1;
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 1px;
			width: 100%;
			background-color: $primary;
			transform: scale3d(1, 1, 1);
			transform-origin: 100% 50%;
			@include transition(transform, 0.3s);
		}

		&:active,
		&.is-active {
			background-color: bulmaDarken($button-text-hover-background-color, 5%);
			color: $button-text-hover-color;
		}

		&[disabled],
		fieldset[disabled] & {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}
	}

	@each $name, $pair in $colors {
		$color: list.nth($pair, 1);
		$color-invert: list.nth($pair, 2);

		&.is-#{$name} {
			background-color: $color;
			border-color: transparent;
			color: $color-invert;

			&:hover,
			&.is-hovered {
				background-color: bulmaDarken($color, 2.5%);
				border-color: transparent;
				color: $color-invert;
			}

			&:focus,
			&.is-focused {
				// border-color: transparent;
				color: $color-invert;

				// &:not(:active) {
				// 	box-shadow: $button-focus-box-shadow-size bulmaRgba($color, 0.25);
				// }
			}

			&:active,
			&.is-active {
				background-color: bulmaDarken($color, 5%);
				border-color: transparent;
				color: $color-invert;
			}

			&[disabled],
			fieldset[disabled] & {
				// background-color: $button-disabled-background-color;
				// border-color: $button-disabled-border-color;
				// color: $button-disabled-color;
				box-shadow: $button-disabled-shadow;
				background-color: #e0e0e0;
				border-color: #e0e0e0;
				color: #828282;
				// background-color: $color;
				// border-color: transparent;
				// box-shadow: none;
			}

			@if (#{$name} == 'white') {
				border-color: $color-invert;
				&:hover {
					background-color: $color-invert;
					border-color: $color-invert;
					color: $color;
				}
			}
			@if (#{$name} == 'black') {
				&:hover,
				&:focus {
					background-color: $color-invert;
					border-color: $color;
					color: $color;
				}
			}

			&.is-inverted {
				background-color: $color-invert;
				color: $color;

				&:hover,
				&:focus,
				&.is-hovered {
					background-color: bulmaDarken($color-invert, 5%);
				}

				&[disabled],
				fieldset[disabled] & {
					background-color: $button-disabled-background-color;
					border-color: $button-disabled-border-color;
					color: $button-disabled-color;
					box-shadow: $button-disabled-shadow;
					// background-color: $color-invert;
					// border-color: transparent;
					// box-shadow: none;
					// color: $color;
				}
			}

			&.is-loading {
				&::after {
					border-color: transparent transparent $color-invert $color-invert !important;
				}
			}

			&.is-outlined {
				background-color: transparent;
				border-color: $color;
				color: $color;

				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					background-color: $color;
					border-color: $color;
					color: $color-invert;
				}

				&.is-loading {
					&::after {
						border-color: transparent transparent $color $color !important;
					}

					&:hover,
					&.is-hovered,
					&:focus,
					&.is-focused {
						&::after {
							border-color: transparent transparent $color-invert $color-invert !important;
						}
					}
				}

				&[disabled],
				fieldset[disabled] & {
					background-color: $button-disabled-background-color;
					border-color: $button-disabled-border-color;
					color: $button-disabled-color;
					box-shadow: $button-disabled-shadow;
					// background-color: transparent;
					// border-color: $color;
					// box-shadow: none;
					// color: $color;
				}
			}

			&.is-inverted.is-outlined {
				background-color: transparent;
				border-color: $color-invert;
				color: $color-invert;

				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					background-color: $color-invert;
					color: $color;
				}

				&.is-loading {
					&:hover,
					&.is-hovered,
					&:focus,
					&.is-focused {
						&::after {
							border-color: transparent transparent $color $color !important;
						}
					}
				}

				&[disabled],
				fieldset[disabled] & {
					background-color: $button-disabled-background-color;
					border-color: $button-disabled-border-color;
					color: $button-disabled-color;
					box-shadow: $button-disabled-shadow;
					// background-color: transparent;
					// border-color: $color-invert;
					// box-shadow: none;
					// color: $color-invert;
				}
			}

			// If light and dark colors are provided
			@if list.length($pair) >= 4 {
				$color-light: list.nth($pair, 3);
				$color-dark: list.nth($pair, 4);
			}
		}
	}

	&.is-primary {
		&:hover,
		&.is-hovered {
			background-color: $primary-light !important;
			border-color: $primary-light !important;
			color: #ffffff !important;
		}
		&:active,
		&.is-active {
			background-color: $primary-dark !important;
			border-color: $primary-dark !important;
			color: #ffffff !important;
		}
	}
	&.is-point {
		background-color: $point-color;
		border-color: $point-color;
		color: $bg-3;
		&:hover,
		&.is-hovered {
			background-color: $primary !important;
			border-color: $primary !important;
			color: #ffffff !important;
		}
		&:active,
		&.is-active {
			background-color: $primary !important;
			border-color: $primary !important;
			color: #ffffff !important;
		}
	}
	&.is-light {
		color: $grey-light;
		background-color: $button-background-color;
		border-color: #d9d9d9;
		&:hover,
		&.is-hovered {
			background-color: $button-background-color;
			color: $grey-light;
			border-color: $input-hover-border-color;
		}

		&:active,
		&.is-active {
			background-color: $button-background-color;
			border-color: $black;
		}
	}

	// Sizes
	&.is-small {
		@include button-small;
	}

	&.is-normal {
		@include button-normal;
	}

	&.is-medium {
		@include button-medium;
	}

	&.is-large {
		@include button-large;
	}

	&.fixed-small {
		//width: 90px;
		min-width: max-content;
		padding-left: 30px;
		padding-right: 30px;
	}
	&.fixed-medium {
		width: 120px;
		padding-left: 0;
		padding-right: 0;
	}
	&.fixed-large {
		width: 180px;
		padding-left: 0;
		padding-right: 0;
	}

	// Modifiers
	&[disabled],
	fieldset[disabled] & {
		background-color: $button-disabled-background-color;
		border-color: $button-disabled-border-color;
		color: $button-disabled-color;
		box-shadow: $button-disabled-shadow;
		// opacity: $button-disabled-opacity;
	}

	&.is-fullwidth {
		display: flex;
		width: 100%;
	}

	&.is-loading {
		color: transparent !important;
		pointer-events: none;

		&::after {
			@extend %loader;
			border-color: transparent transparent $primary $primary;
			@include center(1em);
			position: absolute !important;
		}
	}

	&.is-static {
		background-color: $button-static-background-color;
		border-color: $button-static-border-color;
		color: $button-static-color;
		box-shadow: none;
		pointer-events: none;
	}

	&.is-rounded {
		border-radius: $radius-rounded;
		padding-left: calc(#{$button-padding-horizontal} + 0.25em);
		padding-right: calc(#{$button-padding-horizontal} + 0.25em);
	}
	&.is-light {
		border-color: #ccc;
	}
	&.is-icon {
		min-width: auto !important;
		.icon {
			position: inherit;
			transform: none;
			top: inherit;
			right: inherit;
		}
	}
}

.buttons {
	display: inline-flex;
	margin: 0 auto;
	gap: 10px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	&.text-center {
		width: 100%;
		justify-content: center;
	}
	@include mobile {
		gap: 5px;
	}

	.button {
		margin-bottom: 5px;

		&:not(:last-child):not(.is-fullwidth) {
			margin-right: 4px;
		}
	}

	&:last-child {
		margin-bottom: -5px;
	}

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	// Sizes
	&.are-small {
		.button:not(.is-normal):not(.is-medium):not(.is-large) {
			@include button-small;
		}
	}

	&.are-medium {
		.button:not(.is-small):not(.is-normal):not(.is-large) {
			@include button-medium;
		}
	}

	&.are-large {
		.button:not(.is-small):not(.is-normal):not(.is-medium) {
			@include button-large;
		}
	}

	&.has-addons {
		.button {
			&.is-small {
				height: 36px;
			}
			&:not(:first-child) {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}

			&:not(:last-child) {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				margin-right: -1px;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover,
			&.is-hovered {
				z-index: 2;
			}

			&:focus,
			&.is-focused,
			&:active,
			&.is-active,
			&.is-selected {
				z-index: 3;

				&:hover {
					z-index: 4;
				}
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}
	}

	&.is-centered {
		justify-content: center;
		&:not(.has-addons) {
			.button:not(.is-fullwidth) {
				margin-left: 3px;
				margin-right: 3px;
			}
		}
	}

	&.is-right {
		justify-content: flex-end;

		&:not(.has-addons) {
			.button:not(.is-fullwidth) {
				margin-left: 3px;
				margin-right: 3px;
			}
		}
	}
}

.button-default {
	display: inline-flex;
	width: 100%;
	height: 30px;
	padding: 0 10px;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	&.is-primary {
		background-color: #645f5b;
		color: #fff;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #ff7300;
			}
		}
		@include tablet {
			&:active {
				background-color: #ff7300;
			}
		}
		&.is-rounded {
			width: auto;
			// padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
			&.is-disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
	&.is-secondary {
		background-color: #ff7300;
		color: #fff;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #c45800;
			}
		}
		@include tablet {
			&:active {
				background-color: #c45800;
			}
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
			&.is-disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
	&.is-light {
		background-color: #fff;
		color: #645f5b;
		border: 1px solid #645f5b;
		&:hover,
		&:focus {
			background-color: #645f5b;
			color: #fff;
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
		}
	}
	&.is-gray,
	&.is-grey {
		background-color: #b1afad;
		color: #fff;
		border: 1px solid #b1afad;
		&:hover,
		&:focus {
			background-color: #645f5b;
			color: #fff;
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
		}
	}
	&.is-rounded {
		border-radius: 30px;
	}
	&.is-outlined {
		border: 1px solid #645f5b;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #645f5b;
				color: #fff;
			}
		}
		@include tablet {
			&:active {
				background-color: #645f5b;
				color: #fff;
			}
		}
	}
	&.is-large {
		min-width: 150px;
		width: auto;
		height: 56px;
		padding: 20px 30px;
		font-size: 21px;
		font-weight: bold;
		&.is-rounded {
			font-size: 21px;
		}
	}
	&.is-medium {
		min-width: 110px;
		height: 50px;
		padding: 10px 20px;
		font-size: 16px;
		font-weight: bold;
		&.is-rounded {
			font-size: 16px;
			font-weight: bold;
		}
	}
}
@mixin tag {
	display: inline-block;
	padding: 4px 2.5px;
	font-size: $size-6;
	font-weight: $weight-semibold;
	font-family: $family-primary;
	border-radius: 4px;
	line-height: 16px;
}
.tag {
	@include tag;
	&-error {
		background-color: $tag-error-background-color;
		color: $tag-error-color;
	}
	&-warning {
		background-color: $tag-warning-background-color;
		color: #7f4c0a;
	}
	&-success {
		background-color: $tag-success-background-color;
		color: $tag-success-color;
	}
	&-link {
		background-color: $tag-link-background-color;
		color: $tag-link-color;
	}
	&-warning-reverse {
		background-color: #d4b011;
		color: #fbf3d0;
	}
	&-success-reverse {
		background-color: #155d18;
		color: #c5f2c7;
	}
	&-link-reverse {
		background-color: #0f56b3;
		color: #c5dcfa;
	}
	&-error-reverse {
		background-color: #910b08;
		color: #fcd0cf;
	}
}

.button {
	&.is-black {
		background-color: #0c3c5f;
	}
}
