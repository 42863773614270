.local-map {
	&-wrapper {
		display: flex;
		margin-bottom: 80px;
		overflow: hidden;
		border-radius: 15px;
		border: 1px solid #d5d5d5;
		justify-content: space-between;
		background-color: #ececec;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) inset;
		@include mobile {
			margin-bottom: 0;
			border: none;
			background-color: #fff;
			box-shadow: none;
		}
		.flex-item {
			display: flex;
			width: 50%;
			padding: 35px 55px;
			flex: 1 1 50%;
			align-items: center;
			justify-content: center;
			&.flex {
				&-l {
				}
				&-r {
					border-radius: 0 15px 15px 0;
					border-left: 1px solid #d5d5d5;
					background-color: #fff;
					align-items: flex-start;
					@include mobile {
						display: none;
					}
				}
			}
		}
	}
	&-content {
		position: relative;
		width: 296px;
		height: 522px;
	}
	&-nums {
		display: flex;
		width: 30px;
		height: 30px;
		margin: 4px;
		border-radius: 50%;
		font-size: 16px;
		font-weight: 600;
		color: #a1a1a1;
		align-items: center;
		justify-content: center;
	}
	&-text {
		position: absolute;
		display: none;
		min-width: 26px;
		height: 19px;
		bottom: -19px;
		left: 50%;
		padding: 0 5px;
		border-radius: 7px;
		background: rgba(200, 200, 200, 0.5);
		line-height: 1;
		white-space: nowrap;
		font-size: 11px;
		color: #000;
		align-items: center;
		justify-content: center;
		transform: translateX(-50%);
		@include mobile {
			display: flex;
		}
	}
	&-list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&-item {
		position: absolute;
		display: flex;
		width: 38px;
		height: 52px;
		top: 0;
		left: 0;
		background-image: url('~@/assets/img/common/mappin.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		flex-direction: column;
		&.is-active {
			background-image: url('~@/assets/img/common/mappin_on.svg');
			.local-map-nums {
				color: #e21e2c;
			}
		}
		&__1300001 {
			top: 75px;
			left: 77px;
		}
		&__1300002 {
			left: 164px;
			top: 52px;
		}
		&__1300003 {
			left: 46px;
			top: 178px;
		}
		&__1300004 {
			left: 125px;
			top: 158px;
		}
		&__1300006 {
			left: 173px;
			top: 295px;
		}
		&__1300005 {
			left: 208px;
			top: 201px;
		}
		&__1300009 {
			left: 21px;
			top: 450px;
		}
		&__1300007 {
			left: 59px;
			top: 338px;
		}
		&__1300010 {
			left: 257px;
			top: 271px;
		}
		&__1300008 {
			left: 82px;
			top: 275px;
		}
		&__1300012 {
			left: 216px;
			top: 182px;
		}
		&__1300013 {
			left: 18px;
			top: 444px;
		}
		&__1300011 {
			left: 192px;
			top: 248px;
		}
	}
	&-buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
	}
	&-button {
		display: flex;
		width: calc(33.33% - 10px);
		height: clamp(50px, 5.5vw, 80px);
		padding: 5px;
		border-radius: 15px;
		background-color: #dedede;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex-shrink: 0;
		color: #fff;
		font-size: 21px;
		font-weight: 700;
		@include transition(all, 0.5s);
		@include tablet {
			width: calc(50% - 8px);
		}
		span {
			font-size: clamp(17px, 1.5vw, 21px);
			font-weight: 700;
		}
		&.is-active {
			background-color: #ff922e;
		}
	}
	&-section {
		&__title {
			margin-bottom: 25px;
			font-size: 26px;
			font-weight: 700;
		}
	}
}
.local {
	&-econo {
		&-td1 {
			padding: 0 10px;
			text-align: center;
			font-weight: 700;
			flex: 0 0 200px;
		}
		&-td2 {
			padding: 0 5px;
			text-align: center;
			font-weight: 700;
			flex: 0 0 200px;
		}
		&-td3 {
			padding: 0 10px;
			text-align: center;
			font-weight: 700;
			flex: 1 1;
		}
		&-section {
			&__header {
				display: flex;
				height: 62px;
				background-color: #645f5b;
				border-radius: 15px 15px 0 0;
				font-size: 23px;
				color: #fff;
				align-items: center;
				@include mobile {
					display: none;
				}
			}
		}
		&-article {
			&__header {
				display: flex;
				height: 80px;
				border-bottom: 1px solid #dadada;
				background-color: #fff;
				font-size: 23px;
				align-items: center;
				cursor: pointer;
				h3.local-econo-td1 {
					font-size: 23px;
					color: #ff922e;
				}
				.local-econo-td3 {
					text-align: left;
					font-size: 19px;
				}
				@include mobile {
					height: auto;
					flex-wrap: wrap;
					.local-econo-td1 {
						padding: 15px 10px 8px;
						text-align: left;
						flex: 0 0 20%;
					}
					.local-econo-td2 {
						padding: 18px 10px 8px;
						font-size: 19px;
						text-align: left;
						flex: 0 0 80%;
					}
					.local-econo-td3 {
						padding: 5px 10px 15px;
						text-align: left;
						font-size: 16px;
						font-weight: 400;
						flex: 1 1 100%;
					}
				}
				@include until(500px) {
					.local-econo-td1 {
						flex: 0 0 40%;
					}
					.local-econo-td2 {
						flex: 0 0 60%;
					}
				}
			}
		}
		&-more {
			display: flex;
			max-height: 280px;
			padding: 20px 30px 20px 60px;
			background-color: #f6f6f6;
			align-items: center;
			justify-content: space-between;
			gap: 23px;
			@include until(1100px) {
				max-height: initial;
				height: auto;
				flex-direction: column;
				padding: 20px 30px;
			}
			@include mobile {
				padding: 18px 8px;
				gap: 10px;
				align-items: flex-start;
			}
			&__header {
				display: flex;
				width: 100%;
				height: 195px;
				flex: 0 0 50%;
				justify-content: space-between;
				gap: 23px;
				font-size: 19px;
				font-weight: 700;
				@include mobile {
					gap: 15px;
				}
				.flex-r {
					display: flex;
					flex: 1 1;
					justify-content: space-between;
					flex-direction: column;
				}
			}
			&__swiper {
				position: relative;
				width: calc(50% - 25px);
				padding: 20px;
				flex: 0 0 calc(50% - 25px);
				border-radius: 15px;
				border: 1px solid #e1e1e1;
				background-color: #fff;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				@include until(1100px) {
					width: 100%;
					padding: 20px 10px;
					flex: 0 0 auto;
				}
				@include mobile {
					padding: 12px;
					border-bottom: 8px;
				}
				.swiper-slide {
					width: 155px;
				}
				.swiper {
					@include until(1100px) {
						padding: 0 5px;
					}
				}
				.swiper-wrapper {
				}
				&-title {
					margin-bottom: 14px;
					font-size: 20px;
					font-weight: 700;
					color: #616161;
					@include mobile {
						margin-bottom: 10px;
						font-size: 12px;
					}
				}
				&-body {
					position: relative;
					width: calc(100% + 10px);
					height: 151px;
					margin: 0 -5px;
					@include until(1100px) {
						width: calc(100% + 20px);
						height: auto;
						margin: 0 -10px;
					}
					@include mobile {
						width: calc(100% + 24px);
						margin: 0 -12px;
					}
				}
				&-item {
					display: flex;
					position: relative;
					max-width: 165px;
					height: 155px;
					padding: 0 5px;
					align-items: center;
					justify-content: center;
					@include until(1100px) {
						max-width: none;
						height: auto;
					}
					&-img {
						position: relative;
						display: flex;
						width: 100%;
						height: 100%;
						overflow: hidden;
						align-items: center;
						justify-content: center;
						border-radius: 15px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					&-text {
						position: absolute;
						width: 100%;
						bottom: 12px;
						left: 50%;
						padding: 0 15px;
						font-size: clamp(11px, 1.3vw, 17px);
						font-weight: 700;
						text-align: center;
						color: #fff;
						text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
						transform: translateX(-50%);
					}
				}
				.swiper-buttons-container {
					.swiper-button-next,
					.swiper-button-prev {
						display: flex;
						width: 35px;
						height: 35px;
						overflow: hidden;
						top: 50%;
						border-radius: 50%;
						background-color: #fff;
						border: 1px solid #d5d5d5;
						align-items: center;
						justify-content: center;
						@include until(1100px) {
							display: none;
						}
						&::after {
							width: 9px;
							height: 15px;
							top: 50%;
							left: 50%;
							border: none;
							background-repeat: no-repeat;
							background-position: 50% 50%;
							transform: translate(-50%, -50%);
						}
					}
					.swiper-button-prev {
						left: -12px;
						&::after {
							background-image: url(~@/assets/img/common/arrow-l.svg);
						}
					}
					.swiper-button-next {
						right: -12px;
						&::after {
							background-image: url(~@/assets/img/common/arrow-r.svg);
						}
					}
				}
			}
			&__img {
				display: flex;
				position: relative;
				overflow: hidden;
				width: 195px;
				height: 195px;
				border-radius: 8px;
				border: 2px solid #004898;
				background-color: #fff;
				flex: 0 0 195px;
				@include until(1100px) {
					width: 96px;
					height: 96px;
					flex: 0 0 96px;
				}
				img {
					position: absolute;
					width: 80%;
					height: 80%;
					top: 50%;
					left: 50%;
					object-fit: contain;
					transform: translate(-50%, -50%);
				}
			}
			&__title {
				font-size: 19px;
				font-weight: 700;
				color: #2e2e2e;
				strong {
					font-size: 23px;
					color: #004898;
				}
				@include mobile {
					font-size: 16px;
					strong {
						font-size: 19px;
					}
				}
			}
			&__link {
				display: flex;
				position: relative;
				margin-top: 5px;
				align-items: center;
				gap: 5px;
				&:hover {
					span {
						text-decoration: none;
					}
				}
				span {
					font-size: 17px;
					font-weight: 400;
					text-decoration: underline;
					color: #7e7e7e;
					word-break: break-word;
					@include mobile {
						font-size: 13px;
					}
				}
				i {
					display: flex;
					overflow: hidden;
					width: 26px;
					height: 26px;
					border-radius: 50%;
					border: 1px solid #e1e1e1;
					background-color: #fff;
					align-items: center;
					justify-content: center;
					flex: 0 0 26px;
					img {
						width: 60%;
						height: 60%;
					}
				}
				&::before {
					content: '';
					display: flex;
					// position: absolute;
					// top: 0;
					// right: 0;
					width: 20px;
					height: 20px;
					background-image: url(~@/assets/img/common/icon_exlink.svg);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					@include mobile {
						position: static;
						width: 16px;
						height: 16px;
					}
				}
			}
			&__text {
			}
			&__desc {
				margin-top: 12px;
				padding-top: 12px;
				border-top: 1px solid #c7c7c7;
				font-size: 17px;
				line-height: 1.3;
				font-weight: 400;
				color: #2e2e2e;
				strong {
					font-weight: 700;
				}
				@include mobile {
					padding-top: 0;
					border-top: none;
					font-size: 16px;
				}
			}
		}
	}
}
#localeconomic-center {
	.page-component {
		width: 100%;
		max-width: 1280px;
		padding: 0 40px;
		@include mobile {
			padding: 0 15px;
		}
	}
	.page-header {
		position: relative;
		height: 270px;
		margin-bottom: 70px;
		padding: 0;
		text-align: left;
		// background-color: #c8d7ee;
		background-image: url(~@/assets/img/common/banner_arecono.jpg);
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		@include mobile {
			height: 180px;
			margin-bottom: 40px;
		}
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(41, 38, 35, 0.5);
			z-index: 1;
		}
		.page-component {
			display: flex;
			height: 100%;
			color: #fff;
			justify-content: center;
			flex-direction: column;
		}
		&-title {
			position: relative;
			margin-bottom: 20px;
			text-align: left;
			font-size: 50px;
			font-weight: 700;
			z-index: 1;
			@include mobile {
				font-size: clamp(26px, 9vw, 35px);
			}
		}
		&-desc {
			position: relative;
			text-align: left;
			font-size: 30px;
			font-weight: 300;
			line-height: 1.3;
			z-index: 1;
			@include mobile {
				font-size: clamp(13px, 4.2vw, 20px);
			}
		}
	}
}
