#sme-apply {}
#sme-apply .apply-header {position:relative;padding-top:40px}
#sme-apply .apply-header .title{margin-bottom:20px;font-size:25px;line-height:32px;font-weight:900; margin-top: 12px}
#sme-apply .apply-header .form-select {width:100%;box-shadow:0 0 0 2px #015aa8 inset;color:#015aa8}
#sme-apply .apply-list-wrapper {position:relative;padding:10px 20px 0px 20px;}
#sme-apply .apply-list-wrapper .apply-list {position:relative;}
#sme-apply .apply-list-wrapper .apply-list-item {position:relative;}
#sme-apply .apply-list-wrapper .apply-list-item:not(:first-child) {border-top:1px solid #ebebeb;}
#sme-apply .apply-list-wrapper .item-category {display:block;margin-bottom:10px;font-size:13px;color:#999;font-weight:bold}
#sme-apply .apply-list-wrapper .item-title {position:relative;font-size:1.1rem;line-height:30px;font-weight:bold;word-break: keep-all;margin-bottom: 5px}
#sme-apply .apply-list-wrapper .item-link {position:absolute;right:0;bottom:40px;display:inline-flex;align-items:center;color:#015aa8}
#sme-apply .apply-list-wrapper .item-link-text {font-size:14px;font-weight:900}
#sme-apply .apply-list-wrapper .icon-arrow {width:11px;height:9px;background-image:url(../img/apply/apply_arrow.svg);margin-left:10px;background-size:11px auto;position:relative;top:-2px;}

/* #sme-apply .section-notice-arrow {position:absolute;right:0;bottom:calc(90% - 5px);display:flex; top: 14%}
#sme-apply .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
#sme-apply .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-apply .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-apply .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
#sme-apply .disabled {opacity:.3;}
#sme-apply .btn-arrow:not(:first-child) {margin-left:5px;}
#sme-apply [class^="icon-arrow"] {width:15px;height:15px;}
#sme-apply .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
#sme-apply .icon-arrow-next {background-image:url(../img/main/notice_next.svg)} */

/* 중소벤처기업부 수정 */
#sme-apply .apply-header{display: block; justify-content: space-between; align-items: flex-end;}
#sme-apply .apply-header .btn_search{position: relative;}
#sme-apply .apply-header .btn_search .form_search{display: block;width: 100%;padding: 20px 15px;background-color: #fff;border: none;border-bottom: 1px solid #cccccc;font-weight: bold;color: #111;}
#sme-apply .apply-header .btn_search .form_search:focus {outline: 2px solid #015aa8;}
#sme-apply .apply-header .ico_search{width: 25px;height: 25px; position: absolute;top: 50%; right: 5%; background: url(../img/common/search_icon.svg) no-repeat; transform: translate(0, -50%); text-indent: -9999rem;}
#sme-apply .apply-list-wrapper .apply-list{display: flex; flex-wrap: wrap;justify-content: space-between;}
#sme-apply .apply-list-wrapper .apply-list-item{width: 100%;height: auto; margin: 0 0px 15px 0;border: 1px solid #ddd;border-radius: 15px; cursor: pointer;}
#sme-apply .apply-list-wrapper .thumbail_img img{width: 100%; height:378px; object-fit: fill; border-top-left-radius: 15px; border-top-right-radius: 15px;}
#sme-apply .apply-list-wrapper .item-category{padding: 10px 10px 0 10px;}
#sme-apply .apply-list-wrapper .item-category span{display:inline-block;margin: 0 10px 12px 0;padding: 5px 10px; background: #fbe6d9; border: 1px solid #ff7300;border-radius: 20px; font-size:14px;color:#ff7300; font-weight:bold; text-overflow: ellipsis; white-space: nowrap; max-width: 360px; overflow: hidden;}
#sme-apply .apply-list-wrapper .apply-items {padding: 5px 10px 40px 10px;position: relative; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
#sme-apply .apply-list-wrapper .item-title {height: 50px; font-size:18px;line-height:25px;font-weight:bold;}
#sme-apply .apply-list-wrapper .item-content{margin-top: 10px; font-size: 14px;line-height: 21px;}
#sme-apply .apply-list-wrapper .item-date{position: absolute;bottom: 20px;left: 10px; font-size: 12px;}
.ellipsis2 {overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical; }

#sme-apply .support-title {display:flex;justify-content:center;padding:10px;}
#sme-apply .support-title .support-title-desc {word-break:keep-all;padding:10px;font-weight:700;border-radius:2px;background-color:white;color:#111;text-align: center;font-size:1.1rem;line-height: 1.5;}

/* 중앙부처, 지자체 지원사업 안내 */
.second_wrap .tab_wrap{display: flex;width: 100%;margin: 30px 0 15px;overflow-x: auto; align-items: center;}
.second_wrap .tab_wrap .tab_list{width: 100%;height: 40px;background: #bbb;border: 1px solid #aaa; color: #fff; }
.second_wrap .tab_wrap li:first-child .tab_list{border-top-left-radius: 8px;border-bottom-left-radius: 8px;}
.second_wrap .tab_wrap li:last-child .tab_list{border-top-right-radius: 8px;border-bottom-right-radius: 8px;}
.second_wrap .tab_wrap .tab_list.on, .second_wrap .tab_wrap .tab_list:hover{background: #0457a5;border: 1px solid #0457a5;}
.second_wrap .tbl_wrap{overflow-x: scroll;}

.second_wrap .tbl_wrap table{width: 100%;border-top: 1px solid #333; white-space: nowrap;}
.second_wrap .tbl_wrap table tr{height: 50px;border-bottom: 1px solid #dce2e8;}
.second_wrap .tbl_wrap table th{position: relative;}
.second_wrap .tbl_wrap table th::after{display: block;width: 1px;height: 20px; position: absolute;top: 0; right: -2px;background: #94a3f9; content: "";clear: both;}
.second_wrap .tbl_wrap table th:last-child::after{display: none;}

.second_wrap .tbl_wrap table td{pointer-events: none; position: relative;cursor: pointer;}
.second_wrap .tbl_wrap table td:after{display: block;width: 1px;height: 20px; position: absolute;top: 0; right: -2px;background: #dce2e8; content: "";clear: both;}
.second_wrap .tbl_wrap table td:last-child::after{display: none;}
.second_wrap .tbl_wrap thead tr{background: #f3f9ff; border-top: 2px solid #94a3f9; box-shadow: 0 2px 5px rgb(184 196 251 / 100%);}


.second_wrap .tbl_wrap tbody td{padding: 15px 20px; text-align: center;}
.second_wrap .tbl_wrap tbody td:first-child{border-left: none;}
.second_wrap .tbl_wrap tbody td:last-child{border-right: none;}
.second_wrap .tbl_wrap .title{text-align-last: left;}
.second_wrap .tab_wrap.show_menu {display: none;}
.btn-detail{padding: 8px 30px;border-radius: 20px;}

/* 중앙부처, 지자체 지원사업 안내 탭 메뉴 추가 */
.second_wrap .accordion {margin: 20px 0;}
.second_wrap .accordion #menu {display: none;}
.second_wrap .accordion #menu:checked + label::after {display: inline-block;width: 25px;height: 25px;content: url('../img/common/icon_close.svg');}
.second_wrap .accordion #menu:checked + label::before {display: none;}
.second_wrap .accordion #menu:checked + label + .tab_wrap {display: block;}
.second_wrap .accordion label {display: flex;align-items: center;justify-content: center;width: 100%;height: 40px;border: 1px solid #E3E5E7;border-radius: 4px;background-color: #F9F9F9;}
.second_wrap .accordion .lae::after {content: '지역별 보기'; font-size:18px;font-weight:600}
.second_wrap .accordion .dept::after {content: '부처별 보기'; font-size:18px;font-weight:600}
.second_wrap .accordion label::before {display: inline-block;width: 25px;height: 25px;background-size: 100%;background-image: url('../img/common/removebg-preview.png');content: ''}
.second_wrap .accordion label span {width: 1px;height: 1px;position: absolute;overflow: hidden;clip: rect(0 0 0 0);}
.second_wrap .accordion .tab_wrap {display: none;padding: 5px;margin-top: 5px;gap: 15px;border: 1px solid #E3E5E7;border-radius: 4px;background-color: #F9F9F9;}
.second_wrap .accordion .tab_wrap li {width: 33.333%;float: left;padding: 5px;}
.second_wrap .accordion .tab_wrap li .tab_list {width: 100%;}
.second_wrap .accordion .tab_wrap li:first-child .tab_list,
.second_wrap .accordion .tab_wrap li:last-child .tab_list {border-radius: 0;}

/* 중소벤처기업부, 지자체 셀렉트 추가 */
#sme-apply .apply-header .box_select {width: 205px;position: absolute;top: 5%;right: -10px;}
#sme-apply .apply-header .box_select button {width: 100%;padding: 8px 12px;text-align: left;background: #999999;color: #fff;border-radius: 5px;position: relative;top: 3px;}
#sme-apply .apply-header .box_select button::after {display: inline-block;width: 28px;height: 26px;content: '';background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;position: absolute;top: 3px;right: 2px;}
#sme-apply .apply-header .box_select button.active::after {background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;transform: rotate(180deg);}
#sme-apply .apply-header .box_select ul {display: none;width: 100%;padding: 12px 10px 8px;border-width: 0 1px 1px 1px;border-style: solid;border-color: #999999;}
#sme-apply .apply-header .box_select ul li {font-size: 14px;font-weight: 900;}
#sme-apply .apply-header .box_select ul li + li {margin-top: 8px;}
#sme-apply .apply-header .box_select .show_list {display: block; background: #fff;}