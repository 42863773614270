body {
	overscroll-behavior-y: none;
	overflow-x: initial;
}
html,
body {
	overflow-x: hidden;
}
[class*='-component'] {
	position: relative;
	margin: auto;
	padding: 0 20px;
	width: 100%;
} /* 1280px */
[class^='icon-'] {
	overflow: hidden;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0 0;
	text-indent: -9999em;
}

.btn-icontext {
	display: inline-flex;
	align-items: center;
}
.btn-icontext [class^='icon'] {
}
.btn-texticon {
	display: inline-flex;
	align-items: center;
}

#gachi {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}

.detail-section-footer {
	margin-bottom: 3em;
}
/* gachi-header */
.gachi-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.3);
	transition: background 0.4s;
	.header-component {
		overflow: visible;
	}
	.header-gnb {
		z-index: 499;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 69px;
	}
	.header-logo {
		flex: 0 0 115px;
		position: relative;
		.logo-gachi {
			display: block;
			width: 87px;
			height: 20px;
			background: url(../img/common/logo_w.svg) no-repeat 0 0;
			text-indent: -9999em;
			overflow: hidden;
			background-size: 87px auto;
			&::after {
				display: inline-block;
				width: 22px;
				height: 17px;
				content: '';
				background: url(~@/assets/img/common/portal.svg);
				position: absolute;
				right: 8px;
				bottom: 14px;
				background-repeat: no-repeat;
			}
		}
	}
	.header-menu {
		flex: auto;
		text-align: center;
		&-list {
			position: relative;
			display: inline-flex;
			align-items: center;
		}
		&-link {
			position: relative;
			display: flex;
			height: 80px;
			align-items: center;
		}
		&-item {
			.header-sub {
				display: none;
			}
			&.is-active {
				.header-sub {
					position: absolute;
					z-index: 100;
					display: block;
					&-list {
						position: absolute;
						top: 0px;
						left: 50%;
						display: flex;
						height: 30px;
						padding: 0 2em;
						border-radius: 45px;
						background-color: rgba(0, 0, 0, 0.6);
						gap: 20px;
						transform: translateX(-35%);
						@include tablet {
							top: 0px;
						}
					}
					&-item {
						height: 30px;
					}
					.header-sub-link {
						position: relative;
						z-index: 20;
						display: inline-flex;
						height: 100%;
						color: #fff;
						align-items: center;
						white-space: nowrap;
					}
					// &:after {
					// 	content: '';
					// 	position: fixed;
					// 	left: 0;
					// 	right: 0;
					// 	z-index: 10;
					// 	display: flex;
					// 	height: 45px;
					// 	background-color: rgba(0, 0, 0, 0.3);
					// 	@include tablet {
					// 		top: 60px;
					// 	}
					// }
				}
			}
		}
	}
	/* .header-util {flex:0 0 130px} */
	.header-util {
		display: flex;
		align-items: center;
		gap: 10px;
		.header-market-link {
			margin-top: 2px;
			padding: 3px;
			font-size: 12px;
			letter-spacing: 0;
			background: #fa6f00;
			border-radius: 5px;
			a {
				color: #fff;
				&::after {
					display: inline-block;
					width: 14px;
					height: 14px;
					margin-left: 2px;
					content: '';
					background: url('../img/common/link-arrow-white.png') center / 100% no-repeat;
					vertical-align: bottom;
				}
			}
		}
		&-list {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: 14px;
		}
		&-item:not(:first-child) {
			margin-left: 15px;
		}
		&-link {
			display: block;
		}
	}
	.icon-util-search {
		width: 25px;
		height: 25px;
		background-image: url(../img/common/search_w.svg);
	}
	.icon-util-notice {
		width: 24px;
		height: 25px;
		background-image: url(../img/common/notice_w.svg);
	}
	.icon-util-mypage {
		width: 25px;
		height: 25px;
		background-image: url(../img/common/mypage_w.svg);
	}
	.icon-util-menu {
		width: 25px;
		height: 25px;
		// background-image: url(../img/common/icon_menu_w.svg);
		// background-image: url(../img/common/icon_menu.svg);
	}
	// .header-sub {
	// 	z-index: 499;
	// 	position: absolute;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 78px;
	// 	top: 100%;
	// 	&-list {
	// 		visibility: hidden;
	// 		opacity: 0;
	// 		display: flex;
	// 		align-items: center;
	// 	}
	// 	&-item:not(:first-child) {
	// 		margin-left: 20px;
	// 	}
	// 	&-link {
	// 	}
	// 	&-text {
	// 		font-size: 1rem;
	// 		font-weight: bold;
	// 		white-space: nowrap;
	// 	}
	// 	&-overlay {
	// 		z-index: 490;
	// 		visibility: hidden;
	// 		opacity: 0;
	// 		position: absolute;
	// 		top: 100%;
	// 		height: 78px;
	// 		left: 0;
	// 		width: 100%;
	// 		background-color: #f3f5f7;
	// 	}
	// }
	.header-banner {
		position: fixed;
		left: 0;
		width: 100%;
		z-index: 200;
		background-color: #ff7300;
		transform: translateY(-80px);
		transition: transform 0.4s;
		top: 0;
		.header-component {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.banner-link {
			display: flex;
			height: 80px;
			align-items: center;
			flex: auto;
			&:hover {
				text-decoration: underline;
			}
		}
		.banner-text {
			font-weight: bold;
			color: #fff;
		}
		.icon-banner-noti {
			width: 24px;
			height: 24px;
			background-image: url(../img/common/header_banner_noti.svg);
			margin-right: 10px;
		}
		.icon-banner-close {
			width: 30px;
			height: 30px;
			background-image: url(../img/common/header_banner_close.svg);
		}
		&.is-visible {
			transform: translateY(0px);
		}
	}
	&.is-active {
		background-color: #fff;
		.header-menu-text {
			color: #000;
		}
	}
	.header-sub-overlay {
		&.is-active {
			visibility: visible;
			opacity: 1;
		}
	}
	&[data-header='min'] {
		position: relative;
		.header-gnb {
			justify-content: flex-start;
			padding-top: 23px;
		}
		.logo-gachi {
			background-image: url(../img/common/logo.svg);
		}
	}

	&-fix {
		position: fixed;
		&[data-header='sticky'] {
			position: fixed !important;
		}
	}

	&[data-header='sticky'] {
		// position: sticky;
		// position: -webkit-sticky;
		top: 0;
		border-bottom: 1px solid #ebebeb;
		background-color: #fff;
		.header-logo .logo-gachi {
			background-image: url(../img/common/logo.svg);
		}
		.header-menu-text {
			color: #111;
		}
		.icon-util-search {
			background-image: url(../img/common/search.svg);
		}
		.icon-util-mypage {
			background-image: url(../img/common/mypage.svg);
		}
		.icon-util-notice {
			background-image: url(../img/common/notice.svg);
		}
		// .icon-util-menu {
		// 	background-image: url(../img/common/icon_menu.svg);
		// }
	}

	&[data-header='dark'] {
		.header-logo .logo-gachi {
			background-image: url(../img/common/logo.svg);
		}
		.header-menu-text {
			color: #111;
		}
		.icon-util-search {
			background-image: url(../img/common/search.svg);
		}
		.icon-util-mypage {
			background-image: url(../img/common/mypage.svg);
		}
		.icon-util-notice {
			background-image: url(../img/common/notice.svg);
		}
		.icon-util-menu {
			background-image: url(../img/common/icon_menu.svg);
		}
	}
}

/* gachi-footer */
.gachi-footer {
	position: relative;
	flex: 0 0 auto;
	z-index: 2;
	background-color: white;
}
.gachi-footer .footer-family {
	position: relative;
	background-color: #fafafa;
}
.gachi-footer .footer-family-list {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	border-top: 1px solid #ebebeb;
	background-color: #fff;
}
.gachi-footer .footer-family-item {
}
.gachi-footer .footer-family-item:not(:first-child) {
	margin-left: 80px;
}
.gachi-footer .footer-family-link {
	display: block;
}
.gachi-footer .footer-family-link img {
	width: auto;
	height: 26px;
	max-width: none;
}
.gachi-footer .footer-row {
	position: relative;
	padding: 50px 20px 30px;
	background-color: #f3f5f7;
}
.gachi-footer .footer-logo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 30px;
}
.gachi-footer .footer-logo .logo-link {
	display: inline-block;
}
.gachi-footer .footer-logo .logo-link:not(:first-child) {
	margin-left: 20px;
}
.gachi-footer .footer-logo .logo-mss {
	overflow: hidden;
	text-indent: -9999em;
	display: inline-block;
	width: 114px;
	height: 30px;
	background: url(../img/common/footer_logo_01.svg) no-repeat 0 0;
	background-size: 114px 30px;
}
.gachi-footer .footer-logo .logo-sbdc {
	overflow: hidden;
	text-indent: -9999em;
	display: inline-block;
	width: 143px;
	height: 24px;
	background: url(../img/common/footer_kodma_logo.png) no-repeat 0 0;
	background-size: auto 24px;
}
.gachi-footer .footer-description {
	position: relative;
	text-align: center;
}
.gachi-footer .footer-description .text {
	font-size: clamp(3px, 2vw, 11px);
	line-height: 1.3;
}
.gachi-footer .footer-description .text.second-desc {
	padding-top: 15px;
}

/* page-container */
.page-container {
	position: relative;
	flex: auto;
	padding-bottom: 2em;
	.mbrp-section {
		margin-bottom: 60px;
	}
}

.gachi-skipnav {
	position: absolute;
	left: 0;
	top: -50px;
	text-indent: -9999em;
	font-size: 0;
	width: 100%;
	z-index: 999;
}
.gachi-skipnav li a:active,
.gachi-skipnav li a:focus {
	position: absolute;
	left: 0;
	top: 50px;
	display: block;
	width: 100%;
	padding: 20px 0;
	text-align: center;
	text-indent: 0px;
	font-weight: bold;
	background-color: #015aa8;
	color: #fff;
	font-size: 1rem;
}

.page-header {
	padding: 40px 0;
	background-color: #fff;
}
.page-header.page-header-sub {
	padding: 13px 0 11px 0;
}
.page-header .page-header-title {
	text-align: left;
}
.page-header .page-header-title .title {
	font-size: 25px;
	line-height: 27px;
	font-weight: 900;
}
.page-header .page-header-title .description {
	font-size: 13px;
	line-height: 1.1;
	color: #555;
}
.page-header .page-header-description {
	position: relative;
	padding-top: 10px;
	text-align: center;
}
.page-header .page-header-description .text {
	font-size: 1rem;
	font-weight: bold;
	color: #999;
}
.page-header .page-header-hashtags {
	position: relative;
	padding-top: 10px;
	text-align: center;
}
.page-header .page-header-hashtags .text {
	font-size: 1rem;
	font-weight: bold;
	color: #999;
}

.page-body[data-fill='true'] {
	background-color: #f3f5f7;
}

.gachi-search {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 600;
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.9);
	bottom: 0;
}
.gachi-search .search-header {
	position: relative;
	padding-top: 17px;
	padding-bottom: 75px;
}
.gachi-search .search-header .logo-link {
	display: block;
	width: 130px;
	height: 24px;
}
.gachi-search .search-header .logo {
	display: block;
	width: 130px;
	height: 20px;
	background: url(../img/common/logo.svg) no-repeat 0 0;
	background-size: auto 20px;
	position: relative;
	top: 8px;
}
.gachi-search .search-header .btn {
	position: absolute;
	right: -20px;
	top: 2px;
}
.gachi-search .search-header .icon-close {
	width: 25px;
	height: 25px;
	background-image: url(../img/common/icon_close.svg);
	background-size: 25px;
}
.gachi-search .search-field {
	display: flex;
	padding-bottom: 50px;
}
.gachi-search .search-field .form-control {
	flex: auto;
	background: #fff url(../img/common/search_icon.svg) no-repeat 20px 50%;
	padding-left: 65px;
	box-shadow: 0 0 0 2px #015aa8 inset;
}
.gachi-search .search-results {
	padding-top: 20px;
}
.gachi-search .search-results .page-component {
}
.gachi-search .section-result {
	position: relative;
	padding: 30px 20px;
	background-color: #fff;
}
.gachi-search .section-result ~ .section-result {
	margin-top: 20px;
}
.gachi-search .section-result .section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.gachi-search .section-result .section-header .header-title {
	font-size: 20px;
	font-weight: 900;
}
.gachi-search .search-list {
	padding-top: 10px;
}
.gachi-search .search-list[data-list='thumbnail'] {
	padding-top: 10px;
}
.gachi-search .search-item {
	padding: 20px 0;
}
.gachi-search .search-item:last-child {
	padding-bottom: 0;
}
.gachi-search .search-item ~ .search-item {
	border-top: 1px solid #ebebeb;
}
.gachi-search .search-item .item-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.gachi-search .search-item .item-subtext {
	color: #999;
	font-size: 13px;
}
.gachi-search .search-item .item-title {
	margin-top: 10px;
	font-weight: bold;
	color: #555;
}
.gachi-search .search-item .title-link {
	display: -webkit-box;
	width: calc(100vw - 230px);
	max-height: 44px;
	line-height: 22px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.gachi-search .search-item .image-link {
	display: block;
	width: 100px;
	height: 100px;
	border-radius: 2px;
}
.gachi-search .search-item .image-link img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.gachi-search .search-item .text-link {
	display: inline-block;
	margin-top: 32px;
}
.gachi-search .search-item .text-link .text {
	font-size: 13px;
	font-weight: 900;
}
.gachi-search .search-item .text-link .icon-arrow {
	width: 11px;
	height: 11px;
	background-image: url(../img/common/list_arrow.svg);
	background-size: 11px auto;
}
.gachi-search .notfound-result {
	position: relative;
	padding-top: 20px;
}
.gachi-search .notfound-result .text {
	font-size: 1rem;
	font-weight: bold;
	color: #999;
}

.gachi-search .section-community .item-title {
	font-weight: normal;
}
.gachi-search .section-creator .creator-list {
	padding-top: 30px;
	margin: -15px -5px;
}
.gachi-search .section-creator .creator-item {
	flex: 0 0 50%;
	padding: 15px 5px;
}
.gachi-search .section-creator .creator-card {
	width: 100%;
}

.no-results-found {
	position: relative;
	padding: 120px 0;
	text-align: center;
}
.no-results-found .text {
	font-size: 16px;
	color: #555;
	font-weight: bold;
}

.gachi-search[data-search='results'] {
	position: relative;
	background-color: #f3f5f7;
	padding-bottom: 20px;
}
.gachi-search[data-search='results'] {
}
.gachi-search[data-search='results'] .search-top {
	background-color: #fff;
}

.ie .gachi-header[data-header='sticky'] {
	position: fixed;
}
.ie .gachi-header[data-header='sticky'] ~ .page-container {
	padding-top: 102px;
}

@media (max-width: 345px) {
	.header-component.header-component-sub {
		padding: 0 2px;
	}
}

.arrow {
	border: solid #424242;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	&-down {
		transform: rotate(45deg);
	}
	&-right {
		transform: rotate(-45deg);
	}
	&-up {
		transform: rotate(-135deg);
	}
	&-left {
		transform: rotate(135deg);
	}
}
