%mypage2-boxshadow {
	width: 100%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
}
%mypage2-status {
	display: inline-flex;
	width: 40px;
	height: 40px;
	margin-bottom: 13px;
	padding: 10px;
	border-radius: 50%;
	line-height: 1.05;
	text-align: center;
	font-size: 11px;
	align-items: center;
	justify-content: center;
	@include word-wrap;
	@include mobile {
		width: 35px;
		height: 35px;
	}
}
.font {
	&-14 {
		font-size: 14px;
	}
}
.text-ellipsis {
	@include text-ellipsis;
	&-2 {
		@include text-ellipsis-2;
		max-height: 40px;
	}
	&-3 {
		@include text-ellipsis-3;
	}
	&-4 {
		@include text-ellipsis-4;
	}
}
.thumb {
	display: flex;
	overflow: hidden;
	position: relative;
	border-radius: 8px;
	border: 1px solid #e0e0e0;
	align-items: center;
	justify-content: center;
	&-80-60 {
		width: 80px;
		height: 60px;
	}
	&-120-90 {
		width: 120px;
		height: 90px;
	}
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.mypage2 {
	&-buttons {
		width: 100%;
		margin-top: 30px;
	}
	&-section {
		border-bottom: 1px solid #f4f4f4;
		background-color: #f4f4f4;
		@at-root & {
			&:not(:first-child) {
				margin-top: 30px;
			}
		}
		.table.is-styled {
			.form-select {
				&.is-small {
					min-width: initial;
					padding-left: 8px;
					padding-right: 20px;
					background-position: calc(100% - 6px) 50%;
				}
			}
		}
		.container {
			max-width: 1230px;
			padding: 0 40px;
			@include tablet {
				padding: 0 25px 0 0;
			}
			@include mobile {
				padding: 0 0 50px;
			}
		}
		&-flex {
			display: flex;
			min-height: 800px;
			@include until(940px) {
				flex-direction: column;
			}
			.flex-0 {
				padding: 50px 15px 50px;
				background-color: #fff;
				flex: 0 0 230px;
				@include until(940px) {
					padding: 30px 15px;
					flex: none;
				}
			}
			.flex-1 {
				width: 50%;
				padding: 50px 0 100px 40px;
				flex: 1 1;
				@include until(940px) {
					width: 100%;
					padding: 15px;
				}
			}
		}
		.no-results-found {
			.text {
				color: #bdbdbd;
				font-size: 24px;
			}
			&::before {
				content: '';
				display: flex;
				width: 314px;
				height: 314px;
				margin: 0 auto 30px;
				background-image: url(~@/assets/img/common/noresult.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: 50% 50%;
			}
		}
	}
	&-lnb {
		&-profile {
			display: flex;
			position: relative;
			width: 120px;
			margin: 0 auto 10px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.button-setting {
				position: absolute;
				width: 35px;
				height: 35px;
				bottom: 23px;
				right: 0;
				border: 1px solid #eeeeee;
				border-radius: 50%;
				background: #ffffff;
				&:hover,
				&:focus {
					border-color: #424242;
				}
				@include mobile {
					transform: scale(0.8) translate(20%, 20%);
				}
			}
			.icon-mypage-setting {
				@include icon;
				width: 18px;
				height: 18px;
				background-image: url(~@/assets/img/mypage/icon_setting.svg);
			}
			@include mobile {
				width: 70px;
				margin: 0;
			}
			&__img {
				@include icon;
				@include object-fit;
				// display: flex;
				// position: relative;
				// overflow: hidden;
				width: 120px;
				height: 120px;
				border-radius: 50%;
				border: 1px solid #eee;
				background-image: url(~@/assets/img/community/reply_unknown_icon.svg);
				// align-items: center;
				// justify-content: center;
				// img {
				// 	position: absolute;
				// 	max-width: 100%;
				// 	width: 100%;
				// 	height: 100%;
				// 	object-fit: cover;
				// }
				@include mobile {
					width: 70px;
					height: 70px;
				}
			}
			&__text {
				display: block;
				margin-top: 10px;
				font-size: 14px;
				font-weight: 700;
			}
		}
		&-welcome {
			@include mobile {
				margin-left: 20px;
			}
			&__text {
				line-height: 1.2;
				text-align: center;
				font-size: 24px;
				font-weight: 700;
				color: #757575;
				@include word-wrap;
			}
			&__buttons {
				display: block;
				margin-top: 10px;
				text-align: center;
				.button-default {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
					&.is-small {
						display: inline-flex;
						width: auto;
						height: 21px;
						padding: 5px 10px;
						border: 1px solid #645f5b;
						font-size: 12px;
						&:hover,
						&:focus {
							background-color: #645f5b;
							color: #fff;
						}
						&.is-status {
							border: 1px solid #eee;
							background: #f8f9fa;
							&:hover,
							&:focus {
								background: #f8f9fa;
								color: #424242;
							}
							a {
								display: inline-block;
								margin-right: 5px;
								font-weight: 700;
								text-decoration: underline;
								&:hover,
								&:focus {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}

		&-header {
			margin-bottom: 30px;
			@include mobile {
				display: flex;
				margin-bottom: 20px;
				align-items: center;
				justify-content: center;
			}
		}
		&-nav {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			@include mobile {
				overflow-y: hidden;
				overflow-x: auto;
				margin-left: -15px;
				margin-right: -15px;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 6px;
				white-space: nowrap;
				flex-wrap: nowrap;
				gap: 8px;
			}
			.button {
				display: flex;
				flex-direction: column;
				min-width: 90px;
				width: calc(50% - 5px);
				height: 90px;
				padding: 10px;
				border-radius: 15px;
				border: 1px solid #eee;
				background: #f4f4f4;
				text-align: left;
				font-size: 14px;
				color: #424242;
				justify-content: space-between;
				@include transition(all, 0.3s);
				@include mobile {
					width: calc(25% - 6px);
				}
				&.is-active {
					border-color: #ff7300;
					background-color: #ff7300;
					color: #fff;
				}
				&:hover,
				&:focus,
				&:focus-visible {
					border-color: #ff7300;
				}
			}
			&__title {
				text-align: center;
				flex: 1 1;
				@include word-wrap;
				line-height: 1.3;
			}
			&__footer {
				font-size: 16px;
				font-weight: 400;
				flex: 0 0;
				.num {
					font-weight: 700;
				}
			}
		}
		&-footer {
			margin-top: 40px;
			@include mobile {
				margin-top: 15px;
			}
			&__banner {
				display: flex;
				padding: 10px;
				border-radius: 15px;
				background: #fef5ed;
				color: #b05000;
				font-size: 14px;
				flex-direction: column;
				align-items: flex-start;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				@include mobile {
					padding: 10px 20px;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
				}
				@include until(340px) {
					padding: 10px;
					flex-direction: column;
					align-items: flex-start;
				}
				&-text {
					margin-top: 5px;
					margin-bottom: 15px;
					@include mobile {
						margin-bottom: 0;
					}
					@include until(340px) {
						margin-bottom: 15px;
					}
				}
				&.color-grey {
					background-color: #f0eeee;
					color: #424242;
				}
				span {
					display: block;
					margin-bottom: 5px;
					line-height: 1.2;
				}
				strong {
					display: block;
					line-height: 1.2;
					font-size: 21px;
					font-weight: 700;
				}
			}
		}
	}
	&-hbiz-flex {
		display: flex;
		width: 100%;
		max-width: 100%;
		align-items: center;
		justify-content: space-between;
		gap: 0px;
		@include mobile {
			display: block;
		}
		.mypage2-hsection-content {
			width: calc(50% + 16px);
			max-width: calc(50% + 16px);
			@include mobile {
				width: calc(100% + 30px);
				max-width: calc(100% + 30px);
				+ .mypage2-hsection-content {
					margin-top: 15px;
				}
			}
		}
	}
	&-hsection {
		position: relative;
		width: calc(50% - 60px);
		max-width: calc(50% - 60px);
		// padding-left: 20px;
		// padding-right: 20px;
		&.is-fullwidth {
			width: 100%;
			max-width: 100%;
			@include mobile {
				width: calc(100% + 30px);
				max-width: calc(100% + 30px);
			}
		}
		@include tablet {
			width: 100%;
			max-width: 100%;
		}
		@include mobile {
			overflow: hidden;
			width: calc(100% + 30px);
			max-width: calc(100% + 30px);
			margin-left: -15px;
			margin-right: -15px;
			.swiper {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.button-tabs {
			padding: 0 0 6px;
			@include mobile {
				padding: 0 15px 8px;
			}
		}
		.swiper-slide {
			padding: 5px;
		}

		&-header {
			display: flex;
			margin-bottom: 10px;
			// padding-left: 12px;
			// padding-right: 12px;
			justify-content: space-between;
			@include mobile {
				margin-bottom: 7px;
				padding-left: 15px;
				padding-right: 15px;
			}
			&__title {
				display: flex;
				font-size: 18px;
				font-weight: 700;
				flex: 1 1;
				align-items: flex-end;
			}
			.flex-r {
				flex: 0 0;
				display: flex;
				gap: 15px;
				align-items: flex-end;
				.boradcast-button-cal {
					width: 23px;
					height: 22px;
					.icon-tv {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.simple-notice-list {
			margin-top: 1em;
			&__title {
				font-size: 15px;
			}
			&__date {
				font-size: 13px;
			}
		}
		.swiper-buttons-container {
			.swiper-button-next,
			.swiper-button-prev {
				display: flex;
				width: 28px;
				height: 28px;
				align-items: center;
				justify-content: center;
				&::after {
					position: static !important;
					width: 10px;
					height: 10px;
					border-top: 1px solid #555;
					border-right: 1px solid #555;
				}
				&.swiper-button-disabled {
					opacity: 0.15;
				}
				@include mobile {
					transform: none;
				}
			}
			.swiper-button-prev {
				left: -6px;
				@include mobile {
					left: 14px;
				}
				&:after {
					margin-left: 4px;
				}
			}
			.swiper-button-next {
				right: -6px;
				@include mobile {
					right: 14px;
				}
				&:after {
					margin-left: -4px;
				}
			}
		}
		&-content {
			position: relative;
			padding-left: 15px;
			padding-right: 15px;
			margin-left: -20px;
			margin-right: -20px;
			&.is-onlytext {
				margin-left: -15px;
				margin-right: -15px;
				@include mobile {
					margin-left: 0;
					margin-right: 0;
				}
			}
			.no-results-found {
				display: flex;
				height: 160px;
				padding: 0;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 0.5;
				.text {
					font-size: 18px;
					font-weight: 400;
					color: #999;
				}
				&::before {
					width: 100px;
					height: 100px;
					margin-bottom: 15px;
				}
			}
		}
		.fp-digital-card {
			@extend %mypage2-boxshadow;
			height: auto;
			border-radius: 12px;
			flex-direction: column;
			border: 1px solid #eeeeee;
			background-color: #fff;
			@include transition(border, 0.5s);
			&:hover,
			&:focus {
				border-color: #645f5b;
			}
			&__head {
				display: flex;
				padding: 12px 12px 0;
				gap: 4px;
				align-items: center;
				.card-tag {
					display: inline-flex;
					padding: 3px 4px;
					border-radius: 4px;
					background: #ff7300;
					color: #fff;
					line-height: 1;
					font-size: 10px;
					font-weight: 400;
					align-items: center;
				}
			}
			&__body {
				padding: 8px 12px 12px;
				&-title {
					display: block;
					margin-bottom: 6px;
					font-size: 14px;
					@include text-ellipsis;
				}
			}
			&__tag {
				height: 20px;
				padding: 4px 0;
				border-radius: 4px;
				font-size: 12px;
				@include text-ellipsis;
			}
		}
		.icon-fp-date {
			width: 17px;
			height: 18px;
		}
		.scomm-card {
			display: block;
			height: 160px;
			padding: 12px;
			border: 1px solid #eeeeee;
			@include transition(border, 0.5s);
			&:hover,
			&:focus {
				border-color: #645f5b;
			}
			@extend %mypage2-boxshadow;
			&-thumb {
				position: relative;
				width: 100%;
				max-width: 100%;
				height: 80px;
				margin-bottom: 10px;
				border-radius: 4px;
				img {
					position: absolute;
					width: 100%;
					height: 100%;
				}
			}
			&-path {
				font-size: 12px;
			}
			&-title {
				margin: 0;
				font-size: 15px;
				white-space: normal;
				line-height: 1.2;
				@include text-ellipsis-2;
			}
		}
		.live-card {
			padding-top: 0;
			.video-badge {
				width: 53px;
				height: 36px;
				top: -4px;
				right: -6px;
			}
			.live-video {
				// height: 160px;
				&-link {
					height: auto;
					border: 1px solid #eeeeee;
					@include transition(border, 0.5s);
					@extend %mypage2-boxshadow;
					&:hover,
					&:focus {
						border-color: #645f5b;
					}
				}
			}
			.video-content {
				height: 64px;
				padding: 10px 12px;
				background-color: #fff;
				justify-content: flex-start;
				&-title {
					margin: 0;
					max-height: 4em;
					font-size: 13px;
					line-height: 1.2;
					@include text-ellipsis-3;
				}
			}
			.video-date {
				height: 40px;
				padding: 10px;
				border-radius: 10px;
				&-day {
					margin-bottom: 2px;
					font-size: 13px;
					&__week {
						font-size: 13px;
					}
				}
				&-time {
					font-size: 10px;
					font-weight: 300;
				}
			}
			.video-player {
				height: 100%;
				// padding-top: 138%;
				padding-top: 180%;
				img {
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
		.noimg {
			position: relative;
			height: 160px;
			padding: 12px;
			background-color: #fff;
			@extend %mypage2-boxshadow;
			&:before {
				content: '';
				position: absolute;
				background-color: #fff;
				width: 124px;
				height: 28px;
				@include icon;
				top: 50%;
				left: 50%;
				background-image: url(~@/assets/img/renew/noimg.svg);
				transform: translate(-50%, -50%);
			}
		}
		.sptpgm-article {
			position: relative;
			height: 195px;
			padding: 12px;
			background-color: #fff;
			border: 1px solid #eeeeee;
			@include transition(border, 0.5s);
			@extend %mypage2-boxshadow;
			&:hover,
			&:focus {
				border-color: #645f5b;
			}
			@include mobile {
				padding: 12px;
			}
			&-header {
				margin-bottom: 12px;
				z-index: 1;
				background-color: #fff;
				font-size: 12px;
				gap: 4px;
				@include tablet {
					gap: 5px;
				}
				&__tag {
					display: inline-flex;
					height: 20px;
					padding: 4px;
				}
				&__dday {
				}
			}
			&-title {
				height: 33px;
				margin-bottom: 6px;
				font-size: 15px;
				line-height: 1.1;
				@include text-ellipsis-2;
			}
			&-desc {
				height: 33px;
				font-size: 13px;
			}
			&-dl {
				// width: 124px;
				margin-bottom: 0px;
				font-size: 12px;
				dt {
					padding-right: 0px;
					flex: 0 0 22px;
				}
				dd {
					flex: 1 1;
					letter-spacing: -0.5px;
					@include word-wrap;
				}
			}
			&-body {
				z-index: 1;
				background-color: #fff;
				flex: 1 1;
			}
			&-footer {
				z-index: 1;
				margin-top: 0;
				padding-top: 12px;
				background-color: #fff;
				.button-default {
					&.is-primary.is-rounded {
						height: 24px;
						margin-top: 10px;
						font-size: 13px;
					}
				}
			}
		}
	}
	&-content {
	}
	&-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		flex-wrap: wrap;
	}
	&-body {
		display: flex;
		flex-wrap: wrap;
		gap: 50px;
		justify-content: space-between;
		// overflow: hidden;
		&-title {
			margin-bottom: 16px;
			.title {
				font-size: 19px;
			}
		}
	}
	&-comm {
		width: 100%;
		.scomm-card {
			position: relative;
			&-item {
				&__status {
					&-ing {
						@extend %mypage2-status;
						background-color: #c5f2c7;
						color: #155d18;
					}
					&-close {
						@extend %mypage2-status;
						background-color: #fcd0cf;
						color: #da100b;
					}
					&-join {
						@extend %mypage2-status;
						background-color: #c5dcfa;
						color: #424242;
					}
				}
				&__text {
					> .flex-l {
						padding-right: 10px;
						@include mobile {
							padding-right: 0;
						}
					}
					> .flex-r {
						@include mobile {
							position: absolute;
							display: block;
							top: 4px;
							left: 4px;
						}
					}
				}
			}
		}
	}
	&-broadcast {
		width: 100%;
		.list-item {
			.buttons {
				position: absolute;
				right: 10px;
				bottom: 10px;
				@include until(576px) {
					left: 4px;
					right: inherit;
					bottom: 6px;
				}
				.toggle-switch {
					transform: scale(0.75);
					&-label__after {
						margin-left: 5px;
					}
				}
			}
		}
	}
	&-elearn {
		width: 100%;
		.list-item {
			@include mobile {
				height: auto;
			}
			&-title {
				margin-top: 1em;
				@include text-ellipsis;
				@include mobile {
					margin-top: 0;
					@include text-ellipsis-2;
					white-space: inherit;
				}
			}
			&-image {
				width: 120px;
				flex: 0 0 120px;
				@include until(380px) {
					width: 100%;
					flex: none;
				}
			}
			.buttons {
				// position: absolute;
				// right: 10px;
				// bottom: 10px;
				@include until(380px) {
					position: static;
					margin-top: 1em;
				}
			}
		}
		.display-flex {
			display: flex;
			margin-top: -10px;
			margin-bottom: 20px;
			width: 100%;
			flex-wrap: wrap;
			justify-content: space-between;

			@include mobile {
				display: block;
				margin-top: 0;
				margin-bottom: 0;
			}
			> .list-item-txts__wrap {
				display: flex;
				padding-right: 10px;
				align-items: center;
				flex: 1 1;
				@include mobile {
					width: 100%;
				}
			}
			> .buttons {
				display: flex;
				flex: 0 0 110px;
				text-align: right;
				align-items: flex-end;
				justify-content: flex-end;
				.button-default.is-primary.is-rounded {
					width: 100%;
				}
				@include mobile {
					margin-top: 10px;
					justify-content: flex-start;
					.button-default.is-primary.is-rounded {
						width: auto;
					}
				}
				@include until(380px) {
					margin-top: 1.5em;
				}
			}
		}
	}
	&-support {
		width: 100%;
		.table-responsive {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
	&-myinfo {
		width: 100%;
		.input-password-container {
			.input-password-wrap {
				flex: 1 1;
			}
		}
		.profile-image-container {
			margin-bottom: 2em;
		}
		.btn.is-fullwidth {
			width: calc(100% - 110px);
			margin-top: 20px;
			margin-left: 110px;
			margin-bottom: 1em;
			@include tablet {
				width: 100%;
				margin-left: 0;
			}
			&.is-disabled {
				pointer-events: none;
				opacity: 0.5;
				background-color: #bbb;
			}
		}
		.form-row {
			position: relative;
			.form-cellth {
				position: static;
				@include tablet {
					flex: 0 0 100%;
					label {
						@include word-wrap;
						br {
							display: none;
						}
					}
				}
			}
			&.is-noth {
				padding-top: 0;
				margin-top: -1em;
				margin-left: 110px;
				@include tablet {
					margin: 1em 0 0;
				}
			}
			@include tablet {
				vertical-align: top;
				flex-wrap: wrap;
			}
			.form-celltd {
				@include tablet {
					flex: 1;
				}
			}
			.form-cellth {
				// position: absolute;
				// left: -110px;
				// top: 0;
				width: 110px;
				@include mobile {
					position: static;
					width: inherit;
				}
			}
			~ .form-row .form-cellth {
				top: 20px;
			}
		}
		.form-field {
			fieldset {
				&:not(:last-child) {
					display: block;
					margin-bottom: 5em;
				}
			}
			// legend {
			// 	margin-bottom: 1em;
			// 	font-size: 20px;
			// 	font-weight: 700;
			// }
			.buttons {
				display: flex;
				justify-content: center;
				gap: 10px;
				.btn-light {
					border: 1px solid #ddd;
				}
				&.pull-right {
					justify-content: flex-end;
					margin-bottom: 0.5em;
					.button-text {
						text-decoration: underline;
						&:hover,
						&:focus {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}
.mypage2-form-flex {
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
	@include until(540px) {
		flex-direction: column;
	}
	.fl {
		flex: 1 1;
		@include until(540px) {
			order: 2;
		}
	}
	.fr {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex: 0 0 200px;
		@include until(540px) {
			justify-content: center;
		}
		.profile-image-container {
			margin: 0;
			flex-direction: column;
			.profile-image {
				width: 180px;
				height: 180px;
				margin-bottom: 15px;
			}
		}
	}
}
.button-tabs {
	display: flex;
	margin-bottom: 10px;
	overflow-y: hidden;
	overflow-x: auto;
	white-space: nowrap;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	.button-default {
		width: auto;
		height: 26px;
		border: 1px solid rgba(150, 150, 150, 0.5);
		background-color: #fff;
		font-size: 13.5px;
		color: #645f5b;
		@include transition(all, 0.3s);
		&.is-active,
		&:hover,
		&:focus {
			background-color: #645f5b;
			color: #fff;
		}
	}
}
// Breadcrumb
.breadcrumb {
	display: flex;
	margin: 0;
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
	align-items: center;
	@include tablet {
		display: block;
		font-size: 14px;
	}
	ol {
		display: inline-flex;
		align-items: center;
		li {
			&:not(:first-child) {
				&::before {
					content: '';
					display: inline-block;
					width: 8px;
					height: 8px;
					margin: 0 8px 0 5px;
					border-top: 1px solid #bdbdbd;
					border-right: 1px solid #bdbdbd;
					border-width: 1px;
					transform: rotate(45deg) translate(-10%, -10%);
				}
			}
		}
		a {
			display: inline-flex;
			align-items: center;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}

.link-more {
	white-space: nowrap;
	font-size: 12px;
	display: inline-block;
}
.icon-mypage-home {
	@include icon;
	width: 24px;
	height: 22px;
	margin-right: 8px;
	background-image: url(~@/assets/img/mypage/icon_home.svg);
}
.list-item {
	overflow: hidden;
	position: relative;
	display: flex;
	height: 107px;
	padding: 10px;
	border-radius: 15px;
	background: #ffffff;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	align-items: center;
	gap: 30px;
	@include mobile {
		gap: 15px;
	}
	@include until(576px) {
		height: 180px;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
	}
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	&-container {
	}
	&-image {
		@include object-fit;
		width: 170px;
		height: 100%;
		border-radius: 8px;
		flex: 0 0 170px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		@include mobile {
			width: 100px;
			flex: 0 0 100px;
		}
		@include until(576px) {
			width: 100%;
			height: 87px;
			flex: none;
		}
	}
	&-content {
		width: calc(100% - 200px);
		flex: 1 1;
		@include until(576px) {
			width: 100%;
		}
		&.display-flex {
			display: flex;
			align-items: center;
			@include until(1000px) {
				display: block;
			}
			@include until(576px) {
				display: flex;
				flex: none;
			}
			.list-item-title {
				flex: 0 0 90px;
				@include until(576px) {
					flex: 0 0 80px;
				}
			}
			.list-item-txts {
				margin: 0;
				flex: 1 1;
				padding-right: 140px;
				@include word-wrap;
				@include until(576px) {
					padding-right: 0;
				}
				&__desc {
					max-height: 56px;
					line-height: 1.2;
					color: #757575;
					font-weight: 400;
					@include text-ellipsis-3;
					@include until(1000px) {
						margin-top: 0.5em;
						font-size: 14px;
					}
					@include mobile {
						max-height: 51px;
						font-size: 12px;
					}
					@include until(576px) {
						max-height: 30px;
						@include text-ellipsis-2;
					}
				}
			}
		}
	}
	&-title {
		// max-width: calc(100% - 20px);
		font-size: 26px;
		font-weight: 700;
		line-height: 1.1;
		@include text-ellipsis;
		@include mobile {
			font-size: 22px;
		}
	}
	&-txts {
		display: flex;
		margin-top: 10px;
		flex-wrap: wrap;
		gap: 10px;
		&__dl {
			display: inline-flex;
			margin-right: 20px;
			font-size: 14px;
			color: #757575;
			align-items: center;
			&.is-fullwidth {
				width: 100%;
			}
			&.is-orange {
				color: #ff7300;
			}
			dt {
				margin-right: 10px;
				white-space: nowrap;
			}
		}
	}
}

.profile-image {
	&-container {
		display: flex;
		align-items: center;
		.profile-image {
			overflow: hidden;
			width: 120px;
			height: 120px;
			border-radius: 50%;
			@include object-fit;
			img {
			}
		}
	}
}

#popup-mypage-withdrawal {
	max-width: 600px;
	.popup-body {
		text-align: left;
		padding-top: 0;
		padding-left: 80px;
		padding-right: 80px;
		line-height: 1.4;
		@include until(576px) {
			padding-left: 20px;
			padding-right: 20px;
		}
		.title {
			margin-bottom: 1em;
			font-size: 21px;
			color: #ff7300;
		}
		p {
			margin-bottom: 1em;
		}
	}
}

.display-flex {
	display: flex;
	&.width-full {
		width: 100%;
	}
	&.is-between {
		justify-content: space-between;
		.icon-status {
			display: block;
			overflow: hidden;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
			&.color {
				&-blue {
					background-color: #c5dcfa;
				}
				&-yellow {
					background-color: #fbf3d0;
				}
				&-green {
					background-color: #c5f2c7;
				}
				&-red {
					background-color: #fcd0cf;
				}
			}
		}
	}
}

.text-help {
	display: block;
	margin-top: 0.25em;
	.small-text {
		font-size: 13px;
		line-height: 1.2;
		color: #ff7300;
	}
}
