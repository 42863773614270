/*
    LAYOUT
*/
.g-0,
.gx-0 {
	--gutter-x: var(--gutter-0);
}
.g-1,
.gx-1 {
	--gutter-x: var(--gutter-1);
}
.g-2,
.gx-2 {
	--gutter-x: var(--gutter-2);
}
.g-3,
.gx-3 {
	--gutter-x: var(--gutter-3);
}
.g-4,
.gx-4 {
	--gutter-x: var(--gutter-4);
}
.g-5,
.gx-5 {
	--gutter-x: var(--gutter-5);
}

.row {
	display: flex;
	flex-wrap: wrap;
}
.row > * {
	width: 100%;
	max-width: 100%;
}
.col {
	flex: 1 0 0%;
}
.col-1 {
	flex: 0 0 auto;
	width: 8.33333333333%;
}
.col-2 {
	flex: 0 0 auto;
	width: 16.6666666667%;
}
.col-3 {
	flex: 0 0 auto;
	width: 25%;
}
.col-4 {
	flex: 0 0 auto;
	width: 33.33333333332%;
}
.col-5 {
	flex: 0 0 auto;
	width: 41.66666666665%;
}
.col-6 {
	flex: 0 0 auto;
	width: 50%;
}
.col-7 {
	flex: 0 0 auto;
	width: 58.33333333331%;
}
.col-8 {
	flex: 0 0 auto;
	width: 66.66666666664%;
}
.col-9 {
	flex: 0 0 auto;
	width: 75%;
}
.col-10 {
	flex: 0 0 auto;
	width: 83.3333333333%;
}
.col-11 {
	flex: 0 0 auto;
	width: 91.66666666663%;
}
.col-12 {
	flex: 0 0 auto;
	width: 100%;
}
.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.row.gx-0 > * {
	padding-left: calc(var(--gutter-0) / 2);
	padding-right: calc(var(--gutter-0) / 2);
}
.row.gx-1 > * {
	padding-left: calc(var(--gutter-1) / 2);
	padding-right: calc(var(--gutter-1) / 2);
}
.row.gx-2 > * {
	padding-left: calc(var(--gutter-2) / 2);
	padding-right: calc(var(--gutter-2) / 2);
}
.row.gx-3 > * {
	padding-left: calc(var(--gutter-3) / 2);
	padding-right: calc(var(--gutter-3) / 2);
}
.row.gx-4 > * {
	padding-left: calc(var(--gutter-4) / 2);
	padding-right: calc(var(--gutter-4) / 2);
}
.row.gx-5 > * {
	padding-left: calc(var(--gutter-5) / 2);
	padding-right: calc(var(--gutter-5) / 2);
}

.row.g-0 {
	margin-top: calc(var(--gutter-0) * -1);
	margin-right: calc(var(--gutter-0) / -2);
	margin-left: calc(var(--gutter-0) / -2);
}
.row.g-1 {
	margin-top: calc(var(--gutter-1) * -1);
	margin-right: calc(var(--gutter-1) / -2);
	margin-left: calc(var(--gutter-1) / -2);
}
.row.g-2 {
	margin-top: calc(var(--gutter-2) * -1);
	margin-right: calc(var(--gutter-2) / -2);
	margin-left: calc(var(--gutter-2) / -2);
}
.row.g-3 {
	margin-top: calc(var(--gutter-3) * -1);
	margin-right: calc(var(--gutter-3) / -2);
	margin-left: calc(var(--gutter-3) / -2);
}
.row.g-4 {
	margin-top: calc(var(--gutter-4) * -1);
	margin-right: calc(var(--gutter-4) / -2);
	margin-left: calc(var(--gutter-4) / -2);
}
.row.g-5 {
	margin-top: calc(var(--gutter-5) * -1);
	margin-right: calc(var(--gutter-5) / -2);
	margin-left: calc(var(--gutter-5) / -2);
}

.row.g-0 > * {
	margin-top: var(--gutter-0);
	padding-right: calc(var(--gutter-0) / 2);
	padding-left: calc(var(--gutter-0) / 2);
}
.row.g-1 > * {
	margin-top: var(--gutter-1);
	padding-right: calc(var(--gutter-1) / 2);
	padding-left: calc(var(--gutter-1) / 2);
}
.row.g-2 > * {
	margin-top: var(--gutter-2);
	padding-right: calc(var(--gutter-2) / 2);
	padding-left: calc(var(--gutter-2) / 2);
}
.row.g-3 > * {
	margin-top: var(--gutter-3);
	padding-right: calc(var(--gutter-3) / 2);
	padding-left: calc(var(--gutter-3) / 2);
}
.row.g-4 > * {
	margin-top: var(--gutter-4);
	padding-right: calc(var(--gutter-4) / 2);
	padding-left: calc(var(--gutter-4) / 2);
}
.row.g-5 > * {
	margin-top: var(--gutter-5);
	padding-right: calc(var(--gutter-5) / 2);
	padding-left: calc(var(--gutter-5) / 2);
}

.container {
	width: 100%;
	padding-right: var(--container-padding-x, 0.75rem);
	padding-left: var(--container-padding-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.example {
}
.highlight {
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.text-link {
	color: #015aa8;
}
.text-link:hover {
}

.stretched-link {
}
.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: var(--stretched-link-z-index);
	content: '';
}

h1,
.h1 {
	font-size: 50px;
}
h2,
.h2 {
	font-size: 40px;
}
h3,
.h3 {
	font-size: 30px;
}
h4,
.h4 {
	font-size: 25px;
}
h5,
.h5 {
	font-size: 20px;
}
h6,
.h6 {
	font-size: 18px;
}

.fs-xxs {
}
.fs-xs {
}
.fs-sm {
	font-size: 13px;
}
.fs-md {
	font-size: 16px;
}
.fs-lg {
	font-size: 20px;
}
.fs-xl {
}
.fs-xxl {
}

/*
    UTILITES
*/
/* DISPLAY */
.d-none {
	display: none !important;
}
.d-block {
	display: block !important;
}
.d-inline {
	display: block !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-flex {
	display: flex !important;
}
.d-inline-flex {
	display: inline-flex !important;
}
.d-grid {
	display: grid !important;
}
.d-inline-grid {
	display: inline-grid !important;
}
.d-flow-root {
	display: flow-root !important;
}
.d-table {
	display: table;
}
.d-table-row {
	display: table-row;
}
.d-list-item {
	display: list-item;
}

.visible {
	visibility: visible !important;
}
.invisible {
	visibility: hidden !important;
}

/* BORDERS */
.border {
	border: 1px solid #ebebeb !important;
}
.border-top {
	border-top: 1px solid #ebebeb !important;
}
.border-end {
	border-right: 1px solid #ebebeb !important;
}
.border-bottom {
	border-bottom: 1px solid #ebebeb !important;
}
.border-start {
	border-left: 1px solid #ebebeb !important;
}

.border-0 {
	border: 0 !important;
}
.border-top {
	border-top: 0 !important;
}
.border-end {
	border-right: 0 !important;
}
.border-bottom {
	border-bottom: 0 !important;
}
.border-start {
	border-left: 0 !important;
}

.border-1 {
	border-width: 1px !important;
}
.border-2 {
	border-width: 2px !important;
}
.border-3 {
	border-width: 3px !important;
}
.border-4 {
	border-width: 4px !important;
}
.border-5 {
	border-width: 5px !important;
}

.rounded {
	border-radius: 0.3125rem !important;
}
.rounded-top {
	border-top-left-radius: 0.3125rem !important;
	border-top-right-radius: 0.3125rem !important;
}
.rounded-end {
	border-top-right-radius: 0.3125rem !important;
	border-bottom-right-radius: 0.3125rem !important;
}
.rounded-bottom {
	border-bottom-left-radius: 0.3125rem !important;
	border-bottom-right-radius: 0.3125rem !important;
}
.rounded-start {
	border-top-left-radius: 0.3125rem !important;
	border-bottom-left-radius: 0.3125rem !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}
.rounded-1 {
	border-radius: 0.2rem !important;
}
.rounded-2 {
	border-radius: 0.3125rem !important;
}
.rounded-3 {
	border-radius: 0.3rem !important;
}

/* COLORS */
.text-primary {
	color: #015aa8 !important;
}
.text-secondary {
	color: #ff7300 !important;
}
.text-light {
	color: #ebebeb !important;
}
.text-dark {
	color: #111 !important;
}
.text-body {
	color: #111 !important;
}
.text-muted {
	color: #999 !important;
}
.text-white {
	color: #fff !important;
}
.text-red {
	color: var(--danger) !important;
}
.text-cyan {
	color: #00babf !important;
}
.text-brown {
	color: var(--brown) !important;
}
.text-purple {
	color: var(--purple) !important;
}
.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 bg-dark {
	color: rgba(255, 255, 255, 0.5) !important;
}
.text-green {
	color: #1bbd66;
}

.bg-primary {
	background-color: #015aa8 !important;
}
.bg-secondary {
	background-color: #ff7300 !important;
}
.bg-light {
	background-color: #ebebeb !important;
}
.bg-dark {
	background-color: #111 !important;
}
.bg-body {
	background-color: #fff !important;
}
.bg-white {
	background-color: #fff !important;
}
.bg-transparent {
	background-color: transparent !important;
}

.bg-blue {
	background-color: #015aa8 !important;
}
.bg-orange {
	background-color: #ff7300 !important;
}
.bg-green {
	background-color: #1bbd66 !important;
}

.border-primary {
	border-color: #015aa8 !important;
}
.border-secondary {
	border-color: #ff7300 !important;
}
.border-light {
	border-color: #ebebeb !important;
}
.border-dark {
	border-color: #111 !important;
}
.border-white {
	border-color: #111 !important;
}

/* SHADOWS */
.shadow-none {
	box-shadow: none !important;
}
.shadow-sm {
	box-shadow: var(--box-shadow-sm) !important;
}
.shadow {
	box-shadow: var(--box-shadow) !important;
}
.shadow-lg {
	box-shadow: var(--box-shadow-lg) !important;
}

/* TEXT */
/* Text alignment */
.text-left {
	text-align: left !important;
}
.text-center {
	text-align: center !important;
}
.text-right {
	text-align: right !important;
}

.text-start {
	text-align: left !important;
}
.text-center {
	text-align: center !important;
}
.text-end {
	text-align: right !important;
}

/* Text wrapping and overflow */
.text-wrap {
	white-space: nowrap !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* Word breack */
.text-break {
	word-break: break-word !important;
	overflow-wrap: break-word !important;
}

/* Text transform */
.text-lowercase {
	text-transform: lowercase !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}

/* Font weight and italics */
.font-weight-bold {
	font-weight: 700 !important;
}
.font-weight-border {
	font-weight: 800 !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-lighter {
	font-weight: lighter !important;
}
.font-italic {
	font-style: italic !important;
}

.fw-bold {
	font-weight: 700 !important;
}
.fw-bolder {
	font-weight: 800 !important;
}
.fw-normal {
	font-weight: 400 !important;
}
.fw-light {
	font-weight: 300 !important;
}
.fw-lighter {
	font-weight: lighter !important;
}
.fw-italic {
	font-style: italic !important;
}
.fw-normal {
	font-style: normal !important;
}

.ff-blacksans {
	font-family: 'Black Han Sans', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.lh-1 {
	line-height: 1 !important;
}
.lh-sm {
	line-height: 1.25 !important;
}
.lh-base {
	line-height: 1.5 !important;
}
.lh-lg {
	line-height: 2 !important;
}

/* Rest color */
.text-reset {
	color: inherit !important;
}

/* Text decoration */
.text-decoration-underline {
	text-decoration: underline !important;
}
.text-decoration-line-through {
	text-decoration: line-through !important;
}
.text-decoration-none {
	text-decoration: none !important;
}

/* Vertical alignment */
.align-baseline {
	vertical-align: baseline !important;
}
.align-top {
	vertical-align: top !important;
}
.align-middle {
	vertical-align: middle !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
.align-text-top {
	vertical-align: text-top !important;
}
.align-text-bottom {
	vertical-align: text-bottom !important;
}

/* OVERFLOW */
.overflow-auto {
	overflow: auto !important;
}
.overflow-hidden {
	overflow: hidden !important;
}
.overflow-visible {
	overflow: visible !important;
}
.overflow-scroll {
	overflow: scroll !important;
}

/* POSITION */
/* Position values */
.position-static {
	position: static !important;
}
.position-relative {
	position: relative !important;
}
.position-absolute {
	position: absolute !important;
}
.position-fixed {
	position: fixed !important;
}
.position-sticky {
	position: sticky !important;
}

.fixed-top {
	position: fixed;
	top: 0;
}
.fixed-bottom {
	position: fixed;
	bottom: 0;
}
.sticky-top {
	position: sticky;
	top: 0;
}

/* Arrange elements */
.top-0 {
	top: 0 !important;
}
.top-50 {
	top: 50% !important;
}
.top-100 {
	top: 100% !important;
}
.start-0 {
	left: 0 !important;
}
.start-50 {
	left: 50% !important;
}
.start-100 {
	left: 100% !important;
}
.bottom-0 {
	bottom: 0 !important;
}
.bottom-50 {
	bottom: 50% !important;
}
.bottom-100 {
	bottom: 100% !important;
}
.end-0 {
	right: 0 !important;
}
.end-50 {
	right: 50% !important;
}
.end-100 {
	right: 100% !important;
}

/* Center elements */
.translate-middle {
	transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
	transform: translateX(-50%) !important;
}
.translate-middle-y {
	transform: translateY(-50%) !important;
}

/* FLEX */
.flex {
	display: flex;
	&.gap {
		&-4 {
			gap: 4px;
		}
		&-8 {
			gap: 8px;
		}
		&-12 {
			gap: 12px;
		}
		&-16 {
			gap: 16px;
		}
		&-20 {
			gap: 20px;
		}
		&-24 {
			gap: 24px;
		}
	}
}
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-center-x {
	display: flex;
	justify-content: center;
}
.flex-center-y {
	display: flex;
	align-items: center;
}

.flex-both {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex-row {
	flex-direction: row !important;
}
.flex-row-reverse {
	flex-direction: row-reverse !important;
}
.flex-column {
	flex-direction: column !important;
}
.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}
.justify-content-end {
	justify-content: flex-end !important;
}
.justify-content-center {
	justify-content: center !important;
}
.justify-content-between {
	justify-content: space-between !important;
}
.justify-content-around {
	justify-content: space-around !important;
}
.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}
.align-items-end {
	align-items: flex-end !important;
}
.align-items-center {
	align-items: center !important;
}
.align-items-baseline {
	align-items: baseline !important;
}
.align-items-stretch {
	align-items: stretch !important;
}

.align-self-start {
	align-self: flex-start !important;
}
.align-self-end {
	align-self: flex-end !important;
}
.align-self-center {
	align-self: center !important;
}
.align-self-baseline {
	align-self: baseline !important;
}
.align-self-stretch {
	align-self: stretch !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}
.flex-grow-1 {
	flex: 1 !important;
}
.flex-shrink {
	flex: 1 !important;
}

.me-auto {
	margin-right: auto !important;
}
.ms-auto {
	margin-left: auto !important;
}
.mb-auto {
	margin-bottom: auto !important;
}
.mt-auto {
	margin-top: auto !important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.m-0 {
	margin: var(--spacer-0) !important;
}
.m-1 {
	margin: 5px !important;
}
.m-2 {
	margin: 10px !important;
}
.m-3 {
	margin: 20px !important;
}
.m-4 {
	margin: 30px !important;
}
.m-5 {
	margin: 40px !important;
}
.m-6 {
	margin: 50px !important;
}
.m-7 {
	margin: 60px !important;
}

.mt-0 {
	margin-top: var(--spacer-0) !important;
}
.mt-1 {
	margin-top: 5px !important;
}
.mt-2 {
	margin-top: 10px !important;
}
.mt-3 {
	margin-top: 20px !important;
}
.mt-4 {
	margin-top: 30px !important;
}
.mt-5 {
	margin-top: 40px !important;
}
.mt-6 {
	margin-top: 50px !important;
}
.mt-7 {
	margin-top: 60px !important;
}

.mb-0 {
	margin-bottom: var(--spacer-0) !important;
}
.mb-1 {
	margin-bottom: 5px !important;
}
.mb-2 {
	margin-bottom: 10px !important;
}
.mb-3 {
	margin-bottom: 20px !important;
}
.mb-4 {
	margin-bottom: 30px !important;
}
.mb-5 {
	margin-bottom: 40px !important;
}
.mb-6 {
	margin-bottom: 50px !important;
}
.mb-7 {
	margin-bottom: 60px !important;
}

.ms-0 {
	margin-left: var(--spacer-0) !important;
}
.ms-1 {
	margin-left: 5px !important;
}
.ms-2 {
	margin-left: 10px !important;
}
.ms-3 {
	margin-left: 20px !important;
}
.ms-4 {
	margin-left: 30px !important;
}
.ms-5 {
	margin-left: 40px !important;
}
.ms-6 {
	margin-left: 50px !important;
}
.ms-7 {
	margin-left: 60px !important;
}

.me-0 {
	margin-right: var(--spacer-0) !important;
}
.me-1 {
	margin-right: 5px !important;
}
.me-2 {
	margin-right: 10px !important;
}
.me-3 {
	margin-right: 20px !important;
}
.me-4 {
	margin-right: 30px !important;
}
.me-5 {
	margin-right: 40px !important;
}
.me-6 {
	margin-left: 50px !important;
}
.ms-7 {
	margin-left: 60px !important;
}

.mx-0 {
	margin-left: var(--spacer-0) !important;
	margin-right: var(--spacer-0) !important;
}
.mx-1 {
	margin-left: 5px !important;
	margin-right: 5px !important;
}
.mx-2 {
	margin-left: 10px !important;
	margin-right: 10px !important;
}
.mx-3 {
	margin-left: 20px !important;
	margin-right: 20px !important;
}
.mx-4 {
	margin-left: 30px !important;
	margin-right: 30px !important;
}
.mx-5 {
	margin-left: 40px !important;
	margin-right: 40px !important;
}
.mx-6 {
	margin-left: 50px !important;
	margin-right: 50px !important;
}
.mx-7 {
	margin-left: 60px !important;
	margin-right: 60px !important;
}

.my-0 {
	margin-top: var(--spacer-0) !important;
	margin-bottom: var(--spacer-0) !important;
}
.my-1 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.my-2 {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}
.my-3 {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}
.my-4 {
	margin-top: 30px !important;
	margin-bottom: 30px !important;
}
.my-5 {
	margin-top: 40px !important;
	margin-bottom: 40px !important;
}
.my-6 {
	margin-top: 50px !important;
	margin-bottom: 50px !important;
}
.my-7 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}

.mt-n0 {
	margin-top: calc(var(--spacer-0) * -1) !important;
}
.mt-n1 {
	margin-top: calc(5px * -1) !important;
}
.mt-n2 {
	margin-top: calc(10px * -1) !important;
}
.mt-n3 {
	margin-top: calc(20px * -1) !important;
}
.mt-n4 {
	margin-top: calc(30px * -1) !important;
}
.mt-n5 {
	margin-top: calc(40px * -1) !important;
}
.mt-n6 {
	margin-top: calc(50px * -1) !important;
}
.mt-n7 {
	margin-top: calc(60px * -1) !important;
}

.mb-n0 {
	margin-bottom: calc(var(--spacer-0) * -1) !important;
}
.mb-n1 {
	margin-bottom: calc(5px * -1) !important;
}
.mb-n2 {
	margin-bottom: calc(10px * -1) !important;
}
.mb-n3 {
	margin-bottom: calc(20px * -1) !important;
}
.mb-n4 {
	margin-bottom: calc(30px * -1) !important;
}
.mb-n5 {
	margin-bottom: calc(40px * -1) !important;
}
.mb-n6 {
	margin-bottom: calc(50px * -1) !important;
}
.mb-n7 {
	margin-bottom: calc(60px * -1) !important;
}

.ms-n0 {
	margin-left: calc(var(--spacer-0) * -1) !important;
}
.ms-n1 {
	margin-left: calc(5px * -1) !important;
}
.ms-n2 {
	margin-left: calc(10px * -1) !important;
}
.ms-n3 {
	margin-left: calc(20px * -1) !important;
}
.ms-n4 {
	margin-left: calc(30px * -1) !important;
}
.ms-n5 {
	margin-left: calc(40px * -1) !important;
}
.ms-n6 {
	margin-left: calc(50px * -1) !important;
}
.ms-n7 {
	margin-left: calc(60px * -1) !important;
}

.me-n0 {
	margin-right: calc(var(--spacer-0) * -1) !important;
}
.me-n1 {
	margin-right: calc(5px * -1) !important;
}
.me-n2 {
	margin-right: calc(10px * -1) !important;
}
.me-n3 {
	margin-right: calc(20px * -1) !important;
}
.me-n4 {
	margin-right: calc(30px * -1) !important;
}
.me-n5 {
	margin-right: calc(40px * -1) !important;
}
.me-n6 {
	margin-right: calc(50px * -1) !important;
}
.me-n7 {
	margin-right: calc(60px * -1) !important;
}

.p-0 {
	padding: var(--spacer-0) !important;
}
.p-1 {
	padding: 5px !important;
}
.p-2 {
	padding: 10px !important;
}
.p-3 {
	padding: 20px !important;
}
.p-4 {
	padding: 30px !important;
}
.p-5 {
	padding: 40px !important;
}

.pt-0 {
	padding-top: var(--spacer-0) !important;
}
.pt-1 {
	padding-top: 5px !important;
}
.pt-2 {
	padding-top: 10px !important;
}
.pt-3 {
	padding-top: 20px !important;
}
.pt-4 {
	padding-top: 30px !important;
}
.pt-5 {
	padding-top: 40px !important;
}

.pb-0 {
	padding-bottom: var(--spacer-0) !important;
}
.pb-1 {
	padding-bottom: 5px !important;
}
.pb-2 {
	padding-bottom: 10px !important;
}
.pb-3 {
	padding-bottom: 20px !important;
}
.pb-4 {
	padding-bottom: 30px !important;
}
.pb-5 {
	padding-bottom: 40px !important;
}

.ps-0 {
	padding-left: var(--spacer-0) !important;
}
.ps-1 {
	padding-left: 5px !important;
}
.ps-2 {
	padding-left: 10px !important;
}
.ps-3 {
	padding-left: 20px !important;
}
.ps-4 {
	padding-left: 30px !important;
}
.ps-5 {
	padding-left: 40px !important;
}

.pe-0 {
	padding-right: var(--spacer-0) !important;
}
.pe-1 {
	padding-right: 5px !important;
}
.pe-2 {
	padding-right: 10px !important;
}
.pe-3 {
	padding-right: 20px !important;
}
.pe-4 {
	padding-right: 30px !important;
}
.pe-5 {
	padding-right: 40px !important;
}

.px-0 {
	padding-left: var(--spacer-0) !important;
	padding-right: var(--spacer-0) !important;
}
.px-1 {
	padding-left: 5px !important;
	padding-right: 5px !important;
}
.px-2 {
	padding-left: 10px !important;
	padding-right: 10px !important;
}
.px-3 {
	padding-left: 20px !important;
	padding-right: 20px !important;
}
.px-4 {
	padding-left: 30px !important;
	padding-right: 30px !important;
}
.px-5 {
	padding-left: 40px !important;
	padding-right: 40px !important;
}

.py-0 {
	padding-top: var(--spacer-0) !important;
	padding-bottom: var(--spacer-0) !important;
}
.py-1 {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}
.py-2 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}
.py-3 {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}
.py-4 {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}
.py-5 {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}
.flex-wrap {
	flex-wrap: wrap !important;
}

.order-0 {
	order: 0 !important;
}
.order-1 {
	order: 1 !important;
}
.order-2 {
	order: 2 !important;
}
.order-3 {
	order: 3 !important;
}
.order-4 {
	order: 4 !important;
}
.order-5 {
	order: 5 !important;
}
.order-first {
	order: -1 !important;
}
.order-last {
	order: 6 !important;
}

.align-content-start {
	align-content: flex-start !important;
}
.align-content-end {
	align-content: flex-end !important;
}
.align-content-center {
	align-content: center !important;
}
.align-content-between {
	align-content: space-between !important;
}
.align-content-around {
	align-content: space-around !important;
}
.align-content-stretch {
	align-content: stretch !important;
}

/* FLOAT */
.float-start {
	float: left !important;
}
.float-end {
	float: right !important;
}
.float-none {
	float: none !important;
}

/* SIZING */
.w-25 {
	width: 25% !important;
}
.w-50 {
	width: 50% !important;
}
.w-75 {
	width: 75% !important;
}
.w-100 {
	width: 100% !important;
}
.w-auto {
	width: auto !important;
}

.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}

/* Relative to the viewport */
.mw-100 {
	max-width: 100% !important;
}
.mh-100 {
	max-height: 100% !important;
}
.min-vw-100 {
	min-width: 100vw !important;
}
.min-vh-100 {
	min-height: 100vh !important;
}
.vw-100 {
	width: 100vw !important;
}
.vh-100 {
	height: 100vh !important;
}

/* Interations */
/* Text selection */
.user-select-all {
	user-select: all !important;
	-moz-user-select: all !important;
	-webkit-user-select: all !important;
}
.user-select-auto {
	-webkit-user-select: auto !important;
	-moz-user-select: auto !important;
	user-select: auto !important;
}
.user-select-none {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	user-select: none !important;
}
/* Pointer events */
.pe-none {
	pointer-events: none !important;
} /* & tabindex="-1" */
.pe-auto {
	pointer-events: auto !important;
}

/* Img */
.img-fluid {
	max-width: 100%;
	height: auto;
}

/*  */
.blind,
.sr-only {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	font-size: 1px;
	line-height: 100px;
	white-space: nowrap;
}
.screen-out {
	overflow: hidden;
	border: 0;
	position: absolute;
	width: 1px;
	height: 1px;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
}
.visually-hidden {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

/* List */
.list-unstyled {
	padding-left: 0;
	list-style: none;
}
.list-inline {
	padding-left: 0;
	list-style: none;
}
.list-inline-item {
	display: inline-block;
}
.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices ~ Medium devices */
@media (min-width: 320px) and (max-width: 1199.98px) {
}
