.swiper-button-play,
.swiper-button-stop {
	display: inline-flex;
	overflow: hidden;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	background-color: rgba(0, 0, 0, 0.25);
	align-items: center;
	justify-content: center;
	text-indent: -999em;
	&:after {
		content: '';
		display: block;
		width: 9px;
		height: 11px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.swiper-button-play:after {
	background-image: url(~@/assets/img/common/icon_play.svg);
}
.swiper-button-stop:after {
	width: 7px;
	height: 12px;
	background-image: url(~@/assets/img/common/icon_stop.svg);
}

.swiper-controls {
	display: flex;
	position: absolute;
	bottom: 18px;
	left: 50%;
	z-index: 10;
	transform: translateX(-50%);
	align-items: center;
	justify-content: center;
	gap: 10px;
	.swiper-pagination-container {
		display: flex;
		width: auto;
		height: 28px;
		padding: 0 15px;
		border-radius: 28px;
		background-color: rgba(0, 0, 0, 0.25);
		color: #fff;
		line-height: 1;
		font-size: 12px;
		font-weight: 300;
		align-items: center;
		justify-content: center;
		@include mobile {
			padding: 0 10px;
		}
		span {
			display: inline-block;
			margin: 0 2px;
		}
		.swiper-pagination {
			position: static;
		}
		&.no-bg {
			padding: 0;
			background-color: transparent;
			.swiper-pagination {
				display: flex;
				align-items: center;
			}

			.swiper-pagination-bullet {
				width: 10px;
				height: 10px;
				border: 2px solid transparent;
				background: rgba(0, 0, 0, 0.3);
				opacity: 1;
				transform: scale(1.01);
				@include transition(all, 0.5s);
				&-active {
					margin: 0 5px;
					border: 2px solid #fff;
					transform: scale(1.5);
					// background: transparent;
				}
			}
		}
	}
}

.icon-tv {
	width: 34px;
	height: 32px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(~@/assets/img/common/icon_tv.svg);
}
.icon-arrow-right {
	width: 8px;
	height: 13px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(~@/assets/img/common/icon_arrow_r.svg);
}

.toggle-header-alarm {
	display: flex;
	position: absolute;
	top: -48px;
	left: 250px;
	margin-top: -3px;
	margin-right: 15px;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 20px;

	@include tablet {
		position: static;
	}
}

.page-header-sub {
	background-color: #f4f4f4;
}

.broadcast-banner {
	position: relative;
	// height: 500px;
	margin: 0 auto;
	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #f4f4f4;
	}
	.swiper-container {
		// position: relative;
		// padding-bottom: 40px;
		padding-bottom: 0px;
	}
	.swiper-wrapper {
		// @include desktop {
		// 	height: 500px;
		// }
		height: calc(100% + 40px);
		background-color: transparent;
	}
	.swiper-controls {
		// bottom: 50px;
		bottom: 15px;
	}
	.swiper-slide {
		a {
			display: block;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 50%;
				background: rgb(256, 136, 0);
				background: linear-gradient(180deg, rgba(256, 136, 0, 0.35) 0%, rgba(256, 136, 0, 0) 100%);
				opacity: 0.35;
				@include until(1240px) {
					display: none;
				}
			}
		}
	}
	.swiper-slide .container {
		position: absolute;
		bottom: 50px;
		left: 50%;
		max-width: 1200px;
		transform: translate(-50%, -50%);
	}
	.swiper-slide picture,
	.swiper-slide source,
	.swiper-slide img {
		max-width: inherit;
		width: 100%;
		min-height: 370px;
		max-height: 500px;
		height: 112vw;
		object-fit: contain;
		@include until(1920px) {
			object-fit: cover;
		}
		@include until(1600px) {
			width: 100%;
			max-height: 800px;
			height: auto;
			min-height: initial;
		}
		@include until(1240px) {
			// object-fit: contain;
			max-height: initial;
			object-fit: inherit;
		}
	}
	.toggle-switch-wrap {
		position: absolute;
		right: -22px;
		bottom: -84px;
	}
	.swiper-button-next,
	.swiper-button-prev {
		width: 30px;
		height: 50px;
		&::after {
			position: absolute;
			content: '';
			display: block;
			top: 6px;
			width: 36px;
			height: 36px;
			border-top: 2px solid #666;
			border-right: 2px solid #666;
		}
	}
	.swiper-button-prev {
		left: 10px;
		&::after {
			left: 8px;
			transform: rotate(225deg);
		}
	}
	.swiper-button-next {
		right: 10px;
		&::after {
			right: 8px;
			transform: rotate(45deg);
		}
	}
	.swiper-buttons-container {
		position: absolute;
		width: 100%;
		max-width: 1400px;
		padding: 0 60px;
		margin: 0 auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		@include mobile {
			display: none;
		}
	}
}

.boradcast-button-cal {
	display: flex;
	align-items: center;
	width: 114px;
	height: 32px;
	font-size: 14px;
	justify-content: space-between;
	span {
		font-size: 14px;
	}
}

.broadcast-section {
	.container {
		max-width: 1400px;
		margin: 0 auto 60px auto;
		padding: 0;
	}
	&--bg {
		min-height: 730px;
		background-color: #f2f0ed;
	}
	.page-header-title {
		margin-right: 1em;
		font-size: 24px;
		font-weight: 700;
		white-space: nowrap;
		flex: 0 0;
	}
	.page-header {
		display: flex;
		padding: 60px 74px 20px;
		background-color: transparent;
		align-items: center;
		justify-content: space-between;
		.page-nav {
			border: none;
			background-color: transparent;
			height: inherit;
			.nav-list {
				gap: 10px;
				background-color: transparent;
			}
			.nav-item {
				padding: 0;
				height: auto;
				.nav-text {
					display: flex;
					overflow: hidden;
					padding: 5px 30px;
					height: 32px;
					border-radius: 32px;
					border: 1px solid #c1c1c1;
					font-size: 16px;
					font-weight: 400;
					color: #424242;
					align-items: center;
					justify-content: center;
					line-height: 1;
					white-space: nowrap;
					cursor: pointer;
					transition: all 0.3s;
					&:hover,
					&:focus {
						background-color: #e7e7e7;
					}
				}
				&.is-selected .nav-text {
					background-color: #ff7300;
					color: #fff;
					border-color: #ff7300;
				}
				&:before {
					display: none;
				}
				&:not(:first-child) {
					margin: 0;
				}
			}
		}
	}
}

.broadcast-component {
	width: 100%;
	padding: 0 60px;

	@include mobile {
		padding: 0;
	}
	&-highlight {
		.no-results-found {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: clamp(371px, 43.5vw, 540px);
			@include mobile {
				height: clamp(422px, 83.5vw, 665px);
			}
			@include until(500px) {
				height: clamp(307px, 150vw, 731px);
			}
		}
	}

	.swiper-container {
		width: 100%;
		min-height: 506px;
		@include tablet {
			min-height: inherit;
		}
		@include mobile {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.swiper-slide {
		padding: 0 15px;
		@include tablet {
			padding: 0 8px;
		}
	}
	.swiper-button-next,
	.swiper-button-prev {
		width: 30px;
		height: 50px;
		&::after {
			position: absolute;
			content: '';
			display: block;
			top: 6px;
			width: 36px;
			height: 36px;
			border-top: 2px solid #666;
			border-right: 2px solid #666;
		}
		@include mobile {
			display: none;
			// transform: scale(0.5);
		}
	}

	.swiper-button-prev {
		left: 10px;
		&::after {
			left: 8px;
			transform: rotate(225deg);
		}
		@include mobile {
			left: -5px;
		}
	}
	.swiper-button-next {
		right: 10px;
		&::after {
			right: 8px;
			transform: rotate(45deg);
		}
		@include mobile {
			right: -5px;
		}
	}
}

.swiper-buttons-container {
	.swiper-button-next,
	.swiper-button-prev {
		width: 30px;
		height: 50px;
		&::after {
			position: absolute;
			content: '';
			display: block;
			top: 6px;
			width: 36px;
			height: 36px;
			border-top: 2px solid #666;
			border-right: 2px solid #666;
		}
		@include mobile {
			// display: none;
			transform: scale(0.5);
		}
	}

	.swiper-button-prev {
		left: 10px;
		&::after {
			left: 8px;
			transform: rotate(225deg);
		}
		@include mobile {
			left: -1px;
		}
	}
	.swiper-button-next {
		right: 10px;
		&::after {
			right: 8px;
			transform: rotate(45deg);
		}
		@include mobile {
			right: -1px;
		}
	}
}

/* vod */
.broadcast-vod {
	padding-bottom: 80px;
	.container {
		max-width: 1230px;
		padding: 0 40px;
	}
	.page-header {
		margin-bottom: 14px;
		padding-bottom: 11px;
		border-bottom: 1px solid #000;
	}
	.page-header-title {
		font-size: 24px;
		font-weight: 700;
		color: #424242;
		line-height: 1.1;
		text-align: left;
	}
	&-header {
		color: #424242;
		line-height: 1.2;
		&__cate {
			margin-bottom: 3px;
			font-size: 16px;
			font-weight: 400;
		}
		&__title {
			display: flex;
			margin-bottom: 16px;
			align-items: center;
			justify-content: space-between;
			.title {
				overflow: hidden;
				display: block;
				margin-right: 20px;
				font-size: 21px;
				font-weight: 700;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.date {
				font-size: 16px;
				font-weight: 400;
				white-space: nowrap;
			}
		}
	}
}
.vod-wrap {
	margin-bottom: 10px;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.broadcast-vod-tools {
	display: flex;
	margin-bottom: 10px;
	gap: 18px;
	align-items: center;
	justify-content: flex-start;
	.button {
		display: inline-flex;
		height: 30px;
		padding: 3px 20px;
		border-radius: 30px;
		background-color: #645f5b;
		align-items: center;
		justify-content: center;
		line-height: 1;
		&:hover,
		&:focus {
			background-color: #9a918b;
		}
		&.likeVod {
			.icon-vod-like {
				background-image: url(~@/assets/img/common/icon_vod_like_w_on.svg);
			}
		}
		.icon {
			margin-right: 8px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
		}
		.text,
		.nums {
			display: block;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
		}
		.nums {
			margin-left: 5px;
		}
		&.is-active {
			.icon-vod-soundoff {
				width: 22px;
				height: 22px;
				margin-right: 2px;
				background-image: url(~@/assets/img/common/icon_sound_on.svg);
			}
			.icon-vod-full {
				background-image: url(~@/assets/img/common/icon_live_vfull.svg);
			}
		}
		.icon {
			margin-right: 8px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
		}
		.text,
		.nums {
			display: block;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
		}
		.nums {
			margin-left: 5px;
		}
		&.is-active {
			.icon-vod-soundoff {
				width: 22px;
				height: 22px;
				margin-right: 2px;
				background-image: url(~@/assets/img/common/icon_sound_on.svg);
			}
		}
	}
}
.icon-vod {
	&-like {
		width: 21px;
		height: 20px;
		background-image: url(~@/assets/img/common/icon_vod_like.svg);
	}
	&-share {
		width: 17px;
		height: 18px;
		background-image: url(~@/assets/img/common/icon_vod_share.svg);
	}
	&-link {
		width: 18px;
		height: 22px;
		background-image: url(~@/assets/img/common/icon_vod_link.svg);
	}
	&-comment {
		width: 20px;
		height: 22px;
		background-image: url(~@/assets/img/common/icon_vod_comment.svg);
	}
	&-soundoff {
		width: 21px;
		height: 21px;
		background-image: url(~@/assets/img/common/icon_sound_off.svg);
	}
	&-full {
		width: 21px;
		height: 21px;
		background-image: url(~@/assets/img/common/icon_live_vfull_cancel.svg);
	}
}

.broadcast-vod-notice {
	height: 78px;
	overflow-y: auto;
	padding: 15px;
	border-radius: 15px;
	background: #e6e6e6;
	text-align: left;
	line-height: 1.5;
	font-size: 14px;
	color: #645f5b;
}
.layer-popup-live {
	padding: 60px 50px;
	.popup-header {
		position: static;
		height: auto;
		padding: 0;
		.header-title {
			margin-bottom: 14px;
			padding-bottom: 11px;
			border-bottom: 1px solid #000;
			text-align: left;
		}
		.video-badge {
			display: none;
		}
	}
	.popup-body {
		padding: 0;
		.vod-wrap,
		.vod-wrap iframe {
			height: 455px;
			padding: 0;
		}
		.vod-wrap-large,
		.vod-wrap-large iframe {
			height: 606px;
		}
	}
	.broadcast-vod-header__cate {
		text-align: left;
	}
}
.popup-live-flex {
	display: flex;
	gap: 16px;
	justify-content: space-between;
	.flex-l {
		flex: 1 1;
	}
	.flex-r {
		flex: 0 0 285px;
	}
}

.livechat-container {
	display: flex;
	overflow: hidden;
	position: relative;
	width: 285px;
	height: 583px;
	padding: 0;
	border-radius: 15px;
	border: 1px solid #9a918b;
	background-color: #f4f4f4;
	flex-direction: column;
	#chatHistory {
		height: 100%;
		padding: 13px 24px;
		text-align: left;
	}
	.msg {
		display: flex;
		margin-bottom: 14px;
		font-size: 14px;
		font-weight: 400;
		align-items: flex-start;
	}
	.profile {
		display: flex;
		padding-right: 10px;
		color: #424242;
		flex: 0 0 90px;
		align-items: flex-start;
		justify-content: flex-start;
		.thumb-wrap {
			overflow: hidden;
			width: 28px;
			height: 28px;
			margin-right: 8px;
			border-radius: 28px;
			object-fit: contain;
			flex: 0 0 28px;
		}
		img {
			width: 100%;
			max-width: 100%;
			color: #424242;
			flex: 0 0;
		}
	}
	.nickname {
		color: #424242;
		padding-top: 6px;
		flex: 0 0 50px;
		word-break: break-all;
		&.admin {
			color: #ff7300 !important;
		}
	}
	.txt {
		padding-top: 6px;
		color: #757575;
		line-height: 1.3;
		letter-spacing: -0.5px;
		flex: 1 1;
		word-break: break-all;
		&-reply {
			padding: 7px;
			border-radius: 10px;
			background: #e6e6e6;
			color: #757575 !important;
			text-shadow: none;
			@include mobile {
				margin-left: 5px;
			}
			&-username {
				color: #111;
				font-weight: 500;
				margin-right: 10px;
			}
			&-admin {
				display: block;
				min-height: 16px;
				margin-top: 0.3em;
				padding-left: 16px;
				color: #ff7300;
				&::before {
					content: '';
					display: block;
					margin-top: -5px;
					margin-left: -16px;
					width: 16px;
					height: 12px;
					@include icon;
					background-position: 2px 0;
					background-image: url(~@/assets/img/common/icon_live_re.svg);
				}
			}
		}
	}
}
.livechat-content {
	overflow-y: auto;
	flex: 1 1;
}

.livechat-login {
	display: flex;
	width: 100%;
	height: 50px;
	padding: 0 24px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex: 0 0 50px;
}
.livechat-footer {
	display: flex;
	width: 100%;
	height: 99px;
	padding: 13px 24px;
	background-color: #e6e6e6;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex: 0 0 99px;
	.nickname {
		word-break: normal;
		white-space: nowrap;
	}
	.profile {
		font-size: 14px;
		color: #424242;
		flex: 0 0 28px;
		align-items: center;
		justify-content: flex-start;
	}
	.input {
		width: calc(100% - 36px);
		margin-bottom: 13px;
		height: 24px;
		background-color: transparent;
		margin-left: 36px;
		padding-bottom: 3px;
		padding-right: 26px;
		border: none;
		font-size: 14px;
		font-weight: 400;
		border-bottom: 2px solid #847b73;
		outline: 0;
		transition: all 0.3s;
		&:focus {
			border-bottom: 2px solid #000;
		}
		&::placeholder {
			color: #bdbdbd;
		}
	}
	&-form {
		position: relative;
	}
	&-submit {
		position: absolute;
		display: flex;
		width: 18px;
		height: 16px;
		top: 2px;
		right: 0;
		align-items: center;
		justify-content: center;
		.icon-livechat-send {
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(~@/assets/img/common/icon_livechat_send.svg);
		}
	}
}
.button-chat-login {
	display: flex;
	width: 100%;
	height: 32px;
	border-radius: 32px;
	background-color: #645f5b;
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	align-items: center;
	justify-content: center;
	&:hover,
	&:focus {
		background-color: #9a918b;
	}
}

@include from($mobile) {
	.layer-popup-live .popup-body .vod-wrap,
	.layer-popup-live .popup-body .vod-wrap iframe {
		width: 100%;
	}
}

@include tablet {
	.live-card {
		padding-top: 22px;
		max-width: inherit;
		.video-content {
			padding: 10px;
		}
		.video-badge {
			width: 53px;
			height: 36px;
			right: -8px;
		}
	}
	.layer-popup {
		&.layer-popup-calendar {
			.header-title {
				position: absolute;
				display: flex;
				top: 40px;
				font-size: 12px;
				font-weight: 400;
				color: #424242;
				align-items: center;
			}
			.popup-header {
				height: 15px;
				padding: 0;
			}
		}
		.header-title .icon-tv {
			width: 22px;
			height: 20px;
			margin-right: 5px;
		}
	}

	.broadcast-banner {
		height: auto;
		// max-height: 500px;
		.swiper-container {
			// padding-bottom: 40px;
		}
		.swiper-slide {
			background-color: #f4f4f4;
			.container {
				width: calc(100% - 145px);
				bottom: 10px;
			}
		}
		.toggle-switch-wrap {
			left: 0;
			right: inherit;
			bottom: -44px;
		}
		// .swiper-controls {
		// 	transform: none;
		// 	left: inherit;
		// 	right: 73px;
		// 	// bottom: 10px;
		// }
	}

	.broadcast-section {
		.container {
			max-width: 1350px;
		}
		.page-header {
			.page-nav {
				height: auto;
				.nav-list {
					background: transparent;
					padding: 0 !important;
					padding-left: 0 !important;
				}
			}
		}
		&--bg {
			overflow: hidden;
			min-height: inherit;
			.page-header {
				padding: 60px 74px 20px;
				align-items: flex-start;
				flex-direction: column;
				&-title {
					margin-bottom: 20px;
				}
			}
		}
	}
	.toggle-header-alarm {
		margin-bottom: 5px;
		margin-left: 15px;
		justify-content: flex-start;
	}
	.icon-vod-like {
		width: 21px;
		height: 20px;
		background-image: url(~@/assets/mobile/img/common/icon_vod_like.svg);
	}
	.icon-vod-share {
		width: 17px;
		height: 18px;
		background-image: url(~@/assets/mobile/img/common/icon_vod_share.svg);
	}
	.icon-vod-soundoff {
		width: 20px;
		height: 20px;
		background-image: url(~@/assets/mobile/img/common/icon_sound_off.svg);
	}
	.icon-vod-link {
		width: 25px;
		height: 23px;
		background-image: url(~@/assets/mobile/img/common/icon_vod_link.svg);
	}
	.icon-vod-comment {
		width: 20px;
		height: 22px;
		background-image: url(~@/assets/img/common/icon_vod_comment.svg);
	}
	.broadcast-vod-tools {
		.button {
			padding: 0;
			background-color: transparent;
			.icon {
				margin: 0;
			}
			&.likeVod {
				.icon-vod-like {
					background-image: url(~@/assets/img/common/icon_vod_like_on.svg);
				}
			}
			&:hover,
			&:focus {
				background-color: transparent;
			}
			.text {
				display: none;
			}
			.text,
			.nums {
				color: #424242;
			}
		}
	}
	.broadcast-vod .page-header {
		display: none;
	}
	.broadcast-vod-header {
		margin-top: 10px;
	}
}

@include mobile {
	.broadcast-banner {
		.swiper {
			position: static;
			// overflow: visible;
		}
		.swiper-slide .container {
			width: calc(100% - 30px);
		}
		.swiper-controls {
			right: 15px;
		}
	}
	.broadcast-section {
		.container {
			margin-bottom: 30px;
		}
		.page-header {
			padding: 60px 15px 10px;
			@include until(420px) {
				padding-top: 30px;
			}
			.page-nav .nav-list {
				padding-left: 15px !important;
			}
			&-title {
				font-size: 21px;
			}
		}
		&--bg {
			.page-header {
				padding: 30px 0 10px;
				&-title {
					padding-left: 15px;
					margin-bottom: 20px;
				}
			}
		}
	}

	.boradcast-button-cal {
		width: 32px;
		span {
			display: none;
		}
		.icon-arrow-right {
			display: none;
		}
	}
	.icon-tv {
		width: 32px;
		height: 30px;
	}

	/* .icon-vod-comment {
        width:20px;
        height:22px;
        background-image: url(~@/assets/img/common/icon_vod_comment.svg);
    } */
	.broadcast-vod {
		padding-bottom: 60px;
		.container {
			padding: 0 15px;
		}
		&-notice {
			background-color: #f4f4f4;
		}
		&-header {
			&__title {
				display: block;
				margin-bottom: 10px;
			}
			&__cate {
				font-size: 15px;
			}
			&__title .title {
				margin-bottom: 15px;
				overflow: inherit;
				white-space: normal;
			}
		}
	}
	.layer-popup-live {
		padding: 0;
		.popup-header {
			.icon-close {
				width: 17px;
				height: 17px;
				background-image: url(~@/assets/mobile/img/common/icon_close_w.svg);
				background-size: contain;
				background-position: 50% 50%;
				background-repeat: no-repeat;
			}
			.btn-close {
				z-index: 1;
				top: 35px;
				right: 15px;
			}
			.video-badge {
				display: block;
				top: 15px;
				left: 15px;
			}
			.header-title {
				display: none;
			}
		}
		.popup-body {
			.vod-wrap {
				width: 100vw;
				height: 100vh;
				background-color: #000;
				iframe {
					position: absolute;
					top: 50%;
					left: 50%;
					object-fit: cover;
					max-width: inherit;
					height: 100%;
					transform: translate(-50%, -50%);
				}
			}
		}
		.popup-live-flex {
			display: block;
			margin-top: -1px;
		}

		.broadcast-vod-header {
			position: absolute;
			z-index: 10;
			top: 82px;
			left: 15px;
			color: #fff;
			text-align: left;
			&__title {
				.date {
					position: absolute;
					top: -25px;
					font-size: 12px;
				}
				.title {
					font-size: 16px;
				}
			}
			&__cate {
				font-size: 14px;
			}
		}
		.broadcast-vod-tools {
			display: flex;
			flex-direction: column;
			width: 30px;
			position: absolute;
			bottom: 83px;
			right: 15px;
			z-index: 10;
			.button {
				flex-direction: column;
				height: auto;
				.nums {
					margin: 5px 0 0;
					text-align: center;
					color: #fff;
					font-size: 11px;
				}
				.icon-vod-like {
					background-image: url(~@/assets/mobile/img/common/icon_vod_like_w.svg);
				}
				&.likeVod {
					.icon-vod-like {
						background-image: url(~@/assets/mobile/img/common/icon_vod_like_w_on.svg);
					}
				}
				.icon-vod-share {
					background-image: url(~@/assets/mobile/img/common/icon_vod_share_w.svg);
				}
				.icon-vod-link {
					background-image: url(~@/assets/mobile/img/common/icon_vod_link_w.svg);
				}
				.icon-vod-comment {
					background-image: url(~@/assets/mobile/img/common/icon_vod_comment_w.svg);
				}
			}
		}
		.broadcast-vod-notice {
			position: absolute;
			width: calc(100% - 30px);
			height: 50px;
			bottom: 15px;
			left: 15px;
			right: 15px;
			padding: 5px;
			border-radius: 5px;
			line-height: 1.2;
			background-color: #e6e6e6;
			font-size: 12px;
		}
		.livechat-container {
			position: absolute;
			max-height: 45%;
			height: 45%;
			width: 100%;
			padding-bottom: 80px;
			bottom: 0px;
			border: none;
			border-radius: 0;
			background-color: transparent;
			-webkit-mask-image: linear-gradient(transparent, #000 50%);
			mask-image: linear-gradient(transparent, #000 50%);
			.txt {
				color: #fff;
			}
			#chatHistory {
				padding-left: 15px;
				padding-right: 50px;
				padding-bottom: 80px;
			}
			.profile .thumb-wrap {
				display: none;
			}
			.nickname {
				color: #fff;
				flex-basis: auto;
			}
			.msg {
				font-size: 12px;
				margin-bottom: 0.5em;
				text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1), 1px -1px 0 rgba(0, 0, 0, 0.1), -1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 0 rgba(0, 0, 0, 0.1);
			}
			.profile {
				flex-basis: auto;
			}
		}
		.livechat-footer {
			display: flex;
			position: absolute;
			height: 75px;
			padding: 0;
			bottom: 0;
			border-radius: 0;
			background-color: rgba(50, 50, 50, 0.9);
			align-items: center;
			justify-content: center;
			flex-direction: row;
			flex: none;
			.livechat-footer-form {
				width: 100vw;
				padding: 0 15px;
				.input {
					width: 100%;
					height: 32px;
					margin: 0 0 25px;
					padding: 0 10px;
					border-radius: 15px;
					border: none;
					background-color: #fff;
				}
			}
			.profile {
				display: none;
			}
			&-submit {
				display: none;
			}
		}
		.livechat-login {
			display: none;
		}
	}
}

// 채팅 공지
.icon-pin {
	display: inline-flex;
	width: 15px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(~@/assets/img/common/icon_broadcast_pin.svg);
	background-position: 50% 50%;
	align-items: center;
	justify-content: center;
}
.chat-notice {
	position: relative;
	display: flex;
	min-height: 30px;
	padding: 5px 12px 5px 35px;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
	color: #ffff00;
	font-size: 12px;
	text-align: left;
	line-height: 1.2;
	align-items: center;
	justify-content: flex-start;
	&-wrap {
		padding: 10px 17px 0;
		@include mobile {
			padding: 10px 50px 0px 15px;
			order: 2;
		}
	}
	.icon-pin {
		position: absolute;
		top: 5px;
		left: 12px;
	}
}

.no-results-found.has-icon {
	padding: 20px 0;
	.text {
		padding: 0 1em;
		color: #bdbdbd;
		line-height: 1.3;
		font-size: clamp(18px, 3vw, 24px);
		@include word-wrap;
	}
	&::before {
		content: '';
		display: flex;
		width: 150px;
		min-width: 150px;
		height: 150px;
		min-height: 150px;
		margin: 0 auto 20px;
		background-image: url(~@/assets/img/common/noresult.svg);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
	}
	&.is-large {
		&::before {
			width: clamp(150px, 40vw, 314px);
			height: clamp(150px, 40vw, 314px);
		}
	}
}
