@import 'form/shared';

fieldset {
	display: contents;
}
// Containers
.field {
	&:not(:last-child) {
		margin-bottom: 16px;
		@include mobile {
			margin-bottom: 8px;
		}
	}

	// Modifiers
	&.has-addons {
		display: flex;
		justify-content: flex-start;

		.control {
			&:not(:last-child) {
				margin-right: -1px;
			}

			&:not(:first-child):not(:last-child) {
				.button,
				.input,
				.select select,
				.datetimepicker-dummy-wrapper {
					border-radius: 0;
				}
			}

			&:first-child:not(:only-child) {
				.button,
				.input,
				.select select,
				.datetimepicker-dummy-wrapper {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}

			&:last-child:not(:only-child) {
				.button,
				.input,
				.select select,
				.datetimepicker-dummy-wrapper {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
				}
			}

			.button,
			.input,
			.select select {
				&:not([disabled]) {
					&:hover,
					&.is-hovered {
						z-index: 2;
					}

					&:focus,
					&.is-focused,
					&:active,
					&.is-active {
						z-index: 3;

						&:hover {
							z-index: 4;
						}
					}
				}
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.has-addons-centered {
			justify-content: center;
		}

		&.has-addons-right {
			justify-content: flex-end;
		}

		&.has-addons-fullwidth {
			.control {
				flex-grow: 1;
				flex-shrink: 0;
			}
		}
	}

	&.is-grouped {
		display: flex;
		justify-content: flex-start;
		@include mobile {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
			display: block;
		}
		&.__layout2 {
			.control {
				flex-grow: 1;
				flex-shrink: 1;
				.width-default {
					width: 100%;
				}
			}
		}

		& > .control {
			flex-shrink: 0;

			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: 4px;
				@include tablet {
					margin-right: 4px;
				}
			}
			@include mobile {
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 8px;
				}
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.is-grouped-centered {
			justify-content: center;
		}

		&.is-grouped-right {
			justify-content: flex-end;
		}

		&.is-grouped-multiline {
			flex-wrap: wrap;

			& > .control {
				&:last-child,
				&:not(:last-child) {
					margin-bottom: 8px;
				}
			}

			&:last-child {
				margin-bottom: -8px;
			}

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	// &.is-horizontal {
	// 	display: flex;
	// 	@include tablet {
	// 		display: flex;
	// 	}
	// }
}

.field-label {
	height: 96px;
	.label {
		font-size: inherit;
	}
	@include tablet {
		height: 80px;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 0;
		margin-right: 15px;
		text-align: right;

		&.is-small {
			font-size: $size-6;
			padding-top: 0.375em;
		}

		&.is-normal {
			padding-top: 0.375em;
		}

		&.is-medium {
			font-size: $size-5;
			padding-top: 0.375em;
		}

		&.is-large {
			font-size: $size-4;
			padding-top: 0.375em;
		}
	}
	@include mobile {
		height: 24px;
		margin-bottom: 50px;
	}
}
.control-phone {
	.select,
	.input {
		&:not(:last-child) {
			margin-right: 16px;
			@include tablet {
				margin-right: 8px;
			}
		}
	}
	.select {
		@include mobile {
			flex: 1 1 40%;
		}
	}
}
.control-address {
	@include mobile {
		width: inherit !important;
		margin-bottom: -4px;
		margin-right: -8px;
		.input {
			flex: 1 1 auto;
		}
		.button {
			flex: 0 0 100px;
		}
	}
}
.control-name {
	.button {
		margin-left: 8px;
		@include tablet {
			width: 112px;
		}
	}
	@include mobile {
		width: inherit !important;
		margin-bottom: -4px;
		margin-right: -8px;
		.input {
			flex: 1 1 auto;
		}
		.button {
			margin-left: 4px;
			flex: 0 0 100px;
		}
	}
}
.control-pyung {
	position: relative;
	input {
		padding-right: 50px;
	}
	.unit {
		position: absolute;
		top: 50%;
		right: 16px;
		margin-top: -11px;
		font-size: 16px;
		color: #000;
		@include tablet {
			margin-top: -9px;
			font-size: 14px;
		}
		@include mobile {
			right: 8px;
			margin-top: -11px;
		}
	}
}
.is-email-group {
	display: flex;
	margin-bottom: 0 !important;
	@include mobile {
		margin-right: 0 !important;
	}

	.control {
		&.is-expanded {
			width: 200px;
			height: 56px;
			@include tablet {
				width: 144px;
				height: 44px;
			}
			@include mobile {
				flex: 1 1;
			}
			.input,
			.select {
				width: 100%;
				input,
				select {
					width: 100%;
				}
			}
		}
		&.is-span {
			display: flex;
			width: 45px;
			height: 56px;
			font-size: 16px;
			align-items: center;
			justify-content: center;
			@include tablet {
				width: 29px;
				height: 44px;
				font-size: 14px;
			}
			@include mobile {
				flex: 0 0 22px;
			}
		}
	}
}
.field-body {
	min-height: 96px;
	&.is-flex {
		display: flex;
		align-items: center;
	}

	@include tablet {
		min-height: 80px;
	}
	.field .field {
		margin-bottom: 0;
	}
	.control {
		&.is-flex {
			display: flex;
		}
	}

	&.is-grouped {
		display: flex;
		justify-content: flex-start;

		& > .control {
			flex-shrink: 0;

			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: 16px;
				@include tablet {
					margin-right: 8px;
				}
				@include mobile {
					margin-bottom: 8px;
					margin-right: 8px;
				}
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}
	}

	@include tablet {
		// display: flex;
		// flex-basis: 0;
		// flex-grow: 5;
		// flex-shrink: 1;
		& > .field {
			flex-shrink: 1;

			&:not(.is-narrow) {
				flex-grow: 1;
			}

			&:not(:last-child) {
				margin-bottom: 12px;
				margin-right: 8px;
			}
		}
	}
	@include mobile {
	}
}

.control {
	box-sizing: border-box;
	clear: both;
	font-size: $size-6;
	position: relative;
	text-align: left;

	// Modifiers
	&.has-icons-left,
	&.has-icons-right {
		.input,
		.select {
			&:focus {
				& ~ .icon {
					color: $input-icon-active-color;
				}
			}

			&.is-small ~ .icon {
				font-size: $size-6;
			}

			&.is-medium ~ .icon {
				font-size: $size-5;
			}

			&.is-large ~ .icon {
				font-size: $size-4;
			}
		}

		.icon {
			color: $input-icon-color;
			height: $input-height;
			pointer-events: none;
			position: absolute;
			top: 0;
			width: $input-height;
			z-index: 4;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
		}
	}

	&.has-icons-left {
		.input,
		.select select {
			padding-left: $input-height;
		}

		.icon.is-left {
			left: 0;
		}
	}

	&.has-icons-right {
		.input,
		.select select {
			padding-right: $input-height;
		}

		.icon.is-right {
			right: 0;
		}
	}

	&.is-loading {
		&::after {
			@extend %loader;
			position: absolute !important;
			right: 0.625em;
			top: 1em;
			z-index: 4;
		}

		&.is-small:after {
			font-size: $size-6;
		}

		&.is-medium:after {
			font-size: $size-5;
		}

		&.is-large:after {
			font-size: $size-4;
		}
	}
}

.is-horizontal {
	display: flex;
	@include mobile {
		display: block;
	}
	.field-label {
		display: flex;
		padding: 20px 40px;
		color: #000;
		font-size: 20px;
		font-weight: 600;
		flex: 0 0 296px;
		align-items: center;
		@include tablet {
			padding: 18px 0 18px 32px;
			font-size: 18px;
			flex: 0 0 210px;
		}
		@include mobile {
			display: block;
			padding: 0;
			width: 100%;
			margin: 16px 0 8px;
			text-align: left;
			font-size: 16px;
		}
	}
	.field-body {
		flex: 1 1;
		padding: 20px 40px 20px 0;
		@include tablet {
			padding: 18px 32px 18px 0;
		}
		@include mobile {
			display: block;
			padding: 0;
			min-height: auto;
		}
		.control {
			.checkbox-list {
				display: flex;
				align-items: center;
				height: 56px;
				@include tablet {
					height: 44px;
				}
				@include mobile {
					height: auto;
				}
			}
		}
	}
}
.is-vertical {
	display: flex;
	align-items: center;
	@include mobile {
		display: block;
	}
}
