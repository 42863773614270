.buttons {
	&.buttons-flex {
		display: flex;
		gap: 12px;
		@include mobile {
			gap: 8px;
		}
		.button-default {
			width: 50%;
			flex: 1 1;
			font-size: 16px;
			font-weight: 400;
			&.is-medium {
				min-width: 65px;
				height: 30px;
				margin: 0;
				padding: 0 10px;
				font-size: 16px;
			}
			&.mobile-show {
				display: none;
			}
			@include mobile {
				height: 24px;
				font-size: 13px;
				&.mobile-show {
					display: inline-flex;
				}
			}
		}
	}
}
.v-toast__item {
	border-radius: 15px;
	backdrop-filter: blur(4px);
	font-size: 14px;
	color: #fff;
	&--success {
		background-color: rgba(0, 0, 0, 0.7);
	}
}
.dot-list {
	li {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 0.5em;
		}
		padding: 0 0 0 15px;
		line-height: 1.2;
		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			left: 0;
			top: 6px;
			width: 5px;
			height: 5px;
			margin-right: 10px;
			border-radius: 50%;
			background-color: #757575;
			vertical-align: middle;
		}
	}
}
.local-map {
	&-wrapper {
		display: flex;
		margin-bottom: 80px;
		overflow: hidden;
		border-radius: 15px;
		border: 1px solid #d5d5d5;
		justify-content: space-between;
		background-color: #ececec;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) inset;
		@include mobile {
			margin-bottom: 0;
			border: none;
			background-color: #fff;
			box-shadow: none;
		}
		.flex-item {
			display: flex;
			width: 50%;
			padding: 35px 55px;
			flex: 1 1 50%;
			align-items: center;
			justify-content: center;
			&.flex {
				&-l {
				}
				&-r {
					border-radius: 0 15px 15px 0;
					border-left: 1px solid #d5d5d5;
					background-color: #fff;
					@include mobile {
						display: none;
					}
				}
			}
		}
	}
	&-content {
		position: relative;
	}
	&-numsr {
		display: flex;
		width: 36px;
		height: 36px;
		margin: 4px;
		border-radius: 50%;
		font-size: 19px;
		font-weight: 600;
		color: #645f5b;
		align-items: center;
		justify-content: center;
	}
	&-text {
		position: absolute;
		display: none;
		width: 26px;
		height: 19px;
		bottom: -19px;
		left: 50%;
		margin-left: -13px;
		line-height: 1;
		border-radius: 7px;
		background: rgba(200, 200, 200, 0.5);
		font-size: 11px;
		color: #000;
		align-items: center;
		justify-content: center;
		@include mobile {
			display: flex;
		}
	}
	&-list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&-itemr {
		position: absolute;
		display: flex;
		width: 45px;
		height: 62px;
		top: 0;
		left: 0;
		background-image: url('~@/assets/img/common/mappinr.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		flex-direction: column;
		&.is-active {
			background-image: url('~@/assets/img/common/mappinr_on.svg');
			.local-map-numsr {
				color: #ff7d12;
			}
		}
		&__seoul {
			top: 74px;
			left: 71px;
		}
		&__gangwon {
			top: 61px;
			left: 199px;
		}
		&__busan {
			top: 368px;
			left: 280px;
		}
		&__gwangju {
			top: 378px;
			left: 57px;
		}
		&__jeonju {
			top: 326px;
			left: 96px;
		}
		&__daegu {
			top: 296px;
			left: 226px;
		}
	}
}
.reserv {
	&-program {
		&-header {
			margin-bottom: 24px;
			padding-bottom: 24px;
			border-bottom: 1px solid #e0e0e0;
			&__title {
				font-size: 24px;
				font-weight: 700;
			}
		}
		&-content {
			position: relative;
		}
		.sptpgm-article {
			padding: 16px;
			background-color: #fff;
			&-header {
				min-height: 52px;
				margin-bottom: 8px;
				align-items: flex-start;
				justify-content: space-between;
				flex-wrap: nowrap;
				.fl {
					display: flex;
					gap: 4px;
					align-items: center;
					flex-wrap: wrap;
				}
				.fr {
					padding-top: 5px;
					text-align: right;
					flex: 0 0 50px;
				}
			}
			&-title {
				margin-bottom: 12px;
				height: 42px;
				font-size: 19px;
			}
			&-footer {
				margin-top: 0;
				&.display-flex {
					align-items: center;
					justify-content: space-between;
					.buttons-flex {
						margin: 0;
					}
				}
				.button-default {
					margin: 0;
					padding: 0 20px;
				}
			}
			&-dl {
				margin: 0;
				dt {
					display: none;
				}
			}
		}
	}
	&-list {
		> li {
			border-bottom: 1px solid #e0e0e0;
			&:last-child {
				margin-bottom: 0;
				@include mobile {
					border-bottom: none;
				}
			}
			margin-bottom: 19px;
		}
	}
	&-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 19px;
		&:hover {
			.reserv-item-thumb {
				img {
					transform: translate(-50%, -50%) scale(1.15);
				}
			}
		}
		.fl {
			flex: 0 0 115px;
			@include mobile {
				flex: 0 0 92px;
			}
		}
		.fr {
			width: calc(100% - 115px);
			padding-left: 15px;
			flex: 1 1;
			@include mobile {
				padding-bottom: 12px;
			}
		}
		&-thumb {
			display: flex;
			position: relative;
			overflow: hidden;
			padding-top: 100%;
			border-radius: 15px;
			align-items: center;
			justify-content: center;
			@include mobile {
				border-radius: 12px;
			}
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 50%;
				left: 50%;
				object-fit: cover;
				transform: translate(-50%, -50%) scale(1.01);
				@include transition(transform, 0.5s);
			}
		}
		&-text {
			margin-bottom: 10px;
			@include mobile {
				margin-bottom: 12px;
			}
			&__title {
				display: block;
				margin-bottom: 10px;
				// font-size: 19px;
				font-size: 17px;
				font-weight: 700;
				line-height: 1.2;
				@include text-ellipsis-2;
				@include word-wrap;
				@include mobile {
					margin-bottom: 6px;
					font-size: 16px;
				}
			}
			&__desc {
				display: block;
				margin-bottom: 5px;
				font-size: 15px;
				font-weight: 400;
				color: #757575;
				line-height: 1.2;
				@include text-ellipsis;
				@include mobile {
					font-size: 14px;
					&.mobile-hidden {
						display: none;
					}
				}
			}
		}
	}
	&-thumbnail {
		&-list {
			display: flex;
			flex-wrap: wrap;
			gap: 24px;

			.sptpgm-article {
				width: 100%;
				border-color: #e0e0e0;
				background-color: #fff;
				flex-direction: column;
				// flex: 0 0 calc(50% - 14px);
				gap: 16px;
				&-header {
					// height: 52px;
					margin-bottom: 8px;
					align-items: flex-start;
					flex-wrap: nowrap;
					justify-content: space-between;
					.fl {
						display: flex;
						flex: 1 1;
						flex-wrap: wrap;
						gap: 4px;
					}
					.fr {
						padding-top: 6px;
						text-align: right;
						flex: 0 0 50px;
					}
				}
				&-footer {
					align-items: center;
					justify-content: space-between;
					.mo-flex-wrap {
						margin-bottom: 8px;
						@include mobile {
							display: block;
						}
					}
					.buttons.buttons-flex {
						gap: 8px;
						&.justify-content-end {
							width: 100%;
							justify-content: flex-end;
							.button-default {
								width: auto;
								flex: 0 0 auto;
							}
						}
					}
					@include mobile {
						flex-direction: column;
					}
				}
				&-title {
					max-height: 42px;
					height: auto;
					line-height: 1.2;
					font-size: 19px;
				}
				&-dl {
					margin-bottom: 0;
					@include until(950px) {
						width: 100%;
						margin-bottom: 4px;
						justify-content: flex-start;
					}
					dt {
						display: none;
					}
				}
			}
			.thumbnail-article {
				width: calc(50% - 14px);
				&:hover {
					.thumb-default {
						img {
							transform: translate(-50%, -50%) scale(1.15);
						}
					}
				}
				&.w100 {
					display: block;
					width: 100%;
					background-color: #fff;
					.thumbnail-article-body {
						display: flex;
					}
					.thumbnail-article-footer {
						@include mobile {
							width: 100%;
							margin-top: 8px;
							margin-bottom: 0;
						}
					}
				}

				@include mobile {
					width: 100%;
				}
				.thumb-default {
					padding-top: 115px;
					@include mobile {
						padding-top: 100px;
					}
					img {
						transform: translate(-50%, -50%) scale(1.01);
						@include transition(transform, 0.5s);
					}
				}
			}
		}
	}
	&-notice {
		&-list {
			margin-bottom: 40px;
			li {
				margin-top: 36px;
				padding-bottom: 20px;
				border-bottom: 1px solid #e0e0e0;
			}
		}
	}

	&-page {
		&-header {
			&__button {
				display: flex;
				width: 24px;
				height: 24px;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}
	&-detail {
		&-nav {
			position: sticky;
			top: 150px;
			z-index: 8;
			margin-top: 26px;
			padding-top: 14px;
			background-color: #fff;
			@include tablet {
				top: 129px;
			}
			.page-nav {
				display: block;
				overflow: initial !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
				padding: 0 !important;
				border-bottom: 1px solid #e0e0e0;
				border-radius: 15px 15px 0 0;
				background-color: #f5f5f5;
				.nav-text {
					display: flex;
					width: 100%;
					height: 100%;
					font-size: 21px;
					font-weight: 600;
					color: #757575;
					align-items: center;
					justify-content: center;
					@include mobile {
						font-size: 16px;
					}
					&:focus-visible {
						outline: none;
						border: none;
					}
				}
			}
			.nav-list {
				width: 100% !important;
				padding-left: 0 !important;
				padding-right: 0 !important;
				justify-content: space-between !important;
				background-color: transparent;
			}
			.nav-item {
				width: 50%;
				height: 60px;
				padding: 0;
				text-align: center;
				&:not(:first-child) {
					margin-left: 0;
				}
				&.is-selected {
					&::before {
						height: 3px;
						background-color: #ff7300;
					}
					.nav-text {
						color: #ff7300;
					}
				}
				&::before {
					bottom: -1px;
					height: 3px;
				}
				&:not(.is-selected) {
					&:hover,
					&:focus {
						&:before {
							background-color: #757557;
						}
					}
				}
			}
		}
		&-utils {
			display: flex;
			margin-top: 0;
			margin-bottom: 12px;
			align-items: center;
			justify-content: flex-end;
			gap: 8px;
			.button {
				display: inline-flex;
				min-width: initial;
				width: 24px;
				height: 24px;
				padding: 0;
				align-items: center;
				justify-content: center;
			}
		}
		&-header {
			margin-bottom: 12px;
			padding: 20px;
			border-radius: 15px;
			background-color: #f5f5f5;
			@include mobile {
				padding: 10px;
			}
			+ .buttons.buttons-flex {
				justify-content: flex-end;
				gap: 16px;
				@include mobile {
					gap: 8px;
					justify-content: space-between;
				}
				.button-default {
					min-width: initial;
					width: inherit;
					flex: none;
					font-size: 21px;
					font-weight: 700;
					@include mobile {
						width: 50%;
						height: 30px;
						padding-top: 0;
						padding-bottom: 0;
						flex: 1 1;
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
			&__gallery {
				margin-bottom: 20px;
				ul {
					display: grid;
					gap: 16px;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					grid-template-rows: 1fr 1fr;
					@include mobile {
						gap: 5px;
					}
					li {
						position: relative;
						border-radius: 5px;
						overflow: hidden;
						&:first-child {
							grid-column: 1 / span 2;
							grid-row: 1 / span 2;
						}
						> a {
							display: block;
							&:hover {
								img {
									transform: translate(-50%, -50%) scale(1.1);
								}
							}
						}
						.thumb {
							display: flex;
							position: relative;
							padding-top: 90%;
							align-items: center;
							justify-content: center;
							@include mobile {
								padding-top: 100%;
							}
							img {
								position: absolute;
								width: 100%;
								height: 100%;
								top: 50%;
								left: 50%;
								object-fit: cover;
								transform: translate(-50%, -50%) scale(1.01);
								@include transition(transform, 0.5s);
							}
						}
					}
				}
				.thumb-more {
					display: flex;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 1;
					background-color: rgba(0, 0, 0, 0.4);
					color: #fff;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					i {
						display: block;
						margin-bottom: 5px;
					}
					em {
						display: block;
						font-size: 21px;
						font-weight: 300;
						line-height: 1;
						@include mobile {
							font-size: 13px;
						}
					}
				}
			}
		}
		&-notibox {
			display: block;
			margin-bottom: 24px;
			padding: 20px;
			border-radius: 15px;
			border: 1px solid #e0e0e0;
			background-color: #fff8f2;
			@include mobile {
				padding: 12px;
			}
			.noti-box {
				&-title {
					display: block;
					margin-bottom: 10px;
					font-size: 19px;
					font-weight: 700;
					line-height: 1.2;
				}
				&-list {
					font-size: 16px;
					color: #757575;
				}
			}
		}
		&-content {
			// min-height: 1000px;
			.content {
				line-height: 1.5;
			}
		}
		&-sodaminfo {
			li {
				margin: 0;
				line-height: 1.2;
				gap: 4px;

				a {
					&:hover {
						text-decoration: underline;
					}
				}
				.display-flex {
					display: flex;
					margin: 6px 0;
					align-items: flex-start;
					gap: 4px;
					i {
						margin-top: 0px;
					}
				}
			}
		}
	}
	&-room {
		&-scrollwrap {
			overflow-x: auto;
			@include scrollbar('', 'h');
			text-align: center;
			@include mobile {
				margin-left: -15px;
				margin-right: -15px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		&-swiper {
			position: relative;
			.swiper-container {
				margin-bottom: 24px;
				border-radius: 15px;
				.swiper-slide {
					width: 100%;
					height: 100%;
					overflow: hidden;
					.thumb-default {
						padding-top: 338px;
						@include mobile {
							padding-top: 40%;
						}
						img {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 50%;
							left: 50%;
							object-fit: cover;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
			&__title {
				display: flex;
				position: absolute;
				left: 50%;
				bottom: 18px;
				z-index: 10;
				min-height: 30px;
				padding: 5px 16px;
				border-radius: 30px;
				background-color: rgba(100, 100, 100, 0.6);
				color: #fff;
				line-height: 1.2;
				font-size: 12px;
				text-align: center;
				font-weight: 700;
				color: #fff;
				align-items: center;
				justify-content: center;
				transform: translateX(-50%);
			}
		}
		&-content {
			.reserv-room-content__title {
				font-size: 19px;
				font-weight: 600;
			}
			&__desc {
				font-size: 16px;
				line-height: 1.5;
				font-family: var(--font-family-base);
			}
		}
		&-section {
			margin-top: 40px;
			&.content {
				pre {
					padding: 0;
				}
			}
			.reserv-room-section__title {
				margin: 0;
				font-size: 21px;
				font-weight: 700;
				line-height: 1.2;
			}
			&__header {
				margin-bottom: clamp(12px, 3vw, 24px);
				padding-bottom: clamp(12px, 3vw, 24px);
				border-bottom: 1px solid #e0e0e0;
			}
			+ .buttons {
				margin-top: 40px;
			}
			.calendar-default {
				max-width: 700px;
				margin: clamp(20px, 3vw, 34px) auto;
				user-select: none;
				.month-selector {
					display: flex;
					margin-bottom: clamp(8px, 2vw, 24px);
					align-items: center;
					justify-content: center;
					gap: clamp(10px, 3vw, 20px);
					.calendar-title {
						font-size: clamp(16px, 3vw, 32px);
						font-weight: 700;
						color: #645f5b;
					}
					.btn-selector {
						display: flex;
						width: clamp(18px, 3vw, 30px);
						height: clamp(18px, 3vw, 30px);
						border-radius: 50%;
						align-items: center;
						justify-content: center;
						.icon-arrow {
							@include icon;
							width: clamp(5px, 3vw, 13px);
							height: clamp(10px, 3vw, 18px);
							background-image: url('~@/assets/img/common/icon_arrow.svg');
						}
						&-next {
							.icon-arrow {
								transform: rotate(180deg);
							}
						}
					}
				}
				.ls-calendar {
					width: 100%;
					border-collapse: collapse;
					.tb-header {
						tr {
							display: flex;
							width: 100%;
							align-items: center;
							justify-content: space-around;
						}
						th {
							padding: clamp(2px, 2vw, 12px) 5px;
							text-align: center;
							flex: 0 0 calc(100% / 7);
							text-align: center;
							vertical-align: middle;
							font-size: clamp(12px, 3vw, 24px);
							font-weight: 400;
							color: #757575;
						}
					}
					.tb-body {
						display: block;
						width: 100%;
						border-radius: 15px;
						border: 1px solid #e0e0e0;
						overflow: hidden;
						tr {
							display: table;
							width: 100%;
							&:first-child {
								td {
									border-top: none;
								}
							}
						}
						td {
							padding: 10px;
							width: calc(100% / 7);
							border: 1px solid #e0e0e0;
							border-right: none;
							border-bottom: none;
							text-align: center;
							vertical-align: middle;
							color: #645f5b;
							&:first-child {
								border-left: none;
							}
						}
					}

					.calendar-date {
						font-size: clamp(16px, 3vw, 32px);
						font-weight: 700;

						.date {
							position: relative;
							display: flex;
							width: 100%;
							padding-top: 100%;
							border-radius: 50%;
							background-color: transparent;
							color: #645f5b;
							align-items: center;
							justify-content: center;
							@include transition(background-color, 0.3s);
							.num {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
						&-disabled {
							.date {
								color: #bdbdbd;
								pointer-events: none;
								cursor: not-allowed;
							}
						}
						&-selected {
							.date {
								background-color: #ff7300;
								color: #fff;
							}
						}
						.text {
							display: none;
						}
					}
				}
			}
			.time-selector {
				&-title {
					margin-bottom: clamp(16px, 3vw, 24px);
					text-align: center;
					font-size: clamp(19px, 3vw, 21px);
					font-weight: 700;
					color: #757575;
				}
				&-list {
					display: inline-flex;
					margin: 0 auto;
					text-align: center;
					gap: 1px;
				}
				&-item {
					margin: 0;
					flex: 0 0 53px;
					&:first-child {
						.time-selector-label {
							&::after {
								border-radius: 10px 0 0 10px;
							}
						}
					}
					&:last-child {
						.time-selector-label {
							&::after {
								border-radius: 0 10px 10px 0;
							}
						}
					}
				}
				&-label {
					text-align: left;
					padding: 0;
					height: 100%;
					&:focus,
					&:active {
						border: none;
						outline: none;
					}

					&::after {
						content: '';
						display: block;
						width: 53px;
						height: 40px;
						background-color: #c5dcfa;
					}
					.time {
						display: block;
						margin: 0;
						padding: 2px;
						font-size: 12px;
						font-weight: 400;
						color: #757575;
					}
				}
				&-check {
					position: relative;
				}
				&-input {
					position: absolute;
					margin: 1px;
					width: 53px;
					height: 39px;
					top: initial;
					bottom: 0;
					background-color: transparent;
					border: none;
					opacity: 1;
					z-index: -1;
					&:checked {
						+ label {
							background-color: transparent;
							&::after {
								background-color: #ff7300;
							}
						}
					}
					&:not(:checked) ~ .time-selector-label:hover {
						box-shadow: none;
					}
					&[disabled] {
						+ label {
							pointer-events: none;
							cursor: not-allowed;
							&::after {
								background-color: #e0e0e0;
							}
						}
					}
					&:focus,
					&:active {
						+ label {
							border: none;
							outline: none;
						}
					}
				}
				&-legend {
					display: flex;
					margin-top: 8px;
					align-items: center;
					justify-content: center;
					gap: 24px;
					.time-status {
						display: inline-flex;
						align-items: center;
						font-size: 16px;
						color: #757575;
						&::before {
							content: '';
							display: block;
							width: 20px;
							height: 20px;
							margin-right: 4px;
							border-radius: 5px;
							background-color: #c5dcfa;
						}
						&.is-disabled {
							&::before {
								background-color: #e0e0e0;
							}
						}
					}
				}
			}
		}
	}
	&-forms {
		.fieldset-content {
			padding: 0 20%;
			@include mobile {
				padding: 0;
			}
			.mobile-full {
				.button-default {
					@include mobile {
						width: 100%;
					}
				}
			}
		}
	}
}

.page-nav {
	&.is-rounded {
		border: none;
		background-color: transparent;
		height: inherit;
		.nav-list {
			gap: 10px;
			background-color: transparent;
		}
		.nav-item {
			padding: 0;
			height: auto;
			.nav-text {
				display: flex;
				overflow: hidden;
				padding: 5px 30px;
				height: 32px;
				border-radius: 32px;
				border: 1px solid #c1c1c1;
				font-size: 16px;
				font-weight: 400;
				color: #424242;
				align-items: center;
				justify-content: center;
				line-height: 1;
				white-space: nowrap;
				cursor: pointer;
				transition: all 0.3s;
				&:hover,
				&:focus {
					background-color: #e7e7e7;
				}
			}
			&.is-selected .nav-text {
				background-color: #ff7300;
				color: #fff;
				border-color: #ff7300;
			}
			&:before {
				display: none;
			}
			&:not(:first-child) {
				margin: 0;
			}
		}
	}
}
.page-header {
	&.is-sticky {
		position: sticky !important;
		top: 80px;
		z-index: 10;
		@include tablet {
			top: 60px;
		}
	}

	position: relative;
	height: auto;
	margin-top: 30px;
	margin-bottom: 14px;
	padding-top: 30px;
	padding-bottom: 10px;
	text-align: left;
	.fr {
		.button-default {
			padding: 0 20px;
			white-space: nowrap;
		}
	}
	@include tablet {
		height: auto;
		margin-top: 0;
		margin-bottom: 16px;
	}
	.page-component {
		display: flex;
		height: 100%;
		// background-image: url(~@/assets/img/common/localecono_title.png);
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		justify-content: center;
		flex-direction: column;
	}
	&-title {
		position: relative;
		margin-bottom: 10px;
		text-align: left;
		font-size: 32px;
		font-weight: 700;
		z-index: 1;
		&.is-subtitle {
			font-size: 22px;
		}
	}
	&-desc {
		position: relative;
		text-align: left;
		font-size: 21px;
		color: #757575;
		line-height: 1.3;
		z-index: 1;
		@include mobile {
			display: none;
		}
	}
}
.icon {
	&-reserv-fav {
		width: 23px;
		height: 21px;
		background-image: url('~@/assets/img/common/icon_reserv_fav.svg');
		@include icon;
	}
	&-reserv-fav-on {
		width: 23px;
		height: 21px;
		background-image: url('~@/assets/img/common/icon_reserv_fav_on.svg');
		@include icon;
	}
	&-reserv-share {
		width: 21px;
		height: 22px;
		background-image: url('~@/assets/img/common/icon_reserv_share.svg');
		@include icon;
	}
	&-reserv-map {
		width: 22px;
		height: 21px;
		background-image: url('~@/assets/img/common/icon_reserv_map.svg');
		@include icon;
	}
	&-thumb-more {
		width: 40px;
		height: 40px;
		background-image: url('~@/assets/img/common/icon_thumb_more.svg');
		@include icon;
		@include mobile {
			width: 24px;
			height: 24px;
		}
	}
	&-reserv-check {
		width: 20px;
		height: 14px;
		background-image: url('~@/assets/img/common/icon_reserv_check.svg');
		@include icon;
	}
	&-reserv-info {
		width: 20px;
		height: 20px;
		background-image: url('~@/assets/img/common/icon_reserv_info.svg');
		@include icon;
	}
	&-sodam {
		&-map {
			width: 18px;
			height: 18px;
			background-image: url('~@/assets/img/sodam/icon-sodam-map.svg');
			@include icon;
		}
		&-time {
			width: 18px;
			height: 18px;
			background-image: url('~@/assets/img/sodam/icon-sodam-time.svg');
			@include icon;
		}
		&-reserv {
			width: 18px;
			height: 18px;
			background-image: url('~@/assets/img/sodam/icon-sodam-reserv.svg');
			@include icon;
		}
		&-phone {
			width: 18px;
			height: 18px;
			background-image: url('~@/assets/img/sodam/icon-sodam-phone.svg');
			@include icon;
		}
		&-url {
			width: 18px;
			height: 18px;
			background-image: url('~@/assets/img/sodam/icon-sodam-url.svg');
			@include icon;
		}
	}
}
[id^='reservation-'] {
	.page-header {
		.display-flex {
			&.is-between {
				.fl {
					display: flex;
					gap: 5px;
					align-items: center;
					.page-header-title {
						margin-bottom: 0;
						text-align: left;
					}
					.reserv-page-header__button {
						flex: 0 0 24px;
					}
				}
			}
		}
	}
	&.page-container {
		@include mobile {
			padding-bottom: 30px;
		}
	}
	.local-map-content {
		position: absolute;
		width: 423px;
		height: 749px;
		top: 50%;
		left: 50%;
		margin-left: -21%;
		// transform: translate(-50%, -36%);
		transform: translate(-50%, -31%);
		svg {
			width: 100%;
			height: 100%;
		}
		@include mobile {
			top: 151px;
			margin-left: 6%;
			// transform: translate(-50%, -39.5%) scale(0.72);
			transform: translate(-50%, -33%) scale(0.72);
		}
	}
	.local-map-wrapper {
		position: relative;
		overflow: hidden;
		height: 465px;
		margin-top: 24px;
		margin-bottom: 60px;
		background-color: #f5f5f5;
		border: 1px solid #d5d5d5;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) inset;
		@include mobile {
			display: block;
			height: auto;
		}
		.flex-item {
			&.flex-l {
				width: 100%;
				height: 300px;
				@include mobile {
					height: 330px;
				}
			}
			&.flex-r {
				width: 100%;
				padding: 0px;
				.local-map-section {
					padding: 20px 20px 0;
					width: 100%;
					height: 100%;
					overflow-y: auto;
					@include scrollbar('v', '');
				}
				@include mobile {
					display: block;
					position: relative;
					z-index: 1;
					border-radius: 0;
					border-top: 1px solid #d5d5d5;
					border-left: none;
				}
			}
		}
	}
	.reserv-program {
		@include mobile {
			display: none;
		}
	}
	.page-nav {
		overflow-x: auto;
		margin-bottom: 24px;
		.nav-list {
			justify-content: flex-start;
		}
		@include mobile {
			max-width: calc(100% + 30px);
			margin-left: -15px;
			margin-right: -15px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.page-component {
		width: 100%;
		max-width: 1280px;
		padding: 0 40px;
		@include mobile {
			padding: 0 15px;
		}
	}
}
#reservation-home {
	.page-nav {
		margin-bottom: 0;
		.nav-list {
			padding-left: 0 !important;
			padding-right: 0 !important;
			@include mobile {
				gap: 4px;
			}
			.nav-item {
				.nav-text {
					@include mobile {
						padding: 5px 20px;
						font-size: 15px;
					}
					@include until(400px) {
						padding: 5px 8px;
					}
				}
			}
		}
		&-desc {
			position: absolute;
			top: 64px;
			left: 48px;
			z-index: 1;
			width: calc(50% - 56px);
			padding: 8px;
			line-height: 1.2;
			border-radius: 7px;
			background-color: rgba(255, 255, 255, 0.75);
			box-shadow: 0px 0px 10px 0px #8d553140;
			font-weight: 600;
			color: #ff7300;
			text-align: center;
			@include word-wrap;
			@include mobile {
				left: 23px;
				width: calc(100% - 46px);
				padding: 4px;
				font-size: 14px;
			}
		}
	}
}
a.thumbnail-article {
	@include transition(border, 0.3s);
	&:hover {
		border-color: #888;
		.thumb-default {
			img {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}
}
.thumbnail-article {
	display: flex;
	position: relative;
	padding: 20px;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	border: 1px solid #e0e0e0;

	@include mobile {
		padding: 15px;
	}
	.thumb-default {
		flex: 0 0 115px;
		img {
			@include transition(transform, 0.3s);
		}
		@include mobile {
			padding-top: 100px;
			flex: 0 0 100px;
		}
	}
	&-text {
		display: flex;
		height: 100%;
		padding-left: 15px;
		align-items: flex-start;
		flex-direction: column;
		flex: 1 1;
		&__header {
			margin-bottom: 8px;
		}
	}
	&-tag {
		display: inline-flex;
		height: 24px;
		padding: 0 5px;
		border-radius: 15px;
		font-size: 14px;
		font-weight: 500;
		justify-content: center;
		align-items: center;
		&.color {
			&-yellow {
				background-color: #fbf3d0;
				color: #7f4c0a;
			}
			&-green {
				background-color: #c5f2c7;
				color: #155d18;
			}
			&-blue {
				background-color: #c5dcfa;
				color: #17205b;
			}
			&-gray {
				background-color: #e0e0e0;
				color: #424242;
			}
			&-red {
				background-color: #fcd0cf;
				color: #da100b;
			}
		}
	}
	&-title {
		display: block;
		max-height: 46px;
		margin-bottom: 8px;
		line-height: 1.2;
		font-size: 19px;
		font-weight: 700;
		text-align: left;
		@include text-ellipsis-2;
	}
	&-desc {
		display: block;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.3;
		color: #757575;
		text-align: left;
		word-break: break-all;
	}
	&-date {
		display: block;
		font-size: 16px;
		color: #757575;
		line-height: 1.3;
		@include text-ellipsis-3;
		@include mobile {
			font-size: 14px;
		}
	}
	&-footer {
		position: absolute;
		bottom: 20px;
		right: 20px;
		@include mobile {
			position: static;
		}
		&.buttons {
			.button-default {
				height: 30px;
				padding: 5px 20px;
				font-size: 16px;
				&:hover,
				&:focus {
					color: #424242;
					background-color: #f1f1f1;
				}
				&:active {
					color: #424242;
				}
			}
		}
	}
}
.thumb-default {
	display: flex;
	overflow: hidden;
	position: relative;
	padding-top: 116px;
	border-radius: 5px;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		object-fit: cover;
		transform: translate(-50%, -50%);
	}
	@include mobile {
		border-radius: 7px;
	}
	&-video {
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			background-color: rgba(0, 0, 0, 0.2);
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			width: 48px;
			height: 48px;
			margin: -24px 0 0 -24px;
			@include icon;
			background-image: url('~@/assets/img/common/icon_play_w.svg');
		}
	}
}
.textlist-item {
	display: block;
	&:hover,
	&:focus {
		.textlist-item__title {
			text-decoration: underline;
		}
	}
	&-header {
		display: flex;
		margin-bottom: 8px;
		align-items: flex-start;
		justify-content: flex-start;
		&.display-block {
			display: block;
		}
	}
	&__tag {
		display: inline-flex;
		min-width: 36px;
		height: 20px;
		margin-top: 1.5px;
		margin-right: 8px;
		padding: 0 4px;
		border-radius: 4px;
		background-color: #ff7300;
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		line-height: 1;
		align-items: center;
		justify-content: center;
	}
	&__title {
		font-size: 19px;
		font-weight: 700;
		line-height: 1.25;
		max-height: 45px;
		@include text-ellipsis-2;
		&-full {
			display: inline;
			max-height: initial;
			text-overflow: initial;
			overflow: initial;
			-webkit-line-clamp: initial;
		}
	}
	&__desc {
		display: block;
		margin-bottom: 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.3;
		color: #757575;
		@include text-ellipsis-2;
		@include mobile {
			font-size: 14px;
		}
	}
	&__date {
		display: block;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.3;
		color: #757575;
	}
}
.reserv-pagination-wrap {
	margin: 24px auto;
	.pagination {
		margin-bottom: 0;
		padding-top: 0;
	}
}

.flex {
	display: flex;
	&.justify-content {
		&-start {
			justify-content: flex-start;
		}
		&-center {
			justify-content: center;
		}
		&-end {
			justify-content: flex-end;
		}
	}
	&.gap {
		&-8 {
			gap: 8px;
		}
		&-10 {
			gap: 10px;
		}
		&-16 {
			gap: 16px;
		}
		&-24 {
			gap: 24px;
		}
	}
}
.modal-reserv-boxinfo {
	display: flex;
	margin-bottom: 1em;
	font-size: 14px;
	font-weight: 300;
	color: #777;
	text-align: left;
	line-height: 1.3;
	gap: 6px;
}
.textlist {
	&-detail {
		&-header {
			margin-top: 44px;
			margin-bottom: 30px;
		}
		&-body {
			margin-bottom: 30px;
			line-height: 1.5;
		}
		&-footer {
			margin-bottom: 24px;
			padding-bottom: 24px;
			border-bottom: 1px solid #e0e0e0;
			color: #757575;
			&__attach {
				display: block;
				margin-bottom: 24px;
				font-size: 12px;
				line-height: 1.2;
			}
			&__date {
				display: block;
				font-size: 14px;
			}
			+ .buttons {
				&.flex {
					&.justify-content-center {
						@include mobile {
							.button-default {
								min-width: initial;
								width: 50%;
								flex: 1 1;
							}
						}
					}
				}
			}
		}
	}
}
.reserv-warning-title {
	padding-bottom: 1em;
	font-size: 21px;
	font-weight: 700;
	line-height: 1.2;
	color: #757575;
	text-align: center;
}
.field {
	&.justify-content-between {
		.flex {
			&-0-0 {
				flex: 0 0 110px;
				@include mobile {
					flex: 0 0 auto;
					.button-default {
						flex: 1 1;
						width: 100%;
					}
				}
			}
			&-1-1 {
				flex: 1 1;
			}
		}
	}
	&.align-items-start {
		.field-form {
			@include mobile {
				width: 100%;
			}
		}
	}
	&.display-block {
		display: block;
		margin-top: -8px;
		.box-gray {
			margin-bottom: 8px;
			text-align: center;
			i {
				margin-top: -2px;
				margin-right: 6px;
			}
		}
		.box-info {
			font-size: 16px;
			line-height: 1.4;
			color: #757575;
			i {
				flex: 0 0 20px;
			}
			p {
				padding-left: 6px;
				flex: 1 1;
			}
		}
	}
	.field-checkbox-wrap {
		.form-checkbox {
			margin-top: 0.3em;
			margin-bottom: 0.3em;
			@include mobile {
				width: 100%;
			}
		}
	}
	.control.field-form {
		.file-upload-wrap {
			.fl {
				.input {
					height: 32px;
					font-size: 14px;
				}
			}
			.fr {
				.button-default {
					height: 32px;
					font-size: 16px;
				}
			}
		}
		.input {
			&.no-border {
				border: none;
				font-weight: 700;
			}
		}
	}
	&.mobile-flex {
		@include mobile {
			align-items: center;
			flex-direction: row;
		}
	}
}
.control {
	.form-select {
		height: 50px;
		padding: 2px 15px;
		border-radius: 5px;
		border: 1px solid #bdbdbd;
		font-weight: 400;
		color: #424242;
		box-shadow: none;
	}
	.input {
		&.textarea {
			height: auto;
			padding: 15px;
		}
	}
}

.box-gray {
	margin-bottom: 60px;
	padding: 20px;
	border-radius: 15px;
	background-color: #f5f5f5;
	font-size: 16px;
	font-weight: 400;
	color: #757575;
	line-height: 1.2;
	&-footer {
		display: flex;
		gap: 5px;
		justify-content: center;
		flex-wrap: wrap;
		span {
			margin-left: 1em;
			margin-right: 1em;
		}
	}
	.button-default.is-rounded {
		background-color: #fff;
		font-size: 16px;
		&:hover,
		&:focus {
			background-color: #eee;
			color: #424242;
		}
	}
	&.is-checked-mb0 {
		margin-bottom: 0;
		text-align: center;
		strong {
			font-size: 16px;
		}
		.icon-reserv-check {
			margin-top: -10px;
			margin-right: 6px;
		}
	}
	&.mb24 {
		margin-bottom: 24px;
		@at-root .mypage2-section & {
			padding: 0;
		}
	}

	strong {
		display: block;
		margin-bottom: 8px;
		font-size: 19px;
		font-weight: 700;
		color: #424242;
		line-height: 1.2;
		&.is-strong {
			margin: 0;
			font-size: 21px;
			color: #757575;
			@include mobile {
				font-size: 16px;
			}
		}
	}
}

.file-upload-wrap {
	display: flex;
	width: 100%;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	.fl {
		position: relative;
		flex: 1 1;
	}
	.fr {
		flex: 0 0 68px;
		.button-default {
			input[type='file'] {
				display: none;
			}
		}
	}
	.input {
		display: flex;
		width: 100%;
		height: 32px;
		padding: 5px 10px;
		border-radius: 15px;
		background-color: #fff;
		border: 1px solid #e0e0e0;
		font-size: 12px;
	}
	.button-default {
		width: 100%;
		background-color: #fff;
	}
	.button-close {
		display: flex;
		position: absolute;
		width: 32px;
		height: 32px;
		top: 0;
		right: 0;
		align-items: center;
		justify-content: center;
		.icon-close {
			width: 10px;
			height: 10px;
			opacity: 0.5;
		}
	}
}

.reserv-inquiry-list {
	@at-root .mypage2-section & {
		padding: 1em;
		border-radius: 15px;
		background-color: #fff;
	}
	li {
		border-bottom: 1px solid #e0e0e0;
		background-color: #fff;
		@include transition(background, 0.5s);
		&.is-active {
			background-color: #f5f5f5;
			.textlist-item__title {
				max-height: initial;
				-webkit-line-clamp: initial;
			}
		}
	}
	.textlist {
		&-reply {
			margin-top: 16px;
			padding: 16px;
			border-top: 1px solid #e0e0e0;
			pre {
				font-size: 16px;
				line-height: 1.5;
				word-break: break-all;
				white-space: normal;
				font-family: var(--font-family-base);
			}
			&__title {
				display: block;
				margin-bottom: 10px;
				font-size: 19px;
				font-weight: 700;
			}
			&__date {
				margin-top: 20px;
				font-size: 14px;
				line-height: 1.1;
			}
		}
	}
	.textlist-item {
		padding: 16px;
		&-header {
			display: block;
			margin: 0;
			&__top {
				display: flex;
				margin-bottom: 8px;
				justify-content: space-between;
				align-items: center;
			}
			&__title {
				display: flex;
				margin-bottom: 0;
				justify-content: space-between;
				align-items: flex-start;
				.textlist-item__title {
					width: 100%;
					margin-top: 0.3em;
					text-align: left;
					white-space: inherit;
					line-height: 1.2;
				}
				.flex {
					&-1 {
						flex: 1 1;
						@at-root .mypage2-section & {
							padding: 0;
						}
					}
					&-0 {
						padding-left: 20px;
						flex: 0 0 60px;
						@at-root .mypage2-section & {
							background-color: transparent;
							padding: 0;
						}
						.button-default {
							width: 60px;
						}
					}
				}
			}
		}
		&-content {
			&__desc {
				margin-top: 10px;
				font-size: 16px;
				line-height: 1.4;
				word-break: break-all;
				white-space: normal;
				font-family: var(--font-family-base);
			}
			&__attach {
				display: block;
				color: #757575;
				font-size: 12px;
				margin-top: 16px;
			}
		}
		&__tag {
			margin: 0;
			width: 60px;
			font-weight: 500;
			&.is-blue {
				background-color: #c5dcfa;
				color: #17205b;
			}
			&.is-green {
				background-color: #c5f2c7;
				color: #155d18;
			}
		}
	}
}
.reserv-detail-noticeview {
	.textlist-item__title {
		max-height: initial;
		-webkit-line-clamp: initial;
	}
}

.section {
	&-gallery {
		margin-bottom: 60px;
		@include mobile {
			margin-bottom: 30px;
		}
		&:first-child {
			margin-top: 40px;
			@include mobile {
				margin-top: 24px;
			}
		}
		&-header {
			margin-bottom: 20px;
			&__title {
				font-size: 21px;
				font-weight: 700;
			}
		}
		&-body {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			@include until(600px) {
				gap: 8px;
			}
		}
		&-item {
			width: calc(20% - 16px);
			&:hover {
				.thumb-default {
					img {
						transform: translate(-50%, -50%) scale(1.1);
					}
				}
			}
			@include tablet {
				width: calc(25% - 15px);
			}
			@include mobile {
				width: calc(33.33% - 14px);
			}
			@include until(600px) {
				width: calc(50% - 4px);
			}
			.thumb-default {
				padding-top: 100%;
				img {
					transform: translate(-50%, -50%) scale(1.01);
					@include transition(transform, 0.5s);
				}
				@include until(600px) {
					padding-top: 120%;
				}
			}
		}
	}
}
.sptpgm-article-header__tag {
	&.is-category {
		background-color: #eee;
		border-color: transparent;
	}
}

.floating-buttons {
	display: flex;
	position: fixed;
	right: 8.5%;
	bottom: 4%;
	width: 60px;
	height: auto;
	z-index: 1;
	gap: 8px;
	flex-direction: column;
	@include until(1800px) {
		right: 15px;
		bottom: 10px;
	}
	@include tablet {
		width: 48px;
		gap: 6px;
	}
	.floating-button {
		display: inline-flex;
		border: 1px solid #e0e0e0;
		border-radius: 8px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 60px;
		height: 60px;
		background-color: #fff;
		box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
		&.btn-pagetop {
			border-color: transparent;
			background-color: #645f5b;
			color: #fff;
		}
		.text {
			display: block;
			margin-top: 5px;
			font-size: 14px;
			font-weight: 700;
			line-height: 1;
			@include tablet {
				margin-top: 4px;
				font-size: 12px;
			}
		}
		@include tablet {
			width: 48px;
			height: 48px;
			border-radius: 6px;
		}
	}
}
.icon-floating {
	&-top {
		width: 9px;
		height: 8px;
		@include icon;
		background-image: url('~@/assets/img/common/icon_floating_top.svg');

		@include tablet {
			width: 8px;
			height: 7px;
		}
	}
	&-tv {
		width: 26px;
		height: 26px;
		@include icon;
		background-image: url('~@/assets/img/common/icon_floating_tv.svg');
		@include tablet {
			width: 21px;
			height: 21px;
		}
	}
	&-reserv {
		width: 26px;
		height: 26px;
		@include icon;
		background-image: url('~@/assets/img/common/icon_floating_reserv.svg');
		@include tablet {
			width: 21px;
			height: 21px;
		}
	}
}
