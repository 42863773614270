#sme-noticeinfo {
	background-color: #f3f5f7;
}
#sme-noticeinfo .page-header .page-header-title {
	text-align: center;
}
#sme-noticeinfo .page-body .page-component {
}
#sme-noticeinfo .post-list-wrapper {
	padding-top: 0;
	padding-bottom: 60px;
}
#sme-noticeinfo .post-card-list {
	padding-top: 0;
}
#sme-noticeinfo .title {
	text-align: left;
}
#sme-noticeinfo-view {
	background-color: #fff;
	.nextprev-btn-cont {
		display: flex;
		justify-content: space-between;
		.button-default {
			&:first-child {
				margin-right: 110px !important;
			}
			&.is-primary.is-rounded,
			&.is-secondary.is-rounded {
				width: 100px;
				min-width: initial;
			}
		}
	}
	.list-btn-cont {
		top: calc(100% - 96px) !important;
		.button-default {
			&.is-primary.is-rounded,
			&.is-secondary.is-rounded {
				min-width: initial;
				width: 100px;
			}
		}
	}
}
#sme-noticeinfo .tag-search {
	width: 100%;
	margin: 40px auto 20px;
}
#sme-noticeinfo .tag-search .form-control {
	padding-left: 65px;
	background: url(../img/connect/tag_search_icon.svg) no-repeat 20px 50%;
}

/* #sme-noticeinfo .section-notice-arrow {position:absolute;right:15px;bottom:calc(90% - 5px);display:flex; top:-32px;}
#sme-noticeinfo .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
#sme-noticeinfo .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-noticeinfo .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-noticeinfo .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
#sme-noticeinfo .disabled {opacity:.3;}
#sme-noticeinfo .btn-arrow:not(:first-child) {margin-left:5px;}
#sme-noticeinfo [class^="icon-arrow"] {width:15px;height:15px;}
#sme-noticeinfo .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
#sme-noticeinfo .icon-arrow-next {background-image:url(../img/main/notice_next.svg)} */

/* 공지사항 수정 */
.layout_pc {
	display: none !important;
}
.layout_mo {
	display: block !important;
}
.page-body[data-fill='true'] {
	background: #fff !important;
}
.page-body.page-bg {
	background: #fff;
}
.notice-list-wrapper {
	margin-top: 24px;
	border-top: 1px solid #ebebeb;
}
.box_search {
	display: flex;
	flex-wrap: wrap;
	padding: 20px 10px;
	margin: 20px 0 25px;
	border: 1px solid #ebebeb;
	background: #f9f9f9;
	border-radius: 10px;
	justify-content: space-between;
	align-items: center;
}
.box_calendar {
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	align-items: center;
	cursor: pointer;
}
.box_search .calendar {
	display: inline-block;
	width: calc((100% - 15px) / 2);
	position: relative;
}
.box_search .calendar input {
	width: 100%;
	height: auto;
	padding: 10px 15px;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	border-radius: 2px;
}
.box_search .calendar span {
	display: inline-block;
	width: 40px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: #015aa8;
	border-radius: 5px;
}
.box_search .calendar img {
	width: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.box_search .calendar .mx-icon-calendar {
	display: inline-block;
	width: 40px;
	height: 100%;
	position: absolute;
	right: 1px;
	background: #015aa8;
	border-radius: 5px;
}
.box_search .calendar .mx-icon-calendar > svg {
	width: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	fill: white;
	height: 2em;
}

.filter-selector {
	width: 30%;
}
.filter-selector .form-select {
	padding: 10px 15px;
	background: #fff url(../img/common/icon_form_select.svg) no-repeat calc(100% - 5px) 50%;
}
.box_entry {
	width: 65%;
}
.box_search .search_box {
	display: flex;
}
.box_search .search_box input {
	width: 100%;
	padding: 10px;
	color: #111;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	border-radius: 2px;
}
.box_search .search_box .search-button {
	height: 38px;
	background: #015aa8 url(../img/common/search_w.svg) no-repeat top 40% right 50%;
}
.box_search .search_box .text {
	display: none;
}
.notice_body-header {
	margin: 30px 0 15px 0;
}
.notice_body-header .header_left {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
	justify-content: center;
	align-items: center;
}
.notice_body-header .header_left .all_page {
	margin-right: 10px;
	padding-left: 15px;
	background: url(~@/assets/img/notice/ico_list.png) no-repeat top 2px left / 13px;
}
.notice_body-header .header_left .current_page {
	padding-left: 15px;
	background: url(~@/assets/img/notice/ico_current.png) no-repeat top 2px left / 12px;
}
.notice_body-header .header_left span {
	color: #e24d51;
}
.notice_body-header .header_right {
	text-align: right;
}
.notice_body-header .header_right button {
	height: 40px;
	padding: 8px 19px;
}
.notice_body-header .header_right button .text {
	font-size: 14px;
}
.notice_body-header .header_right .btn_cur {
	margin-right: 10px;
	background: #424242;
}
.notice_body-header .header_right .btn_cur .text {
	color: #fff;
}
.notice_body-list table {
	width: 100%;
	border-top: 2px solid #5368f5;
}
.notice_body-list table colgroup,
.notice_body-list table thead {
	display: none;
}
.notice_body-list table tr {
	height: 50px;
	border-bottom: 1px solid #dce2e8;
}
.notice_body-list table td {
	display: none;
	padding: 10px;
	border: none;
}
.title_mo p {
	display: block;
	line-height: 22px;
	font-weight: 900;
	color: #505050;
	word-break: keep-all;
}
.info_mo {
	margin-top: 10px;
	font-size: 12px;
}
.info_mo ul li:first-child {
	width: 50%;
	display: inline-block;
}
.info_mo ul li:last-child {
	width: 45%;
	display: inline-block;
	text-align: right;
}
.info_mo ul li span:first-child {
	padding-right: 5px;
}
.blind {
	position: absolute !important;
	top: -9999rem !important;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
}

/* 공지사항 상세 */
.notice-post-view-wrapper {
	width: 100% !important;
}
.board_info {
	width: 100%;
}
.board_info dl {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
}
.board_info dt,
.board_info dd {
	padding: 10px 15px;
	word-wrap: break-word;
}
.board_info dt {
	width: 90px;
	background: #f3f9ff;
	font-weight: 500;
	color: #000;
	border: 1px solid #cfd8dc;
}
.board_info dd {
	display: block;
	width: calc(100% - 90px);
	word-break: break-all;
	border: 1px solid #cfd8dc;
}
.board_info dt {
	border-left: none;
}
.board_info dd {
	border-right: none;
}
.board_info .x4 dt {
	width: 90px;
	word-wrap: break-word;
	line-height: 96px;
}
.board_info .x4 dd {
	width: calc(100% - 90px);
}
.att_wap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.att_info span {
	display: inline-block;
	position: relative;
}
.att_icon {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	text-indent: -9999rem;
}
.att_icon.pdf {
	background: url(~@/assets/img/notice/ico_pdf.png) no-repeat top left / 20px 20px;
}
.att_icon.doc {
	background: url(~@/assets/img/notice/ico_doc.png) no-repeat top left / 20px 20px;
}
.att_icon.wod {
	background: url(~@/assets/img/notice/ico_doc.png) no-repeat top left / 20px 20px;
}
.att_icon.docx {
	background: url(~@/assets/img/notice/ico_doc.png) no-repeat top left / 20px 20px;
}
.att_icon.hwp {
	background: url(~@/assets/img/notice/ico_hwp.png) no-repeat top left / 20px 20px;
}
.att_icon.jpg {
	background: url(~@/assets/img/notice/ico_jpg.png) no-repeat top left / 20px 20px;
}
.att_icon.png {
	background: url(~@/assets/img/notice/ico_png.png) no-repeat top left / 20px 20px;
}
.att_icon.ppt {
	background: url(~@/assets/img/notice/ico_ppt.png) no-repeat top left / 20px 20px;
}
.att_icon.pptx {
	background: url(~@/assets/img/notice/ico_ppt.png) no-repeat top left / 20px 20px;
}
.att_icon.xlsx {
	background: url(~@/assets/img/notice/ico_xlsx.png) no-repeat top left / 20px 20px;
}
.att_icon.xlx {
	background: url(~@/assets/img/notice/ico_xlsx.png) no-repeat top left / 20px 20px;
}
.att_icon.txt {
	background: url(~@/assets/img/notice/ico_txt.png) no-repeat top left / 20px 20px;
}
.btn_link,
.btn_down {
	display: inline-block;
	padding: 10px 25px 10px 10px;
	text-align: center;
	border-radius: 4px;
	font-weight: 500;
}
.btn_att {
	margin-top: 10px;
}
.btn_link {
	background: #fff url(../../mobile/img/notice/ico_link.png) no-repeat top 7px right 5px;
	border: 2px solid #e0e0e0;
	color: #003a88;
}
.btn_down {
	margin-left: 10px;
	background: #003a88 url(../../mobile/img/notice/ico_down_white.png) no-repeat top 7px right 5px;
	border: 2px solid #003a88;
	color: #fff;
}
.board_content {
	padding: 20px 5px;
	border-bottom: 1px solid #cfd8dc;
	line-height: 30px;
	font-size: 18px;
	color: #464646;
	word-break: keep-all;
}

@media only screen and (max-width: 359px) {
	.btn_att {
		margin-top: 10px;
	}
	.btn_down {
		margin: 5px 0 0 0;
	}
	.btn-lg {
		min-width: 80px;
		padding: 0 25px;
	}
	.btn.btn-default:first-child {
		margin-right: 110px !important;
	}
	// .list-btn-cont {
	// 	left: calc(50% - 40px) !important;
	// }
}

/* 셀렉트 추가 S (20220721 - hib) */
#sme-noticeinfo .box_select {
	width: 205px;
	position: absolute;
	top: -47px;
	right: 5px;
}
#sme-noticeinfo .box_select button {
	width: 100%;
	padding: 8px 12px;
	text-align: left;
	background: #999999;
	color: #fff;
	border-radius: 5px;
	position: relative;
	top: 3px;
}
#sme-noticeinfo .box_select button::after {
	display: inline-block;
	width: 28px;
	height: 26px;
	content: '';
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	position: absolute;
	top: 3px;
	right: 2px;
}
#sme-noticeinfo .box_select button.active::after {
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	transform: rotate(180deg);
}
#sme-noticeinfo .box_select ul {
	display: none;
	width: 100%;
	padding: 12px 10px 8px;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #999999;
}
#sme-noticeinfo .box_select ul li {
	font-size: 14px;
	font-weight: 900;
}
#sme-noticeinfo .box_select ul li + li {
	margin-top: 8px;
}
#sme-noticeinfo .box_select .show_list {
	display: block;
	background: #fff;
}
/* 셀렉트 추가 E (20220721 - hib) */
