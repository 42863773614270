/* btn */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 2px solid transparent;
	height: 50px;
	border-radius: 2px;
	padding: 0 20px;
	transition: background 0.4s;
}
.btn .text {
	font-weight: bold;
	white-space: nowrap;
}
.btn-default {
	background-color: #ebebeb;
}
.btn-default .text {
	color: #555;
}
.btn-default:hover {
	background-color: #d3d3d3;
}
.btn-primary {
	background-color: #ff7300;
}
.btn-primary:hover {
	background-color: #015197;
}
.btn-primary .text {
	color: #fff;
}
.btn[disabled],
.btn:disabled {
	background-color: #999;
	border-color: #999;
}
.btn[disabled]:active,
.btn:disabled:active,
.btn[disabled]:focus,
.btn:disabled:focus,
.btn[disabled]:hover,
.btn:disabled:hover {
	background-color: #999;
	border-color: #999;
}
.btn[disabled] .text,
.btn:disabled .text {
	color: #fff;
}
.btn-secondary {
	background-color: #999;
	color: #fff;
}
.btn-secondary:hover {
	background-color: #aaa;
}
.btn-secondary .text {
	color: #fff;
}
.btn-light {
	background-color: #f3f5f7;
}
.btn-light:hover {
	background-color: #ebebeb;
}
.btn-gray {
	background-color: #ebebeb;
}
.btn-gray:hover {
	background-color: #ebebeb;
}
.btn-white {
	background-color: #fff;
}
.btn-white .text {
	text-decoration: none;
	text-decoration-color: transparent;
	-webkit-text-decoration-color: transparent;
	transition: all 0.2s;
}
.btn-white:focus .text,
.btn-white:hover .text {
	text-decoration-color: inherit;
	-webkit-text-decoration-color: inherit;
}
.btn-outline-white {
	background-color: #fff;
	border-color: #ebebeb;
}
.btn-outline-light {
	background-color: #fafafa;
	border-color: #ebebeb;
}
.btn-outline-primary {
	background-color: #fff;
	border-color: #ff7300;
}

.btn-xl {
	min-width: 120px;
	text-align: center;
	height: 60px;
	padding: 0 30px;
}
.btn-xl .text {
	font-size: 16px;
	font-weight: 900;
}
.btn-lg {
	min-width: 100px;
	text-align: center;
	height: 60px;
	padding: 0 30px;
}
.btn-lg .text {
	font-size: 1.1rem;
	font-weight: 900;
}
.btn-lg [class^='icon-'] {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}
.btn-md {
	height: 50px;
	padding: 0 20px;
}
.btn-sm {
	height: 30px;
	padding: 0 10px;
}
.btn-sm .text {
	font-size: 11px;
	font-weight: 900;
}

.btn-texticon {
	display: inline-flex;
	align-items: center;
}
.btn-texticon [class^='icon'] {
	margin-left: 10px;
}
.btn-icontext {
	display: inline-flex;
	align-items: center;
}
.btn-icontext [class^='icon'] {
	margin-right: 10px;
}

.btn-more {
}
.btn-more .icon-arrow {
	width: 20px;
	height: 20px;
	margin-right: -8px;
	background-image: url(../img/common/more_arrow.svg);
}

.btn-wide {
	width: 100%;
}

/* table */
.table {
	width: 100%;
	table-layout: fixed;
	border-color: #ebebeb;
	font-weight: 400;
}
.table > :not(caption) > * > * {
	padding: var(--table-cell-padding-y) var(--table-cell-padding-x);
}
.table tbody {
	vertical-align: inherit;
}
.table thead {
	vertical-align: bottom;
}
.table th {
	background-color: #f3f5f7;
	padding: 10px 20px;
	text-align: left;
	font-weight: 400;
	line-height: 1.2;
	height: 57px;
	vertical-align: middle;
}
.table td {
	padding: 10px 20px;
	height: 57px;
	vertical-align: middle;
}
.table-bordered:not(caption) > * > * {
	border-width: 1px 0;
}
.table th,
.table td {
	line-height: 1.4;
}
/*.table-bordered:not(caption) > * > * {border-width:0 1px;}*/
.table-borderless > :not(caption) > * > * {
	border-bottom-width: 0;
}
.table-striped {
}
.table-column {
}
.table-column th {
	text-align: center;
	background-color: #ffffff;
}

/*--------------*/
.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
	margin-bottom: var(--breadcrumb-margin-bottom);
	font-size: var(--breadcrumb-font-size);
	list-style: none;
	background-color: var(--breadcrumb-bg);
	border-radius: var(--breadcrumb-border-radius);
}
.breadcrumb-item ~ .breadcrumb-item {
	padding-left: var(--breadcrumb-item-padding-x);
}
.breadcrumb-item.active {
	color: var(--breadcrumb-active-color);
}
.breadcrumb-item ~ .breadcrumb-item::before {
	float: left;
	padding-right: var(--breadcrumb-item-padding-x);
	color: var(--breadcrumb-column-color);
	content: '/';
}

/*--------------*/

/*--------------*/
.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	justify-content: center;
	padding-top: 30px;
	// margin-bottom: clamp(30px, 4vw, 50px);
}
.page-link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 34px;
	background-color: #ebebeb;
	border: 1px solid #ebebeb;
	border-radius: 0.3125rem;
	font-size: clamp(13px, 2vw, 1rem);
	color: #999;
	transition: var(--btn-transition);
	font-weight: 800;
	&:hover {
		z-index: 2;
		text-decoration: none;
		color: #111;
	}
	&:focus {
		z-index: 3;
	}
	[class^='icon'] {
		width: 7px;
		height: 10px;
		background-color: transparent;
	}
	.icon-pagination-prev {
		background-image: url(~@/assets/img/common/icon_pagination_prev.svg);
	}
	.icon-pagination-next {
		background-image: url(~@/assets/img/common/icon_pagination_next.svg);
	}
	.icon-pagination-first {
		width: 10px;
		background-image: url(~@/assets/img/common/icon_pagination_first.svg);
	}
	.icon-pagination-last {
		width: 10px;
		background-image: url(~@/assets/img/common/icon_pagination_last.svg);
	}
}
.page-item {
}
.page-item:not(:first-child) .page-link {
	margin-left: 5px;
}
.page-item.active .page-link {
	z-index: 3;
	background-color: #515151;
	color: #ffffff;
}
.page-item.disabled .page-link {
	pointer-events: none;
}

/*--------------*/
.scroll-tabs {
	overflow-x: auto;
	background-color: #f3f5f7;
	padding: clamp(10px, 1.5vw, 20px) 0;
}
.scroll-tabs .tab-list {
	display: flex;
	margin-left: -10px;
}
.scroll-tabs .tab-item {
}
.scroll-tabs .tab-item ~ .tab-item {
	margin-left: 10px;
}
.scroll-tabs .tab-item:last-child {
	padding-right: 25px;
}
.scroll-tabs .tab-link {
	display: block;
	padding: 9px 10px;
	border-radius: 0.3125rem;
}
.scroll-tabs .tab-text {
	font-weight: 800;
	color: var(--text-muted);
	white-space: nowrap;
}
.scroll-tabs .tab-item .tab-link:hover {
	background-color: #ebebeb;
}
.scroll-tabs .tab-item.is-selected .tab-text {
	color: #ff7300;
}
.scroll-tabs .tab-item.is-selected {
}

/*--------------*/
.ratio {
	position: relative;
	width: 100%;
	display: inline-block;
	width: 10rem;
}
.ratio::before {
	display: block;
	content: '';
}
.ratio-1x1::before {
	padding-top: var(--ratio-1x1);
}
.ratio-4x3::before {
	padding-top: var(--ratio-4x3);
}
.ratio-16x9::before {
	padding-top: var(--ratio-16x9);
}
.ratio-21x9::before {
	padding-top: var(--ratio-21x9);
}
.ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ratio > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*--------------*/
.form-check {
	display: flex;
	align-items: center;
}
.form-check-input {
	width: 20px;
	height: 20px;
	margin-top: 0;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
}
.form-check-input[type='checkbox'] {
	border-radius: 2px;
}
.form-check-input[type='radio'] {
	width: 18px;
	height: 18px;
	border-radius: 50%;
}
.form-check-input:checked {
	background-color: #ff7300;
	box-shadow: none;
}
.form-check-input:checked[type='checkbox'] {
	background-image: url(~@/assets/img/common/icon_form_checkbox.svg);
	background-size: 11px auto;
}
.form-check-input:checked[type='radio'] {
	background-image: url(~@/assets/img/common/icon_form_radio.svg);
	background-size: 7px auto;
}
.form-check-label {
	position: relative;
	cursor: pointer;
	margin-bottom: 0;
	line-height: 1.2;
	font-weight: bold;
	margin-left: 10px;
	font-size: 14px;
}
.form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
	opacity: 0.5;
}
.form-check-input[type='radio'] ~ .form-check-label {
	color: var(--text-muted);
	font-weight: 700;
}
.form-check-input:checked[type='radio'] ~ .form-check-label {
	color: #111;
}
.form-check-inline {
	display: inline-flex;
	margin-right: calc(30px + 5px);
	margin-bottom: calc(5px * 3);
}
.form-check-group {
}
.form-check-group.whole {
	margin-bottom: -5px;
}

.form-control {
	display: block;
	width: 100%;
	padding: 19px 20px;
	line-height: 22px;
	font-size: 16px;
	font-weight: bold;
	color: #111;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 2px;
}
.form-control-lg {
	min-height: var(--input-height-lg);
	padding: var(--input-padding-y-lg) var(--input-padding-x-lg);
	font-size: var(--input-font-size-lg);
	border-radius: calc(0.3125rem * 2);
}
.form-control-sm {
	min-height: var(--input-height-sm);
	padding: var(--input-padding-y-sm) var(--input-padding-x-sm);
	font-size: var(--input-font-size-sm);
	border-radius: calc(0.3125rem / 2);
}
.form-control:disabled,
.form-control[readonly] {
	box-shadow: 0 0 0 2px #ff7300 inset;
	color: #ff7300;
	opacity: 1;
}
.form-control[type='file'] {
	overflow: hidden;
}
.form-control[type='file']:not(:disabled):not([readonly]) {
	cursor: pointer;
}
.form-control[type='password'] {
	font-family: Apple SD Gothic Neo, arial, sans-serif;
}

.form-control[data-actions] {
	padding-right: 50px;
}
.form-control[data-valid] {
	padding-right: 50px;
}
.form-control[data-valid='invalid'] {
	background: url(../img/common/icon_form_invalid.svg) no-repeat calc(100% - 28px) 50%;
}
.form-control[data-valid='check'] {
	background: url(../img/common/icon_form_check.svg) no-repeat calc(100% - 21px) 50%;
}
.form-group {
	position: relative;
}
.form-group .btn-security {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	line-height: 0;
}
.form-group .icon-security {
	width: 18px;
	height: 18px;
	background-image: url(../img/common/icon_form_security.svg);
}
.form-group .icon-security-off {
	width: 18px;
	height: 18px;
	background-image: url(../img/common/icon_form_security_off.svg);
}

.form-file {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	visibility: hidden;
}
.form-file-label {
	position: relative;
	width: 200px;
	height: 50px;
	background-color: #fbfbfb;
	display: block;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
}
.form-file-label .text {
	font-weight: 700;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: calc(0.9375rem - 3px) 0;
	margin-bottom: 0;
	line-height: var(--input-line-height);
	color: var(--input-color);
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}

.form-control[type='file']::file-selector-button {
	padding: var(--input-padding-y) var(--input-padding-x);
	margin: calc(var(--input-padding-y) * -1) calc(var(--input-padding-x) * -1);
	margin-inline-end: var(--input-padding-x);
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
}
.form-control[type='file']:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color: var(--form-file-button-hover-bg);
}
.form-control[type='file']::-webkit-file-upload-button {
	padding: var(--input-padding-y) var(--input-padding-x);
	margin: calc(var(--input-padding-y) * -1) calc(var(--input-padding-x) * -1);
	margin-inline-end: var(--input-padding-x);
	color: var(--form-file-button-color); /*gradient-bg:(var(--form-file-button-bg);*/
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: var(--input-border-width);
	border-radius: 0;
	transition: var(--btn-transition);
}

.form-control::placeholder {
	color: #bdbdbd;
}
.form-control:-ms-input-placeholder {
	color: #bdbdbd;
}
.form-control::-ms-input-placeholder {
	color: #bdbdbd;
}

.form-control:focus {
	outline: none;
}
.form-control:focus {
	border-color: #ced4da;
}

.form-control.is-valid {
	padding-right: 50px;
	background: url(~@/assets/img/common/icon_invalid_check.svg) no-repeat calc(100% - 20px) 50%;
}
.form-control.is-security {
	padding-right: 50px;
	background: url(~@/assets/img/common/icon_invalid_security.svg) no-repeat calc(100% - 17px) 50%;
}
.form-control.is-invalid {
	padding-right: 50px;
	background: url(~@/assets/img/common/icon_invalid_invalid.svg) no-repeat calc(100% - 25px) 50%;
	color: #ff7300;
}

textarea.form-control {
}

.form-control[type='password'] {
}
.form-control[type='password']:focus {
}

.form-select {
	display: block;
	width: 100%;
	padding: 20px 15px;
	line-height: 20px;
	font-size: 16px;
	font-weight: bold;
	color: #111;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 2px;
	padding-right: 50px;
	background: #fff url(../img/common/icon_form_select.svg) no-repeat calc(100% - 20px) 50%;
}
.form-select:focus {
	outline: 0;
}
.form-select:disabled {
	color: var(--form-select-disabled-color);
	background-color: var(--form-select-disabled-bg);
	border-color: var(--form-select-disabled-border-color);
}
.form-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 var(--form-select-color);
}
.form-select::placeholder {
	color: var(--text-muted);
}
/* .form-select:invalid {color:var(--text-muted)} */
.form-select:checked {
	color: #111;
}
.form-select {
	color: #111;
}
.form-select:invalid {
	color: var(--text-muted);
}
.form-select-sm {
	padding-top: var(--form-select-padding-y-sm);
	padding-bottom: var(--form-select-padding-y-sm);
	padding-left: var(--form-select-padding-x-sm);
	font-size: var(--form-select-font-size-sm);
}
.form-select-lg {
	padding-top: var(--form-select-padding-y-lg);
	padding-bottom: var(--form-select-padding-y-lg);
	padding-left: var(--form-select-padding-x-lg);
	font-size: var(--form-select-font-size-lg);
}

option[value=''][disabled] {
	display: none;
}

input[type='date'] {
	position: relative;
	padding-left: 30px;
	font-size: 13px;
	font-weight: 400;
}
input[type='date']::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}
input[type='date']:before {
	content: '';
	display: block;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 50%;
	margin-top: -6px;
	left: 6px;
	color: #999;
	background: url(~@/assets/img/common/icon_form_calendar.svg) no-repeat 0 0;
}

input[type='date'] {
	height: 50px;
}
input[type='date']:before {
	top: 13px;
	left: 8px;
}

/*****************************/
.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
	z-index: 3;
}
.input-group .btn {
	position: relative;
	z-index: 2;
}
.input-group .btn:focus {
	z-index: 3;
}
.input-group > .form-check {
	margin-bottom: 0;
}
.input-group-text {
	display: flex;
	align-items: center;
	padding: var(--input-group-addon-padding-y) var(--input-group-addon-padding-x);
	font-size: var(--input-font-size);
	font-weight: var(--input-group-addon-font-weight);
	line-height: var(--input-line-height);
	color: var(--input-group-addon-color);
	text-align: center;
	white-space: nowrap;
}
.input-group.is-inside .input-group-text {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 50;
}
.input-group.is-inside > [class^='form-'] {
	padding-right: 55px;
}

.form-actions {
	position: relative;
}
.form-actions .form-control {
	padding-right: 50px;
}
.form-actions.is-security {
}
.form-actions.is-security .form-control {
}
.form-actions.is-security .btn-security {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	line-height: 0;
}
.form-actions.is-security .icon-security {
	width: 18px;
	height: 18px;
	background-image: url(~@/assets/img/common/icon_invalid_security2.svg);
}
.form-actions.is-security .icon-security-off {
	width: 18px;
	height: 18px;
	background-image: url(~@/assets/img/common/icon_invalid_security2_off.svg);
}

.form-row {
	display: flex;
	flex-wrap: nowrap;
	vertical-align: top;
	flex-wrap: wrap !important;
}
.form-row ~ .form-row:not(.value) {
	padding-top: 20px;
}
.form-row.text {
	padding-top: 15px;
	padding-bottom: 10px;
}
.form-row .form-cellth {
	flex: 0 0 100%;
}
.form-row .form-cellth label {
	position: relative;
}
.form-row .form-celltd {
	-webkit-box-flex: 1;
	flex: 1;
}
.form-cellth {
	margin-bottom: 10px;
}
.form-cellth label {
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.2;
}
.form-celltd > .form-check-group {
	padding-top: 15px;
}
.form-row .form-cellth.is-required .text {
	position: relative;
}
.form-row .form-cellth.is-required .text:after {
	content: '';
	position: absolute;
	right: -5px;
	top: -2px;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background-color: #ff7300;
}
.form-text {
	padding-top: 15px;
	line-height: 1.2;
}

.form-group {
	display: flex;
	flex-wrap: nowrap;
	vertical-align: top;
}
.form-group > * ~ .btn {
	margin-left: 10px;
}
.form-group > .form-control ~ .btn {
	min-width: clamp(90px, 15vw, 160px);
}

.form-help {
	display: inline-block;
	margin-top: 5px;
}
.form-help .text {
	font-size: 13px;
	color: var(--text-muted);
	line-height: 1.6;
}
.form-help .text ~ .text {
	padding-top: 7px;
}

.title-link[data-anim] {
	text-decoration: underline;
	text-decoration-color: transparent;
	-webkit-text-decoration-color: transparent;
	transition: text-decoration-color 0.2s ease;
}
.title-link[data-anim]:focus,
.title-link[data-anim]:hover {
	text-decoration-color: inherit;
	-webkit-text-decoration-color: inherit;
}
div[data-anim] {
	display: block;
	overflow: hidden;
	line-height: 0;
}
div[data-anim] img {
	transition: transform 0.4s ease;
}
div[data-anim]:focus img,
div[data-anim]:hover img {
	transform: scale(1.065) rotate(0.01deg);
}

.ie .title-link[data-anim] {
	text-decoration: none;
}
.ie .title-link[data-anim]:hover {
	text-decoration: underline;
}

a[data-anim] {
	display: block;
	overflow: hidden;
}
a[data-anim] img {
	transition: transform 0.4s ease;
}
a[data-anim]:focus img,
a[data-anim]:hover img {
	transform: scale(1.065) rotate(0.01deg);
}

.badge {
	display: inline-flex;
	height: 24px;
	padding: 5px 10px;
	align-items: center;
	border-radius: 2px;
}
.badge .badge-text {
	font-size: 14px;
	font-weight: bold;
	color: #111 !important;
}
.badge-white {
	background-color: #f3f5f7;
}

.creator-list {
	display: inline-flex;
	flex-wrap: wrap;
	margin: -20px -20px;
	margin: auto;
	justify-content: center;
}
.creator-item {
	flex: 0 0 290px;
	padding: 20px 20px;
}

.creator-card {
	position: relative;
	width: 70vw;
	margin: auto;
}
.creator-card .creator-image {
	overflow: hidden;
	position: relative;
	border-radius: 2px;
	height: 0;
	padding-top: 120%;
}
.creator-card .creator-image img {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.creator-card .creator-image .icon-authentication {
	position: absolute;
	width: 65px;
	height: 65px;
	z-index: 1;
	background-size: 65px auto;
}
.creator-card .creator-image .icon-authentication[data-position='insert'] {
	right: 10px;
	top: 10px;
}
.creator-card .creator-content {
	position: relative;
	padding-top: 20px;
}
.creator-card .creator-title {
	position: relative;
	margin-bottom: 10px;
}
.creator-card .creator-title .text {
	font-size: 20px;
	font-weight: bold;
}
.creator-card .creator-hashtag {
	padding-top: 10px;
	line-height: 1;
	color: #555;
	font-size: 13px;
}

.list-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;
}
.list-header .title {
	font-weight: 900;
	font-size: 30px;
}
.list-header .filter-selector {
	width: 100%;
}

.post-list-wrapper {
	padding-top: 20px;
	padding-bottom: 40px;
}
.post-card-list {
	position: relative;
	margin: -10px -20px;
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
}
.post-card-list ~ .page-buttons {
	padding-bottom: 60px;
}
.post-card-list ~ .page-buttons .btn {
	width: 100%;
}
.post-card-item {
	flex: 0 0 100%;
	padding: 10px 20px;
}

.post-card-list-issue {
	position: relative;
	margin: -10px -20px;
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
}
.post-card-item-issue {
	flex: 0 0 100%;
	padding: 10px 20px;
}

.playground-title {
	display: flex;
	justify-content: center;
	padding: 16px;
	margin-top: 10px;
}
.playground-title .playground-title-desc {
	word-break: keep-all;
	padding: 10px;
	font-weight: 700;
	border-radius: 2px;
	background-color: white;
	font-size: 1.1rem;
	color: #111;
	text-align: center;
	line-height: 1.5;
}

.post-card {
	position: relative;
}
.post-card .post-image {
	overflow: hidden;
	position: relative;
	height: 0;
	padding-top: 100%;
	border: 1px solid #ebebeb;
	margin-top: -1px;
}
.post-card .post-image img {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.post-card .post-content {
	padding: 20px 20px;
	border: 1px solid #ebebeb;
	background-color: #fff;
}
.post-card .post-category {
	margin-bottom: 10px;
}
.post-card .post-title {
}
.post-card .post-title .title-link {
}
.post-card .post-title .title {
	font-weight: bold;
	font-size: 25px;
	line-height: 32px;
}
.post-card .post-subtext {
	padding-top: 20px;
}
.post-card .post-subtext .text {
	font-weight: bold;
	font-size: 13px;
	color: #999;
}

.post-layer-card {
	overflow: hidden;
	position: relative;
}
.post-layer-card .card-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 11;
}
.post-layer-card .card-link:focus ~ .card-image img,
.post-layer-card .card-link:hover ~ .card-image img {
	transform: scale(1.065) rotate(0.01deg);
}
.post-layer-card .card-link:focus ~ * .title,
.post-layer-card .card-link:hover ~ * .title {
	text-decoration-color: #fff;
	-webkit-text-decoration-color: #fff;
}
.post-layer-card .card-inner {
	position: relative;
	height: 0;
	// padding-top: 148%;
	padding-top: 100%;
	z-index: 10;
	background: url(../img/notice/deem_image.png) no-repeat 0 100%;
	background-size: 100% auto;
}
.post-layer-card .issue {
	position: absolute;
	right: 30px;
	top: 30px;
	color: #fff;
	text-align: center;
}
.post-layer-card .issue-title {
	font-size: 11px;
	font-weight: 900;
	text-transform: uppercase;
}
.post-layer-card .issue-num {
	font-size: 30px;
	font-weight: 900;
	margin-top: 1px;
}
.post-layer-card .card-image {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.post-layer-card .card-image img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.4s ease;
	object-position: 50%;
}
.post-layer-card .card-content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 30px;
}
.post-layer-card .card-content * {
	color: #fff;
}
.post-layer-card .card-content .tag {
	font-size: 13px;
	font-weight: bold;
	margin-top: 10px;
}
.post-layer-card .card-content .view {
	display: flex;
	align-items: center;
	margin-top: 20px;
}
.post-layer-card .card-content .view .icon-view {
	width: 20px;
	height: 20px;
	background-image: url(../img/main/view_icon.svg);
	margin-right: 5px;
	background-size: 20px 20px;
}
.post-layer-card .card-content .view .text {
	font-weight: bold;
	font-size: 13px;
	letter-spacing: 0;
}
.post-layer-card .card-content .subtitle {
	font-weight: bold;
	margin-bottom: 10px;
	font-size: 13px;
}
.post-layer-card .card-content .title {
	font-size: 25px;
	font-weight: 900;
	line-height: 30px;
}

.post-layer-card-issue {
	overflow: hidden;
	position: relative;
}
.post-layer-card-issue .card-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 11;
}

.post-view-wrapper {
	margin: auto;
}
.post-view-header {
	position: relative;
	padding: 40px 0;
	text-align: center;
}
.post-view-header .header-category {
	margin-bottom: 10px;
	text-align: center;
}
.post-view-header .header-issue {
	margin-bottom: 15px;
}
.post-view-header .header-issue .issue-text {
	font-size: 14px;
	font-weight: 900;
	color: #ff7300;
}
.post-view-header .header-title {
	font-size: 25px;
	line-height: 32px;
	font-weight: bold;
	text-align: center;
}
.post-view-header .header-tags {
	padding-top: 10px;
	font-weight: bold;
	color: #999;
	font-size: 14px;
	text-align: center;
}
.post-view-body {
}
.post-view-body {
}
.post-view-body img {
	max-width: 100%;
}
.post-view-body p {
	color: #555;
	line-height: 22px;
}
.post-view-body p ~ img {
	margin-top: 30px;
}
.post-view-body p ~ iframe {
	margin-top: 30px;
}
.post-view-body .post-title {
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	color: #222;
}
.post-view-body .post-cover {
	margin: 0 -20px;
}
.post-view-body .post-video {
	position: relative;
	height: 0;
	padding-top: 56.2%;
	margin-bottom: 20px;
}
.post-view-body .post-video iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.page-buttons {
	position: relative;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	&[data-align='center'] {
		justify-content: center;
		.w-120 {
			width: 120px;
			padding: 0;
		}
		.btn-lg ~ .btn-lg {
			margin-left: 10px;
		}
	}
	&[data-align='space-between'] {
		justify-content: space-between;
	}
	&[data-direction='column'] {
		flex-direction: column;
		> .btn {
			width: 100%;
		}
		> .btn ~ .btn {
			margin-left: 0;
			margin-top: 10px;
		}
		.button-row ~ .button-row {
			margin-left: 0;
			margin-top: 10px;
		}
		.button-row .btn {
			margin-top: 0;
			width: 100%;
		}
		.button-row {
			display: flex;
		}
	}
}

.page-nav {
	background-color: #fff;
	max-width: 100%;
	overflow-y: hidden;
	height: 60px;
	text-align: center;
}
.page-nav {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.page-nav::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}
.page-nav .nav-list {
	display: inline-flex;
	padding-left: 20px !important;
	padding-right: 20px !important;
	width: auto;
	background: #fafafa;
}
.page-nav .nav-item {
	position: relative;
	height: 60px;
	padding-top: 21px;
	padding-bottom: 21px;
}
.page-nav .nav-item:not(:first-child) {
	margin-left: 30px;
}
.page-nav .nav-item:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: transparent;
	transition: background-color 0.4s;
}
.page-nav .nav-item.is-selected .nav-text {
	color: #ff7300;
}
.page-nav .nav-item.is-selected:before {
	background-color: #ff7300;
}
.page-nav .nav-item:not(.is-selected):hover:before,
.page-nav .nav-item:not(.is-selected):focus:before {
	background-color: #999;
}
.page-nav .nav-text {
	font-size: 16px;
	font-weight: 900;
	color: #999;
	white-space: nowrap;
}

.icon-authentication {
	background-image: url(../img/common/authentication.svg);
}

.badge {
	display: inline-flex;
	height: 24px;
	align-items: center;
	padding: 0 10px;
	border-radius: 2px;
	background-color: #f3f5f7;
}
.badge-text {
	font-weight: bold;
	font-size: 13px;
}

.ie .post-card-item {
	flex: 0 0 406px;
}

/* toggle switch checkbox */
.toggle-switch {
	display: inline-block;
	position: relative;
	width: 54px;
	font-size: 80%;
}
.toggle-switch-container {
	position: relative;
	display: flex;
	height: 30px;
	align-items: center;
}
.toggle-switch-container input[type='checkbox'] {
	position: absolute;
	top: 5px;
	left: 10px;
	width: 0.01em;
	height: 0.01em;
	overflow: hidden;
	margin: -1px;
	clip: ret(0, 0, 0, 0);
}
.toggle-switch-container input[type='checkbox']:checked ~ .toggle-switch .toggle-switch-label {
	background-color: #ff7300;
}
.toggle-switch-container input[type='checkbox']:checked ~ .toggle-switch .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.toggle-switch-container input[type='checkbox']:checked ~ .toggle-switch .toggle-switch-label .toggle-switch-switch {
	right: 3px;
	background-color: #ffffff;
}
.toggle-switch-container input[type='checkbox']:disabled ~ .toggle-switch {
	opacity: 0.5;
}
.toggle-switch-container input[type='checkbox']:focus ~ .toggle-switch {
	outline: 0;
}
/* .toggle-switch-container input[type='checkbox']:focus ~ .toggle-switch .toggle-switch-inner:before,
.toggle-switch-container input[type='checkbox']:focus ~ .toggle-switch .toggle-switch-inner:after  {
    border-color: black;
    border-width: 1px;
} */
.toggle-switch-container label {
	display: inline-block;
	position: absolute;
	z-index: 1;
	cursor: pointer;
	width: 100%;
	height: 30px;
	text-align: left;
	line-height: 30px;
	font-weight: 200;
	-moz-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.toggle-switch-label {
	display: block;
	overflow: hidden;
	height: 30px;
	cursor: pointer;
	background-color: #f0f0f0;
	color: hsl(0, 0%, 80%);
	text-align: right;
	border-radius: 30px;
	box-shadow: 0px 1px 0px #ffffff, inset 0px 2px 4px rgba(0, 0, 0, 0.04), inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.06), inset 0px 0px 8px rgba(0, 0, 0, 0.02);
	transition: background 0.15s ease-in-out;
}
.toggle-switch-inner {
	width: 200%;
	margin-left: -100%;
	transition: margin 0.15s ease-in-out;
}

/* .toggle-switch-inner:before,
.toggle-switch-inner:after {
    float: left;
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 16px;
}
.toggle-switch-inner:before {
    content: '';
    padding-left: 8px;
    background-color: black;
    border: 1px solid black;
    color: hsl(0, 0%, 100%);
}
.toggle-switch-inner:after {
    content: '';
    padding-right: 6px;
} */
.toggle-switch-switch {
	position: absolute;
	top: 3px;
	right: 27px;
	bottom: 0;
	width: 24px;
	height: 24px;
	margin: 0;
	border: 1px solid #fff;
	border-radius: 50%;
	background: #fff;
	transition: all 0.15s ease-in-out;
}
.toggle-switch-label__after {
	margin-left: 10px;
	font-size: 14px;
}
.toggle-switch-wrap.color-orange .toggle-switch-label__after {
	color: #ff7300;
}

/* live-card */
.live-card {
	position: relative;
	padding-top: 34px;
	max-width: 280px;
}
.live-card .live-video-link:hover .live-video img {
	transform: scale(1.06);
}
.live-card .live-video-link:hover .live-video .video-content-title .text {
	text-decoration: underline;
}
.live-card .live-video-link:hover .live-title .text {
	text-decoration: underline;
}
.live-card .live-video-link {
	display: block;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	border: 1px solid #eee;
}

.live-card .live-video {
	position: relative;
}
.live-card .live-video .video-watch,
.live-card .live-video .video-date {
	z-index: 1;
	position: absolute;
	top: clamp(10px, 1vw, 10px);
	left: clamp(10px, 1vw, 10px);
	display: inline-flex;
	height: 27px;
	padding: 12px;
	border-radius: 15px;
	background-color: rgba(0, 0, 0, 0.35);
	padding: 0 10px;
	align-items: center;
	color: #fff;
	line-height: 1.1;
	align-items: center;
	justify-content: center;
}
.live-card .live-video .video-date {
	flex-direction: column;
	height: 70px;
}
.live-card .video-date-day {
	display: inline-flex;
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 700;
	align-items: center;
}
.live-card .video-date-day__week {
	font-size: 21px;
}
.live-card .video-date-time {
	font-size: 16px;
	font-weight: 400;
}
.live-card .live-video .video-watch .icon-watch {
	width: 20px;
	height: 12px;
	margin-right: 10px;
	background: url(~@/assets/img/common/icon_watch.svg) no-repeat 0 0;
	background-size: contain;
}
.live-card .live-video .video-watch .text {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
}
.live-card .live-video .video-player {
	overflow: hidden;
	position: relative;
	width: 100%;
	background-image: url(~@/assets/img/common/broadcast_noimg.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-color: #f4f4f4;
	background-size: cover;
}
.live-card .live-video .video-player video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.live-card .live-video .video-player iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.live-card .live-video .video-player img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
	border-radius: 15px;
	transform: scale(1.01);
	transition: transform 0.6s;
}
.live-card .live-video .video-player:hover img {
	transform: scale(1.06);
}
.video-badge {
	z-index: 2;
	position: absolute;
	top: 0;
	right: -15px;
	width: 53px;
	height: 36px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.video-badge-live {
	background-image: url(../img/common/badge_live.svg);
}
.video-badge-vod {
	background-image: url(../img/common/badge_vod.svg);
}
.video-badge-comingsoon {
	background-image: url(../img/common/badge_coming.svg);
}

.live-card .video-airtime {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding-bottom: 80px;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.live-card .video-airtime .airtime-date {
	display: block;
	font-size: 15px;
	color: #fff;
	font-weight: 700;
}
.live-card .video-airtime .airtime-time {
	display: block;
	font-size: clamp(40px, 4.7vw, 50px);
	margin-top: 10px;
	color: #fff;
	font-weight: 700;
}
.live-card .video-airtime .airtime-notify {
	display: flex;
	align-items: center;
	margin-top: 10px;
	padding: 10px;
	height: 44px;
	border-radius: 0.3125rem;
	background-color: #ffffff;
	transition: background-color 0.4s;
}
.live-card .video-airtime .airtime-notify:not(.is-active):hover {
	background-color: #ff7300;
}
.live-card .video-airtime .airtime-notify:not(.is-active):hover .text {
	color: #fff;
}
.live-card .video-airtime .airtime-notify:not(.is-active):hover .icon-airtime {
	background-image: url(~@/assets/img/common/icon_live.svg);
}
.live-card .airtime-notify .icon-airtime {
	width: 16px;
	height: 20px;
	margin-right: 5px;
	background: url(~@/assets/img/common/icon_live.svg) no-repeat;
}
.live-card .airtime-notify .text {
	color: var(--text-muted);
	transition: color 0.25s;
}
.live-card .airtime-notify.is-active {
	background-color: #ff7300;
}
.live-card .airtime-notify.is-active .text {
	color: #ffffff;
}
.live-card .airtime-notify.is-active .icon-airtime {
	background-image: url(~@/assets/img/common/icon_live.svg);
}
.live-card .video-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #f4f4f4;
	padding: 15px;
	display: flex;
	height: 120px;
	justify-content: center;
	align-items: flex-start;
	min-width: 0;
	flex-direction: column;
}
.live-card .video-text-link {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: top;
}
.live-card .video-text-link:hover .text {
	text-decoration: underline;
}
.live-card .video-content-title,
.live-card .video-content-desc {
	text-align: left;
	color: #645f5b;
	font-size: 14px;
}
.live-card .video-content-title {
	overflow: hidden;
	margin-bottom: 10px;
	line-height: 1.3em;
	max-height: 2.6em;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.live-card .video-content-desc {
	overflow: hidden;
	line-height: 1.1em;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.live-card .video-content-group {
	overflow: hidden;
	display: flex;
	width: 100%;
	flex-direction: column;
}
.live-card .video-content-image {
	flex: 0 0 clamp(40px, 4vw, 50px);
	width: clamp(40px, 4vw, 50px);
	height: clamp(40px, 4vw, 50px);
	border-radius: 5px;
	overflow: hidden;
}
.live-card .video-content-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.live-card .live-title {
	margin-top: clamp(15px, 1.57vw, 20px);
	font-weight: 700;
}
.live-card .live-title .text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	line-height: 1.5em;
	font-size: clamp(13px, 1.375vw, 16px);
	text-align: left;
	font-weight: 700;
}
.live-card .live-title-link {
}
.live-card .live-channel {
	padding-top: 10px;
}
.live-card .live-channel .text {
	font-size: 13px;
	color: var(--text-muted);
	font-weight: 700;
}
.live-card .live-channel .icon-time {
	width: 9px;
	height: 11px;
}
.live-card .live-bottom {
	display: flex;
	align-items: center;
	padding-top: clamp(5px, 1.18vw, 15px);
}
.live-card .live-bottom .text {
	font-size: clamp(11px, 1.3vw, 13px);
	color: var(--text-muted);
	font-weight: 700;
}
.live-card .live-bottom .icon-duration {
	position: relative;
	top: -1px;
	width: 9px;
	height: 11px;
	margin-right: 5px;
}
.live-card .live-bottom .bottom-duration {
	margin-left: 5px;
	white-space: nowrap;
}
.live-card .live-airtime {
	padding-top: 10px;
}
.live-card .live-airtime .text {
	font-size: 13px;
	color: #f14e6d;
	font-weight: 700;
}
.live-card.is-onair {
}
.live-card.is-onair .bottom-duration {
	display: none;
}
.live-card.is-end {
}
.live-card.is-end .video-onair {
	display: none;
}
.live-card.is-end .video-badge {
	display: none;
}
.live-card .video-content-reflink {
	position: relative;
	z-index: 5;
	width: 100%;
	margin-top: 10px;
	text-align: center;
}
.live-card .video-content-reflink .button {
	display: inline-flex;
	width: 120px;
	height: 30px;
	background-color: #645f5b;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #fff;
}
.live-card .video-content-reflink .button:hover,
.live-card .video-content-reflink .button:focus {
	background-color: #ff7300;
}

.list-btn-cont .btn-lg {
	margin-bottom: 10px;
}
