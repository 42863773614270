#sme-campaign {background-color:#f3f5f7}
#sme-campaign .page-header-title {text-align:center}

#sme-campaign-view {}
#sme-campaign-view .post-view-wrapper {width:100%;}
#sme-campaign-view .post-view-body {}
#sme-campaign-view .post-editor {flex:0 0 800px;}
#sme-campaign-view .post-campaing {position: -webkit-sticky;position:sticky;align-self:flex-start;margin-bottom:40px;}
#sme-campaign-view .post-campaing-title {font-size:20px;line-height:30px;letter-spacing:0;font-weight:bold;margin-bottom:20px;}
#sme-campaign-view .post-campaing-content {}
#sme-campaign-view .post-campaing-content .content-row {display:flex;font-size:16px;line-height:22px;}
#sme-campaign-view .post-campaing-content .content-row ~ .content-row {margin-top:10px;}
#sme-campaign-view .post-campaing-content .content-title {flex:0 0 80px; color:#555}
#sme-campaign-view .post-campaing-content .content-text {color:#111;font-weight:bold}
#sme-campaign-view .post-campaing-buttons {position:fixed;left:0;bottom:0;width:100%;}
#sme-campaign-view .post-campaing-buttons.is-deactive {visibility: hidden;}
#sme-campaign-view .post-campaing-buttons .btn {height:80px;width:100%;}
#sme-campaign-view .post-campaing-buttons .btn .text {font-size:16px;font-weight:900}
#sme-campaign-view .post-view-header {text-align:left;padding-bottom:40px;}
#sme-campaign-view .post-view-header .header-tags {padding-top:20px;}
#sme-campaign-view .post-view-body {}
#sme-campaign-view .post-view-body img {width:100%}
#sme-campaign-view .post-view-cover {position:relative;margin:0 -20px;height:0;padding-top:100%;margin-bottom:30px;}
#sme-campaign-view .post-view-cover img {position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover;}

/* 220608 디자인 변경 */
.badge_wrap{text-align: left;}
.badge_wrap .badge-text{display:inline-block;margin: 0 10px 12px 0;padding: 5px 10px; background: #fbe6d9; border: 1px solid #ff7300;border-radius: 20px; font-size:14px;color:#ff7300 !important;font-weight:bold}
.post-aside.apply_wrap {width: 100%; position:fixed;bottom:0px;left: 50%;background: #eff6f9;transform: translate(-50%,0);box-shadow: 0px -3px 7px rgba(0,0,0,.2);}
.apply_wrap .post-aside-wrapper {width: 100%;padding:10px;}
.apply_wrap .post-aside-wrapper .post-aside-content{width: 100%; margin-bottom: 10px;}
.apply_wrap .post-aside-wrapper .post-aside-title .content-text{font-size:14px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;-webkit-box-orient: vertical;}
.apply_wrap .post-aside-wrapper .content-title {margin-bottom: 5px;color: #111; font-size: 16px;font-weight:bold}
.apply_wrap .post-aside-buttons {width: 100%;}
.apply_wrap .post-aside-buttons button{height: 45px !important; border-radius: 20px;}