.page-nav {
	border-bottom: 1px solid #ebebeb;
}
.page-header .page-header-title .title {
	font-size: 25px;
	line-height: 32px;
}
.page-header-title .mypage-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}
.page-header-title .mypage-header .header-profile {
	position: relative;
	width: 64px;
	height: 64px;
	overflow: hidden;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.mypage-section {
	padding-top: 30px;
}
.mypage-section ~ .mypage-section {
	border-top: 1px solid #ebebeb;
}
.mypage-section .section-header {
	margin-bottom: 30px;
}
.mypage-section .section-title {
	margin-bottom: 20px;
	font-size: 20px;
	font-weight: 900;
}
.mypage-section .section-subtext {
	color: #555;
	line-height: 22px;
}

#sme-mypage .mypage-section {
}
#sme-mypage .mypage-section ~ .mypage-section {
	margin-top: 40px;
}
#sme-mypage .mypage-section .form-row {
	position: relative;
}
#sme-mypage .mypage-section .form-cellth {
}
#sme-mypage .mypage-section .form-buttons {
	text-align: center;
	padding-top: 40px;
}
#sme-mypage .mypage-section .btn {
	width: 100%;
}
#sme-mypage .page-body {
	padding-bottom: 60px;
}

#sme-mypage .connect-p {
	padding-top: 30px;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.2;
	padding-bottom: 20px;
	color: #999;
}

.mypage-dm-list {
	position: relative;
	padding-top: 10px;
}
.mypage-dm-item {
	padding: 20px 0;
}
.mypage-dm-item ~ .mypage-dm-item {
	border-top: 1px solid #ebebeb;
}
.mypage-dm-card {
	display: flex;
	align-items: center;
	min-width: 0;
}
.mypage-dm-card .card-image {
	overflow: hidden;
	border-radius: 5px;
	flex: 0 0 50px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.mypage-dm-card .card-image .image-link {
	display: flex;
	width: 50px;
	height: 50px;
	position: relative;
	align-items: center;
	justify-content: center;
}
.mypage-dm-card .card-image img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.mypage-dm-card .card-content {
	position: relative;
	padding-left: 20px;
	flex: auto;
}
.mypage-dm-card .card-content .sender-link {
	display: inline-block;
}
.mypage-dm-card .card-content .sender-link .text:not(.sender) {
	font-weight: bold;
	color: #999;
}
.mypage-dm-card .card-content .sender-link .text.sender {
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: bold;
}
.mypage-dm-card .card-content .sender-link .text-truncate {
	font-size: 13px;
	max-width: calc(100vw - 200px);
	display: inline-block;
}
.mypage-dm-card .card-content .dm-content {
	font-weight: bold;
	color: #999;
}
.mypage-dm-card .card-content .dm-date {
	position: absolute;
	right: 0;
	top: 0;
	font-weight: bold;
	color: #999;
	font-size: 13px;
}

.mypage-studio-list {
	position: relative;
	padding-top: 10px;
}
.mypage-studio-item {
	padding: 20px 0;
}
.mypage-studio-item ~ .mypage-studio-item {
	border-top: 1px solid #ebebeb;
}
.mypage-studio-card {
	position: relative;
	display: flex;
	align-items: center;
	min-width: 0;
}
.mypage-studio-card .card-image {
	flex: 0 0 100px;
}
.mypage-studio-card .card-image .image-link {
}
.mypage-studio-card .card-image img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}
.mypage-studio-card .card-content {
	position: relative;
	padding-left: 20px;
	flex: auto;
	height: 100px;
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-bottom: 10px;
}
.mypage-studio-card .card-content .title-link {
	display: inline-block;
}
.mypage-studio-card .card-content .title-link .title {
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 18px;
	font-weight: bold;
	flex: 0 0 auto;
}
.mypage-studio-card .card-content .title-link .text-truncate {
	max-width: 1000px;
	display: inline-block;
}
.mypage-studio-card .card-content .studio-text {
	color: #999;
	font-weight: bold;
	font-size: 13px;
	flex: 1 1 auto;
}
.mypage-studio-card .card-content .status-text {
	font-weight: 900;
	font-size: 13px;
}
.mypage-studio-card .card-content .btn {
	height: 30px;
	width: 68px;
	min-width: auto;
	margin-bottom: -10px;
}
.mypage-studio-card .card-content .btn .text {
	font-size: 13px;
	font-weight: 900;
}

.post-badge {
	position: absolute;
	right: 15px;
	bottom: 14px;
}
.post-badge .icon-selection {
	width: 50px;
	height: 50px;
	background-image: url(../img/mypage/icon_selection.svg);
	background-size: 50px;
}
.post-badge .icon-proceeding {
	width: 50px;
	height: 50px;
	background-image: url(../img/mypage/icon_proceeding.svg);
	background-size: 50px;
}
.post-badge .icon-unselection {
	width: 50px;
	height: 50px;
	background-image: url(../img/mypage/icon_unselection.svg);
	background-size: 50px;
}

.segment-controller {
	position: relative;
	margin-bottom: 25px;
}
.segment-controller .segment-list {
	width: 242px;
	padding: 5px;
	background-color: #ebebeb;
	display: flex;
	margin: auto;
}
.segment-controller .segment-item {
	flex: 1;
}
.segment-controller .segment-link {
	display: block;
	height: 40px;
	line-height: 40px;
	text-align: center;
}
.segment-controller .segment-text {
	font-size: 13px;
	font-weight: 900;
	color: #555;
}
.segment-controller .is-selected .segment-link {
	background-color: #fff;
}
.segment-controller .is-selected .segment-text {
	color: #111;
}

#sme-mypage-campaign {
}
#sme-mypage-campaign .page-body {
	background-color: #f3f5f7;
	padding-bottom: 60px;
}
#sme-mypage-campaign .segment-controller {
	padding-top: 30px;
	margin-bottom: 30px;
}
#sme-mypage-campaign .segment-pane {
	display: none;
}
#sme-mypage-campaign .segment-pane.is-visible {
	display: block;
}
#sme-mypage-campaign .post-card-list {
	padding-top: 0;
}

.board-list-container {
	position: relative;
	padding-top: 50px;
}
.board-list {
}
.board-list-item {
	padding: 20px 0;
}
.board-list-item:not(:first-child) {
	border-top: 1px solid #ebebeb;
}
.board-list-row {
	display: flex;
	justify-content: space-between;
	height: 121px;
	min-width: 0;
}
.board-list-content {
	flex: 0 0 calc(100% - 100px);
}
.board-list-content .badge {
	margin-bottom: 10px;
}
.board-list-content .title-link {
	display: inline-block;
	max-width: 1100px;
	font-size: 25px;
	line-height: 32px;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.board-list-content .content-date {
	margin-top: 13px;
}
.board-list-content .content-date .text {
	font-size: 1rem;
	line-height: 18px;
	font-weight: bold;
	color: #999;
}
.board-list-actions {
	padding-top: 20px;
	flex: 0 0 100px;
}

.board-view-content {
}
.board-view-content {
	text-align: left;
}
.board-view-content .view-content-image {
}
.board-view-content .view-content-title {
	font-size: 20px;
	line-height: 26px;
	font-weight: 900;
	margin-bottom: 20px;
}
.board-view-content .view-content-description {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}

#sme-mypage-apply .page-body {
	background-color: #f3f5f7;
	padding-bottom: 60px;
}
#sme-mypage-apply .myapply-list-container .myapply-header {
	padding-top: 40px;
	padding-bottom: 30px;
}
#sme-mypage-apply .myapply-list-container .myapply-header .title {
	font-size: 20px;
	font-weight: 900;
	line-height: 32px;
}
#sme-mypage-apply .myapply-list-container .myapply-header em {
	font-size: 20px;
	font-weight: 900;
	line-height: 32px;
}
#sme-mypage-apply .myapply-list {
}
#sme-mypage-apply .myapply-item {
}
#sme-mypage-apply .myapply-item ~ .myapply-item {
	margin-top: 10px;
}
#sme-mypage-apply .myapply-card {
	border: 1px solid #ebebeb;
	background-color: #fff;
}
#sme-mypage-apply .myapply-content {
	position: relative;
	padding: 30px 20px;
	height: 160px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
#sme-mypage-apply .myapply-content .department {
	font-size: 13px;
	font-weight: bold;
	color: #999;
	margin-bottom: 8px;
	flex-shrink: 0;
}
#sme-mypage-apply .myapply-content .title {
	flex: 1 1 auto;
	max-width: 320px;
}
#sme-mypage-apply .myapply-content .title-link {
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
}
#sme-mypage-apply .myapply-content .icon-link-arrow {
	margin-left: 10px;
	width: 16px;
	height: 16px;
	background-image: url(../img/mypage/myapply_linkarrow.svg);
	background-size: 16px;
	position: relative;
	top: 4px;
}
#sme-mypage-apply .myapply-content .manager {
	font-size: 13px;
	font-weight: bold;
	color: #ff7300;
}
#sme-mypage-apply .myapply-proceeding {
	display: flex;
	background-color: #fafafa;
}
#sme-mypage-apply .proceeding-item {
	position: relative;
	width: 33.3%;
	height: 80px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: #fafafa;
}
#sme-mypage-apply .proceeding-item * {
	color: #999;
}
#sme-mypage-apply .proceeding-item.is-proceeding {
	background-color: #015aa8 !important;
}
#sme-mypage-apply .proceeding-item.is-proceeding * {
	color: #fff;
}
#sme-mypage-apply .proceeding-item.is-proceeding:after {
	z-index: 1;
	content: '';
	width: 0;
	position: absolute;
	right: -5px;
	top: 19px;
	height: 0;
	border-left: 5px solid #015aa8;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
}
#sme-mypage-apply .proceeding-item .number {
	display: block;
	font-size: 11px;
	font-weight: 900;
	line-height: 12px;
	margin-bottom: 10px;
}
#sme-mypage-apply .proceeding-item .title {
	flex: 1 1 auto;
	font-size: 13px;
	font-weight: 900;
	line-height: 15px;
}
#sme-mypage-apply .proceeding-item .state {
	display: block;
	font-weight: 900;
}
#sme-mypage-apply .proceeding-item:nth-child(2) {
	background-color: #ebebeb;
}
#sme-mypage-apply .proceeding-item.is-proceeding ~ .proceeding-item:nth-of-type(odd) {
	background-color: #ebebeb;
}
#sme-mypage-apply .proceeding-item.is-proceeding ~ .proceeding-item:nth-of-type(even) {
	background-color: #fafafa;
}
#sme-mypage-apply .proceeding-item:last-child.is-proceeding {
	background-color: #ff7300 !important;
}
#sme-mypage-apply .proceeding-item:last-child.is-proceeding:after {
	display: none;
}
