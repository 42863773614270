.card-list {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	@include mobile {
		gap: 20px;
	}
	&-wrapper {
		+ .page-navigation {
			margin-top: 30px;
			@include mobile {
				margin-top: 5px;
			}
		}
		.page-header {
			padding-top: 60px;
			padding-bottom: 24px;
			@include mobile {
				padding-top: 30px;
			}
			&-title {
				display: flex;
				font-size: 24px;
				font-weight: 700;
				text-align: left;
				align-items: center;
				@include word-wrap;
			}
		}
		.list {
			&-header {
				margin-bottom: 10px;
				border: none;
				@include mobile {
					display: block;
					margin-bottom: 0;
					padding: 0 0 12px;
				}
				&-title {
					font-size: 21px;
					font-weight: 700;
					@include mobile {
						margin-bottom: 12px;
					}
				}
			}
			&-body {
			}
		}
	}
	.card-item {
		width: calc(25% - 23px);
		@include mobile {
			width: calc(50% - 10px);
		}
		&__link {
			&:hover,
			&:focus {
				.card-item {
					&__title {
						text-decoration: underline;
					}
					&__image img {
						transform: scale(1.12);
					}
				}
			}
		}
		&__image {
			display: flex;
			position: relative;
			height: 0;
			padding-bottom: 100%;
			border: 1px solid #eee;
			border-radius: 15px;
			overflow: hidden;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: scale(1.01);
				@include transition(transform, 0.3s);
			}
		}
		&__title {
			margin-top: 16px;
			font-size: 18px;
			font-weight: 700;
			line-height: 1.3;
			@include text-ellipsis-2;
		}
	}
}
.page-header-banner {
	display: flex;
	height: 267px;
	background-color: #eee;
	picture {
		display: flex;
		position: relative;
		width: 100%;
		height: 100%;
		img {
			position: absolute;
			width: auto;
			height: 100%;
			top: 0;
			left: 50%;
			object-fit: cover;
			transform: translateX(-50%) scale(1.01);
		}
	}
	@include mobile {
		height: auto;
		picture {
			img {
				position: static;
				width: 100%;
				max-width: none;
				height: auto;
				transform: none;
			}
		}
	}
}
.page-nav {
	&.style-orange {
		max-width: 100%;
		height: 60px;
		margin-bottom: 60px;
		border: none;
		background-color: #eee;
		overflow-y: hidden;
		overflow-x: auto;
		text-align: center;
		@include mobile {
			margin-bottom: 30px;
			scrollbar-width: none;
		}
		.nav {
			&-list {
				display: inline-flex;
				background-color: transparent;
				justify-content: flex-start;
				gap: 38px;
				@include mobile {
					padding-left: 0 !important;
					padding-right: 20px !important;
				}
			}
			&-item {
				display: inline-flex;
				height: 60px;
				padding: 0;
				align-items: center;
				&:not(:first-child) {
					margin: 0;
				}
				&::before {
					display: none;
				}
				a {
					display: flex;
					height: 100%;
					align-items: center;
					.nav-text {
						@include transition(all, 0.3s);
					}
					&::before {
						content: '';
						display: block;
						position: absolute;
						width: 0;
						height: 3px;
						left: 50%;
						bottom: 0;
						opacity: 0;
						transform: translateX(-50%);
						@include transition(all, 0.3s);
					}
				}
				&.is-active {
					a {
						.nav-text {
							color: #ff7300;
						}
						&::before {
							width: 100%;
							background-color: #ff7300;
							opacity: 1;
						}
					}
				}

				&:not(.is-active) {
					a {
						&:hover,
						&:focus {
							&::before {
								width: 100%;
								background-color: #757575;
								opacity: 1;
							}
						}
					}
				}
			}
			&-link {
			}
			&-text {
				letter-spacing: -0.5px;
				font-size: 19px;
				font-weight: 700;
				color: #757575;
				white-space: nowrap;
			}
		}
	}
}
.sort-list {
	display: flex;
	align-items: center;
	gap: 10px;
	.sort-item {
		button {
			font-size: 16px;
			font-weight: 700;
			color: #bdbdbd;
		}
		&.is-active {
			button {
				color: #424242;
			}
		}
		&:not(:first-child)::before {
			content: '';
			display: inline-block;
			width: 1px;
			height: 12px;
			margin-right: 10px;
			border-left: 1px solid #ccc;
		}
	}
}

.home-section {
	margin-bottom: 60px;
	&:last-child {
		margin-bottom: 0;
		@include mobile {
			margin-bottom: 30px;
		}
	}
	@include mobile {
		margin-bottom: 30px;
	}
	&-header {
		display: flex;
		margin-bottom: 24px;
		align-items: flex-end;
		justify-content: space-between;
		gap: 30px;
		@include mobile {
			margin-bottom: 8px;
		}
	}
	&-title {
		font-size: 24px;
		font-weight: 700;
		@include word-wrap;
	}
	@include mobile {
		.card-item {
			display: none;
			&:first-child {
				display: block;
			}
			&:nth-child(2) {
				display: block;
			}
		}
	}
}

.link-more-icon {
	display: inline-flex;
	white-space: nowrap;
	align-items: center;
	gap: 4px;
	.text {
		font-size: 14px;
	}
	&::after {
		content: '';
		display: inline-block;
		position: relative;
		width: 8px;
		height: 13px;
		background-image: url('~@/assets/img/common/icon_linkmore.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.detail-section {
	.page-header {
		padding-top: 60px;
		padding-bottom: 0;
		@include mobile {
			padding-top: 30px;
		}
		&-title {
			margin-bottom: 12px;
			padding-bottom: 12px;
			border-bottom: 1px solid #e0e0e0;
			text-align: left;
			font-size: 24px;
			font-weight: 700;
			line-height: 1.2;
		}
	}
	&-header {
		&__sub {
			margin-bottom: 12px;
			font-size: 16px;
			font-weight: 400;
		}
		&__title {
			margin-bottom: 20px;
			font-size: 21px;
			font-weight: 700;
		}
	}
	&-content {
		margin-top: 20px;
		font-size: 14px;
		line-height: 1.5;
		padding: 15px;
		border-radius: 15px;
		background-color: #e6e6e6;
	}
	&-footer {
		margin-top: 60px;
		text-align: center;
		.button-default {
			min-width: 110px;
			@include mobile {
				min-width: 90px;
			}
		}
		@include mobile {
			margin-top: 30px;
		}
	}
	&-smblnb {
		.button-support-smb {
			@include tablet {
				top: 243px;
			}
			@include mobile {
				top: 213px;
			}
		}
	}
}

.support-smb {
	&-top {
		margin-top: 60px;
		@include mobile {
			margin-top: 30px;
		}
		&__title {
			font-size: 24px;
			font-weight: 700;
			line-height: 1.25;
			margin-bottom: 24px;
		}
		&__dl {
			display: block;
			margin-bottom: 24px;
			dt {
				display: block;
				margin-bottom: 8px;
				font-size: 21px;
				font-weight: 700;
				line-height: 1.25;
			}
			dd {
				display: block;
				font-size: 16px;
				font-weight: 400;
				color: #757575;
				line-height: 1.5;
			}
		}
	}
	&-nav {
		display: block;
		position: fixed;
		width: 270px;
		height: 100%;
		left: 0;
		top: 80px;
		z-index: 1000;
		border-radius: 0 15px 15px 0;
		background-color: #fff;
		transform: translateX(-130%);
		box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.1);
		@include tablet {
			top: 60px;
		}
		@include mobile {
			top: 0;
		}
		@include transition(transform, 0.3s);
		&.is-active {
			transform: translateX(0);
		}
		&__header {
			position: relative;
			display: flex;
			height: 100px;
			padding: 0 20px;
			border-radius: 0 15px 0 0;
			background-color: #ff7300;
			color: #fff;
			align-items: center;
			justify-content: center;
			h3 {
				color: #fff;
				font-size: 24px;
				font-weight: 700;
				line-height: 1.2;
				text-align: left;
				@include word-wrap;
			}
		}
		&__body {
			overflow-y: auto;
			max-height: calc(100% - 190px);
			background-color: #fff;
			@include scrollbar('v', '');
			@include tablet {
				max-height: calc(100% - 170px);
			}
			@include mobile {
				max-height: calc(100% - 108px);
			}
			> ul {
				.text,
				a {
					display: flex;
					margin: 1px 0;
					padding: 5px 0;
					align-items: center;
					font-size: 16px;
					line-height: 1.1;
					letter-spacing: -0.5px;
				}

				.text {
					display: block;
					font-size: 14px;
					font-weight: 400;
					color: #bbb;
				}
				> li {
					border-bottom: 1px solid #999;
					> a {
						position: relative;
						display: flex;
						height: 70px;
						padding: 10px 20px;
						// border-bottom: 1px solid #e1e1e1;
						background-color: #fff;
						font-size: 19px;
						font-weight: 700;
						color: #757575;
						align-items: center;
						&::after {
							content: '';
							display: block;
							position: absolute;
							width: 8px;
							height: 8px;
							right: 20px;
							top: 50%;
							border-width: 2px 2px 0 0;
							border-color: #757575;
							border-style: solid;
							transform: translateY(-50%) rotate(-45deg) rotate(180deg);
							@include transition(transform, 0.3s);
						}
					}
					&.is-expand {
						a {
							&::after {
								transform: translateY(-50%) rotate(-45deg) rotate(0);
							}
						}
					}
					> div {
						> ul {
							padding: 15px 20px;
							background-color: #fff;
							border-top: 1px solid #e1e1e1;
							> li {
								&.mt1 {
									margin-top: 10px;
								}
								a {
									&:hover,
									&:focus {
										text-decoration: underline;
									}
								}
								> ul {
									> li {
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.button-close {
	position: absolute;
	top: 0px;
	right: 10px;
	display: flex;
	width: 30px;
	height: 30px;
	align-items: center;
	justify-content: center;
	.icon-close {
		display: flex;
		align-items: center;
		justify-content: center;
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 1px;
			height: 14px;
			background-color: #fff;
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
}
.button-support-smb {
	display: flex;
	position: fixed;
	// top: 407px;
	top: 50%;
	left: 0;
	width: 43px;
	height: 70px;
	margin-top: -35px;
	border-radius: 0 15px 15px 0;
	background-color: #ff7300;
	box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.15);
	align-items: center;
	justify-content: center;
	z-index: 10;
	@include tablet {
		width: 30px;
		height: 70px;
		// top: 60px;
		margin-top: 25px;
		border-radius: 0 10px 10px 0;
	}
	@include mobile {
		margin-top: -35px;
	}
	@at-root .support-smb-nav & {
		position: absolute;
		left: unset;
		right: -30px;
		width: 30px;
		margin-top: -115px;
		@include tablet {
			top: 50%;
			margin-top: 0;
			margin-top: -35px;
		}
	}
}
.icon-nav-list {
	display: block;
	width: 22px;
	height: 22px;
	font-size: 0;
	text-indent: -999em;
	@include icon;
	background-image: url('~@/assets/img/common/icon_navlist.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	@include tablet {
		width: 16px;
		height: 16px;
	}
}
.icon-nav-close {
	width: 10px;
	height: 10px;
	border-width: 0 2px 2px 0;
	border-style: solid;
	border-color: #fff;
	transform: rotate(135deg);
}
.support-smb-footer {
	display: flex;
	position: sticky;
	bottom: 0;
	justify-content: flex-end;
	.buttons {
		margin: 0 -10px;
		padding: 10px;
		border-radius: 70px;
		background-color: rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(5px);
		@include mobile {
			display: flex;
			flex: 1 1;
			.button-default {
				flex: 1 1;
			}
		}
	}
}
.button-link-home {
	display: inline-flex;
	position: relative;
	width: 24px;
	height: 23px;
	margin-top: -2px;
	margin-right: 5px;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	&::after,
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 24px;
		height: 23px;
		top: 50%;
		left: 50%;
		opacity: 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		transform: translate(-50%, -50%);
		@include transition(opacity, 0.5s);
	}
	&::before {
		opacity: 1;
		background-image: url('~@/assets/img/common/icon_home.svg');
	}
	&::after {
		background-image: url('~@/assets/img/common/icon_home_on.svg');
	}
	&:hover,
	&:focus {
		&::before {
			opacity: 0;
		}
		&::after {
			opacity: 1;
		}
	}
}

#supportBiz-home {
	.page-header {
		position: relative;
		height: 270px;
		margin-top: 0;
		margin-bottom: 70px;
		padding: 0;
		text-align: left;
		// background-color: #c8d7ee;
		background-image: url(~@/assets/img/common/banner_policpr.jpg);
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		@include mobile {
			height: 180px;
			margin-bottom: 40px;
		}
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #262321;
			opacity: 0.4;
			z-index: 1;
		}
		.page-component {
			display: flex;
			max-width: 1440px;
			height: 100%;
			color: #fff;
			justify-content: center;
			flex-direction: column;
		}
		&-title {
			position: relative;
			margin-bottom: 20px;
			text-align: left;
			font-size: 50px;
			font-weight: 700;
			z-index: 1;
			@include mobile {
				font-size: clamp(26px, 9vw, 35px);
			}
		}
		&-desc {
			position: relative;
			text-align: left;
			font-size: 30px;
			font-weight: 300;
			line-height: 1.3;
			color: #f1f1f1;
			z-index: 1;
			@include mobile {
				font-size: clamp(13px, 4.2vw, 20px);
			}
		}
	}
}

.page-header-full {
	position: relative;
	height: 270px;
	margin-bottom: 0;
	padding: 0;
	text-align: left;
	// background-color: #c8d7ee;
	background-image: url(~@/assets/img/common/banner_sentcont.jpg);
	background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	@include mobile {
		height: 180px;
	}
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #262321;
		opacity: 0.4;
		z-index: 1;
	}
	.container {
		display: flex;
		height: 100%;
		color: #fff;
		justify-content: center;
		flex-direction: column;
	}
	&__title {
		position: relative;
		margin-bottom: 20px;
		text-align: left;
		font-size: 50px;
		font-weight: 700;
		z-index: 1;
		@include mobile {
			font-size: clamp(26px, 9vw, 35px);
		}
	}
	&__desc {
		position: relative;
		text-align: left;
		font-size: 30px;
		font-weight: 300;
		line-height: 1.3;
		z-index: 1;
		@include mobile {
			font-size: clamp(13px, 4.2vw, 20px);
		}
	}
}
