#elearning {
    .page-header-full {
        background-image: url(~@/assets/img/elearning/banner_bg.png);
        @include mobile {
            background-position: 80% 50%;
        }
        .container {
            max-width: 1200px;
            text-align: left;
            padding: 0 10px;
            @include mobile {
                width: 100%;
                padding: 0 22px;
            }
        }
        &_title {
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 20px;
            z-index: 1;
            @include mobile {
                font-size: 35px;
            }
        }
        &_desc {
            font-size: 30px;
            font-weight: 300;
            line-height: 1.3;
            z-index: 1;
            @include mobile {
                font-size: 17px;
            }
        }
    }
    .page-body {
        .container {
            max-width: 1200px;
            padding: 0 10px;
            @include mobile {
                width: 100%;
                padding: 0;
            }
            .hidde-mobile {
                display: block;
                @include mobile {
                    display: none;
                }
            }
            .visible-mobile {
                display: none;
                @include mobile {
                    display: block;
                }
            }
        }
    }   
    .elearning-content {
        display: flex;
        color: #645f5b;
        border: 1px solid #e0e0e0;
        border-radius: 15px;
        margin: 60px 0;
        overflow: hidden;
        @include mobile {
            flex-direction: column;
            border: none;
            border-radius: 0 ;
            margin: 32px 0;
        }
        &-left {
            width: 290px;
            background-color: #e4eff9;
            padding: 33px;
            z-index: 1;
            @include mobile {
                background-color: #ffffff;
                width: 100%;
                height: 254px;
                padding: 0 16px;
            }
            .content-left {
                &-buttons {
                    margin-bottom: 30px;
                    .buttons {
                        justify-content: center;
                        .button-default {
                            &.is-large {
                                width: 204px;
                                height: 50px;
                                font-size: 16px;
                                font-weight: 700;
                                padding: 15px 30px;
                            }
                        }
                    }
                }
                &-desc {
                    @include mobile {
                        font-weight: 700;
                        line-height: 1.2;
                        &-title {
                            font-size: 24px;
                            margin-bottom: 12px;
                        }
                        &-text {
                            font-size: 17px;
                        }
                    }
                }
                &-icon-box {
                    width: 280px;
                    height: 135px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    @include mobile {
                        padding: 0;
                        margin: 24px auto;
                    }
                }
            }
            
        }
        &-right {
            width: calc(100% - 290px);
            background-color: #ffffff;
            padding: 33px;
            @include mobile {
                width: 100%;
                background-color: #f5f5f5;
                padding: 16px;
            }
            .content-right {
                &-title {
                    font-size: 24px;
                    margin-bottom: 24px;
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 48px;
                        height: 1px;
                        background-color: #757575;
                        margin: 25px 17px 0 0;
                        @include mobile {
                            display: none;
                        }
                    }
                }
                &-elearning-guide {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    padding-left: 65px;
                    @include mobile {
                        padding: 0;
                    }
                    .elearning-guide-item {
                        display: flex;
                        flex-direction: column;
                        &:last-child {
                            .guide-item-title {
                                @include mobile {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                        .guide-item {
                            &-title {
                                display: flex;
                                font-size: 19px;
                                font-weight: 700;
                                line-height: 1.2;
                                gap: 22px;
                                margin-bottom: 8px;
                                @include mobile {
                                    font-size: 16px;
                                    gap: 8px;
                                }
                                .item-title-number {
                                    color: #ff7300;
                                    flex-shrink: 0;
                                }
                                .item-title {
                                    font-size: 19px;
                                    word-break: keep-all;
                                    @include mobile {
                                        font-size: 16px ;
                                    }
                                }
                            }
                            &-process {
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                                gap: 8px;
                                @include mobile {
                                    flex-direction: column;
                                    align-items: flex-start;
                                    gap: 8px;
                                    margin-top: 12px;
                                }
                                li {
                                    display: flex;
                                    align-items: center;
                                    &:not(:last-child) {
                                        &::after {
                                            content: "";
                                            display: inline-block;
                                            width: 8px;
                                            height: 10px;
                                            background-image: url(~@/assets/img/elearning/guide_icon.png);
                                            background-repeat: no-repeat;
                                            background-size: cover;
                                            margin: {
                                                left: 16px;
                                                right: 8px;
                                            }
                                            @include mobile {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &-step {
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 196px;
                                    height: 50px;
                                    background-color: #f5f5f5;
                                    border-radius: 6px;
                                    font-size: 14px;
                                    font-weight: 600;
                                    text-align: center;
                                    line-height: 1.1rem;
                                    padding: 8px;
                                }
                                &-li {
                                    @include mobile {
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;
                                        padding-left: 60px;
                                        img {
                                            width: 30px;
                                            height: 30px;
                                        }
                                        &-title {
                                            font-size: 16px;
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                            &-content {
                                width: 100%;
                                .buttons {
                                    flex-wrap: nowrap;
                                    width: 100%;
                                    margin: 0;
                                    @include mobile {
                                        padding-left: 60px;
                                    }
                                    @include until (500px) {
                                        flex-wrap: wrap;
                                    }
                                    .button-default {
                                        word-break: keep-all;
                                        &.is-large {
                                            height: 46px;
                                            font-size: 16px;
                                            font-weight: 700;
                                            padding: 0 22px;
                                            @include mobile {
                                                width: 50%;
                                            }
                                            @include until (500px) {
                                                width: 260px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
        &-process-introduct {
            max-width: 1200px;
            margin-bottom: 60px;
            @include mobile {
                width: 100%;
                padding: 0 16px;
                margin-bottom: 32px;
            }
            .elearning-content-title {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 12px;
            }
            .elearning-content-process-list {
                display: flex;
                gap: 20px;
                @include mobile {
                    flex-direction: column;
                }
                li {
                    width: calc(100% / 3 - 13px);
                    @include mobile {
                        width: 100%;
                    }
                    .process-list-item {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        background-color: #f5f5f5;
                        border: 1px solid #e0e0e0;
                        border-radius: 15px;
                        overflow: hidden;
                        &-imgbox {
                            width: 100%;
                            height: 260px;
                            position: relative;
                            overflow: hidden;
                            @include mobile {
                                height: 220px;
                            }
                            .imgbox-icons {
                                display: flex;
                                padding: 20px;
                                gap: 4px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 1;
                                .imgbox-icon {
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: #424242;
                                    border-radius: 20px;
                                    color: #ffffff;
                                    font-size: 18px;
                                    font-weight: 500;
                                    padding: 7px 12px;
                                    opacity: 0.8;
                                }
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                object-fit: cover;
                            }
                        }
                        &-textbox {
                            width: 100%;
                            padding: 20px;
                            .textbox {
                                width: 100%;
                                &-title {
                                    font-size: 24px;
                                    font-weight: 700;
                                    margin-bottom: 12px;
                                }
                                &-desc {
                                    height: 70px;
                                    font-size: 19px;
                                    font-weight: 400;
                                    line-height: 1.2;
                                    @include until (1180px) {
                                        @include text-ellipsis-3;
                                    }
                                }
                                &-buttons {
                                    width: 100%;
                                    margin-top: 24px;
                                    .buttons {
                                        width: 100%;
                                        flex-wrap: nowrap;
                                        @include until (1180px) {
                                            flex-wrap: wrap;
                                        }
                                        @include mobile {
                                            flex-wrap: nowrap;
                                        }
                                        .button-default {
                                            width: 100%;
                                            @include until (1180px) {
                                                width: 100%;
                                            }
                                            &.is-large {
                                                height: 50px;
                                                font-size: 16px;
                                                font-weight: 700;
                                                padding: 15px 30px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}