.guide_wrap .portal_menu,
.guide_wrap .guide_menu .education1 {display: none;}

.guide_wrap > div {padding: 20px 0;}
.guide_wrap h3 {margin-bottom: 15px;font-size: 21px;font-family: 'S-CoreDream';}
.guide_wrap h3 span {color: #0f518e;}

.guide_wrap .txt_box {margin-left: 5px;}
.guide_wrap .guide_menu .txt_box h5 + p {margin-left: 10px;}
.guide_wrap .guide_menu .txt_box h5,
.guide_wrap .guide_menu .txt_box p {line-height: 25px;font-size: 16px;}

.guide_wrap .guide_menu ul li {padding-top: 20px;text-align: center;}
.guide_wrap .guide_menu ul li h5 {padding: 8px 0;font-size: 18px;font-family: 'S-CoreDream';color: #fff;background: #0f518e;border: 1px solid #000;}
.guide_wrap .guide_menu ul li p {margin-top: 20px;font-size: 15px;font-family: 'S-CoreDream';line-height: 25px;color: #0f518e;}
.guide_wrap .guide_menu ul li img {width: 100%;margin-top: 10px;}

.guide_wrap .inquiry {padding-bottom: 40px;}
.guide_wrap .inquiry ul li + li {margin-top: 10px;}
.guide_wrap .inquiry ul li strong {font-size: 18px;}
.guide_wrap .inquiry ul li p {margin-top: 5px;line-height: 20px;}
.guide_wrap .inquiry ul li span {display: block;}

@media (max-width: 434px) {
    .guide_wrap .guide_menu ul li p {font-size: 11px;margin-top: 15px;line-height: 18px;}
}