#sme-we {background-color:#f3f5f7;}
#sme-we .page-body .page-component {padding:0px 40px 0;}
#sme-we .post-list-wrapper {padding-top:0;padding-bottom:60px}
#sme-we .post-card-list {padding-top:0;}

#sme-we .page-body .page-nav {background:#f3f5f7;}
#sme-we .page-body .nav-list {padding:0; display: inline-flex;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: auto;
    background: #fafafa;}

/* #sme-we .section-notice-arrow {position:absolute;right:15px;bottom:calc(90% - 5px);display:flex}
#sme-we .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
#sme-we .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-we .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-we .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
#sme-we .disabled {opacity:.3;}
#sme-we .btn-arrow:not(:first-child) {margin-left:5px;}
#sme-we [class^="icon-arrow"] {width:15px;height:15px;}
#sme-we .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
#sme-we .icon-arrow-next {background-image:url(../img/main/notice_next.svg)}
 */
/* 셀렉트 추가 S (20220721 - hib) */
#sme-we .box_select {width: 205px;position: absolute;top: -47px;right: 5px;}
#sme-we .box_select button {width: 100%;padding: 8px 12px;text-align: left;background: #999999;color: #fff;border-radius: 5px;position: relative;top: 3px;}
#sme-we .box_select button::after {display: inline-block;width: 28px;height: 26px;content: '';background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;position: absolute;top: 3px;right: 2px;}
#sme-we .box_select button.active::after {background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;transform: rotate(180deg);}
#sme-we .box_select ul {display: none;width: 100%;padding: 12px 10px 8px;border-width: 0 1px 1px 1px;border-style: solid;border-color: #999999;}
#sme-we .box_select ul li {font-size: 14px;font-weight: 900;}
#sme-we .box_select ul li + li {margin-top: 8px;}
#sme-we .box_select .show_list {display: block; background: #fff;}
/* 셀렉트 추가 E (20220721 - hib) */

/* 소상공인포스트 구독하기 버튼 추가 (20220823 hib) */
.btn_sopo_stibee {float: right; background-image: url('../img/common/btn_sopo_stibee.svg'); background-repeat: no-repeat; display: inline-block; width: 155px; height: 50px; text-indent: -9999em; margin-bottom: 10px;}