.section-subscription {
	margin-bottom: 80px;

	@include mobile {
		display: none;
		margin-bottom: 50px;
		&.is-active {
			display: block;
		}
	}
	&-mtab {
		display: none;
		@include mobile {
			display: flex;
			margin: 0 -16px 17px;
			.button-tab {
				display: flex;
				width: 33.33%;
				height: 53px;
				border: 1px solid #dbdbdb;
				border-left: none;
				font-size: 15px;
				color: #757575;
				align-items: center;
				justify-content: center;
				@include word-wrap;
				&:first-child {
					border-left: 1px solid #dbdbdb;
				}
				&.is-active {
					background-color: #eee;
					color: #424242;
					font-weight: bold;
				}
			}
		}
	}
	&.subs1 {
	}
	&.subs2 {
	}
	&.subs3 {
	}
	.swiper {
	}
	.section {
		&-header {
			margin-bottom: 10px;
			@include mobile {
				display: none;
			}
			&-title {
				font-size: 26px;
				font-weight: 400;
				@include mobile {
					font-size: 20px;
				}
			}
		}
		&-content {
			position: relative;
			padding: 30px 0;
			border-radius: 15px;
			background: #e9ebee;
			@include mobile {
				padding: 0;
				background-color: transparent;
			}
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				left: 15px;
				right: 15px;
				width: calc(100% - 30px);
				height: 15px;
				background-position: 50% 50%;
				background-size: contain;
				// box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
				z-index: 1;
				@include mobile {
					display: none;
				}
			}
			&::before {
				top: 0;
				background-image: url(~@/assets/img/common/subs_bg_t.png);
			}
			&::after {
				bottom: 0;
				background-image: url(~@/assets/img/common/subs_bg_b.png);
			}
			.swiper {
				padding: 0 30px;
				@include mobile {
					margin: 0 -15px;
					padding: 0 15px;
				}
			}
			&-bg {
				&__l,
				&__r {
					display: block;
					position: absolute;
					top: 15px;
					bottom: 15px;
					width: 15px;
					height: calc(100% - 30px);
					background-repeat: repeat-y;
					background-position: 50% 50%;
					background-size: contain;
					z-index: 1;
					&::before,
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 15px;
						height: 15px;
						background-repeat: no-repeat;
						background-position: 50% 50%;
						background-size: contain;
						z-index: 1;
					}
					@include mobile {
						display: none;
					}
				}
				&__l {
					left: 0;
					background-image: url(~@/assets/img/common/subs_bg_l.png);
					&::before,
					&::after {
						left: 0;
					}
					&::before {
						top: -15px;
						background-image: url(~@/assets/img/common/subs_bg_lt.png);
					}
					&::after {
						bottom: -15px;
						background-image: url(~@/assets/img/common/subs_bg_lb.png);
					}
				}
				&__r {
					right: 0;
					background-image: url(~@/assets/img/common/subs_bg_r.png);
					&::before,
					&::after {
						right: 0;
					}
					&::before {
						top: -15px;
						background-image: url(~@/assets/img/common/subs_bg_rt.png);
					}
					&::after {
						bottom: -15px;
						background-image: url(~@/assets/img/common/subs_bg_rb.png);
					}
				}
			}
		}
		&-subs {
			&-link {
				display: flex;
				overflow: hidden;
				position: relative;
				width: 200px;
				height: 100px;
				border-radius: 15px;
				border: 1px solid #d5d5d5;
				background-color: #fff;
				align-items: center;
				justify-content: center;
				@include mobile {
					width: 116px;
					height: 60px;
				}
				img {
					width: 200px;
					max-width: 200px;
					height: auto;
					@include mobile {
						width: 116px;
						max-width: 116px;
					}
				}
				&.is-active,
				&:hover,
				&:focus {
					.section-subs-desc {
						visibility: visible;
						opacity: 1;
					}
				}
			}
			&-desc {
				display: flex;
				position: absolute;
				visibility: hidden;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 1em 2em;
				opacity: 0;
				z-index: 1;
				// color: #fff;
				border: 2px solid #659427;
				border-radius: 15px;
				// background-color: rgba(0, 0, 0, 0.7);
				font-size: 15px;
				font-weight: 400;
				line-height: 1.4;
				text-align: center;
				align-items: center;
				justify-content: center;
				@include word-wrap;
				@include transition(all, 0.5s);
			}
		}
	}
	.swiper-buttons-container {
		.swiper-button-next,
		.swiper-button-prev {
			display: flex;
			width: 35px;
			height: 35px;
			overflow: hidden;
			top: 82px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #d5d5d5;
			align-items: center;
			justify-content: center;
			@include mobile {
				display: none;
			}
			&::after {
				width: 9px;
				height: 15px;
				top: 50%;
				left: 50%;
				border: none;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				transform: translate(-50%, -50%);
			}
		}
		.swiper-button-prev {
			left: -17px;
			&::after {
				background-image: url(~@/assets/img/common/arrow-l.svg);
			}
		}
		.swiper-button-next {
			right: -17px;
			&::after {
				background-image: url(~@/assets/img/common/arrow-r.svg);
			}
		}
	}
}

.swiper-buttons-container {
	&__sround {
		.swiper-button-next,
		.swiper-button-prev {
			display: flex;
			width: 36px;
			height: 36px;
			overflow: hidden;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #d5d5d5;
			align-items: center;
			justify-content: center;
			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
			@include mobile {
				transform: scale(0.8);
			}
			&::after {
				width: 9px;
				height: 15px;
				top: 50%;
				left: 50%;
				border: none;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				transform: translate(-50%, -50%);
			}
		}
		.swiper-button-prev {
			left: -17px;
			&::after {
				background-image: url(~@/assets/img/common/arrow-l.svg);
			}
		}
		.swiper-button-next {
			right: -17px;
			&::after {
				background-image: url(~@/assets/img/common/arrow-r.svg);
			}
		}
	}
}

#subscription-center {
	@include mobile {
		padding-bottom: 0px;
	}
	.page-component {
		width: 100%;
		max-width: 1280px;
		padding: 0 40px;
		@include mobile {
			padding: 0 15px;
		}
		.summary {
			margin-top: 16px;
			color: #51653a;
			&-title {
				position: relative;
				margin-bottom: 10px;
				color: #3f5525;
				font-size: 27px;
				font-weight: 900;
				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 12px;
					left: -60px;
					width: 45px;
					height: 1px;
					border-bottom: 1px solid #3f5525;
				}
			}
			&-wrap {
				display: flex;
				position: relative;
				overflow: hidden;
				margin-bottom: 50px;
				padding-bottom: 25px;
				border-bottom: 1px solid #e0e0e0;
				justify-content: space-between;
				@include mobile {
					margin-bottom: 30px;
				}
				.fl {
					width: 270px;
					border-radius: 15px 0 0 15px;
					background-color: #31471e;
					flex: 0 0 270px;
					@include mobile {
						display: none;
					}
					&::before {
						position: absolute;
						content: '';
						@include icon;
						top: 27px;
						left: 30px;
						width: 341px;
						height: 288px;
						z-index: 10;
						background-image: url(~@/assets/img/common/img_sentpr.png);
					}
				}
				.fr {
					padding-top: 30px;
					padding-bottom: 30px;
					padding-left: 110px;
					flex: 1 1;
					@include mobile {
						padding-top: 0;
						padding-bottom: 20px;
						padding-left: 0;
					}
				}
			}

			&-header {
				margin-bottom: 5px;
				&-title {
					display: flex;
					font-size: 19px;
					font-weight: 700;
					align-items: center;
					strong {
						display: inline-block;
						margin-right: 6px;
						font-size: 24px;
						font-weight: 800;
						color: #ff7300;
					}
				}
			}
			.center {
				@include mobile {
					text-align: center;
				}
			}
			&-content {
				@include mobile {
					.center {
						width: 80%;
						display: inline-block;
					}
				}
				&-text {
					word-break: keep-all;
					font-size: 17px;
					line-height: 1.3;
					padding: 2.5px 0;
					&.center {
						display: flex;
						width: 80%;
						align-items: center;
						gap: 25px;
						@include tablet {
							width: 100%;
						}
						@include until(1050px) {
							margin: 0 auto;
							flex-direction: column;
							justify-content: center;
						}
					}
					span:not(:first-child) {
						margin-left: 3px;
					}
					em {
						display: inline-block;
						color: #757575;
						font-size: 16px;
						@include mobile {
							display: block;
						}
					}
					@include mobile {
						&-num {
							width: 3%;
						}
						&-numx {
							margin-left: 3px;
							width: calc(97% - 3px);
						}
						&-num,
						&-numx {
							display: inline-block;
						}
					}

					&-depth {
						position: relative;
						display: inline-block;
						padding: 10px 20px;
						border-radius: 8px;
						background: #e1e4db;
						text-align: center;
						white-space: nowrap;
						flex: 1 1;
						&:not(:first-child) {
							&::before {
								content: '';
								position: absolute;
								display: inline-block;
								top: 50%;
								left: -16px;
								width: 0px;
								height: 0px;
								margin-top: -5px;
								border-style: solid;
								border-width: 5px 0 5px 8px;
								border-color: transparent transparent transparent #3f5525;
								@include until(1050px) {
									top: -17px;
									left: 50%;
									margin-top: 0;
									margin-left: -3px;
									transform: rotate(90deg);
								}
							}
						}
						@include until(1050px) {
							width: 100%;
							margin: 0;
						}
					}
					&-arrow {
						margin: 10px;
						padding: 10px 20px 10px 20px;
						border-radius: 30px;
						display: inline-block;
						@include mobile {
							// width: 100%;
							text-align: center;
							margin: 0;
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}
	.page-header {
		position: relative;
		height: 270px;
		margin-bottom: 70px;
		padding: 0;
		text-align: left;
		// background-color: #c8d7ee;
		background-image: url(~@/assets/img/common/banner_sentpr.jpg);
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		@include mobile {
			height: 180px;
			margin-bottom: 40px;
		}
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #262321;
			opacity: 0.4;
			z-index: 1;
		}
		.page-component {
			display: flex;
			height: 100%;
			color: #fff;
			justify-content: center;
			flex-direction: column;
		}
		&-title {
			position: relative;
			margin-bottom: 20px;
			text-align: left;
			font-size: 50px;
			font-weight: 700;
			z-index: 1;
			@include mobile {
				font-size: clamp(26px, 9vw, 35px);
			}
		}
		&-desc {
			position: relative;
			text-align: left;
			font-size: 30px;
			font-weight: 300;
			line-height: 1.3;
			z-index: 1;
			@include mobile {
				font-size: clamp(13px, 4.2vw, 20px);
			}
		}
	}

	// .page-header {
	// 	position: relative;
	// 	height: 270px;
	// 	margin-bottom: 70px;
	// 	padding: 0;
	// 	text-align: left;
	// 	background-color: #c8d7ee;
	// 	@include mobile {
	// 		height: 180px;
	// 		margin-bottom: 0;
	// 	}
	// 	&::before {
	// 		content: '';
	// 		position: absolute;
	// 		width: 100%;
	// 		height: 100%;
	// 		background-color: #262321;
	// 		opacity: 0.6;
	// 		z-index: 1;
	// 	}
	// 	.page-component {
	// 		display: flex;
	// 		height: 100%;
	// 		background-image: url(~@/assets/img/common/subs_title.png);
	// 		background-position: 100% 50%;
	// 		background-repeat: no-repeat;
	// 		background-size: cover;
	// 		color: #fff;
	// 		justify-content: center;
	// 		flex-direction: column;
	// 	}
	// 	&-title {
	// 		position: relative;
	// 		margin-bottom: 20px;
	// 		text-align: left;
	// 		font-size: 50px;
	// 		font-weight: 700;
	// 		z-index: 1;
	// 		@include mobile {
	// 			font-size: clamp(26px, 9vw, 35px);
	// 		}
	// 	}
	// 	&-desc {
	// 		position: relative;
	// 		text-align: left;
	// 		font-size: 30px;
	// 		line-height: 1.3;
	// 		z-index: 1;
	// 		@include mobile {
	// 			font-size: clamp(13px, 4.2vw, 20px);
	// 		}
	// 	}
	// }
	.section-subscription {
		&.is-active {
			.section-content-plus {
				// display: flex;
				// opacity: 1;
				@include mobile {
					opacity: 1;
					display: block;
				}
			}
		}
		.section-content-plus {
			display: flex;
			&.is-rounded {
				.section-content-plus__body {
					border-radius: 0 15px 15px 0;
				}
			}
			@include desktop {
				max-height: 231px;
			}
			@include mobile {
				opacity: 0;
			}
		}
	}
	.section-content {
		&-plus {
			position: relative;
			margin-left: 30px;
			margin-top: 30px;

			@include mobile {
				display: block;
				margin-left: -15px;
				margin-right: -15px;
				margin-top: 17px;
			}
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 230px;
				z-index: 0;
				background-color: #d4d4d4;
				width: 30px;
				height: 100%;
			}
			&__header {
				display: flex;
				position: relative;
				padding: 27px;
				border-radius: 15px;
				background-color: #659427;
				color: #fff;
				align-items: flex-start;
				justify-content: center;
				flex-direction: column;
				flex: 0 0 240px;
				@include mobile {
					padding: 10px 15px;
					border-radius: 0;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
				}

				strong {
					display: block;
					margin-bottom: 20px;
					line-height: 1.2;
					font-size: 26px;
					font-weight: 700;
					@include word-wrap;
					@include mobile {
						max-width: 50%;
						margin: 0;
						font-size: 16px;
					}
				}
				a {
					display: inline-flex;
					color: #fff;
					font-size: 21px;
					font-weight: 400;
					align-items: center;
					&:hover,
					&:focus {
						text-decoration: underline;
					}
					@include mobile {
						font-size: 14px;
						&::after {
							content: '';
							display: block;
							width: 0px;
							height: 0px;
							margin-left: 4px;
							border-style: solid;
							border-width: 4px 0 4px 5px;
							border-color: transparent transparent transparent #ffffff;
							transform: rotate(0deg);
						}
					}
				}
			}
			&__body {
				display: flex;
				position: relative;
				max-width: calc(100% - 240px);
				padding: 13px 16px;
				z-index: 1;
				overflow-x: auto;
				background-color: #d4d4d4;
				white-space: nowrap;
				gap: 10px;
				@include scrollbar('', 'h');
				@include mobile {
					overflow: initial;
					max-width: initial;
					padding: 15px;
					background-color: #f2f2f2;
					white-space: inherit;
					flex-direction: column;
					gap: 12px;
				}
			}

			&__item {
				display: flex;
				position: relative;
				width: 406px;
				padding: 18px;
				border-radius: 10px;
				border: 1px solid #e1e1e1;
				background-color: #fff;
				gap: 13px;
				flex: 0 0 406px;
				@include mobile {
					width: 100%;
					padding: 12px;
					flex: 0 0 100%;
				}
				&.is-bgimage {
					overflow: hidden;
					min-height: 199px;
					padding: 0;
					border: none;
					@include mobile {
						min-height: 156px;
						flex: 0 0 156px;
					}
					.item {
						&-image {
							position: static;
							width: 100%;
							height: 100%;
							transform: scale(1.02);
							flex: 1 1 auto;
							img {
								object-fit: cover;
								width: initial;
								height: initial;
								top: 0;
							}
							&::before {
								content: '';
								display: block;
								position: absolute;
								left: 0;
								right: 0;
								width: 100%;
								height: 100%;
								z-index: 2;
								background: rgba(0, 0, 0, 0.68);
								//gradient
								background: linear-gradient(to right, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.7) 80%);
							}
						}
						&-text {
							position: absolute;
							width: 210px;
							padding: 29px 22px;
							top: 0;
							right: 0;
							color: #fff;
							z-index: 10;
							@include mobile {
								width: 200px;
								padding: 10px 16px;
							}
							&-title {
								display: inline-block;
								margin-bottom: 10px;
								text-align: left;
								font-size: 21px;
								font-weight: 700;
								line-height: 1.2;
								@include word-wrap;
								@include mobile {
									margin-bottom: 8px;
								}
							}
							&-desc {
								display: flex;
								margin-bottom: 20px;
								font-size: 16px;
								font-weight: 300;
								line-height: 1.2;
								align-items: center;
								gap: 5px;
								@include mobile {
									margin-bottom: 12px;
								}
								li {
									display: inline-flex;
									align-items: center;
									gap: 5px;
									&:not(:first-child) {
										&::before {
											content: '';
											display: inline-block;
											width: 4px;
											height: 4px;
											border-radius: 4px;
											background-color: #ff7300;
										}
									}
								}
							}
						}
					}
				}

				.item {
					&-image {
						display: flex;
						position: relative;
						width: 160px;
						height: 167px;
						border: 1px solid #d5d5d5;
						align-items: center;
						justify-content: center;
						flex: 0 0 160px;
						@include mobile {
							width: 125px;
							height: 130px;
							flex: 0 0 125px;
						}
						img {
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					&-text {
						em {
							display: block;
							margin-bottom: 5px;
							font-size: 16px;
							font-weight: 700;
							color: #ff7300;
							white-space: normal;
							@include mobile {
								font-size: 12px;
							}
						}
						strong {
							display: block;
							margin-bottom: 11px;
							font-size: 17px;
							font-weight: 800;
							white-space: normal;
							line-height: 1.2;
							@include text-ellipsis-2;
							@include mobile {
								font-size: 15px;
							}
						}
						span {
							display: block;
							font-size: 16px;
							font-weight: 400;
							white-space: normal;
							line-height: 1.2;
							@include text-ellipsis-4;
							@include mobile {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}
.icon-arrow-right-orange {
	width: 8px;
	height: 13px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(~@/assets/img/common/icon_arrow_r_orange.svg);
}
.icon-plus-orange {
	width: 13px;
	height: 13px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(~@/assets/img/common/icon_plus.svg);
}
.position-item-right {
	position: absolute;
	top: 10px;
	right: 10px;
}
