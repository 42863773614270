@include mobile {
	.gachi-login .gachi-footer .footer-family-list {
		display: none;
	}
	.gachi-footer .footer-row {
		background-color: transparent;
	}
}
#gachi.gachi-signup {
	padding-top: 0;
	.gachi-header[data-header='min'] {
		.header-component {
			display: flex;
			align-items: center;
		}
		.header-gnb {
			height: auto;
			padding: 0;
		}
	}
}
.auth-header {
	text-align: center;
	padding: 80px 0;
	@include mobile {
		padding: 40px 0;
	}
	.title {
		font-size: 50px;
		font-weight: 900;
		@include mobile {
			font-size: 30px;
		}
	}
}

.auth-buttons {
	display: flex;
	margin-top: 50px;
	@include mobile {
		margin-top: 40px;
	}
	.btn {
		flex: 1;
		~ .btn {
			margin-left: 20px;
			@include mobile {
				margin-left: 10px;
			}
		}
	}
	&.wide {
		width: 100%;
		.btn {
			width: 100%;
		}
	}
}

.auth-component .form-control:focus {
	box-shadow: 0 0 0 2px #015aa8 inset;
}

#sme-login {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 80px;
	@include mobile {
		padding-bottom: 0;
	}
	.auth-component {
		width: 540px;
		@include mobile {
			max-width: 540px;
		}
	}
	.form-row {
		display: flex;
		justify-content: space-between;
		.form-control:focus {
			box-shadow: 0 0 0 2px #015aa8 inset;
		}
		@include mobile {
			~ .form-row {
				padding-top: 10px;
			}
		}
	}
	.field-actions {
		margin-top: 40px;
		padding-top: 0;
		@include mobile {
			margin-top: 10px;
		}
	}
	.login-links {
		display: flex;
		align-items: center;
		.link {
			display: inline-block;
			&:not(:first-child) {
				position: relative;
				display: inline-block;
				margin-left: 6px;
				padding-left: 15px;
				@include mobile {
					padding-left: 8px;
				}
				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					width: 1px;
					border-radius: 1px;
					height: 14px;
					background: rgba(0, 0, 0, 0.6);
					@include mobile {
						top: 1px;
						height: 8px;
					}
				}
			}
		}
		.text {
			font-weight: bold;
			@include mobile {
				font-size: 13px;
			}
		}
	}
	.auth-buttons {
		margin-top: 30px;
		@include mobile {
			margin-top: 17px;
		}
	}
	.login-links {
		a {
			&:hover .text {
				color: #015aa8;
			}
			&:not(.link) {
				margin-right: 10px;
			}
		}
	}
}

#sme-signup {
	display: flex;
	align-items: center;
	justify-content: center;
	.agree-list {
		position: relative;
		padding-bottom: 50px;
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 50px;
		@include mobile {
			padding-bottom: 20px;
			margin-bottom: 20px;
		}
	}
	.agree-item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		~ .agree-item {
			margin-top: 25px;
		}
	}
	.agree-link {
		display: inline-flex;
		align-items: center;
		.text {
			font-size: 12px;
			font-weight: bold;
			color: #015aa8;
			&:hover {
				text-decoration: underline;
			}
		}
		.icon-arrow {
			margin-left: 5px;
			width: 11px;
			height: 17px;
			background-image: url(~@/assets/img/common/icon_agree_arrow.svg);
		}
	}
	.auth-component {
		width: 680px;
		&.is-wide {
			width: 800px;
			max-width: 800px;
			#field-sme + .btn.is-fullwidth {
				width: calc(100% - 110px);
				margin-top: 20px;
				margin-left: 110px;
				margin-bottom: 1em;
				@include tablet {
					width: 100%;
					margin-left: 0;
				}
				&.is-disabled {
					pointer-events: none;
					opacity: 0.5;
					background-color: #bbb;
				}
			}
			.form-row {
				.form-cellth {
					position: static;
					@include tablet {
						flex: 0 0 100%;
						label {
							@include word-wrap;
							br {
								display: none;
							}
						}
					}
				}
			}
			.form-field {
				fieldset {
					&:not(:last-child) {
						display: block;
						margin-bottom: 2em;
					}
				}
				legend {
					margin-bottom: 1em;
					font-size: 20px;
					font-weight: 700;
				}
			}
		}
		@include mobile {
			max-width: 680px;
		}
	}
	.form-check-box {
		margin-bottom: 50px;
		@include mobile {
			margin-bottom: 30px;
		}
		.form-check-input {
			position: absolute;
			left: 0;
			top: 0;
			width: 1px;
			height: 1px;
			&:checked {
				~ .form-check-label {
					border-color: #015aa8;
					.text {
						color: #111;
					}
					.icon-check {
						background-image: url(~@/assets/img/common/icon_form_checkbox_active.svg);
					}
				}
			}
		}
		.form-check-label {
			height: 80px;
			border-radius: 2px;
			border: 2px solid #ebebeb;
			width: 100%;
			text-align: center;
			line-height: 78px;
			margin-left: 0;
			@include mobile {
				display: flex;
				height: 60px;
				line-height: 1;
				align-items: center;
				justify-content: center;
			}
			.icon-check {
				position: absolute;
				top: 50%;
				left: 35px;
				width: 24px;
				height: 20px;
				background-image: url(~@/assets/img/common/icon_form_checkbox.svg);
				transform: translateY(-50%);
				@include mobile {
					width: 20px;
					height: 13px;
					margin-right: 10px;
					background-size: 20px auto;
				}
			}
			.text {
				font-size: 20px;
				font-weight: 900;
				color: #999;
				@include mobile {
					font-size: 16px;
				}
			}
			&:hover {
				border-color: #999;
				.icon-check {
					background-image: url(~@/assets/img/common/icon_form_checkbox_hover.svg);
				}
				.text {
					border-color: #999;
				}
			}
		}
	}
	.form-row {
		position: relative;
		&.is-noth {
			padding-top: 0;
			margin-top: -1em;
			margin-left: 110px;
			@include tablet {
				margin: 1em 0 0;
			}
		}
		@include tablet {
			vertical-align: top;
			flex-wrap: wrap;
		}
		.form-celltd {
			@include tablet {
				flex: 1;
			}
		}
		.form-cellth {
			position: absolute;
			left: -110px;
			top: 0;
			width: 110px;
			@include mobile {
				position: static;
				width: inherit;
			}
		}
		~ .form-row .form-cellth {
			top: 20px;
		}
	}
	.signup-agree-list {
		font-size: 16px;
		line-height: 1.5;
		&__desc {
			margin-bottom: 1em;
			font-size: 20px;
			font-weight: bold;
		}
		&__ul {
			li {
				margin-top: 1.5em;
				&.color-red {
					margin-top: 0.5em;
					color: #ff7300;
				}
			}
		}
	}
	.signup-auth {
		.btn-verify {
			margin-bottom: 40px;
			@include mobile {
				display: flex;
				height: 60px;
				margin-bottom: 30px;
				align-items: center;
				justify-content: center;
				.icon-check {
					width: 20px;
					height: 13px;
					margin-right: 10px;
					background-size: 20px auto;
					top: 50%;
					transform: translateY(-50%);
				}
				.text {
					font-size: 16px;
				}
			}
			.icon-phone {
				position: absolute;
				left: 35px;
				width: 30px;
				height: 30px;
				background-image: url(~@/assets/img/common/icon_phone.svg);
				@include mobile {
					width: 20px;
					height: 20px;
					background-size: 20px auto;
					margin-right: 5px;
				}
			}
		}
	}
}

#sme-unsubscribe {
	display: flex;
	flex-direction: column;
	justify-content: center;
	.auth-header .title {
		line-height: 1.2;
	}
	.auth-body-desc {
		@include until(900px) {
			br {
				display: none;
			}
		}
	}
	.auth-component {
		max-width: 1000px;
		@include word-wrap;
	}
	.button-wrap {
		gap: 10px;
		@include until(360px) {
			transform: scale(0.8);
		}
	}
}
#sme-signup-policy {
	.auth-body {
		.terms-wrap {
			font-size: 18px;
		}
		.terms-section {
			margin-bottom: 2em;
			&-title {
				margin-bottom: 0.6em;
				font-weight: 800;
			}
		}
	}
}
.policy-article {
	~ .policy-article {
		padding-top: 40px;
		@include mobile {
			padding-bottom: 30px;
		}
	}
	&-header {
		font-size: 20px;
		font-weight: 800;
		line-height: 1.2;
		margin-bottom: 20px;
		@include mobile {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
	&-text {
		font-size: 16px;
		line-height: 1.2;
		color: #555;
		@include mobile {
			font-size: 13px;
		}
	}
	&-intro .policy-article-text {
		font-weight: bold;
	}
}

#sme-signup-completed {
	display: flex;
	align-items: center;
	justify-content: center;
	.auth-component {
		width: 540px;
	}
	.auth-content {
		position: relative;
		text-align: center;
		.text {
			font-size: 30px;
			@include mobile {
				font-size: 20px;
				line-height: 20px;
			}
		}
		@include mobile {
			.emoji {
				font-size: 80px;
				padding-top: 28px;
			}
		}
	}
}

[id^='sme-recovery'] {
	.auth-content {
		position: relative;
		text-align: center;
		.title {
			font-size: 20px;
			font-weight: 900;
			line-height: 1.2;
			@include mobile {
				font-size: 16px;
			}
		}
		.subtext {
			display: block;
			font-size: 13px;
			color: #999;
			margin-top: 20px;
			@include mobile {
				color: #555;
			}
		}
	}
	.auth-buttons {
		width: 420px;
		margin: 50px auto 0;
		@include mobile {
			width: inherit;
			margin: 30px auto 0;
			justify-content: center;
			.btn {
				min-width: auto;
				flex: 0 0 auto;
			}
		}
	}
}

#sme-recovery-id {
	@include mobile {
		.auth-header {
			padding-top: 60px;
		}
	}
}
#sme-recovery-password {
	.auth-forms {
		.form-row {
			width: 420px;
			margin: 50px auto;
			@include mobile {
				max-width: 420px;
				margin: 30px auto;
			}
		}
	}
}

.segment-controller {
	position: relative;
	margin-bottom: 50px;
	@include mobile {
		margin-bottom: 25px;
	}
	.segment-list {
		width: 420px;
		padding: 5px;
		background-color: #ebebeb;
		display: flex;
		margin: auto;
		@include mobile {
			width: 242px;
		}
	}
	.segment-item {
		flex: 1;
	}
	.segment-link {
		display: block;
		height: 50px;
		line-height: 1;
		text-align: center;
		@include mobile {
			height: 40px;
		}
	}
	.segment-text {
		font-size: 20px;
		font-weight: 900;
		color: #555;
		@include mobile {
			font-size: 13px;
		}
	}
	.is-selected {
		.segment-link {
			background-color: #fff;
		}
		.segment-text {
			color: #111;
		}
	}
}

.ie #sme-login {
	display: block;
}
.ie #sme-signup {
	display: block;
}
.ie #sme-signup-completed {
	display: block;
}

.auth-body {
	&-desc {
		font-size: 26px;
		line-height: 1.3;
		@include mobile {
			font-size: 20px;
		}
	}
	@include mobile {
		margin-bottom: 40px;
	}
}

.color-danger {
	color: red;
	font-weight: 400;
	font-size: 0.93em;
	line-height: 1.3;
}
.form-group + .small-text {
	display: inline-block;
	margin-top: 0.5em;
	line-height: 1.2;
}
.form-subdesc {
	font-size: 0.9em;
	line-height: 1.5;
	margin-bottom: 1em;
}
