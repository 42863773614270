.fp-index-2024 {
	// background-image: url(~@/assets/img/main/bg_main2024.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	#mainVideo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@include mobile {
		&.fullpage {
			padding-top: 60px;
			padding-bottom: 120px;
			.fp-content {
				margin-top: 32px;
			}
		}
	}
	.fp-index-slogan {
		color: #424242;
		text-shadow: none;
		margin-top: 3em;
		margin-bottom: clamp(20px, 4vw, 50px);
		@include mobile {
			margin-top: 0.3em;
			margin-bottom: 32px;
		}
		&__title {
			margin-bottom: 0;
			font-size: 48px;
			font-weight: 800;
			line-height: 1.25;
			letter-spacing: -1px;
			opacity: 0;
			@include word-wrap;
			transform: translateY(50%);
			animation: aniFadeUp 3s forwards;
			strong {
				font-weight: 800;
			}
			@include mobile {
				font-size: 32px;
			}
			@include until(440px) {
				font-size: 26px;
			}
			@include until(350px) {
				font-size: 24px;
			}
		}
		&__desc {
			margin-bottom: 0.3em;
			width: 430px;
			margin-left: auto;
			margin-right: auto;
			font-size: 26px;
			@include mobile {
				width: 328px;
				margin-bottom: 12px;
				font-size: 20px;
			}
			@include until(440px) {
				width: 262px;
				font-size: 16px;
			}
			span {
				display: block;
				overflow: hidden;
				width: 0;
				white-space: nowrap;
				animation: typing 1.2s steps(18, end) forwards;
			}
		}
	}
	@keyframes aniFadeDown {
		50% {
			opacity: 0;
			transform: translateY(-10%);
		}
		80% {
			opacity: 1;
			transform: translateY(0);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
	@keyframes aniFadeUp {
		50% {
			opacity: 0;
			transform: translateY(50%);
		}
		80% {
			opacity: 1;
			transform: translateY(0);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
	@keyframes typing {
		from {
			width: 0;
		}
		to {
			width: 100%;
		}
	}

	.fp-light-box {
		position: relative;
		// padding: 30px 0;
		padding: 20px 0;
		border-radius: 30px;
		background-color: rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(10px);
		box-shadow: 0 0 15px rgba(141, 85, 49, 0.25);
		order: 3;
		@include mobile {
			padding: 15px 0;
		}
		.swiper-container {
			padding: 0 30px;
			@include mobile {
				padding: 0 15px;
				border-radius: 10px;
			}
		}
	}
	.fp-index {
		&-steps {
			width: 70%;
			margin: 0 auto 20px;
			@include mobile {
				width: 100%;
			}
			&__item {
				display: inline-flex;
				flex-direction: column;
				gap: 8px;
				@include mobile {
					gap: 4px;
				}
				em,
				strong {
					display: block;
					color: #757575;
					text-align: center;
					line-height: 1;
				}
				em {
					width: 100%;
					font-size: 14px;
					font-weight: 700;
				}
				strong {
					position: relative;
					font-size: clamp(19px, 4vw, 26px);
					font-weight: 400;
					&:after {
						content: '';
						position: absolute;
						display: block;
						width: 0;
						left: 50%;
						bottom: -7px;
						height: 1px;
						border-bottom: 3px solid transparent;
						transform: translateX(-50%);
						@include transition(width, 0.3s);
					}
				}
			}
			> ol {
				display: flex;

				align-items: center;
				justify-content: center;
				li {
					display: block;
					position: relative;
					text-align: center;
					width: 33.33%;
					&:not(:first-child) {
						&:before {
							content: '';
							@include icon;
							position: absolute;
							width: 36px;
							height: 17px;
							left: -19px;
							top: 50%;
							background-image: url(~@/assets/img/main/main-index-arrow.svg);
							transform: translateY(-50%);
							@include mobile {
								width: 29px;
								height: 14px;
								left: -14px;
							}
						}
					}
					&.is-current {
						em,
						strong {
							font-weight: 700;
							color: #ff7300;
						}
						strong {
							&:after {
								width: 100%;
								border-color: #ff7300;
							}
						}
					}
				}
			}
		}
		&-card {
			// display: inline-flex;
			min-width: 220px;
			// height: 238px;
			padding: 20px;
			border-radius: 20px;
			border: 1px solid #e0e0e0;
			background-color: #fff;
			// flex-direction: column;
			&__header {
				margin-bottom: 12px;
				flex: 0 0 54px;
				strong {
					display: block;
					margin-bottom: 10px;
					font-size: 14px;
					font-weight: 700;
					color: #ff7300;
				}
			}
			&__title {
				display: block;
				font-size: 24px;
				font-weight: 700;
			}
			&__content {
				height: 77px;
				margin-bottom: 26px;
				font-size: 19px;
				font-weight: 400;
				color: #757575;
				line-height: 1.4;
				flex: 1 1;
				strong {
					font-weight: 700;
					color: #555;
				}
				@include text-ellipsis-3;
				@include word-wrap;
				@include mobile {
					height: 66px;
					font-size: 16px;
				}
			}
			&__footer {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex: 0 0 30px;
				.button-default {
					&.is-small {
						&.is-rounded {
							height: 30px;
							padding-left: 20px;
							padding-right: 20px;
							font-size: 16px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}
.fp-video-2024 {
	.fp-index-slogan {
		margin-bottom: clamp(20px, 4vw, 40px);
	}
	.fp-light-box {
		max-width: 840px;
		margin: 0 auto;
		padding: 10px;
	}
	.fp-video-box {
		position: relative;
		display: flex;
		width: 100%;
		max-width: 860px;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		// opacity: 0;
		// transform: translateY(-10%);
		// animation: aniFadeDown 3s forwards;
		video {
			display: block;
			overflow: hidden;
			border-radius: 20px;
			width: 100%;
		}
	}
}
.bounce-ani {
	animation: bounceAni 1.5s ease infinite;
}
@keyframes bounceAni {
	30% {
		transform: translateY(-8px);
	}
	50% {
		transform: translateY(4px);
	}
	60% {
		transform: translateY(-2px);
	}
	70% {
		transform: translateY(1px);
	}
}

.fp-panro {
	.broadcast-banner {
		.swiper-button-prev,
		.swiper-button-next {
			&::after {
				display: none;
			}
		}
	}
}
#MainIndexNoti {
	margin-bottom: 24px;

	&.main-noti-wrap {
		width: 100%;
		flex: 1 1;

		.swiper-controls {
			position: absolute;
			top: 0px;
			right: 14px;
			left: initial;
			z-index: 10;
			display: flex;
			width: 16px;
			height: 60px;
			gap: 2px;
			flex-direction: column;
			@include tablet {
				right: 8px;
			}
			@include mobile {
				top: initial;
				right: 0;
				bottom: 4px;
			}

			.swiper-controls-center {
				position: static;
				margin: 0;
				transform: none;
			}
			.swiper-pagination-container {
				display: none;
			}
			.swiper-button {
				&-next,
				&-prev {
					position: static;
					width: 16px;
					height: 16px;
					margin: 0;
					border: none;
					background-color: transparent;
					box-shadow: none;
					.icon {
						margin: 0;
					}
					&:active {
						background-color: rgba(255, 115, 0, 0.3);
					}
				}
				&-prev {
					.icon {
						transform: scale(0.55) rotate(-90deg);
					}
				}
				&-next {
					.icon {
						transform: scale(0.55) rotate(90deg);
					}
				}
				&-playstop {
					width: 16px;
					height: 16px;
					border: none;
					background-color: transparent;
					box-shadow: none;
					.icon {
						transform: scale(0.7);
					}
					&:active {
						background-color: rgba(255, 115, 0, 0.3);
					}
				}
			}
		}
	}
	.main-noti {
		&-title {
			display: flex;
			width: 175px;
			padding: 12px 16px;
			border-radius: 16px;
			background-color: #ff7300;
			color: #fff;
			font-size: 24px;
			font-weight: 700;
			align-items: center;
			gap: 8px;
			flex: 0 0 175px;
			@include tablet {
				font-size: 18px;
			}
			@include mobile {
				width: 100%;
				height: 32px;
				padding: 4px 8px;
				border-radius: 12px;
				flex: 0 0 32px;
			}
			.icon-main-noti {
				@include icon;
				width: 48px;
				height: 38px;
				background-image: url(~@/assets/img/main/icon_bell.png);
				@include tablet {
					width: 32px;
					height: 25px;
				}
			}
		}
	}
	.container {
		position: relative;
		z-index: 1;
		display: flex;
		padding: 7px;
		height: 60px;
		border-radius: 20px;
		border: 1px solid #eee;
		background-color: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(10px);
		box-shadow: 0 0 10px rgba(141, 85, 49, 0.25);
		gap: 26px;
		@include tablet {
			gap: 6px;
		}
		@include mobile {
			height: 110px;
			border-radius: 16px;
			flex-direction: column;
		}
	}
	.swiper {
		&-container {
			width: 100%;
			padding: 0 1em;
			@include mobile {
				padding: 0;
			}
		}
		&-wrapper {
			height: 36px;
			margin-top: 9px;
			@include mobile {
				height: 52px;
			}
		}
		&-slide {
			display: flex;
			padding-right: 30px;
			align-items: center;
			gap: 16px;
			@include tablet {
				padding-right: 20px;
			}
			@include mobile {
				display: block;
				padding-top: 2px;
				padding-right: 28px;
			}
			a {
				position: relative;
				width: 50%;
				@include text-ellipsis;
				padding-left: 20px;
				font-size: 21px;
				font-weight: 500;
				color: #424242;
				@include tablet {
					font-size: 16px;
				}
				@include mobile {
					width: 100%;
					margin: 10px 0;
					// padding: 3px 0;
					font-size: 14px;
				}
				&:hover {
					text-decoration: underline;
				}
				&::before {
					content: '\00b7';
					position: absolute;
					left: 0;
					top: 50%;
					display: inline-block;
					height: 10px;
					margin-top: -6px;
					line-height: 10px;
					font-size: 2.3em;
					font-weight: 700;
					color: #ff7300;
				}
			}
		}
	}
}
