#page-privacy {
	line-height: 1.3;
	.auth-component {
		width: auto;
		max-width: 1400px;
	}
	.mt1 {
		margin-top: 0.3em;
	}
	.mr1 {
		margin-right: 0.3em;
	}
	.section-policies {
		padding-top: 5em;
		// margin-bottom: 3em;
		// margin-top: 3em;
		// margin-bottom: 3em;
		@include tablet {
			padding-top: 3.5em;
		}
		&-title {
			margin-bottom: 0.5em;
			line-height: 1.2;
			font-size: 20px;
			font-weight: 700;
		}
		&-desc {
			margin-top: 0.5em;
		}
		&-infograph {
			padding: 1em 0;
			img {
				display: block;
				width: 100%;
				max-width: 1300px;
				margin: 0 auto 1em;
				height: auto;
			}
		}
	}
	.page-header {
		position: relative;
		padding-top: 50px;
		padding-bottom: 30px;
		border-bottom: 1px solid #ebebeb;
		.title {
			font-weight: 900;
			font-size: 30px;
			color: #111;
		}
	}
	.privacy-legend {
		padding: 20px;
		border: 1px solid #005aa9;
		.tooltip-item {
			em {
				margin-top: 95px;
			}
		}

		&-list {
			display: flex;
			gap: 30px;
			align-items: flex-start;
			justify-content: space-around;
			@include mobile {
				flex-wrap: wrap;
				gap: 10px;
			}
			> li {
				display: flex;
				width: 50%;
				text-align: center;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				@include word-wrap;
				.text {
					margin-top: 0.5em;
					line-height: 1.2;
					@include mobile {
						letter-spacing: -1px;
						font-size: 14px;
					}
				}
				@include mobile {
					width: 25%;
				}
			}
		}
		.icon-privacy {
			width: 58px;
			height: 65px;
		}
	}
	.privacy-index {
		padding: 20px;
		border: 1px solid #dbdbdb;
		&-list {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			> li {
				display: flex;
				width: calc(50% - 5px);
				align-items: center;
				@include until(500px) {
					width: 100%;
				}
				@include mobile {
					font-size: 14px;
					letter-spacing: -1px;
				}
			}
		}
		&-link {
			margin-right: 0.5em;
			@include word-wrap;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.tos-content {
		position: relative;
		padding-top: 10px;
		// white-space: pre-line;
		p {
			font-size: 15px;
			line-height: 1.5;
		}
		.title {
			font-size: 15px;
			line-height: 20px;
			font-weight: 700;
		}
		.btn {
			margin-top: 30px;
			.text {
				color: #fff !important;
			}
			&:hover .text {
				text-decoration: underline;
			}
		}
	}
	.tos-history {
		text-align: right;
		margin-bottom: 20px;
		p {
			font-weight: 900;
		}
		&.bottom {
			text-align: left;
			margin-top: 30px;
		}
	}
	.tos-table {
		overflow-x: auto;
		margin-top: 20px;
		margin-bottom: 30px;
		caption {
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 1px;
			font-size: 1px;
			line-height: 100px;
			white-space: nowrap;
		}
		table {
			min-width: 1020px;
			width: 99.9%;
			table-layout: fixed;
			font-size: 15px;
			line-height: 20px;
			@include mobile {
				font-size: 14px;
			}
		}
		thead th,
		thead td,
		tbody td {
			padding: 10px 15px;
			border: 1px solid #dbdbdb;
		}
		thead th,
		thead td {
			border-bottom: none;
			background-color: #f7f7f7;
			font-weight: bold;
			text-align: center;
		}
		tbody td {
			text-align: center;
			vertical-align: top;
			line-height: 1.3;
			.tooltip-item {
				margin-top: -1em;
			}
			&.cell-middle {
				padding-top: 0;
				padding-bottom: 0;
				vertical-align: middle;
			}
			a {
				text-decoration: underline;
				color: #005aa9;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	#tos-table-01 {
		.col-1 {
			width: 13%;
		}
		.col-2 {
			width: 13%;
		}
		.col-3 {
			width: auto;
		}
		.col-4 {
			width: 20%;
		}
		.col-5 {
			width: 15%;
		}
	}
	#tos-table-02 {
		table {
			min-width: 800px;
		}
		.col-1 {
			width: 30%;
			min-width: 300px;
		}
		.col-2 {
			width: 10%;
			min-width: 100px;
		}
		.col-3 {
			width: auto;
		}
	}
	#tos-table-03 {
		.col-1 {
			width: 180px;
		}
		.col-2 {
			width: auto;
		}
		.col-3 {
			width: 150px;
		}
	}
	.process {
		position: relative;
		padding: 30px;
		background-color: #f7f7f7;
		margin-top: 30px;
		overflow-x: auto;
		.process-steps {
			display: flex;
			min-width: 900px;
			width: 100%;
		}
		.step {
			position: relative;
			flex: 1;
			text-align: center;
			~ .step:before {
				content: '';
				position: absolute;
				left: -20px;
				top: -11px;
				width: 40px;
				height: 40px;
				background: url(~@/assets/img/common/icon_arrow_right.svg) no-repeat 50%;
			}
		}
		.step-title {
			font-size: 20px;
			font-weight: 900;
			color: #015aa8;
			margin-bottom: 30px;
		}
		.step-text {
			font-size: 15px;
		}
	}

	#popup-policies {
		max-width: 1100px;
		width: 100%;
		.tos-content {
			position: relative;
			padding-top: 10px;
			padding-bottom: 80px;
			p {
				font-size: 15px;
				line-height: 20px;
			}
			.title {
				font-size: 15px;
				line-height: 20px;
				font-weight: 700;
			}
			.btn {
				margin-top: 30px;
				.text {
					color: #fff !important;
				}
				&:hover .text {
					text-decoration: underline;
				}
			}
		}
		.tos-history {
			text-align: right;
			margin-bottom: 20px;
			p {
				font-weight: 900;
			}
			&.bottom {
				text-align: left;
				margin-top: 30px;
			}
		}
		.tos-table {
			overflow-x: auto;
			margin-top: 20px;
			margin-bottom: 30px;
			table {
				min-width: 1020px;
				width: 99.9%;
				table-layout: fixed;
				font-size: 15px;
				line-height: 20px;
			}
			thead th {
				height: 60px;
				padding: 20px;
				background-color: #f7f7f7;
				border: 1px solid #999;
			}
			tbody td {
				height: 60px;
				padding: 20px;
				border: 1px solid #999;
				vertical-align: top;
				&.cell-middle {
					padding-top: 0;
					padding-bottom: 0;
					vertical-align: middle;
				}
			}
		}
		#tos-table-01 {
			.col-1 {
				width: 13%;
			}
			.col-2 {
				width: 13%;
			}
			.col-3 {
				width: auto;
			}
			.col-4 {
				width: 20%;
			}
			.col-5 {
				width: 15%;
			}
		}
		#tos-table-02 {
			table {
				min-width: 800px;
			}
			.col-1 {
				width: 30%;
				min-width: 300px;
			}
			.col-2 {
				width: 10%;
				min-width: 100px;
			}
			.col-3 {
				width: auto;
			}
		}
		#tos-table-03 {
			.col-1 {
				width: 180px;
			}
			.col-2 {
				width: auto;
			}
			.col-3 {
				width: 150px;
			}
		}
		.process {
			position: relative;
			padding: 30px;
			background-color: #f7f7f7;
			margin-top: 30px;
			overflow-x: auto;
			.process-steps {
				display: flex;
				min-width: 900px;
				width: 100%;
			}
			.step {
				position: relative;
				flex: 1;
				text-align: center;
				~ .step:before {
					content: '';
					position: absolute;
					left: -20px;
					top: -11px;
					width: 40px;
					height: 40px;
					background: url(~@/assets/img/common/icon_arrow_right.svg) no-repeat 50%;
				}
			}
			.step-title {
				font-size: 20px;
				font-weight: 900;
				color: #015aa8;
				margin-bottom: 30px;
			}
			.step-text {
				font-size: 15px;
			}
		}
	}
}

.tooltip-item {
	display: inline-block;
	position: relative;
	.text {
		display: block;
	}
	em {
		position: absolute;
		width: 154px;
		left: 50%;
		z-index: 1;
		margin-left: -77px;
		margin-top: 42px;
		padding: 0.5em 0.3em;
		border-radius: 4px;
		border: 1px solid #005aa9;
		background-color: #fff;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.35;
		text-align: center;
		visibility: hidden;
		opacity: 0;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
		&.text-left {
			padding-left: 0.9em;
		}
		&:before {
			content: '';
			position: absolute;
			top: -8px;
			left: 50%;
			display: block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 6px 8px 6px;
			border-color: transparent transparent #005aa9 transparent;
			transform: translateX(-50%);
		}
		&:after {
			content: '';
			position: absolute;
			top: -7px;
			left: 50%;
			display: block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 6px 8px 6px;
			border-color: transparent transparent #fff transparent;
			transform: translateX(-50%);
		}
	}
	> i:hover {
		+ em {
			visibility: visible;
			opacity: 1;
		}
	}
}
.icon-privacy {
	@include icon;
	width: 33px;
	height: 37px;
	&.privacy {
		&1-1 {
			background-image: url(~@/assets/img/common/privacy1-1.png);
		}
		&2-1 {
			background-image: url(~@/assets/img/common/privacy2-1.png);
		}
		&2-2 {
			background-image: url(~@/assets/img/common/privacy2-2.png);
		}
		&2-3 {
			background-image: url(~@/assets/img/common/privacy2-3.png);
		}
		&2-4 {
			background-image: url(~@/assets/img/common/privacy2-4.png);
		}
		&2-5 {
			background-image: url(~@/assets/img/common/privacy2-5.png);
		}
		&2-6 {
			background-image: url(~@/assets/img/common/privacy2-6.png);
		}
		&3-1 {
			background-image: url(~@/assets/img/common/privacy3-1.png);
		}
		&3-2 {
			background-image: url(~@/assets/img/common/privacy3-2.png);
		}
		&3-3 {
			background-image: url(~@/assets/img/common/privacy3-3.png);
		}
		&4-1 {
			background-image: url(~@/assets/img/common/privacy4-1.png);
		}
		&4-2 {
			background-image: url(~@/assets/img/common/privacy4-2.png);
		}
		&4-3 {
			background-image: url(~@/assets/img/common/privacy4-3.png);
		}
		&4-4 {
			background-image: url(~@/assets/img/common/privacy4-4.png);
		}
		&4-5 {
			background-image: url(~@/assets/img/common/privacy4-5.png);
		}
		&4-6 {
			background-image: url(~@/assets/img/common/privacy4-6.png);
		}
		&4-7 {
			background-image: url(~@/assets/img/common/privacy4-7.png);
		}
	}
}
