.support {
	&-section {
		.container {
			max-width: 1230px;
			padding: 0 40px;
			@include mobile {
				padding: 0 15px;
			}
		}
		&-header {
			margin-top: 70px;
			margin-bottom: 30px;
			@include mobile {
				margin-top: 30px;
				margin-bottom: 20px;
			}
		}
		&-title {
			font-size: 26px;
			font-weight: 700;
			line-height: 1.1;
			@include mobile {
				font-size: 19px;
			}
		}
		&-body {
			.fp-biz-buttons {
				margin-left: 0;
				margin-right: 0;
			}
		}
		&-biz {
			&__form {
				margin: 30px 0;
				.form-row {
					gap: 10px;
					flex-wrap: wrap;
					@include mobile {
						flex-direction: column;
					}
					.control {
						@include tablet {
							width: calc(50% - 10px);
							flex: 1 1 43%;
						}
						@include mobile {
							width: 100%;
						}
						&-group {
							display: flex;
							gap: 10px;
							align-items: center;
							@include tablet {
								width: 100%;
								margin-bottom: 0.5em;
								.control {
									flex: 1 1;
								}
							}
							+ .control.flex-1 {
								.checkbox-list {
									padding-left: 11%;
									@include tablet {
										padding-left: 0;
									}
								}
							}
						}
						&.flex-1 {
							display: flex;
							flex: 1 1;
							justify-content: flex-end;
							@include tablet {
								display: block;
							}
							.checkbox-list {
								padding-left: 0;
								flex: 1 1;
								justify-content: flex-start;
								gap: 10px;
								@include tablet {
									gap: 5px;
									flex-wrap: wrap;
									justify-content: flex-start;
								}
								.check {
									display: inline-flex;
								}
								input[type='checkbox'] {
									position: absolute;
									overflow: hidden;
									width: 1px;
									height: 1px;
									margin: -1px;
									padding: 0;
									clip: rect(0, 0, 0, 0);
									border: 0;
									&:focus + label {
										box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
										// border-color: #111;
									}
									&[disabled] {
										+ label {
											opacity: 1;
											color: #808ea9;
											color: #cdd3dd;
											background-color: #e6ebf1;
											border-color: #cdd3dd;
										}
										&:checked {
											+ label {
												border-color: #ff7300;
												background-color: #ff7300;
												color: #fff;
											}
										}
									}
									+ label {
										display: flex;
										min-width: 60px;
										height: 30px;
										padding: 5px 10px;
										border: 1px solid #645f5b;
										border-radius: 30px;
										background: #fff;
										font-size: 16px;
										color: #645f5b;
										align-items: center;
										justify-content: center;
										cursor: pointer;
									}
									&:checked + label {
										color: #fff;
										background-color: #ff7300;
										border-color: #ff7300;
									}
									// &:focus:checked + label {
									// 	// border-color: #111;
									// }
								}
							}
							.control-nums {
								display: flex;
								padding-bottom: 5px;
								font-size: 16px;
								color: #645f5b;
								align-items: flex-end;
								flex: 0 0;
								white-space: nowrap;
								@include tablet {
									margin-top: 0.5em;
									flex: none;
									justify-content: flex-start;
								}
								strong {
									display: inline-block;
									margin-left: 0.2em;
									font-size: 21px;
									font-weight: 700;
								}
							}
						}
					}
					@include until(500px) {
						.form-select {
							min-width: inherit;
						}

						.button-default {
							padding: 0 20px;
							// flex: 0 0 68px;
						}
					}
				}
			}
			> ul {
				display: flex;
				gap: 20px;
				flex-wrap: wrap;
				> li {
					width: calc(25% - 15px);
					@include tablet {
						width: calc(33% - 15px);
					}
					@include mobile {
						width: calc(50% - 15px);
					}
					@include until(400px) {
						width: 100%;
					}
				}
			}
		}
	}
}

.form-select {
	&.is-small {
		min-width: 150px;
		height: 30px;
		padding-top: 0;
		padding-right: 30px;
		padding-bottom: 0;
		padding-left: 15px;
		border: 1px solid #bdbdbd;
		background-position: calc(100% - 12px) 50%;
		border-radius: 5px;
		font-size: 14px;
		font-weight: 400;
		color: #424242;
		box-shadow: none;
	}
}

.checkbox-list {
	display: flex;
}

.control-nums {
	justify-content: flex-end;
	flex: 0 0;
}
