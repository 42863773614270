.keyword {
	color: $orange;
}

.scomm {
	&-section {
		padding-top: 30px;
		padding-bottom: 30px;
		.container {
			max-width: 1230px;
			padding: 0 40px;
			@include mobile {
				padding: 0 15px;
			}
		}
		&--bg {
			background-color: #f2f0ed;
			@include mobile {
				padding: 20px 0;
			}
		}
	}
	&-main {
		&-slogan {
			margin-top: 30px;
			@include mobile {
				display: block;
				margin-top: 0px;
			}
			&__strong {
				margin-bottom: 10px;
				color: $orange;
				font-size: clamp(33px, 10vw, 43px);
				font-family: 'GmarketSans';
				font-weight: 700;
				line-height: 1;
			}
			.flex-0 {
				clear: both;
				.link-text {
					float: right;
				}
			}
			&__desc {
				max-width: 450px;
				font-size: 16px;
				line-height: 1.2;
				@include word-wrap;
				@include mobile {
					margin-bottom: 10px;
					max-width: inherit;
					font-size: 14px;
					br {
						display: none;
					}
				}
			}
			.link-text {
				font-size: 12px;
				font-weight: 400;
				color: #757575;
				text-decoration: underline;
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
		&-pic {
			padding: 0 50px;
			@include mobile {
				padding: 0;
			}
			&__list {
				padding: 50px 0;
				background-image: url(~@/assets/img/scommunity/main_visual.png);
				background-position: 100% 50%;
				background-repeat: no-repeat;
				background-size: 510px auto;
				@include until(1000px) {
					margin-bottom: 30px;
					padding-bottom: clamp(190px, 53vw, 320px);
					background-position: 50% 100%;
					background-repeat: no-repeat;
					background-size: auto clamp(170px, 50vw, 300px);
				}
				li {
					max-width: 400px;
					@include until(1000px) {
						max-width: inherit;
					}
					&:not(:last-child) {
						margin-bottom: 15px;
					}
					> strong {
						position: relative;
						display: block;
						padding-left: 25px;
						margin-bottom: 10px;
						font-size: 26px;
						font-weight: 700;
						line-height: 1.1;
						@include mobile {
							font-size: 16px;
							padding-left: 20px;
						}
						> em {
							position: absolute;
							left: 0;
							display: inline-block;
							width: 25px;
							color: $orange;
							@include mobile {
								width: 20px;
							}
						}
					}
					> p {
						padding-left: 25px;
						font-size: 16px;
						font-weight: 400;
						color: #757575;
						line-height: 1.3;
						@include word-wrap;
						@include mobile {
							padding-left: 20px;
							font-size: 14px;
						}
					}
				}
			}
		}
		&-search {
			position: relative;
			&__input {
				width: 100%;
				height: 31px;
				padding: 5px 0 10px 40px;
				font-size: 16px;
				border: none;
				border-bottom: 1px solid #bdbdbd;
				background: transparent;
				outline: 0;
				@include transition(border, 0.5s);
				@include placeholder {
					color: #bdbdbd;
				}
				&:focus {
					border-bottom: 1px solid #000;
				}
			}
			&__submit {
				display: inline-flex;
				position: absolute;
				left: 0;
				top: 0;
				width: 25px;
				height: 25px;
				align-items: center;
				justify-content: center;
			}
		}
	}
	&-result-desc {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 1.2;
		@include mobile {
			font-size: 14px;
		}
	}

	&-card {
		a {
			color: #424242;
			line-height: 1.2;
		}
		&:not(:last-child) {
			margin-bottom: 20px;
			@include mobile {
				margin-bottom: 10px;
			}
		}
		padding: 10px;
		border-radius: 15px;
		background: #ffffff;
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
		&-item {
			> .flex-l {
				flex: 0 0;
			}
			> .flex-r {
				overflow: hidden;
				padding-left: 30px;
				flex: 1 1 auto;
				@include mobile {
					padding-left: 20px;
				}
			}
			&__text {
				align-items: flex-end;
				> .flex-l {
					overflow: hidden;
					padding-right: 30px;
					flex: 1 1 auto;
				}
				> .flex-r {
					flex: 0 0;
					white-space: nowrap;
					@include mobile {
						display: none;
					}
				}
			}
		}
		.flex-both {
		}
		&-thumb {
			display: flex;
			overflow: hidden;
			max-width: 120px;
			width: 120px;
			height: 100%;
			max-height: 90px;
			border-radius: 8px;
			align-items: center;
			justify-content: center;
			img {
				object-fit: cover;
			}
		}
		&-path {
			max-width: 100%;
			margin-bottom: 7px;
			font-size: 14px;
			color: #bdbdbd;
			line-height: 1;
			@include text-ellipsis;
			@include mobile {
				font-size: 11px;
			}
		}
		&-title {
			margin-bottom: 7px;
			font-size: 26px;
			font-weight: 700;
			@include desktop {
				@include text-ellipsis;
			}
			@include tablet {
				@include text-ellipsis-2;
			}
			@include mobile {
				font-size: 14px;
			}
		}
		&-desc {
			font-size: 16px;
			font-weight: 400;
			@include desktop {
				@include text-ellipsis;
			}
			@include tablet {
				@include text-ellipsis-2;
			}
			@include mobile {
				font-size: 12px;
			}
		}
		&-info {
			display: flex;
			margin-top: 5px;
			font-size: 14px;
			font-weight: 400;
			align-items: center;
			dt {
				margin-right: 15px;
			}
		}
	}

	&-detail {
		&-header {
			display: flex;
			margin-top: 15px;
			margin-bottom: 45px;
			align-items: flex-start;
			justify-content: space-between;
			gap: 20px;
			@include mobile {
				margin-top: 0;
				margin-bottom: 1em;
				gap: 10px;
				flex-direction: column;
			}
			&__title {
				margin-top: -2px;
				font-size: 26px;
				font-weight: 700;
				line-height: 1.2;
				flex: 1 1 auto;
				@include mobile {
					font-size: 24px;
				}
			}
			.scomm-main-search {
				width: 205px;
				flex: 0 0 205px;
				@include mobile {
					width: 100%;
					flex: 1 1 auto;
				}
			}
		}
		&-flex {
			display: flex;
			justify-content: space-between;
			gap: 40px;
			@include mobile {
				display: block;
			}
			> .flex-0 {
				padding: 15px;
				border: 1px solid #bdbdbd;
				border-radius: 15px;
				flex: 0 0 200px;
				@include mobile {
					display: flex;
					padding: 8px;
					align-items: flex-start;
					gap: 10px;
					margin-bottom: 40px;
				}
				[role='tablist'] {
					display: flex;
					margin-bottom: 0.7em;
					padding-bottom: 0.4em;
					border-bottom: 2px solid #645f5b;
					align-items: center;
					justify-content: space-around;
					@include mobile {
						width: 120px;
						margin-bottom: 0.4em;
						padding-bottom: 0;
						border-bottom: none;
						justify-content: space-between;
					}
					button {
						border: none;
						padding: 5px 8px;
						background-color: transparent;
						font-weight: 400;
						font-size: 14px;
						color: #888;
						@include mobile {
							position: relative;
							width: auto;
							padding: 2px 0;
							font-size: 12px;
						}
						&[aria-selected='true'] {
							color: #424242;
							font-weight: 700;
						}
						&:not(:first-child) {
							&::before {
								content: '';
								display: inline-block;
								width: 1px;
								height: 10px;
								margin: 0 10px 0 -12px;
								border-left: 1px solid #d1d1d1;
								vertical-align: middle;
								@include mobile {
									margin: -2px 7px 0 0;
								}
							}
						}
					}
				}
				.button-default {
					margin: 1.4em 0;
					.is-mobile {
						display: none;
					}
					@include mobile {
						position: fixed;
						width: 100%;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 10;
						height: 40px;
						margin: 0;
						border-radius: 0;
						.is-desktop {
							display: none;
						}
						.is-mobile {
							display: inline-flex;
							align-items: center;
							justify-content: center;
							.icon-info-join {
								margin-right: 8px;
							}
						}
					}
				}
			}
			> .flex-1 {
				flex: 1 1 auto;
			}
		}
		&-info {
			&__thumb {
				display: flex;
				overflow: hidden;
				width: 170px;
				height: 170px;
				margin-bottom: 10px;
				border: 1px solid rgba(0, 0, 0, 0.25);
				border-radius: 7px;
				align-items: center;
				justify-content: center;
				img {
					object-fit: contain;
				}
				+ div {
					flex: 1 1 auto;
				}
				@include mobile {
					width: 70px;
					height: 70px;
					margin: 0;
					flex: 0 0 70px;
				}
			}
			&__panel {
				.scomm-detail-user {
					&__photo {
						width: 50px;
						height: 50px;
						flex: 0 0 50px;
						@include mobile {
							width: 30px;
							height: 30px;
							flex: 0 0 30px;
						}
					}
					&__name {
						margin-bottom: 0.3em;
					}
				}
			}
			&__links {
				@include mobile {
					display: none;
				}
				> ul {
					> li {
						margin-bottom: 1em;
						> a {
							display: flex;
							color: #424242;
							align-items: center;
							gap: 8px;
							> i {
								display: block;
								flex: 0 0 14px;
								width: 14px;
								height: 14px;
							}
							> span {
								font-size: 16px;
							}
						}
					}
				}
			}
			&__dl {
				display: flex;
				overflow: hidden;
				margin-top: 1em;
				color: #424242;
				font-size: 14px;
				font-weight: 400;
				align-items: center;
				justify-content: space-between;
				gap: 10px;
				@include mobile {
					display: inline-flex;
					width: 50%;
					padding: 0 0.2em 0 0;
					margin-top: 0.5em;
					font-size: 12px;
					justify-content: flex-start;
				}
				@include until(420px) {
					width: 100%;
					padding: 0;
				}
				dt {
					display: flex;
					flex: 1 1 auto;
					gap: 5px;
					> i {
						flex: 0 0 14px;
						@include mobile {
							display: none;
						}
					}
					> .text {
						display: block;
						@include text-ellipsis;
						flex: 1 1 auto;
					}
					@include mobile {
						flex: none;
					}
				}
				dd {
					flex: 0 0 35px;
					text-align: right;
					white-space: nowrap;
					@include mobile {
						flex: none;
					}
				}
				a {
					color: #424242;
					text-decoration: underline;
					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}
		}
		&-user {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 5px;
			&__photo {
				width: 30px;
				height: 30px;
				flex: 0 0 30px;
				overflow: hidden;
				border-radius: 50%;
				background-repeat: no-repeat;
				background-image: url(~@/assets/img/scommunity/icon_article_noimg.svg);
				background-size: cover;
				background-position: 50% 50%;
			}
			&__text {
				flex: 1 1 auto;
			}
			&__name {
				display: flex;
				line-height: 1;
				font-size: 14px;
				font-weight: 700;
				align-items: center;
			}
			&__tag {
				display: inline-block;
				padding: 1px 5px;
				color: #ff7300;
				margin-left: 5px;
				border-radius: 14px;
				border: 1px solid #ff7300;
				line-height: 1;
				font-size: 12px;
				font-weight: 400;
			}
			&__date {
				display: inline-block;
				margin-top: 3px;
				font-size: 12px;
				font-weight: 400;
				color: #bdbdbd;
			}
			&__count {
				display: inline-block;
				margin-top: 3px;
				margin-left: 1em;
				font-size: 12px;
				font-weight: 400;
				color: #bdbdbd;
			}
		}
		&-notice {
			margin-bottom: 2em;
			&__header {
				display: flex;
				padding-bottom: 10px;
				border-bottom: 1px solid #bdbdbd;
				align-items: center;
				justify-content: space-between;
			}
			&__title {
				font-size: 21px;
				font-weight: 700;
			}
			&__more {
				font-size: 14px;
				color: #757575;
			}
			&__content {
			}
			&__item {
				display: flex;
				overflow: hidden;
				margin-top: 0.5em;
				align-items: center;
				justify-content: space-between;
			}
			&__link {
				display: flex;
				align-items: center;
				width: calc(100% - 50px);
				font-size: 14px;
				font-weight: 700;
				color: #757575;
				strong {
					display: block;
					@include text-ellipsis;
				}
				&:hover,
				&:focus {
					strong {
						text-decoration: underline;
					}
				}
			}
			&__tag {
				display: inline-flex;
				width: 35px;
				height: 20px;
				margin-right: 11px;
				border-radius: 4px;
				background-color: #645f5b;
				font-size: 12px;
				font-weight: 700;
				color: #fff;
				align-items: center;
				justify-content: center;
				flex: 0 0 35px;
			}
			&__count {
				font-size: 12px;
				font-weight: 400;
				color: #757575;
			}
		}
		&-write {
			position: relative;
			margin-top: 20px;
			margin-bottom: 20px;
			padding: 12px;
			border: 1px solid #bdbdbd;
			border-radius: 15px;
			.textarea {
				width: 100%;
				height: 80px;
				padding: 10px;
				border-radius: 4px;
				border: 1px solid #eee;
				background-color: #fff;
				font-size: 14px;
			}
			&__header {
				strong {
					display: block;
					font-size: 14px;
					font-weight: 700;
					line-height: 1.2;
					margin-bottom: 0.5em;
				}
			}
			&__footer {
				display: flex;
				margin-top: 8px;
				align-items: center;
				justify-content: space-between;
				.flex-l {
					display: flex;
					align-items: center;
					flex: 1 1 auto;
				}
				.flex-r {
					flex: 0 0 100px;
					text-align: right;
				}
				.button-upload-photo {
					display: flex;
					width: 19px;
					height: 19px;
					margin-right: 12px;
					align-items: center;
					justify-content: center;
					input[type='file'] {
						display: none;
					}
					label {
						display: flex;
						width: 19px;
						height: 19px;
						cursor: pointer;
						align-items: center;
						justify-content: center;
						.icon-detail-photo {
							width: 19px;
							height: 19px;
						}
					}
				}
				.button-default {
					width: 36px;
					height: 21px;
					padding: 0;
					border-radius: 5px;
					background-color: #9a918b;
					color: #fff;
					font-size: 14px;
					font-weight: 400;
					line-height: 1;
				}
				&-count {
					font-size: 12px;
					color: #9a918b;
				}
			}
			&__upload {
				position: relative;
				display: flex;
				align-items: center;
				margin-right: 12px;
				.thumb {
					display: flex;
					overflow: hidden;
					margin-right: 5px;
					width: 30px;
					height: 30px;
					border: 1px solid #eee;
					border-radius: 5px;
					align-items: center;
					justify-content: center;
					img {
						object-fit: contain;
					}
				}
				.button-delete {
					@include delete;
					background-color: #ff2f35;
					position: absolute;
					top: -3px;
					right: 0;
					min-width: 14px;
					max-width: 14px;
					min-height: 14px;
					max-height: 14px;
					&:before,
					&:after {
						background-color: #fff;
					}
					&:before {
						width: 60%;
					}
					&:after {
						height: 60%;
					}
					&:hover {
						background-color: #d11d23;
					}
				}
			}
		}
		&-articles {
			li {
				.scomm-detail-write {
					margin-top: 1em;
					margin-bottom: 0;
					background-color: #fff;
					.textarea {
						height: 40px;
					}
				}
			}
			> li {
				&.is-pinned {
					> article {
						border-radius: 15px;
						border: 1px solid #ff7300;
						&::before {
							content: '';
							display: flex;
							position: absolute;
							top: -10px;
							left: -2px;
							width: 19px;
							height: 28px;
							@include icon;
							background-image: url(~@/assets/img/scommunity/icon_pin.svg);
						}
					}
					+ .is-pinned {
						margin-top: 1em;
					}
				}
				> article {
					position: relative;
					border-bottom: 1px solid #bdbdbd;
					padding: 3px 12px;

					> ul {
						padding: 0 15px 15px 40px;
						border-top: 1px solid #eaeaea;
						background-color: #f9f9f9;
						@include mobile {
							padding-left: 15px;
						}
						.scomm-detail-article {
							border-bottom: 1px solid #eaeaea;
						}
						li {
							&:not(:last-child) {
								margin-bottom: 1em;
							}
						}
						> li {
							> ul {
								padding-left: 30px;
								@include mobile {
									padding-left: 15px;
								}
							}
						}
					}
				}
				// padding: 15px 15px 15px 50px;
				// border-top: 1px solid #eaeaea;
				// background-color: #f9f9f9;
				//
				// > li {
				// 	> ul {
				// 		padding: 15px 0 15px 50px;
				// 	}
				// }
				// }
			}
		}
		&-article {
			position: relative;
			padding-top: 1em;
			padding-bottom: 1em;

			&__header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;
			}
			.button-open-util {
				display: flex;
				width: 30px;
				height: 30px;
				align-items: center;
				justify-content: center;
			}
			&__util {
				display: block;
				position: absolute;
				top: 45px;
				right: 0;
				padding: 10px;
				background-color: #f2f0ed;
				border-radius: 10px;
				text-align: center;
				z-index: 10;
				.button {
					display: block;
					padding: 5px 10px;
					font-size: 14px;
					font-weight: 400;
					color: #000;
					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
			&__content {
				font-size: 14px;
				line-height: 1.4;
			}
			&__image {
				display: flex;
				margin-top: 10px;
				width: 100px;
				height: 100px;
				overflow: hidden;
				border-radius: 5px;
				align-items: center;
				justify-content: center;
				.lightBox {
					position: relative;
					> img {
						object-fit: cover;
						height: 100%;
					}
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 23px;
						height: 23px;
						top: 3px;
						right: 3px;
						background-image: url(~@/assets/img/scommunity/icon_viewplus.svg);
						background-position: 50% 50%;
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
			}
			&__footer {
				display: flex;
				margin-top: 10px;
				font-size: 12px;
				font-weight: 400;
				align-items: center;
				justify-content: flex-start;
				gap: 20px;
				.date {
					font-size: 12px;
					color: #bdbdbd;
				}
				.button {
					display: flex;
					font-size: 12px;
					font-weight: 400;
					gap: 4px;
					align-items: center;
					.text {
						&.is-strong {
							font-weight: 700;
						}
					}
				}
			}
		}
		&-search {
			margin-bottom: 10px;
			padding: 20px 15px;
			border: 1px solid #bdbdbd;
			border-radius: 15px;
			font-size: 16px;
			&__cate {
				display: flex;
				margin-bottom: 20px;
				font-size: 16px;
				line-height: 1.3;
				font-weight: 400;
				align-items: center;
				@include tablet {
					display: block;
					font-size: 14px;
				}
				.display-inline-block {
					display: inline-block;
				}
				ul {
					display: inline-flex;
					align-items: center;
					li {
						&::after {
							content: '';
							display: inline-block;
							width: 8px;
							height: 8px;
							margin: 0 8px 0 5px;
							border-top: 1px solid #bdbdbd;
							border-right: 1px solid #bdbdbd;
							border-width: 1px;
							transform: rotate(45deg) translate(-10%, -10%);
						}
					}
				}
				.keyword {
					display: inline-block;
					margin: 0 3px;
				}
			}
			&__form {
				.text-link {
					display: flex;
					width: 100%;
					border: none;
					padding: 8px 15px;
					background-color: transparent;
					font-size: 14px;
					color: #424242;
					line-height: 1;
				}
				@include tablet {
					.field {
						flex-wrap: wrap;
						align-items: flex-start;
						.control {
							width: 100%;
							flex: none;
							&-input {
								width: calc(100% - 88px);
							}
							&-submit {
								width: 77px;
								flex: 0 0 77px;
							}
						}
					}
				}
				.dropdown-item {
					padding: 0;
					&-date {
						flex-direction: column;
						.dropdown-text {
							display: block;
							width: 100%;
							padding: 8px 15px;
						}
						.input-date-wrap {
							display: flex;
							width: 100%;
							padding: 0px 5px 10px 5px;
							gap: 3px;
							.input {
								min-width: inherit;
								height: 22px;
								padding: 0 1px;
								font-size: 11px;
								flex: 1 1;
							}
							.button-default.is-small {
								width: 30px;
								height: 22px;
								border-radius: 25px;
								background-color: #645f5b;
								font-size: 12px;
								color: #fff;
								flex: 0 0 30px;
								@include tablet {
									flex: 0 0 50px;
								}
							}
						}
					}
				}
				.button-submit {
					width: 100%;
					height: 30px;
					border-radius: 30px;
					font-size: 16px;
					font-weight: 400;
				}
				.control {
					width: 100%;
					flex: 1 1;
					.dropdown {
						width: 100%;
					}
				}
				.dropdown .dropdown-trigger {
					width: 100%;
				}
				.control-submit {
					flex: 0 0 68px;
				}
			}
		}
	}
}
.icon-scomm-search {
	width: 25px;
	height: 25px;
	background-image: url(~@/assets/img/scommunity/icon_search.svg);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;
}
.color-orange {
	color: $orange;
}

.page-container {
	.pagination {
		margin-bottom: 0;
		@include tablet {
			margin-bottom: 30px;
		}
	}
}

%icon-info {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;
}
.icon-info {
	&-member {
		width: 13px;
		height: 11px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_member.svg);
	}
	&-board {
		width: 13px;
		height: 13px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_board.svg);
	}
	&-user {
		width: 14px;
		height: 14px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_user.svg);
	}
	&-join {
		width: 25px;
		height: 18px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_join.svg);
	}
	&-myarticle {
		width: 13px;
		height: 14px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_myarticle.svg);
	}
	&-mycomm {
		width: 14px;
		height: 16px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_mycomm.svg);
	}
	&-like {
		width: 12px;
		height: 12px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_like.svg);
	}
	&-home {
		width: 13px;
		height: 13px;
		flex: 0 0 13px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_home.svg);
	}
	&-notice {
		@extend %icon-info;
		background-position: 0 50%;
		background-image: url(~@/assets/img/scommunity/icon_info_notice.svg);
	}
	&-data {
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_info_data.svg);
	}
}
.icon-detail {
	&-photo {
		width: 17px;
		height: 17px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_detail_photo.svg);
	}
	&-more {
		width: 15px;
		height: 4px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_detail_more.svg);
	}
	&-like {
		width: 18px;
		height: 16px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_detail_like.svg);
	}
	&-like-o {
		width: 18px;
		height: 16px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_detail_like_o.svg);
	}
	&-comment {
		width: 18px;
		height: 16px;
		@extend %icon-info;
		background-image: url(~@/assets/img/scommunity/icon_detail_comment.svg);
	}
}
.button-default {
	display: inline-flex;
	width: 100%;
	height: 30px;
	padding: 0 10px;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	&.is-primary {
		background-color: #645f5b;
		color: #fff;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #ff7300;
			}
		}
		@include tablet {
			&:active {
				background-color: #ff7300;
			}
		}
		&.is-rounded {
			width: auto;
			// padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
			&.is-disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
	&.is-secondary {
		background-color: #ff7300;
		color: #fff;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #c45800;
			}
		}
		@include tablet {
			&:active {
				background-color: #c45800;
			}
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
			&.is-disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
	&.is-gray {
		background-color: #b1afad;
		color: #fff;
		border: 1px solid transparent;
		&:hover,
		&:focus {
			background-color: #645f5b;
			color: #fff;
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
		}
	}
	&.is-light {
		background-color: #fff;
		color: #645f5b;
		border: 1px solid #645f5b;
		&:hover,
		&:focus {
			background-color: #645f5b;
			color: #fff;
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
		}
	}
	&.is-rounded {
		border-radius: 30px;
	}
	&.is-outlined {
		border: 1px solid #645f5b;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #645f5b;
				color: #fff;
			}
		}
		@include tablet {
			&:active {
				background-color: #645f5b;
				color: #fff;
			}
		}
	}
	&.is-large {
		min-width: 150px;
		width: auto;
		height: 56px;
		padding: 20px 30px;
		font-size: 21px;
		font-weight: bold;
		&.is-rounded {
			font-size: 21px;
		}
	}
	&.is-medium {
		min-width: 110px;
		height: 50px;
		padding: 10px 20px;
		font-size: 16px;
		font-weight: bold;
		&.is-rounded {
			font-size: 16px;
			font-weight: bold;
		}
	}
	&.is-small {
		min-width: 60px;
		height: 35px;
		padding: 5px 15px;
		font-size: 14px;
		font-weight: bold;
		&.is-rounded {
			font-size: 14px;
			font-weight: bold;
		}
	}
}
.button-general {
	display: inline-flex;
	height: 50px;
	padding: 0 10px;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	&.is-primary {
		background-color: #645f5b;
		color: #fff;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #ff7300;
			}
		}
		@include tablet {
			&:active {
				background-color: #ff7300;
			}
		}
		&.is-rounded {
			width: auto;
			// padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
			&.is-disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
	&.is-secondary {
		background-color: #ff7300;
		color: #fff;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #c45800;
			}
		}
		@include tablet {
			&:active {
				background-color: #c45800;
			}
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
			&.is-disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
	&.is-light {
		background-color: #fff;
		color: #645f5b;
		border: 1px solid #645f5b;
		&:hover,
		&:focus {
			background-color: #645f5b;
			color: #fff;
		}
		&.is-rounded {
			width: auto;
			padding: 0 25px;
			font-size: 16px;
			font-weight: 400;
		}
	}
	&.is-rounded {
		border-radius: 30px;
	}
	&.is-outlined {
		border: 1px solid #645f5b;
		@include desktop {
			&:hover,
			&:focus {
				background-color: #645f5b;
				color: #fff;
			}
		}
		@include tablet {
			&:active {
				background-color: #645f5b;
				color: #fff;
			}
		}
	}
	&.is-large {
		min-width: 150px;
		width: auto;
		height: 56px;
		padding: 20px 30px;
		font-size: 21px;
		font-weight: bold;
		&.is-rounded {
			font-size: 21px;
		}
	}
	&.is-medium {
		min-width: 110px;
		height: 50px;
		padding: 10px 20px;
		font-size: 16px;
		font-weight: bold;
		&.is-rounded {
			font-size: 16px;
			font-weight: bold;
		}
	}
}
.layer-scomm-edit {
	position: absolute;
	width: 100%;
	top: 45px;
	right: 0;
	z-index: 10;
	padding: 12px;
	border: 1px solid #bdbdbd;
	border-radius: 15px;
	background-color: #fff;
	box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
	&__header {
		display: flex;
		margin-bottom: 0.3em;
		align-items: center;
		justify-content: flex-end;
	}
	.scomm-detail-write {
		margin: 0 !important;
		padding: 0;
		border: none;
		&.scomm-detail-write__main {
			.textarea {
				height: 80px;
			}
		}
	}
}
.icon-popup-close {
	width: 20px;
	height: 20px;
	@extend %icon-info;
	background-image: url(~@/assets/img/common/icon_popup_close.svg);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.gachi {
	@include mobile {
		padding-bottom: 40px;
	}
}

/* 셀렉트 추가 S (20220721 - hib) */
.box_select {
	width: 205px;
	position: absolute;
	top: 8px;
	right: 5px;
	z-index: 9;
	&.is-static {
		position: relative;
		margin: 0 10px 0 auto;
	}
	button {
		width: 100%;
		padding: 8px 12px;
		text-align: left;
		background: #999999;
		color: #fff;
		border-radius: 5px;
		position: relative;
		top: 3px;
	}
	button::after {
		display: inline-block;
		width: 28px;
		height: 26px;
		content: '';
		background: url(~@/assets/img/common/list_arrow_btn_gray.png) center / 85% no-repeat;
		position: absolute;
		top: 3px;
		right: 2px;
	}
	button.active::after {
		background: url(~@/assets/img/common/list_arrow_btn_gray.png) center / 85% no-repeat;
		transform: rotate(180deg);
	}
	ul {
		display: none;
		width: 100%;
		padding: 12px 10px 8px;
		border-width: 0 1px 1px 1px;
		border-style: solid;
		border-color: #999999;
	}
	ul li {
		font-size: 14px;
		font-weight: 900;
	}
	ul li + li {
		margin-top: 8px;
	}
	.show_list {
		display: block;
		background: #fff;
	}
}
