input[type='text'][readonly],
input[type='text'][disabled] {
	&,
	&:hover,
	&:active,
	&:focus {
		cursor: not-allowed;
		pointer-events: none;
	}
}

input[type='radio'][disabled] input[type='checkbox'][disabled] {
	&,
	&:hover,
	&:active,
	&:focus {
		cursor: not-allowed;
		opacity: 0.4;
		& + label {
			cursor: not-allowed;
			opacity: 0.4;
		}
	}
}

.form {
	&-checkbox,
	&-radio {
		display: inline-flex;
		position: relative;
		vertical-align: middle;
		align-items: center;
		&:not(:last-child) {
			margin-right: 1em;
		}
		input[type='checkbox'],
		input[type='radio'] {
			position: relative;
			overflow: hidden;
			width: 24px;
			height: 24px;
			border-radius: 2px;
			border: 2px solid #bdbdbd;
			background: #fff;
			appearance: none;
			cursor: pointer;
			@include transition(all, 0.3s);
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 3px;
				border: 1px solid #bdbdbd;

				background-color: transparent;
				text-align: center;
				@include transition(all, 0.3s);
			}
			&::before {
				border: none;
				background-color: transparent;
				transform: translateY(-50%);
			}
			&::after {
				border: none;
				background-color: transparent;
				transform: translateY(-50%);
			}
			&:hover {
				&::before {
					border-color: #ff7300;
				}
			}
		}
		label {
			display: inline-flex;
			margin-left: 6px;
			overflow: hidden;
			font-size: 16px;
			align-items: center;
			cursor: pointer;
		}
	}
	&-checkbox {
		input[type='checkbox'] {
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 1.5px;
				background-color: #ff7300;
				transform-origin: left;
				@include transition(all, 0.15s);
			}
			&::before {
				transform: translate(8px, 13px) rotate(-45deg);
				transition-delay: 0;
			}
			&::after {
				transform: translate(5px, 9px) rotate(45deg);
				transition-delay: 0.1s;
			}

			&:checked {
				&::before {
					width: 10px;
					transition-delay: 0.15s;
				}
				&::after {
					width: 5px;
					transition-delay: 0;
				}
			}
		}
		&.is-round {
			input[type='checkbox'] {
				border-radius: 50%;
				transition-delay: 0.25s;
				background-color: transparent;
				z-index: 1;
				&::before,
				&::after {
					background-color: #aaa;
					transform-origin: left;
					z-index: 2;
				}
				&::before {
					transform: translate(8px, 12.5px) rotate(-45deg);
					transition-delay: 0.1s;
				}
				&::after {
					transform: translate(5px, 8.5px) rotate(45deg);
					transition-delay: 0.2s;
				}

				&:checked {
					border-color: #ff7300;
					background-color: #ff7300;
					transition-delay: 0s;
					&::before,
					&::after {
						background-color: #fff;
					}
					&::before {
						transition-delay: 0.2s;
					}
					&::after {
						transition-delay: 0.1s;
					}
				}
			}
			label {
				&::before {
					position: absolute;
					top: 1px;
					left: 4px;
					content: $ico-check;
					border-radius: 50%;
					font-family: 'ico';
					font-size: 16px;
					font-weight: normal;
					color: #bdbdbd;
					line-height: 1.4;
					opacity: 1;
					z-index: 0;
					speak: never;
				}
			}
			input[type='checkbox'] {
				&:checked {
					+ label {
						&::before {
							opacity: 0;
						}
					}
				}
			}
		}
		label {
			// &::after {
			// 	width: 24px;
			// 	height: 18px;
			// 	top: -50%;
			// 	left: 0;
			// 	content: $ico-check;
			// 	border: none;
			// 	font-family: 'ico';
			// 	font-size: 18px;
			// 	font-weight: normal;
			// 	color: #fff;
			// 	speak: never;
			// }
		}
	}
	&-radio {
		input[type='radio'] {
			border-radius: 50%;
			&::before,
			&::after {
				border-radius: 50%;
			}
			&::after {
				width: 0;
				height: 0;
				top: 50%;
				left: 10px;
			}
			&:checked {
				border-color: #ff7300;
				&::before {
					border-color: #ff7300;
				}
				&::after {
					width: 12px;
					height: 12px;
					top: 50%;
					left: 4px;
					background-color: #ff7300;
				}
			}
		}
	}
}
