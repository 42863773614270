.gachi-search2 {
	.container {
		max-width: 1230px;
		padding: 0 40px;
		@include mobile {
			padding: 0 15px;
		}
	}
	.search {
		&-top {
			margin-top: 70px;
			margin-bottom: 30px;
			@include mobile {
				margin-top: 40px;
				margin-bottom: 20px;
			}
			.page-component {
				width: auto;
				padding: 0;
			}
		}
		&-header {
			margin-bottom: 30px;
			font-size: 32px;
			text-align: center;
			@include mobile {
				margin-bottom: 20px;
			}
		}
		&-field {
			position: relative;
			.form-control {
				display: flex;
				height: 50px;
				padding: 0 30px;
				border-radius: 50px;
				border: 1px solid #bdbdbd;
				align-items: center;
				box-shadow: none;
				font-size: 16px;
				font-weight: 400;
				&:focus {
					border-color: #111;
				}
			}
			.search-button {
				position: absolute;
				display: flex;
				width: 60px;
				height: 50px;
				top: 0;
				right: 0;
				padding-right: 20px;
				border-top-right-radius: 30px;
				border-bottom-right-radius: 30px;
				align-items: center;
				justify-content: center;
				i {
					margin-top: -2px;
				}
			}
		}
		&-category {
			position: relative;
			display: flex;
			justify-content: center;
			margin-bottom: 50px;
			gap: 10px;
			flex-wrap: wrap;
			&-wrap {
				@include mobile {
					position: relative;
					margin-left: -15px;
					margin-right: -15px;
					&::before,
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 15px;
						height: 100%;
						top: 0;
						z-index: 1;
					}
					&::before {
						left: 0;
						background: rgb(255, 255, 255);
						background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
					}
					&::after {
						right: 0;
						background: rgb(255, 255, 255);
						background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
					}
				}
			}
			@include mobile {
				margin-bottom: 30px;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 7px;
				justify-content: flex-start;
				flex-wrap: nowrap;
				overflow-x: scroll;
				overflow-y: hidden;
				gap: 5px;
			}
			&-button {
				display: inline-flex;
				height: 40px;
				padding: 0 18px;
				border-radius: 40px;
				border: 1px solid #645f5b;
				font-size: 18px;
				font-weight: 400;
				color: #645f5b;
				align-items: center;
				white-space: nowrap;
				@include mobile {
					height: 38px;
					padding: 0 10px;
					font-size: 16px;
				}
				strong {
					display: inline-block;
					margin-left: 0.3em;
					font-weight: 700;
				}
				&.is-active {
					border-color: #ff7300;
					background-color: #ff7300;
					color: #fff;
				}
				@include desktop {
					&:hover,
					&:focus {
						border-color: #ff7300;
						background-color: #ff7300;
						color: #fff;
					}
				}
				@include tablet {
					&:active {
						border-color: #ff7300;
						background-color: #ff7300;
						color: #fff;
					}
				}
			}
		}
		&-results {
			.page-component {
				width: auto;
				padding: 0;
			}
			.section {
				&-result {
					margin-bottom: 50px;
				}
				&-header {
					display: flex;
					margin-bottom: 20px;
					padding-bottom: 25px;
					border-bottom: 2px solid #757575;
					align-items: flex-end;
					justify-content: space-between;
					.header-title {
						display: inline-block;
						font-size: 26px;
						font-weight: 700;
						flex: 1 1;
						@include mobile {
							font-size: 21px;
						}
						.text-strong {
							font-weight: 700;
							color: #ff7300;
						}
					}
					.link-more {
						margin-left: 10px;
						font-size: 16px;
						font-weight: 400;
						color: #424242;
						flex: 0 0;
						@include mobile {
							font-size: 12px;
						}
					}
				}
			}
		}
		&-list {
			li {
				margin-bottom: 10px;
			}
		}
		&-item {
			display: flex;
			align-items: center;
			&-thumb {
				overflow: hidden;
				position: relative;
				display: flex;
				width: 87px;
				height: 87px;
				border-radius: 15px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				align-items: center;
				justify-content: center;
				@include mobile {
					width: 70px;
					height: 70px;
				}
				img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&-title {
				margin-left: 20px;
				line-height: 1.2;
				font-size: 21px;
				font-weight: 700;
				@include text-ellipsis-2;
				@include word-wrap;
				@include mobile {
					font-size: 18px;
					margin-left: 15px;
				}
			}
			&:hover,
			&:focus {
				.search-item-title {
					text-decoration: underline;
				}
			}
		}
		&-item--text {
			display: block;
			height: 87px;
			align-items: flex-start;
			.search-item {
				&-title {
					margin: 0;
					@include word-wrap;
				}
				&-desc {
					margin: 8px 0 0;
					line-height: 1.4;
					font-size: 16px;
					font-weight: 400;
					@include text-ellipsis-2;
					@include mobile {
						font-size: 14px;
					}
				}
			}
			&:hover,
			&:focus {
				.search-item-title {
					text-decoration: underline;
				}
			}
		}
	}
}
.icon-search-total {
	@include icon;
	width: 25px;
	height: 25px;
	background-image: url(~@/assets/img/common/icon_search_total.svg);
}

.is-search-total {
	.no-results-found {
		padding: 4em 0;
	}
}
