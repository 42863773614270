#AiChatBot {
	overflow: hidden;
	height: 100%;
	padding: 64px 0 65px;
	.aichat {
		&-header {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1;
			display: flex;
			width: 100%;
			height: 64px;
			padding: 20px;
			background-color: #ff7300;
			align-items: center;
			&-title {
				display: inline-flex;
				font-size: 21px;
				font-weight: 700;
				color: #fff;
				align-items: center;
				.icon {
					@include icon;
					width: 56px;
					height: 22px;
					margin-right: 8px;
					background-image: url(~@/assets/img/selfdx/icon_chatbot_onfan_w.svg);
				}
			}
		}
		&-list {
			// height: calc(100% - 130px); 해당 소스 적용하니 챗봇 글 작성시 스크롤이 자동으로 최하단으로 이동을 안함 하여 우선 vh로 변경함
			height: calc(100vh - 130px);
			// padding: 20px;
			overflow-x: hidden;
			overflow-y: auto;
			background: #fff;
			li {
				position: relative;
				margin-bottom: 32px;
				&.aichat-msg-intro {
					margin-bottom: 36px;
				}
				.txt {
					font-size: var(--message-list-li-txt-font-size, 14px);
					.box {
						font-size: var(--message-list-li-txt-font-size, 14px);
					}
				}
				.buttons .btn {
					font-size: var(--message-list-li-txt-font-size, 14px);
				}
				.time {
					font-size: 12px;
				}
				.bubble {
					position: relative;
					display: inline-block;
					padding: 10px 13px;
					border-radius: 15px;
					font-size: 14px;
				}
				&.bot {
					float: left;
					width: 100%;
					.name {
						font-size: 14px;
						font-weight: 500;
						margin: 0 !important;
						padding: 0;
						.pic {
							display: inline-block;
							width: 36px;
							height: 36px;
							margin: 0 3px 0 0;
							vertical-align: top;
							img {
								width: 36px;
								height: 36px;
								border-radius: 100%;
							}
						}
						.txt {
							line-height: 28px;
						}
					}
					.bubble {
						margin: -20px 0 10px 38px !important;
						float: left;
					}
					.time {
						position: absolute;
						bottom: 0;
					}
				}
				&.user {
					float: right;
					width: 100%;
					.bubble {
						background-color: var(--user-bubble-color, #cbd4f7);
						font-size: var(--user-bubble-font-size);
						float: right;
						margin-bottom: 40px;
						border-top-right-radius: 3px;
					}
					.time {
						position: absolute;
						bottom: 0;
						left: -55px;
					}
				}
			}
		}
		&-msg {
			&-intro {
				margin-bottom: 8px;
				text-align: center;
				&__title {
					display: inline-flex;
					margin-bottom: 10px;
					font-size: 36px;
					font-weight: 700;
					color: #ff7300;
					align-items: center;
					.icon {
						@include icon;
						width: 56px;
						height: 22px;
						margin-right: 8px;
						background-image: url(~@/assets/img/selfdx/icon_chatbot_onfan.svg);
					}
				}
				&__desc {
					line-height: 1.3;
					@include word-wrap;
				}
			}
			&-button {
				margin-top: 16px;
				padding: 7px 8px 4px;
				border-radius: 6px;
				background-color: #f5f5f5;
				text-decoration: none;
				text-align: left;
				font-weight: 400;
				color: #424242;
				line-height: 1.01;
				@include text-ellipsis;
				vertical-align: middle;
				&:hover,
				&:active {
					background-color: #eaeaea;
				}
			}
			&-links {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
			}
			&-link {
				position: relative;
				width: calc((100% - 12px) / 2);
				padding: 8px 8px 8px 38px;
				border-radius: 12px;
				border: 1px solid transparent;
				text-align: left;
				line-height: 1.3;
				box-shadow: 0 3px 8px 0 rgba(255, 115, 0, 0.5);
				&:hover,
				&:active {
					border-color: #ff7300;
				}
				.icon {
					position: absolute;
					top: 8px;
					left: 8px;
					@include icon;
					width: 24px;
					height: 24px;
					margin-right: 8px;
					background-image: url(~@/assets/img/selfdx/icon_chatbot_q.svg);
				}
			}
			&-user {
				.aichat-msg {
					&-bubble {
						order: 2;
					}
					&-time {
						order: 1;
					}
				}
			}
			&-bot {
				.aichat-msg {
					&-bubble {
						overflow-x: hidden;
						color: #424242;
						border: 1px solid #e1e1e1;
						border-radius: 0 12px 12px 12px;
						background-color: #fff;
					}
				}
			}
			&-item {
				display: flex;
				gap: 4px;
				align-items: flex-end;
				justify-content: space-between;
			}
			&-bubble {
				line-height: 1.3;
				padding: 12px;
				border-radius: 12px 0 12px 12px;
				border: none;
				background-color: #645f5b;
				color: #fff;
				flex: 1 1;
				&__title {
					display: flex;
					margin-top: -4px;
					margin-bottom: 5px;
					color: #26293f;
					font-weight: 700;
					align-items: center;
					.icon {
						@include icon;
						width: 36px;
						height: 30px;
						margin-right: 4px;
						background-size: cover;
						background-image: url(~@/assets/img/selfdx/icon_chatbot_orange.svg);
					}
				}
			}
			&-loading {
				display: flex;
				text-align: center;
				font-size: 15px;
				align-items: center;
				justify-content: center;
				gap: 8px;
				em {
					display: flex;
					align-items: center;
					gap: 2px;
					justify-content: space-between;
					i {
						content: '';
						display: flex;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: #ff7300;
						animation: aniScale 0.9s infinite ease-in-out both;
						&:nth-child(1) {
							animation-delay: 0.1s;
						}
						&:nth-child(2) {
							animation-delay: 0.2s;
						}
						&:nth-child(3) {
							animation-delay: 0.3s;
						}
					}
					&:last-child {
						transform: rotate(180deg);
					}
				}
			}
			&-time {
				font-size: 14px;
				font-weight: 300;
				color: #000000;
				flex: 0 0 36px;
			}
		}
		&-write {
			position: fixed;
			bottom: 0;
			left: 0;
			display: flex;
			width: 100%;
			height: 65px;
			padding: 12px 20px;
			border-top: 1px solid #e1e1e1;
			background-color: #fff;
			form,
			fieldset {
				width: 100%;
			}
			&-input {
				display: block;
				width: 100%;
				height: 42px;
				font-size: 14px;
				padding: 4px 55px 4px 16px;
				border: 1px solid transparent;
				border-radius: 42px;
				background-color: #f5f5f5;
				line-height: 1;
				&:focus,
				&:focus-visible {
					border-color: #424242;
					outline: none;
				}
			}
			&-button {
				position: absolute;
				right: 20px;
				top: 12px;
				display: flex;
				width: 53px;
				height: 42px;
				margin: 0;
				padding-left: 5px;
				border-radius: 0 42px 42px 0;
				border: 1px solid transparent;
				align-items: center;
				justify-content: flex-start;
				.icon {
					@include icon;
					width: 27px;
					height: 27px;
					background-size: cover;
					background-image: url(~@/assets/img/selfdx/icon_chatbot_send.svg);
				}
			}
		}
	}
	.icon-aichat-link {
		display: inline-flex;
		@include icon;
		width: 24px;
		height: 24px;
		margin-top: -2px;
		margin-right: 6px;
		border-radius: 50%;
		background-color: #ff7300;
		background-position: center;
		background-size: 18px 18px;
		background-image: url(~@/assets/img/selfdx/icon_aichat_link.svg);
		align-items: center;
		justify-content: center;
		vertical-align: middle;
	}
}
@keyframes aniScale {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(0.5);
		opacity: 0.5;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
