$steps-width: 140px;
$steps-marker-width: 45px;
$steps-marker-height: 45px;
$steps-default-width: 30px;
$steps-default-height: 30px;
$steps-active-color: #ff7300;
$steps-inactive-color: #bdbdbd;

.steps {
	display: flex;
	&:not(:last-child) {
		display: flex;
		height: 100%;
		flex-direction: column;
		.steps-segment:not(:last-child) {
			flex-grow: 1;
		}
	}
	&-wrapper {
		margin-bottom: 60px;
	}
	&-inner {
		display: flex;
		width: $steps-width;
		height: 100%;
		text-align: center;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		@include mobile {
			width: 56px;
		}
	}
	&-segment {
		position: relative;
		&:not(:last-child) {
			flex-basis: 1rem;
			flex-grow: 1;
			flex-shrink: 1;
			&:after {
				content: ' ';
				display: block;
				position: absolute;
				height: 5px;
				top: calc(50% - 25px);
				// left: calc(45px + 4px);
				// right: 4px;
				left: calc($steps-width / 2 + $steps-default-width - 15px);
				right: calc(-1 * ($steps-default-width / 2 + $steps-default-width + 10px));
				background-color: $steps-inactive-color;
				z-index: 0;
				@include mobile {
					top: calc(50% - 30px);
					left: calc($steps-width / 2 + $steps-default-width - 57px);
					right: calc(-1 * ($steps-default-width / 2 + $steps-default-width - 25px));
				}
			}
		}
		&.is-active,
		&.is-complete {
			.steps-marker {
				width: $steps-marker-width;
				height: $steps-marker-height;
				border-radius: 50%;
				border: none;
				background-color: $steps-active-color;
				color: #fff;
				transform: none;
			}
			.steps-content {
				.heading {
					font-size: 21px;
					font-weight: 700;
					color: #424242;
					@include mobile {
						font-size: 16px;
					}
				}
			}
		}
		&.is-complete {
			&::after {
				left: calc($steps-width - $steps-marker-width - 3px);
				right: calc(-1 * $steps-marker-width - 3px);
				background-color: $steps-active-color;
				@include mobile {
					left: calc(56px - $steps-marker-width + 30px);
					right: calc(-56px + 40px);
				}
			}
		}
		@include mobile {
			display: block;
			&:not(:last-child) {
				min-height: 4em;
			}
		}
	}
	&-marker {
		position: relative;
		display: inline-flex;
		width: $steps-default-width;
		height: $steps-default-height;
		z-index: 1;
		border-radius: 50%;
		border: 5px solid $steps-inactive-color;
		background-color: #fff;
		color: #fff;
		font-size: 21px;
		font-weight: 700;
		align-items: center;
		justify-content: center;
		line-height: 1;
		transform: translateY(25%);
	}
	&-content {
		.heading {
			display: flex;
			height: 45px;
			padding-top: 20px;
			font-size: 16px;
			font-weight: 700;
			color: $steps-inactive-color;
			text-align: center;
			line-height: 1.1;
			align-items: center;
			@include mobile {
				display: block;
				height: 54px;
				font-size: 14px;
				font-weight: 400;
				vertical-align: top;
			}
		}
	}
}
