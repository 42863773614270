*,
*::before,
*::after {
	box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	margin: 0;
	padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
audio,
canvas,
progress,
video {
	display: inline-block;
}
fieldset,
img {
	border: 0 none;
}
dl,
ul,
ol,
menu,
li {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
input,
select,
textarea,
button {
	font-size: 100%;
	vertical-align: middle;
}
button {
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
	-webkit-text-size-adjust: none;
}
input[type='text'],
input[type='password'],
input[type='submit'],
input[type='search'] {
	appearance: none;
	border-radius: 0;
}
// input:checked[type='checkbox'] {
// }
button,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
	appearance: button;
	border-radius: 0;
}
input[type='search']::-webkit-search-cancel-button {
	appearance: none;
}
body {
	position: relative;
	background: #fff;
}
/* body,th,td,input,select,textarea,button{font-size:1rem;line-height:1;font-family: 'NanumSquare', 'Pretendard', 'Malgun Gothic','맑은 고딕',Dotum,'돋움',system-ui, -apple-system, 'Segoe UI', 'Helvetica Neue', Arial, 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';color:#333;} */
body,
th,
td,
input,
select,
textarea,
button {
	font-size: 1rem;
	line-height: 1;
	font-family: var(--font-family-base);
	color: #424242;
	letter-spacing: -0.02em;
}
iframe {
	border: none;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
[hidden],
template {
	display: none;
}
a {
	color: #333;
	text-decoration: none;
	cursor: pointer;
}
a:active,
a:hover {
	text-decoration: none;
}
address,
caption,
cite,
code,
dfn,
em,
var {
	font-style: normal;
	font-weight: normal;
}
a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
br,
button,
canvas,
caption,
cite,
code,
col,
data,
datalist,
dd,
del,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hr,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
main,
mark,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
select,
small,
span,
strong,
sub,
sup,
table,
td,
textarea,
th,
time,
u,
ul,
var,
video {
	box-sizing: border-box;
}
button,
a,
image,
input,
span,
b,
strcong,
em {
	vertical-align: top;
}
/*--- Default ---*/
html,
body {
	position: relative;
	margin: 0;
	padding: 0;
	// min-width: 100vw;
	min-width: 100%;
	// min-height: 100vh;
}
html {
	-ms-touch-action: none;
	overflow-x: auto;
	// @include tablet() {
	// 	overflow-x: hidden;
	// }
}
body {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	@include desktop() {
		min-width: 1440px;
	}
	@include tablet() {
		min-width: 320px;
		// overflow-x: hidden;
	}
}
caption {
	position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1rem;
}
strong em,
b,
strong {
	font-weight: 600;
}
i {
	font-style: normal;
}
svg {
	overflow: hidden;
	vertical-align: middle;
}
[hidden] {
	display: none !important;
}
/*p, a, h1, h2, h3, h4, h5, h6, span, label, input, li, article, footer, header, ul, section {transform:skew(-0.01deg);}*/
img {
	max-width: 100%;
}
