// 반응형 통합

.layer-popup-container .form-control {
	width: 100%;
}
.layer-popup-container {
	display: flex;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2000;
	padding: 0 120px;
	background-color: rgba(0, 0, 0, 0.4);
	align-items: center;
	justify-content: center;
	// backdrop-filter: blur(4px);
	@include mobile {
		padding: 0;
	}
}
.layer-popup-alert {
	position: relative;
	width: 600px;
	margin: auto;
	padding: 20px;
	border-radius: 15px;
	background-color: #f2f0ed;
	text-align: center;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
	@include mobile {
		max-width: 320px;
		width: 100%;
	}
	.popup-header {
		display: flex;
		width: 100%;
		color: #000;
		align-items: center;
		justify-content: center;
		.header-title {
			font-size: 26px;
			font-weight: 700;
		}
		button {
			display: flex;
			position: absolute;
			top: 14px;
			right: 14px;
			width: 30px;
			height: 30px;
			align-items: center;
			justify-content: center;
		}
	}
	.popup-body {
		display: flex;
		min-height: 102px;
		padding-top: 15px;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.popup-title {
			margin-bottom: 1em;
			padding-top: 10px;
			font-size: 20px;
			font-weight: 700;
			line-height: 1.3;
		}
		.popup-subtext {
			padding-top: 10px;
			font-size: 16px;
			line-height: 22px;
			color: #555;
			strong {
				font-weight: 700;
			}
		}
		.popup-text {
			padding-bottom: 1em;
			font-size: 21px;
			font-weight: 400;
			color: #000;
			line-height: 1.3;
			&:not(:last-clild) {
				padding-bottom: 0;
			}
		}
	}
	.popup-buttons {
		display: flex;
		margin-top: 1em;
		align-items: center;
		justify-content: center;
		gap: 10px;
		.button-default {
			width: auto;
			padding: 5px 30px;
			border-radius: 30px;
			text-align: center;
			transition: background 0.3s ease;
		}
		.text {
			font-size: 16px;
			font-weight: 400;
		}
	}
}
.icon-close {
	@include icon;
	background-image: url(~@/assets/img/common/icon_popup_close.svg);
}
.layer-popup-inquiry {
	position: relative;
	width: 600px;
	margin: auto;
	padding: 20px;
	border-radius: 15px;
	background-color: #f2f0ed;
	text-align: center;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
	@include mobile {
		max-width: 100%;
		width: 100%;
	}
	.popup-header {
		display: flex;
		width: 100%;
		margin-bottom: 5px;
		color: #757575;
		align-items: center;
		justify-content: space-between;
		.header-title {
			font-size: 16px;
			font-weight: 700;
		}
		.btn-close {
			display: flex;
			position: absolute;
			width: 14px;
			height: 14px;
			top: 20px;
			right: 20px;
			align-items: center;
			justify-content: center;
		}
	}
	.icon-close {
		width: 14px;
		height: 14px;
	}
	.popup-body {
		display: flex;
		min-height: 102px;
		padding-top: 15px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.box-round {
			width: 100%;
			margin-bottom: 20px;
			padding: 15px;
			border: 1px solid #e0e0e0;
			border-radius: 15px;
			background-color: #fff;
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: #757575;
			line-height: 1.6;
			.icon-reserv-check {
				margin-right: 8px;
			}
		}
		.button-default {
			font-size: 16px;
		}
	}
	.popup-buttons {
		display: flex;
		margin-top: 20px;
		align-items: center;
		justify-content: center;
		gap: 10px;
		.button-default {
			width: auto;
			padding: 5px 30px;
			border-radius: 30px;
			font-size: 16px;
			text-align: center;
			transition: background 0.3s ease;
		}
		.text {
			font-size: 16px;
			font-weight: 400;
		}
	}
}

#popup-sso-reconsent {
	max-width: 600px;
	.popup-body {
		padding: 0 20px 20px;
		text-align: left;
		@include mobile {
			overflow-y: auto;
			max-height: calc(100vh - 224px);
		}
		&-desc {
			margin-bottom: 50px;
			font-size: 19px;
			line-height: 1.4;
			@include mobile {
				font-size: 16px;
				margin-bottom: 20px;
			}
		}
		.form-checkbox.is-largetext {
			margin-bottom: 20px;
			font-size: 21px;
			font-weight: 700;
			@include mobile {
				font-size: 19px;
			}
		}
	}
	.section {
		&:not(:last-child) {
			margin-bottom: 50px;
			@include mobile {
				margin-bottom: 20px;
			}
		}
		&-header {
			margin-bottom: 20px;
			.title {
				font-size: 19px;
				.color-orange {
					font-weight: normal;
				}
				@include mobile {
					font-size: 17px;
				}
			}
		}
		&-content {
			overflow-x: hidden;
			overflow-y: auto;
			width: 100%;
			height: 135px;
			padding: 20px;
			border-radius: 5px;
			border: 1px solid #bdbdbd;
			background-color: #fff;
		}
	}
}

.layer-popup {
	position: relative;
	background-color: #fff;
	text-align: center;
	margin: auto;
	max-width: 1200px;
	width: 100%;
	border-radius: 15px;
	background-color: #f2f0ed;
	&.layer-popup-compnay-reg {
		max-width: 900px;
		.popup-body {
			display: block;
			padding: 0 10px;
			overflow-y: auto;
			max-height: calc(100vh - 185px);
		}
		.mbrp-cert-wrap {
			margin-bottom: 0;
		}
	}
	@include mobile {
		height: 100vh;
		border-radius: 0;
	}
	.popup-header {
		position: relative;
		height: 80px;
		padding-top: 23px;
		@include mobile {
			position: sticky;
			height: auto;
			margin-bottom: 20px;
			// height: 65px;
			// flex: 0 0 65px;
			background-color: transparent;
			z-index: 101;
		}

		.btn-close {
			display: flex;
			position: absolute;
			width: 30px;
			height: 30px;
			top: 20px;
			right: 20px;
			align-items: center;
			justify-content: center;
			@include mobile {
				top: 10px;
				right: 15px;
			}
		}
		.icon-close {
			width: 22px;
			height: 21px;
		}
	}
	.header-title {
		padding-left: 20px;
		padding-right: 55px;
		font-size: 26px;
		font-weight: 800;
		text-align: left;
		line-height: 1.2;
		@include word-wrap;
		// @include mobile {
		// 	font-size: 16px;
		// }
		.icon-tv {
			width: 34px;
			height: 32px;
			margin-right: 8px;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(~@/assets/img/common/icon_tv.svg);
		}
	}

	&.layer-popup-calendar {
		background-color: #f2f0ed;
		.popup-header {
			height: 0;
		}
		.header-title {
			position: absolute;
			display: flex;
			font-size: 16px;
			font-weight: 400;
			color: #424242;
			align-items: center;
		}
	}
	.popup-body {
		padding: 0 150px;
		@include mobile {
			padding: 20px;
			flex: 1 1 auto;
		}
	}
	.popup-buttons {
		display: flex;
		padding: 30px 0 20px;
		justify-content: center;
		&.pd0 {
			padding: 10px 0;
		}
		@include mobile {
			padding: 20px;
			&[data-direction='column'] {
				flex-direction: column;
				> .btn {
					width: 100%;
					~ .btn {
						margin-left: 0;
						margin-top: 10px;
					}
				}
				.button-row {
					display: flex;
					~ .button-row {
						margin-left: 0;
						margin-top: 10px;
					}
					.btn {
						margin-top: 0;
						width: 100%;
					}
				}
			}
		}
		.btn {
			width: 120px;
			text-align: center;
			@include mobile {
				width: inherit;
				flex: 1;
			}
			~ .btn {
				margin-left: 10px;
			}
		}
		.text {
			font-size: 16px;
			font-weight: 600;
		}
	}
	.btn-popup-close {
		position: absolute;
		right: 0;
		top: 0;
	}

	/* 팝업 컨트롤 */
	.popup-body {
		// .prevImg {
		// 	background: url(~@/assets/img/common/more_arrow.svg) center center no-repeat;
		// 	background-size: 55px;
		// 	width: 40px;
		// 	transform: rotate(180deg);
		// }
		// .nextImg {
		// 	background: url(~@/assets/img/common/more_arrow.svg) center center no-repeat;
		// 	background-size: 55px;
		// 	width: 45px;
		// }
		.swiper-button-next,
		.swiper-button-prev {
			&::after {
				display: block;
				position: absolute;
				content: '';
				top: 0;
				width: 23px;
				height: 23px;
				border-top: 2px solid #999;
				border-right: 2px solid #999;
			}
		}
		.swiper-button-prev::after {
			left: 8px;
			transform: rotate(225deg);
		}
		.swiper-button-next::after {
			right: 8px;
			transform: rotate(45deg);
		}
	}
	.popup-checkfooter {
		padding: 0 20px 20px;
		text-align: left;
	}
	.popup-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		.button-default {
			width: auto;
			padding: 5px 30px;
			border-radius: 30px;
			text-align: center;
			transition: background 0.3s ease;
		}
		.text {
			font-size: 16px;
			font-weight: 400;
		}
	}
}

/* 플러그인에 있는 화살표 없애기 */
.swiper-button-next:after,
.swiper-rtl,
.swiper-button-prev:after {
	content: '' !important;
}

#popup-community-contact {
	min-width: 1020px;
	@include mobile {
		min-width: inherit;
		background-color: #f3f5f7;
	}
	.popup-wrapper {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 120px);
		max-height: 733px;
		@include mobile {
			height: calc(100vh - 20px);
		}
	}
	.popup-header {
		flex: 0 0 137px;
		@include mobile {
			background-color: #fff;
		}
	}
	.popup-body {
		flex: auto;
		max-height: 426px;
		form,
		textarea {
			height: 100%;
			@include mobile {
				border: none;
				background-color: transparent;
				box-shadow: none;
				padding: 0;
			}
		}
		textarea {
			resize: none;
		}
	}
}

/*#popup-community-write {min-width:1020px}*/
/*#popup-community-write .popup-wrapper {display:flex;flex-direction:column;height:calc(100vh - 120px);max-height:733px}*/
#popup-community-write {
	.popup-wrapper {
		@include mobile {
			display: flex;
			flex-direction: column;
			height: calc(100vh - 120px);
			max-height: 733px;
		}
	}
	.popup-header {
		flex: 0 0 137px;
		@include mobile {
			flex: 0 0 65px;
		}
	}
	.popup-body {
		flex: auto;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 120px);
		max-height: 600px;
		overflow-y: auto;
		form,
		textarea {
			height: 100%;
		}
		textarea {
			resize: none;
		}
	}
	.write-category {
		width: 100%;
		margin-bottom: 10px;
		@include mobile {
			margin: 0 auto 20px;
		}
	}
	.write-editor {
		height: 346px;
		box-shadow: 0 0 0 1px #ebebeb inset;
		@include mobile {
			overflow-y: auto;
			height: calc(100vh - 265px);
		}
	}
	.write-prh {
		color: #999;
		font-size: 16px;
		padding-bottom: 10px;
	}
}

#alert-community-delete {
}

.ie #popup-livestudio-resve {
	width: 1112px;
}
#popup-livestudio-resve {
	.popup-body {
		max-height: calc(100% - 240px);
		overflow-y: auto;
		padding: 0 0 20px;
		margin: 0 150px;
		border-bottom: 1px solid #ddd;
		@include mobile {
			max-height: calc(100vh - 165px);
			margin: 0;
			padding: 0;
		}
	}
	.popup-wrapper {
		height: calc(100vh - 160px);
		max-height: 950px;
		@include mobile {
			display: flex;
			height: 100vh;
			flex-direction: column;
		}
	}
	.form-row {
		width: 90%;
		@include mobile {
			width: inherit;
		}
		.form-cellth {
			text-align: left;
			width: 110px;
		}
	}
	.livestudio-revse-status {
		margin-bottom: 20px;
		@include mobile {
			display: flex;
			margin-bottom: 40px;
			padding-top: 20px;
			text-align: left;
			justify-content: space-between;
		}
		.revse-image {
			position: relative;
			margin-bottom: 20px;
			@include mobile {
				margin-bottom: 0;
			}
			img {
				width: 120px;
				height: 120px;
				object-fit: cover;
				@include mobile {
					width: 70px;
					height: 70px;
					border-radius: 1px;
				}
			}
		}
		.revse-content {
		}
		.revse-room {
			@include mobile {
				font-size: 20px;
				font-weight: bold;
			}
		}
		h5 {
			font-size: 20px;
			font-weight: bold;
		}
		.revse-time {
			font-size: 16px;
			color: #015aa8;
			margin-top: 10px;
			line-height: 1.2;
			@include mobile {
				font-size: 13px;
			}
		}
	}
	.input-group.field-phone {
		.form-select {
			flex: 0 0 180px;
			@include mobile {
				flex: 0 0 33.3%;
			}
		}
		.form-control {
			flex: 0 0 180px;
			margin-left: 10px;
			@include mobile {
				flex: 0 0 calc(33.3% - 10px);
			}
		}
	}
}

#popup-mypage-profile {
	.popup-wrapper {
		@include mobile {
			display: flex;
			flex-direction: column;
			height: 100vh;
			overflow-y: auto;
		}
	}
	.form-field {
		width: 560px;
		margin: auto;
		@include mobile {
			width: inherit;
		}
	}
	.form-row {
		position: relative;
		.form-cellth {
			position: absolute;
			right: 100%;
			text-align: left;
			width: 110px;
		}
	}
	.profile-image-wrapper {
		margin-bottom: 40px;
		.profile-image {
			width: 120px;
			height: 120px;
			border-radius: 2px;
			margin: auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.profile-actions {
			padding-top: 20px;
			.btn {
				height: auto;
			}
			.text {
				font-weight: bold;
			}
		}
	}
	.popup-buttons {
		padding-bottom: 118px;
		@include mobile {
			padding-top: 0;
			padding-bottom: 0;
			.button-row {
				&:last-child {
					margin-left: -20px;
					margin-right: -20px;
					margin-top: 20px;
					.btn {
						height: 78px;
					}
				}
			}
		}
		.btn-text {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 50px;
		}
	}

	.agree-list {
		position: relative;
		padding-bottom: 50px;
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 50px;
	}
	.agree-item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		~ .agree-item {
			margin-top: 25px;
		}
	}
	.agree-link {
		display: inline-flex;
		align-items: center;
		.text {
			font-size: 12px;
			font-weight: bold;
			color: #015aa8;
			&:hover {
				text-decoration: underline;
			}
		}
		.icon-arrow {
			margin-left: 5px;
			width: 11px;
			height: 17px;
			background-image: url(~@/assets/img/common/icon_agree_arrow.svg);
		}
	}
}

#popup-mypage-creator {
	.popup-wrapper {
		@include mobile {
			height: 100vh;
			display: flex;
			flex-direction: column;
		}
	}
	.popup-body {
		flex: auto;
		max-height: 426px;
		@include mobile {
			padding-bottom: 0;
			max-height: inherit;
		}
		form,
		textarea {
			height: 100%;
		}
		textarea {
			resize: none;
		}
	}
	.write-editor {
		height: 346px;
		box-shadow: 0 0 0 1px #ebebeb inset;
		@include mobile {
			height: 100%;
		}
	}
}

#popup-mypage-dm {
	@include mobile {
		background-color: #f3f5f7;
	}
	.popup-body {
		min-height: 395px;
		@include mobile {
			min-height: inherit;
			padding-bottom: 0;
			overflow-y: auto;
		}
	}
	.mypage-dm-card {
		display: flex;
		align-items: center;
		min-width: 0;
		text-align: left;
		.card-image {
			flex: 0 0 100px;
			@include mobile {
				flex: 0 0 50px;
			}
			.image-link {
			}
			img {
				width: 100px;
				height: 100px;
				object-fit: cover;
				@include mobile {
					width: 50px;
					height: 50px;
				}
			}
		}
		.card-content {
			position: relative;
			padding-left: 20px;
			flex: auto;
			.sender-link {
				display: inline-block;
				@include mobile {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.text {
					&:not(.sender) {
						font-weight: bold;
						color: #999;
						@include mobile {
							font-size: 13px;
							white-space: nowrap;
							display: inline-block;
							padding-left: 15px;
						}
					}
					&.sender {
						display: block;
						margin-bottom: 13px;
						font-size: 25px;
						line-height: 1.3;
						font-weight: bold;
						@include mobile {
							font-size: 1rem;
						}
					}
				}
				.text-truncate {
					max-width: 1000px;
					display: inline-block;
				}
			}
			.dm-content {
				font-weight: bold;
				color: #999;
			}
			.dm-date {
				position: absolute;
				right: 0;
				bottom: 0;
				font-weight: bold;
				color: #999;
			}
		}
	}
	.mypage-dm-contents {
		position: relative;
		padding-top: 50px;
		@include mobile {
			padding-top: 30px;
		}
		.text {
			max-height: 400px;
			overflow: auto;
			font-size: 16px;
			line-height: 1.3;
			color: #555;
			text-align: left;
			box-shadow: 0 0 0 2px #ebebeb inset;
			padding: 10px;
			@include mobile {
				width: 100%;
				height: 100%;
				max-height: inherit;
			}
		}
	}
}

#popup-mypage-review {
	@include mobile {
		background-color: #f3f5f7;
	}
	.popup-body {
		@include mobile {
			padding-bottom: 0;
		}
	}
	.mypage-review-rating {
		position: relative;
		margin-bottom: 40px;
		text-align: center;
		@include mobile {
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid #ebebeb;
		}
		.rating-wrapper {
			display: inline-flex;
			@include mobile {
				display: block;
				.rating {
					display: inline-flex;
				}
			}
		}
		.rating-title {
			font-size: 15px;
			font-weight: bold;
			margin-right: 10px;
			@include mobile {
				margin-right: 0;
				margin-bottom: 20px;
				.title {
					font-size: 13px;
					font-weight: bold;
				}
			}
		}
		.star {
			width: 24px;
			height: 24px;
			background-size: 24px;
			cursor: pointer;
			@include mobile {
				width: 50px;
				height: 50px;
				background-size: 50px;
			}
		}
		.star-btn ~ .star-btn {
			@include mobile {
				margin-left: 10px;
			}
		}
	}
	.mypage-review-form {
		@include mobile {
			height: calc(100vh - 334px);
		}
	}
	.form-control {
		height: 354px;
		@include mobile {
			box-shadow: none;
			background-color: transparent;
			padding: 0;
			height: 100%;
		}
	}
}

.rating-wrapper {
	display: flex;
	align-items: center;
	.rating {
		display: flex;
	}
	.star {
		display: block;
		background-image: url(~@/assets/img/common/icon_star_default.svg);
		background-position: 0 0;
		background-repeat: no-repeat;
		&.is-active {
			background-image: url(~@/assets/img/common/icon_star.svg);
		}
	}
	.rating-text {
		display: block;
		margin-left: 10px;
		transform: translateY(-1px);
		.text {
			font-size: 15px;
		}
	}
}
.rating-sm {
	.star {
		position: relative;
		top: -1px;
		width: 16px;
		height: 16px;
		background-size: 16px auto;
	}
	.rating-text .text {
		font-weight: bold;
		font-size: 1rem;
		vertical-align: middle;
	}
}
.rating-lg {
	.star {
		position: relative;
		top: -3px;
		width: 32px;
		height: 32px;
		background-size: 32px auto;
	}
	.rating-text .text {
		font-size: 30px;
		font-weight: 800;
	}
}

/*
.ie .layer-popup-container {display:block;position:absolute;}
.ie .layer-popup-container .layer-popup {margin:120px auto 0}
*/
.ie .layer-popup-container {
	display: block;
	position: absolute;
}
.ie .layer-popup-container .layer-popup {
	display: block;
	margin-top: 120px;
}
.ie .layer-popup-alert {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.ie #popup-community-write .popup-header {
	flex: 0 0 70px;
}
.ie #popup-livestudio-resve .popup-wrapper {
	height: calc(100vh - 250px);
}
.ie #popup-community-write .popup-wrapper {
	height: calc(100vh - 250px);
}
.ie #popup-community-write .write-editor {
	max-height: 346px;
	height: 346px;
}
.ie #popup-mypage-profile .popup-body {
	max-height: calc(100vh - 600px);
	overflow-y: auto;
}

.board-view-container {
	padding-bottom: 120px;
}
.board-view-header {
	padding-top: 0;
	padding-bottom: 50px;
	margin-top: -70px;
	.badge {
		margin-bottom: 20px;
	}
	.view-header-title {
		margin-bottom: 10px;
		font-size: 50px;
		line-height: 65px;
		font-weight: bold;
	}
	.view-header-date {
		font-size: 1rem;
		line-height: 19px;
		font-weight: bold;
		color: #999;
	}
}
.board-view-content {
	position: relative;
	max-height: calc(100vh - 400px);
	overflow-y: auto;
	text-align: left;
	.view-content-image {
		margin-bottom: 30px;
	}
	.view-content-title {
		font-size: 20px;
		line-height: 26px;
		font-weight: 900;
		margin-bottom: 20px;
	}
	.view-content-description {
		font-size: 1rem;
		line-height: 22px;
		color: #555;
	}
}

#popup-notice {
	max-height: 80vh;
	width: 600px;
	height: 600px;
	.popup-wrapper {
		position: relative;
	}
	@include mobile {
		display: flex;
		// width: inherit;
		// height: inherit;
		width: 100%;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		background: none;
		.swiper-container {
			width: 100%;
		}
	}
	.layer-popup .popup-header {
		height: 0px;
		padding-top: 0px;
	}
	.popup-body {
		padding: 0px;
		.swiper-slide {
			img {
				width: 100%;
			}
		}
	}
	.notice-image {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.popup-footer {
		margin: -3px 0 0;
		padding: 10px 20px;
		background-color: #fff;
		justify-content: flex-start;
		@include mobile {
			padding: 10px 20px;
		}
		.form-check-input {
			&[type='checkbox'] {
				border-radius: 4px;
				border-color: #757575;
				box-shadow: inset 0 0 0 1px #bbb;
			}
		}
		.form-check-label {
			color: #000;
			font-weight: normal;
		}
	}
	.btn-close {
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 99;
		padding: 0.7em;
		background-color: #fff;
		border-radius: 50%;
		transition: opacity 0.3s;
		.icon-close {
			width: 20px;
			height: 20px;
		}
		&:hover {
			opacity: 0.7;
		}
		@include mobile {
			top: -52px;
			right: 50%;
			transform: translateX(50%);
			// margin-top: 30px;
		}
	}
	.icon-close {
		width: 30px;
		height: 30px;
		@include mobile {
			width: 40px;
			height: 40px;
			background-size: 40px;
		}
	}
	.form-check {
		@include mobile {
			// margin-top: 10px;
			justify-content: center;
		}
	}
}

#edu-popup-notice {
	max-height: 80vh;
	width: 600px;
	height: 600px;
	.layer-popup .popup-header {
		height: 0px;
		padding-top: 0px;
	}
	.popup-body {
		padding: 0px;
	}
	.notice-image {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.popup-footer {
		justify-content: flex-start;
		padding: 20px 0px;
		.form-check-label {
			color: white;
			font-weight: normal;
		}
	}
	.btn-close {
		position: absolute;
		right: 30px;
		top: 30px;
		z-index: 99;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		&:hover {
			opacity: 0.7;
		}
	}
	.icon-close {
		width: 30px;
		height: 30px;
	}
}

/* 맞춤 인플루언서 찾기 팝업 */
.layer-popup .popup_influencer {
	.popup-header .header-title {
		position: absolute;
		top: 30px;
		left: 30px;
	}
	.popup-body2 {
		display: flex;
		justify-content: space-between;
		.left {
			width: 27%;
			.form-control {
				width: 100%;
				height: 40px;
				margin: 20px auto 0;
			}
		}
		.right {
			width: 70%;
			.form-row {
				flex-direction: column;
				~ .form-row:not(.value) {
					padding-top: 10px;
				}
			}
			.form-cellth label {
				height: 30px;
			}
			.form-select {
				height: 60px;
			}
			iframe {
				width: 100%;
				height: auto;
			}
		}
	}
	.popup-body {
		.right {
			@include mobile {
				margin-top: 20px;
				iframe {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

#popup-mypage-creator-foothold {
	.left {
		.profile-image-wrapper {
			.profile-image {
				width: 100%;
				height: 300px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

/* 홍보영상 보기 모달 페이지 종료 아이콘 S (20220803 hib) */
#popup-video {
	@include mobile {
		flex-direction: column;
		justify-content: center;
		background: none;
		.btn-close {
			position: relative;
			z-index: 99;
			margin-top: 100px;
		}
		.icon-close {
			width: 40px;
			height: 40px;
			background-size: 40px;
		}
	}
}
/* 홍보영상 보기 모달 페이지 종료 아이콘 E (20220803 hib) */

.popup-map-container {
	width: 100%;
	height: 440px;
	overflow: hidden;
	border-radius: 15px;
	border: 1px solid #e0e0e0;
	@include mobile {
		height: 360px;
	}
	@include until(380px) {
		height: 300px;
	}
	#map {
		width: 100%;
		height: 100%;
	}
}
.popup-body {
	&-dls {
		margin-top: 30px;
		text-align: left;
	}
	&-dl {
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		dt {
			display: block;
			margin-bottom: 6px;
			font-size: 19px;
			font-weight: 700;
			line-height: 1.1;
		}
		dd {
			display: block;
			font-size: 16px;
			font-weight: 400;
			line-height: 1.5;
		}
	}
}
.popup-map {
	max-width: 1200px !important;
	.popup-body {
		padding: 0 20px;
	}
	&-flexwrap {
		display: flex;
		height: 440px;
		align-items: flex-start;
		gap: 30px;
		.popup-map-container {
			width: 55%;
			@include tablet {
				width: 100%;
			}
		}
		.popup-body-dls {
			width: 45%;
			@include tablet {
				width: 100%;
			}
		}
		@include tablet {
			display: block;
			height: auto;
		}
	}
}
