// $family-sans-serif: Roboto, 'Noto Sans KR', 'Noto+Sans', 'Malgun Gothic', '맑은 고딕', arial, sans-serif;
$family-primary: Roboto, 'Noto Sans KR', 'Noto+Sans', 'Malgun Gothic', '맑은 고딕', arial, sans-serif;
$family-sans-serif: 'Noto Sans KR', 'Noto+Sans', 'Malgun Gothic', '맑은 고딕', arial, sans-serif;
// $family-primary: 'Noto Sans KR', 'Noto+Sans', 'Malgun Gothic', '맑은 고딕', arial, sans-serif;
$family-monospace: monospace !default;
// Break Point
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default;
// desktop : 1039px --> @media min-width
$desktop: 1328px;
// tablet : 768px ~ 1038px --> @media max-width
$tablet: 1327px;
// mobile : ~ 767px --> @media max-width
// $mobile: 768px;
$mobile: 800px;
$small: 576px;

// 1152px container + 4rem
$widescreen: 1152px + 2 * $gap !default;
$widescreen-enabled: true !default;
$fullhd-enabled: false !default;

$black: #222 !default;
$black-bis: hsl(0, 0%, 7%) !default;
$black-ter: hsl(0, 0%, 14%) !default;
$black-invert: #fff !default;

$grey-darker: #111 !default;
$grey-dark: #333 !default;
$grey: #434343 !default;
$grey-light: #737373 !default;
$grey-lighter: #bcbcbc !default;
$grey-lightest: #ececec !default;

$white-ter: hsl(0, 0%, 96%) !default;
$white-bis: hsl(0, 0%, 98%) !default;
$white: hsl(0, 0%, 100%) !default;

$orange: #ff7300 !default;
$yellow: #ee9900 !default;
$green: #1f8b24 !default;
$turquoise: hsl(171, 100%, 41%) !default;
$sand: #53575a !default;
$cyan: #4388bc !default;
$blue: #0088eb !default;
$purple: #863399 !default;
$red: #b31013 !default;
$navy: #0b58a1 !default;

$bg-1: #ebf4ff !default;
$bg-2: #f2f2f2 !default;
$bg-3: #fff !default;

$point-color: #68b2fc;

$size-1: 38px !default;
$size-2: 36px !default;
$size-3: 30px !default;
$size-4: 20px !default;
$size-5: 16px !default;
$size-6: 14px !default;
$size-7: 13px !default;
// $size-1: 3rem !default;
// $size-2: 2rem !default;
// $size-3: 1.8rem !default;
// $size-4: 1.6rem !default;
// $size-5: 1.5rem !default;
// $size-6: 1.3rem !default;
// $size-7: 1.2rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 600 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// $border: #000;
$radius-rounded: 0.3 !default;

$link-active: #000;
$link-active-border: #000;
$link-focus: #000;
$link-focus-border: #000;
$link-invert: #000;
$scheme-invert: #000;
$size-large: $size-4;
$size-normal: $size-6;
$size-small: $size-7;
$control-height: 40px;
$control-line-height: 40px;
$control-padding-vertical: 5px;
$control-padding-horizontal: 12px;

$input-icon-active-color: #000 !default;
// $input-icon-color: $primary;
$input-height: 40px !default;

// Spacing
$block-spacing: 15px !default;

// Miscellaneous

$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;

// list
$thumbColor: #f7f7f7;
