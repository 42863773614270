.selfdx-input {
	// min-height: 100vh;
	padding-bottom: 0;
	background-image: url('~@/assets/img/selfdx/bg_input.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.92);
	}
	.container {
		position: relative;
		z-index: 1;
		@include mobile {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	.steps-wrapper {
		padding-top: 60px;
		@include mobile {
			margin-bottom: 0;
		}
		@include mobile {
			margin-left: -5px;
			margin-right: -5px;
			padding-top: 40px;
		}
		.steps {
			&-marker {
				transform: translateY(50%);
				@include mobile {
					width: 17px;
					height: 17px;
					border-width: 3px;
					transform: translateY(65%);
					.icon {
						font-size: 0;
					}
				}
			}
			&-segment {
				&:not(:last-child) {
					&:after {
						height: 3px;
						@include mobile {
							left: 33px;
							height: 2px;
						}
					}
				}
				&.is-complete {
					.steps-marker {
						transform: translateY(18%);
						@include mobile {
							width: 30px;
							height: 30px;
							transform: translateY(14%);
							.icon {
								font-size: 14px;
							}
						}
					}
					.steps-content .heading {
						font-size: 16px;
						color: #424242;
						@include mobile {
							font-size: 14px;
							font-weight: 400;
							color: #424242;
						}
					}

					&::after {
						right: calc(-1 * $steps-marker-width - 13px);

						@include mobile {
							left: calc(56px - $steps-marker-width + 30px);
							right: -23px;
						}
					}
					&.is-active {
						&::after {
							background-color: #bdbdbd;
						}
					}
				}
				&.is-active {
					.steps-content .heading {
						font-size: 21px;
						color: #424242;
						@include mobile {
							font-size: 16px;
							color: #424242;
						}
					}
					.steps-marker {
						width: 60px;
						height: 60px;
						transform: translateY(0);
						.icon {
							font-size: 26px;
						}
						@include mobile {
							width: 42px;
							height: 42px;
							transform: translateY(-5%);
							.icon {
								font-size: 18px;
							}
						}
					}
				}
			}
			&-content .heading {
				color: #757575;
				@include mobile {
					padding-top: 12px;
				}
			}
		}
	}
	&-header {
		margin-top: 60px;
		margin-bottom: 60px;
		@include mobile {
			margin-top: 20px;
			margin-bottom: 44px;
		}
		.title {
			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.2;
			@include word-wrap;
			@include mobile {
				font-size: 24px;
			}
		}
	}
	&-section {
		margin-bottom: 60px;
		@include mobile {
			margin-bottom: 44px;
		}
		&__header {
			position: relative;
			padding-left: 16px;
			display: flex;
			margin-bottom: 24px;
			gap: 8px;
			align-items: center;
			&:before {
				content: '';
				position: absolute;
				top: -2px;
				left: 0;
				display: block;
				width: 8px;
				height: 25px;
				border-radius: 8px;
				background-color: #ff7300;
			}
			@include mobile {
				flex-wrap: wrap;
			}
			.title {
				font-size: 21px;
				font-weight: 700;
				@include mobile {
					font-size: 19px;
				}
			}
			.desc {
				font-size: 14px;
				font-weight: 300;
				@include mobile {
					width: 100%;
				}
			}
		}
		&__content {
			padding: 30px;
			border-radius: 30px;
			border: 1px solid #fff;
			background-color: rgba(0, 0, 0, 0.05);
			@include mobile {
				padding-left: 16px;
				padding-right: 16px;
			}
		}
	}
	&-info {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		display: flex;
		margin-left: 30px;
		margin-right: 30px;
		line-height: 1.2;
		align-items: center;
		gap: 16px;
		@include mobile {
			margin-left: 0;
			margin-right: 0;
		}
		dt {
			width: 130px;
			margin: 0;
			font-size: 19px;
			font-weight: 700;
			flex: 0 0 130px;
		}
		dd {
		}
	}
	&-question {
		&:not(:last-child) {
			margin-bottom: 44px;
		}
		&__title,
		dt {
			margin-bottom: 16px;
			font-size: 19px;
			font-weight: 700;
			line-height: 1.2;
			@include mobile {
				margin-bottom: 10px;
				font-size: 16px;
			}
			&.is-small {
				margin-bottom: 8px;
				font-size: 16px;
				font-weight: 400;
			}
		}
		dd {
		}
		&.bottom-border {
			border-bottom: 1px solid #757575;
			padding-bottom: 44px;
		}
	}
	&-footer {
		display: flex;
		padding-bottom: 60px;
		align-items: center;
		justify-content: center;
	}
	&-skip {
		display: flex;
		padding-bottom: 30px;
		justify-content: center;
	}
}

.icon {
	&-selfdx-input1 {
		@include icon;
		width: 26px;
		height: 28px;
		background-image: url('~@/assets/img/selfdx/icon_input1.svg');
		@include mobile {
			width: 34px;
			height: 36px;
		}
	}
}
.control-flex {
	display: flex;
	flex-wrap: wrap;
	gap: 4px 10px;
	align-items: center;
	.input {
		width: 100%;
		margin-top: 1em;
		padding: 1em;
		border-radius: 5px;
		border: 1px solid #bdbdbd;
		font-size: 16px;
	}
	&.is-icon {
		@include mobile {
			width: 100%;
			align-items: initial;
		}
		@include until(430px) {
			justify-content: space-around;
		}
	}
	&.is-small {
		margin-top: 16px;
		padding-top: 16px;
		border-top: 1px solid #b0b0b0;
		.radio,
		.checkbox {
			&.is-style {
				width: 132px;
				@include until(520px) {
					width: calc((100% - 10px) / 2);
				}
				label {
					padding: 6px 0 6px 26px;
					font-size: 14px;
					&::before {
						width: 20px;
						height: 20px;
						border-radius: 0.25rem;
					}
				}
				input {
					&:checked + label {
						padding: 6px 16px;
						&::before {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
		.radio {
			&.is-style {
				label {
					&::before {
						border-radius: 28px;
					}
				}
			}
		}
	}
}
.radio,
.checkbox {
	&.is-style {
		display: inline-block;
		position: relative;
		input {
			position: absolute;
			opacity: 0;
			z-index: -1;

			&:checked + label {
				padding: 12px 16px;
				color: #fff;
				font-weight: 600;
				&::before {
					width: 100%;
					height: 100%;
					background: #ff7300;
					border: 0;
				}
			}
		}
		label {
			display: flex;
			position: relative;
			padding: 12px 0 12px 30px;
			font-size: 16px;
			font-weight: 400;
			align-items: center;
			line-height: 1.1;
			cursor: pointer;
			transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 23px;
				height: 23px;
				background: transparent;
				border: 2px solid #ff7300;
				border-radius: 6px;
				z-index: -1;
				transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
			}
		}
	}
	&.is-twoline {
		width: 152px;
		@include until(420px) {
			width: calc((100% - 10px) / 2);
		}
	}
}
.radio {
	&.is-style {
		label {
			&::before {
				border-radius: 42px;
			}
		}
	}
	&.is-normal {
		input {
			position: absolute;
			opacity: 0;
			z-index: -1;

			&:checked + label {
				&::before {
					border-color: #ff7300;
				}
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 6px;
					z-index: 1;
					width: 11px;
					height: 11px;
					margin: -5.5px 0 0 0;
					border-radius: 50%;
					background-color: #ff7300;
				}
			}
		}
		label {
			display: flex;
			position: relative;
			padding: 6px 0 6px 30px;
			font-size: 16px;
			font-weight: 400;
			align-items: center;
			line-height: 1.1;
			cursor: pointer;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 23px;
				height: 23px;
				background: #fff;
				border: 2px solid #d9d9d9;
				border-radius: 23px;
				z-index: -1;
			}
			strong {
				font-weight: 700;
			}
		}
	}
	&.is-icon {
		display: inline-block;
		position: relative;
		&:nth-child(1) {
			input {
				&:checked + label {
					&::before {
						background-image: url('~@/assets/img/selfdx/icon_smile1_on.png');
					}
				}
			}
			label {
				&::before {
					background-image: url('~@/assets/img/selfdx/icon_smile1.png');
				}
			}
		}
		&:nth-child(2) {
			input {
				&:checked + label {
					&::before {
						background-image: url('~@/assets/img/selfdx/icon_smile2_on.png');
					}
				}
			}
			label {
				&::before {
					background-image: url('~@/assets/img/selfdx/icon_smile2.png');
				}
			}
		}
		&:nth-child(3) {
			input {
				&:checked + label {
					&::before {
						background-image: url('~@/assets/img/selfdx/icon_smile3_on.png');
					}
				}
			}
			label {
				&::before {
					background-image: url('~@/assets/img/selfdx/icon_smile3.png');
				}
			}
		}
		&:nth-child(4) {
			input {
				&:checked + label {
					&::before {
						background-image: url('~@/assets/img/selfdx/icon_smile4_on.png');
					}
				}
			}
			label {
				&::before {
					background-image: url('~@/assets/img/selfdx/icon_smile4.png');
				}
			}
		}
		&:nth-child(5) {
			input {
				&:checked + label {
					&::before {
						background-image: url('~@/assets/img/selfdx/icon_smile5_on.png');
					}
				}
			}
			label {
				&::before {
					background-image: url('~@/assets/img/selfdx/icon_smile5.png');
				}
			}
		}
		input {
			position: absolute;
			opacity: 0;
			z-index: -1;
			&:checked + label {
				color: #ff7300;
				font-weight: 600;
			}
		}
		label {
			display: flex;
			position: relative;
			width: 120px;
			padding: 0;
			font-size: 16px;
			font-weight: 300;
			align-items: center;
			line-height: 1.1;
			gap: 4px;
			cursor: pointer;
			@include mobile {
				width: 56px;
				margin: 0 4px;
				flex-direction: column;
			}
			@include until(430px) {
				width: 30px;
			}
			&::before {
				content: '';
				width: 41px;
				height: 40px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
}
.select-flex {
	display: flex;
	gap: 8px;
}
.form-select {
	&.is-rounded {
		height: 42px;
		padding: 5px 38px 5px 16px;
		border: 1px solid #757575;
		border-radius: 42px;
		font-size: 16px;
		line-height: 1.2;
		font-weight: 400;
		color: #757575;
		box-shadow: none;
		background-image: url(~@/assets/img/common/icon_form_select.svg);
		background-position: calc(100% - 13px) 50%;
		background-repeat: no-repeat;
		background-size: 16px 15px;
	}
}

#selfdxStart {
	.page-header-full {
		background-image: url('~@/assets/img/selfdx/bg_start.jpg');
	}
	.page-body {
		position: relative;
		padding: 60px 0;
		@include mobile {
			padding: 32px 0;
		}
		.buttons {
			width: 100%;
			margin: 0;
			justify-content: center;
		}
	}
}
.selfdx-start {
	&-video {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		object-fit: cover;
		@include mobile {
			display: none;
		}
	}
	&-box {
		margin-bottom: 60px;
		padding: 60px;
		border-radius: 30px;
		border: 1px solid #fff;
		background-color: rgba(255, 115, 0, 0.05);
		box-shadow: 0 0 10px rgba(255, 115, 0, 0.3);
		@include mobile {
			margin-bottom: 32px;
			padding: 20px;
		}
	}
	&-section {
		&:not(:last-child) {
			margin-bottom: 60px;
			@include mobile {
				margin-bottom: 32px;
			}
		}
		&__title {
			display: flex;
			margin-bottom: 24px;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.1;
			color: #ff7300;
			align-items: center;
			gap: 8px;
			@include mobile {
				margin-bottom: 16px;
				font-size: 24px;
			}
			i {
				@include mobile {
					flex: 0 0 35px;
				}
			}
		}
		&__desc {
			max-width: 850px;
			font-size: 21px;
			font-weight: 300;
			line-height: 1.4;
			@include word-wrap;
			strong {
				font-weight: 700;
				&.mt1 {
					display: block;
					margin-top: 0.5em;
				}
			}
			@include mobile {
				font-size: 16px;
			}
		}
		&__list {
			counter-reset: myCounter;
			list-style: none;
			li {
				position: relative;
				display: flex;
				padding-left: 60px;
				gap: 16px;
				align-items: center;
				counter-increment: myCounter;
				@include mobile {
					padding-left: 38px;
					align-items: flex-start;
				}
				&::before {
					content: counter(myCounter);
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					display: inline-flex;
					width: 45px;
					height: 45px;
					border-radius: 45px;
					background-color: #ffbf8a;
					font-size: 21px;
					font-weight: 700;
					color: #fff;
					align-items: center;
					justify-content: center;
					@include mobile {
						width: 30px;
						height: 30px;
						border-radius: 30px;
						font-size: 14px;
					}
				}

				@include until(640px) {
					flex-direction: column;
				}
				&:not(:last-child) {
					margin-bottom: 24px;
					&::after {
						content: '';
						position: absolute;
						top: 40px;
						left: 20px;
						z-index: 1;
						display: block;
						width: 3px;
						height: 100%;
						background-color: #ffbf8a;
						@include mobile {
							top: 30px;
							left: 14px;
							width: 2px;
						}
					}
				}
				dl {
					display: block;
					dt {
						display: block;
						margin-bottom: 20px;
						font-size: 28px;
						font-weight: 700;
						line-height: 1.2;
						@include mobile {
							margin-bottom: 8px;
							font-size: 19px;
						}
					}
					dd {
					}
				}
			}
		}
		&__img {
			display: flex;
			overflow: hidden;
			position: relative;
			border-radius: 16px;
			width: 250px;
			height: 145px;
			align-items: center;
			justify-content: center;
			flex: 0 0 250px;
			@include mobile {
				width: 100%;
				height: 0;
				flex: 0 0 40%;
				padding-top: 23.5%;
			}
			@include until(640px) {
				padding-top: 58%;
				flex: initial;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
.icon-selfdx {
	&-start1 {
		@include icon;
		width: 45px;
		height: 45px;
		background-image: url('~@/assets/img/selfdx/icon-start1.png');
		@include mobile {
			width: 35px;
			height: 35px;
		}
	}
	&-start2 {
		@include icon;
		width: 45px;
		height: 48px;
		background-image: url('~@/assets/img/selfdx/icon-start2.png');
		@include mobile {
			width: 35px;
			height: 38px;
		}
	}
}
