$dropdown-menu-min-width: 130px !default;

$dropdown-content-background-color: #fff !default;
$dropdown-content-arrow: $red !default;
$dropdown-content-offset: 4px !default;
$dropdown-content-padding-bottom: 0 !default;
$dropdown-content-padding-top: 0 !default;
$dropdown-content-radius: 4px !default;
$dropdown-content-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.05), 0 0px 0 1px rgba($scheme-invert, 0.01) !default;
$dropdown-content-z: 20 !default;

$dropdown-item-color: #606c88 !default;
$dropdown-item-hover-color: #1a233a !default;
$dropdown-item-hover-background-color: #f1f7ff !default;
$dropdown-item-active-color: #1a233a !default;
$dropdown-item-active-background-color: #f1f7ff !default;

$dropdown-divider-background-color: #bdbdbd !default;

.dropdown {
	display: inline-flex;
	position: relative;
	vertical-align: top;

	.dropdown-trigger {
		min-width: inherit;
		display: flex;
		align-items: center;
		.button {
			min-width: inherit;
			padding-left: $control-padding-horizontal;
			padding-right: $control-padding-horizontal;
			border-color: $border;
			border-radius: $radius;
			color: #707070;
			justify-content: space-between;
			@include control;
			border-color: #bdbdbd;
			border-radius: 5px;
			.dropdown__placeholder {
				flex: 1 1;
				text-align: left;
			}
			.ico {
				display: inline-flex;
				margin-top: -2px;
				margin-left: 6px;
				color: #bdbdbd;
				transform: rotate(0);
				transform-origin: 50% 50%;
				align-items: center;
				justify-content: center;
				@include transition(transform, 0.3s);
			}
		}
	}

	.dropdown-menu {
		margin-top: -1px;
		padding-top: 0;
		width: 100%; // 2023-02-01 화면검수 메뉴버튼에 맞게 크기 맞춤
	}

	&.is-active,
	&.is-hoverable:hover,
	&.is-hoverable.is-hover {
		.dropdown-menu {
			display: block;
		}
		.dropdown-trigger {
			.button {
				border-color: $black;
				background-color: #fff;
				.ico {
					transform: rotate(-180deg) translateY(-2px);
				}
				&:focus,
				&.is-focused {
					background-color: #fff;
				}
			}
		}
	}

	&.is-right {
		.dropdown-menu {
			left: auto;
			right: 0;
		}
	}

	&.is-up {
		.dropdown-menu {
			bottom: 100%;
			padding-bottom: $dropdown-content-offset;
			padding-top: inherit;
			top: auto;
		}
	}
	&--round {
		.dropdown-trigger {
			.button {
				min-width: 150px;
				height: 30px;
				border-radius: 50px;
				border-color: $grey-lighter;
				.ico {
					color: #1c1c1c;
				}
			}
		}
	}
}

.dropdown-menu {
	display: none;
	left: 0;
	min-width: $dropdown-menu-min-width;
	padding-top: $dropdown-content-offset;
	position: absolute;
	top: 100%;
	z-index: $dropdown-content-z;
	&--round {
		width: 100%;
		transform: translateY(5px);
	}
}

.dropdown-content {
	background-color: $dropdown-content-background-color;
	border-radius: $dropdown-content-radius;
	// box-shadow: $dropdown-content-shadow;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
	padding-bottom: $dropdown-content-padding-bottom;
	padding-top: $dropdown-content-padding-top;
	border: 1px solid #bdbdbd;

	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background-color: #e9e9e9;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background-color: #c9c9c9;
	}
	&--round {
		width: 100%;
		border-radius: 10px;
	}
}

.dropdown-item {
	color: $dropdown-item-color;
	display: flex;
	// font-size: 9px;
	line-height: 1.1;
	padding: 8px 15px 5px;
	position: relative;
	align-items: center;
	text-align: left;
	white-space: nowrap;
	width: 100%;
	font-size: $size-normal;
	font-weight: 400;
	&.--small {
		font-size: 12px;
	}
	&:hover,
	&:focus {
		background-color: $dropdown-item-hover-background-color;
		color: $dropdown-item-hover-color;
	}

	&.is-active {
		background-color: $dropdown-item-active-background-color;
		color: $dropdown-item-active-color;
	}
}

// a.dropdown-item,
// button.dropdown-item {
// 	text-align: left;
// 	white-space: nowrap;
// 	width: 100%;
// 	font-size: $size-normal;
// 	font-weight: 400;
// 	&.--small {
// 		font-size: 12px;
// 	}
// 	&:hover,
// 	&:focus {
// 		background-color: $dropdown-item-hover-background-color;
// 		color: $dropdown-item-hover-color;
// 	}

// 	&.is-active {
// 		background-color: $dropdown-item-active-background-color;
// 		color: $dropdown-item-active-color;
// 	}
// }

.dropdown-divider {
	background-color: $dropdown-divider-background-color;
	border: none;
	display: block;
	height: 1px;
	margin: 0;
	// margin: 5px 0;
}
