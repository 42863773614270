$control-radius: $radius !default;
$control-radius-small: $radius-small !default;

$control-border-width: 1px !default;

$control-height: 40px !default;
$control-line-height: 1.5 !default;

// $control-padding-vertical: calc(0.1em - #{$control-border-width}) !default;
// $control-padding-vertical: 5px !default;
// $control-padding-horizontal: 15px !default;

@mixin control {
	appearance: none;
	align-items: center;
	border: $control-border-width solid transparent;
	// border-radius: $control-radius;
	box-shadow: none;
	display: inline-flex;
	font-size: $size-normal;
	height: $control-height;
	justify-content: flex-start;
	line-height: $control-line-height;
	padding-bottom: $control-padding-vertical;
	padding-left: $control-padding-horizontal;
	padding-right: $control-padding-horizontal;
	padding-top: $control-padding-vertical;
	position: relative;
	vertical-align: top;

	// States
	// &:focus,
	// &.is-focused,
	// &:active,
	// &.is-active {
	// 	outline: none;
	// }

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}
}
@mixin control2 {
	appearance: none;
	align-items: center;
	// border: $control-border-width solid transparent;
	// border-radius: $control-radius;
	box-shadow: none;
	display: inline-flex;
	font-size: $size-normal;
	height: $control-height;
	justify-content: flex-start;
	line-height: $control-line-height;
	padding-bottom: $control-padding-vertical;
	padding-left: $control-padding-horizontal;
	padding-right: $control-padding-horizontal;
	padding-top: $control-padding-vertical;
	position: relative;
	vertical-align: top;

	// States
	// &:focus,
	// &.is-focused,
	// &:active,
	// &.is-active {
	// 	outline: none;
	// }

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}
}

%control {
	@include control;
}
%control2 {
	@include control2;
}

// The controls sizes use mixins so they can be used at different breakpoints
@mixin control-small {
	border-radius: $control-radius-small;
	font-size: $size-small;
}

@mixin control-medium {
	font-size: $size-medium;
}

@mixin control-large {
	font-size: $size-large;
}
