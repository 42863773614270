//  공지사항/자료실 게시판

.bbs {
	&-list {
		&-header {
			line-height: 1.2;
			margin-bottom: 1em;
		}
		&-title {
			font-size: 21px;
			font-weight: 700;
		}
	}
	&-detail {
		&-header {
			&__back {
				margin-bottom: 1em;
				.icon-bbs-back {
					margin-top: -2px;
				}
				> a {
					display: inline-flex;
					align-items: center;
				}
				.text {
					font-size: 14px;
					font-weight: 400;
					color: #ff7300;
					line-height: 1.1;
				}
			}
			&__title {
				margin-bottom: 0.5em;
				font-size: 24px;
				font-weight: 700;
				line-height: 1.2;
			}
		}
		&-body {
			margin-top: 1.5em;
			margin-bottom: 1.5em;
			padding: 1.5em 0;
			border-top: 1px solid #bdbdbd;
			border-bottom: 1px solid #bdbdbd;
		}
		&-content {
			font-size: 14px;
			line-height: 1.6;
		}
		&-footer {
		}
		&-buttons {
			display: flex;
			justify-content: flex-end;
			.button-default {
				width: auto;
				height: 30px;
				padding: 0 20px;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
}
.attach-list {
	&-dl {
		display: flex;
		margin-top: 6em;
		font-size: 12px;
	}
	&-dt {
		flex: 0 0 45px;
		margin-right: 0.6em;
	}
	&-dd {
	}
	&-item {
		&:not(:first-child) {
			margin-top: 1em;
		}
		> a {
			display: inline-block;
			font-weight: 400;
			line-height: 1.2;
			&:hover,
			&:focus {
				.text {
					text-decoration: underline;
				}
			}
			.icon-attach {
				margin-left: 0.5em;
			}
		}
	}
}
.table {
	&-bbs {
		font-size: 16px;
		font-weight: 400;
		&.is-bordered {
			@include mobile {
				border-top: 2px solid #757575;
				colgroup {
					display: none;
				}
			}
			th,
			td {
				height: auto;
				padding: 0.6em;
				border-bottom: 1px solid #bdbdbd;
				text-align: center;
				@include mobile {
					border: none;
					padding: 0;
				}
			}
			thead {
				th {
					border-top: 1px solid #757575;
					border-bottom: none;
					background-color: #f2f0ed;
					font-size: 14px;
					font-weight: 700;
				}
				@include mobile {
					display: none;
				}
			}
			tbody {
				tr {
					@include mobile {
						display: flex;
						padding: 0.8em 0.4em;
						border-bottom: 1px solid #bdbdbd;
						flex-wrap: wrap;
						gap: 5px;
					}
				}
				th,
				td {
					font-size: 14px;
					font-weight: 400;
					color: #757575;
					padding: 1em 0.5em;
					@include mobile {
						margin-right: 1em;
						padding: 0;
						font-size: 12px;
					}
					&.bbs-title {
						padding-left: 1em;
						text-align: left;
						@include mobile {
							width: 100%;
							padding: 0;
						}
					}
					&.bbs-download {
						a {
							display: inline-block;
							padding: 0 0.5em;
						}
						@include mobile {
							display: none;
						}
					}
					&.bbs-count {
						@include mobile {
							&::before {
								content: '조회 ';
								display: inline-block;
								margin-right: 0.3em;
							}
						}
					}
				}
			}
		}
		.bbs-title {
			a {
				display: flex;
				font-size: 16px;
				color: #424242;
				white-space: nowrap;
				@include mobile {
					font-size: 15px;
				}
				strong {
					@include text-ellipsis;
					font-weight: 600;
				}
				em {
					font-weight: 400;
				}
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.icon-download {
	@include icon;
	width: 16px;
	height: 17px;
	background-image: url(~@/assets/img/scommunity/icon_download.svg);
}
.icon-bbs-back {
	@include icon;
	width: 13px;
	height: 12px;
	margin-right: 3px;
	background-image: url(~@/assets/img/scommunity/icon_back.svg);
}
.icon-attach {
	@include icon;
	width: 13px;
	height: 15px;
	background-image: url(~@/assets/img/scommunity/icon_attach.svg);
}
