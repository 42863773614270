.page-header.page-header-self {
	padding: 20px 0 40px;
}

.box_logo {
	text-align: center;
}
.box_logo a {
	display: inline-block;
}
.box_logo span {
	display: block;
	width: 190px;
	height: 45px;
	background: url('../img/common/footer_sbdc_logo.png') no-repeat;
	text-indent: -9999em;
	overflow: hidden;
	background-size: auto 50px;
}

.box_title {
	padding: 20px 0 5px;
	margin-top: 20px;
	text-align: center;
	background: #eee;
}
.box_title h2 {
	line-height: 45px;
	font-family: 'GmarketSans';
	font-weight: 700;
	color: #285e3b;
	-webkit-text-stroke: 2px white;
}
.box_title h2 span {
	display: block;
	-webkit-text-stroke: 3px white;
}

.self_wrap .miri,
.self_wrap ul li + li {
	margin-top: 50px;
}
.self_wrap ul li {
	padding: 30px 15px;
	position: relative;
}
.self_wrap ul li a {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.self_wrap ul li:first-child {
	background: #eee;
}
.self_wrap ul li:not(:first-of-type)::before {
	display: inline-block;
	width: 100%;
	height: 265px;
	background: #eee;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
}
.self_wrap ul li:last-child::before {
	height: 180px;
}
.self_wrap ul li::after {
	width: calc(100% - 200px);
	padding: 6px 0px 3px;
	text-align: center;
	font-size: 18px;
	font-family: 'GmarketSans';
	border-radius: 50px;
	color: #fff;
	background: #285e3b;
	position: absolute;
	content: '바로가기';
	top: -15px;
	left: 100px;
}
.self_wrap ul li:nth-child(2)::after {
	content: '이용안내';
}
.self_wrap ul li:last-child::after {
	content: '학습하기';
}
.self_wrap .mango::after {
	display: inline-block;
	border-bottom: 2px dashed #b2afaf;
	content: '';
	position: absolute;
	left: 15px;
	right: 15px;
}

.self_wrap .page_link {
	padding: 20px;
	border: 3px solid #f7ca3d;
	border-radius: 30px;
	background: #fff;
	position: relative;
}
.self_wrap .page_link span {
	display: inline-block;
	margin-bottom: 10px;
	font-size: 25px;
	font-family: 'GmarketSans';
	font-weight: 700;
	color: #f7ca3d;
}
.self_wrap .page_link p {
	line-height: 23px;
	font-size: 16px;
	font-family: 'GmarketSans';
}
.self_wrap .miri .page_link {
	border-color: #4fd2b3;
}
.self_wrap .miri .page_link span {
	color: #4fd2b3;
}

.self_wrap .page_img {
	margin-top: 15px;
	position: relative;
}
.self_wrap .page_img img {
	width: 100%;
	border-radius: 30px;
}

.self_wrap .manual {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}
.self_wrap .manual .manual_down {
	margin-top: 20px;
}
.self_wrap .manual .manual_down .logo {
	display: block;
	margin-bottom: 10px;
}
.self_wrap .manual .manual_down p {
	display: inline-block;
	margin-right: 25px;
	line-height: 30px;
	font-size: 25px;
	font-family: 'GmarketSans';
	font-weight: 700;
	color: #285e3b;
}
.self_wrap .manual .manual_down .down_icon {
	width: 57px;
	height: 57px;
}
.self_wrap .manual .manual_icon {
	width: 220px;
}
.self_wrap .miri .manual .manual_icon {
	width: 230px;
	margin-bottom: 15px;
	position: relative;
	bottom: -10px;
}

.self_wrap .video_link {
	margin: 20px auto;
	text-align: center;
	position: relative;
}
.self_wrap .video_link img {
	width: 100%;
}
.self_wrap .video_link p {
	padding: 15px 0 15px;
	font-size: 21px;
	font-family: 'GmarketSans';
	background: #f7ca3d;
}
.self_wrap .video_link span {
	padding: 3px 20px 0px;
	font-size: 16px;
	font-family: 'GmarketSans';
	border-radius: 50px;
	color: #fff;
	background: #285e3b;
	position: relative;
	bottom: 5px;
}

.box_logo.box_logo_gachi {
	padding-bottom: 40px;
}
.box_logo.box_logo_gachi span {
	width: 170px;
	height: 40px;
	background: url('../img/common/logo.svg') no-repeat;
	background-size: auto 40px;
}
