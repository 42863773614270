@use 'functions';
@use 'variables';

$primary: $blue !default;

$link: #0f56b3 !default;
$info: $cyan !default;
$success: $green !default;
$warning: #d4b011 !default;
$danger: #da100b !default;
$error: #da100b !default;

$light: #f9f9f9 !default;
$dark: #888 !default;

// Invert colors

$orange-invert: functions.findColorInvert($orange) !default;
$yellow-invert: functions.findColorInvert($yellow) !default;
$green-invert: functions.findColorInvert($green) !default;
$turquoise-invert: functions.findColorInvert($turquoise) !default;
$cyan-invert: functions.findColorInvert($cyan) !default;
$blue-invert: functions.findColorInvert($blue) !default;
$purple-invert: functions.findColorInvert($purple) !default;
$red-invert: functions.findColorInvert($red) !default;

$primary-invert: #fff !default;
$primary-light: #69b4ff !default;
$primary-normal: #1890ff !default;
$primary-dark: #0059cb !default;
$info-invert: functions.findColorInvert($info) !default;
$info-light: functions.findLightColor($info) !default;
$info-dark: functions.findDarkColor($info) !default;
$success-invert: functions.findColorInvert($success) !default;
$success-light: #c5f2c7 !default;
$success-dark: #155d18 !default;
$warning-invert: functions.findColorInvert($warning) !default;
$warning-light: #fbf3d0 !default;
$warning-dark: #7f4c0a !default;
$danger-invert: functions.findColorInvert($danger) !default;
$danger-light: #fcd0cf !default;
$danger-dark: #910b08 !default;
$error-invert: functions.findColorInvert($error) !default;
$error-light: $danger-light !default;
$error-dark: $danger-dark !default;
$link-invert: functions.findColorInvert($link) !default;
$link-light: #c5dcfa !default;
$link-dark: #17205b !default;
$light-invert: functions.findColorInvert($light) !default;
$dark-invert: functions.findColorInvert($dark) !default;

// General colors

$scheme-main: $white !default;
$scheme-main-bis: $white-bis !default;
$scheme-main-ter: $white-ter !default;
$scheme-invert: $black !default;
$scheme-invert-bis: $black-bis !default;
$scheme-invert-ter: $black-ter !default;

$background: $white-ter !default;

$border: #d9d9d9 !default;
$border-hover: $blue !default;
$border-light: $grey-lightest !default;
$border-light-hover: $grey-light !default;

// Text colors
$text: $black !default;
$text-invert: functions.findColorInvert($text) !default;
$text-light: $grey !default;
$text-lighter: $grey-light !default;
$text-strong: $black !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: #fff !default;
$link-invert: #1a233a !default;
$link-light: functions.findLightColor($link) !default;
$link-dark: functions.findDarkColor($link) !default;
$link-visited: $purple !default;

$link-hover: $grey-darker !default;
$link-hover-border: $grey-light !default;

$link-focus: $grey-darker !default;
$link-focus-border: $blue !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

// Typography

$family-primary: $family-sans-serif !default;
$family-secondary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-6 !default;
$size-normal: $size-5 !default;
$size-medium: $size-4 !default;
$size-large: $size-3 !default;

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

$colors: functions.mergeColorMaps(
	(
		'white': (
			$white,
			$black,
		),
		'black': (
			$black,
			$white,
		),
		'light': (
			$light,
			$light-invert,
		),
		'dark': (
			$dark,
			$dark-invert,
		),
		'primary': (
			$primary,
			$primary-invert,
			$primary-light,
			$primary-dark,
		),
		'link': (
			$link,
			$link-invert,
			$link-light,
			$link-dark,
		),
		'info': (
			$info,
			$info-invert,
			$info-light,
			$info-dark,
		),
		'success': (
			$success,
			$success-invert,
			$success-light,
			$success-dark,
		),
		'warning': (
			$warning,
			$warning-invert,
			$warning-light,
			$warning-dark,
		),
		'danger': (
			$danger,
			$danger-invert,
			$danger-light,
			$danger-dark,
		),
	),
	$custom-colors
) !default;
$shades: functions.mergeColorMaps(
	(
		'black-bis': $black-bis,
		'black-ter': $black-ter,
		'grey-darker': $grey-darker,
		'grey-dark': $grey-dark,
		'grey': $grey,
		'grey-light': $grey-light,
		'grey-lighter': $grey-lighter,
		'white-ter': $white-ter,
		'white-bis': $white-bis,
	),
	$custom-shades
) !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 !default;
