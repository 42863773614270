#onfan-intro {
	.container {
		max-width: 1280px;
	}
	.hidden-mobile {
		display: flex !important;
		@include mobile {
			display: none !important;
		}
	}
	.visible-mobile {
		display: none !important;
		@include mobile {
			display: block !important;
		}
	}
	.fullpage {
		height: auto;
	}
	.fullpage-partner {
		.fp-fade-down {
			display: none;
		}
		.fp-content {
			max-height: 400px;
		}
	}
	.onfan {
		&-intro {
			&-top {
				// padding-bottom: clamp(160px, 47vw, 630px);
				padding-bottom: clamp(140px, 30vw, 430px);
				background-position: 50% 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-image: url(~@/assets/img/onfan/bg_top.png);
				@include tablet {
					padding-bottom: clamp(120px, 14vw, 350px);
					background-size: 100% auto;
					background-image: url(~@/assets/img/onfan/bg_top_mo.png);
				}
				&__image {
					display: flex;
					position: relative;
					height: auto;
					margin-bottom: clamp(60px, 12vw, 250px);

					&::before {
						content: '';
						position: absolute;
						top: 0;
						width: 50%;
						height: 100%;
						left: 0;
						background-color: #141318;
						@include tablet {
							display: none;
						}
					}
					picture {
						width: 100%;
						position: relative;
						justify-content: flex-end;
					}
					source,
					img {
						max-height: 780px;
						margin: 0;
						@include mobile {
							width: 100%;
						}
					}
				}
			}
			&-mypage {
				&__title {
					margin-bottom: clamp(20px, 5vw, 57px);
					text-align: center;
					em {
						display: block;
						margin: 0 auto clamp(10px, 2vw, 20px);
						font-size: clamp(14px, 3vw, 40px);
						font-weight: 700;
						letter-spacing: 0.5em;
						@include word-wrap;
					}
					strong {
						display: block;
						margin: 0 auto;
						font-size: clamp(24px, 5vw, 65px);
						font-weight: 800;
						color: #ff7300;
						@include word-wrap;
					}
				}
				&__img {
					display: flex;
					max-width: 1200px;
					width: 80%;
					min-width: 280px;
					margin: 0 auto;
					overflow: hidden;
					border-radius: 20px;
					box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.2);
					@include mobile {
						max-width: 298px;
					}
				}
			}
			&-content {
				position: relative;
				z-index: 1;
				margin-top: -400px;
				padding: 300px 0 120px;
				background-color: #3b3b3b;
				border-radius: 400px 0 400px 0;
				@include tablet {
					margin-top: -110px;
					padding: clamp(44px, 15vw, 300px) 0 clamp(4px, 2vw, 120px);
					border-radius: 0 110px 0 110px;
				}
				@include mobile {
					padding: 40px 0 1px;
				}
				.onfan-intro-section {
					margin-bottom: clamp(44px, 12vw, 180px);
				}
				.onfan-intro-section__header {
					color: #fff;
					@include mobile {
						.onfan-intro-section__title {
							&:after {
								display: block;
							}
						}
					}
				}
				&::after {
					content: '';
					position: absolute;
					display: block;
					width: 410px;
					height: 660px;
					bottom: 143px;
					right: 84px;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url(~@/assets/img/onfan/bg_logo.png);
					@include tablet {
						display: none;
					}
				}
				&__feature {
					display: flex;
					max-width: 866px;
					width: 100%;
					height: auto;
					overflow: hidden;
					margin: 0 auto;
					source,
					img {
						width: 100%;
						height: auto;
					}
					&-wrap {
						position: relative;
						padding: clamp(38px, 10vw, 140px) 0 0;
						&::before {
							content: '';
							position: absolute;
							display: block;
							width: 1211px;
							height: 200px;
							top: 45px;
							left: 50%;
							transform: translateX(-50%);
							opacity: 0.05;
							background-position: center;
							background-size: contain;
							background-repeat: no-repeat;
							background-image: url(~@/assets/img/onfan/bg_onestop.png);
							@include tablet {
								width: 95%;
								height: 14vw;
								top: 3%;
							}
						}
					}
				}
				&__vision {
					position: relative;
					display: flex;
					margin-top: clamp(20px, 3.5vw, 50px);
					gap: 20px;
					z-index: 1;
					@include tablet {
						gap: 10px;
					}
					li {
						position: relative;
						width: 50%;
						&:before {
							content: '';
							position: absolute;
							display: block;
							width: 89px;
							height: 89px;
							top: 50px;
							right: 50px;
							opacity: 0.2;
							@include icon;
							@include tablet {
								transform: scale(0.5);
								top: initial;
								bottom: 0px;
								right: 0px;
							}
						}
						&:first-child {
							dl {
								border-radius: 100px 0 0 0;
								@include tablet {
									border-radius: 0 0 0 50px;
								}
							}
							&:before {
								background-image: url(~@/assets/img/onfan/icon1.svg);
							}
						}
						&:last-child {
							dl {
								border-radius: 0 0 100px 0;
								@include tablet {
									border-radius: 0 50px 0 0;
								}
							}
							&:before {
								width: 86px;
								height: 86px;
								background-image: url(~@/assets/img/onfan/icon2.svg);
							}
						}
					}
					dl {
						color: #fff;
						height: 100%;
						min-height: 135px;
						padding: clamp(20px, 5vw, 60px);
						background-color: #262626;
						font-size: clamp(16px, 4vw, 30px);
						font-weight: 300;
						box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
						dt {
							margin-bottom: 30px;
							font-size: clamp(16px, 3vw, 35px);
							font-weight: 700;
							line-height: 1.2;
							@include word-wrap;
							strong {
								display: block;
								margin-bottom: clamp(1px, 0.5vw, 10px);
								color: #ff8a24;
								font-size: clamp(21px, 4vw, 48px);
								font-weight: 700;
							}
							@include tablet {
								margin: 0;
							}
							@include mobile {
								width: 100px;
							}
						}
						dd {
							line-height: 1.3;
							@include tablet {
								display: none;
							}
						}
					}
				}
			}
			&-bottom {
				position: relative;
				margin-top: -400px;
				padding-top: 670px;
				background-size: 100% auto;
				background-image: url(~@/assets/img/onfan/bg_main.jpg);
				.onfan-intro-section__header {
					margin-bottom: 70px;
					@include tablet {
						margin-bottom: clamp(10px, 3vw, 70px);
					}
				}
				@include tablet {
					margin-top: -110px;
					padding-top: clamp(80px, 24vw, 670px);
				}
				@include mobile {
					padding-top: 165px;
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					z-index: 0;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 800px;
					background: rgb(255, 255, 255);
					background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8169642857142857) 50%, rgba(255, 255, 255, 0) 100%);
				}
			}
			&-footer {
				background-color: #fff;
			}
			&-section {
				position: relative;
				margin-bottom: 300px;
				z-index: 1;
				@include tablet {
					margin-bottom: clamp(44px, 12vw, 200px);
				}
				&__header {
					display: flex;
					align-items: center;
					gap: 28px;
					@include tablet {
						display: block;
					}
				}
				&__title {
					display: flex;
					font-size: 64px;
					font-weight: 600;
					flex: 0 0;
					gap: 28px;
					align-items: center;
					white-space: nowrap;
					@include word-wrap;
					@include tablet {
						font-size: clamp(20px, 4vw, 64px);
						gap: clamp(10px, 2vw, 28px);
					}
					&::after {
						content: '';
						display: block;
						width: clamp(4px, 1vw, 13px);
						height: clamp(4px, 1vw, 13px);
						border-radius: 50%;
						background-color: #ff7300;
						flex: 0 0 clamp(4px, 1vw, 13px);
						@include mobile {
							display: none;
						}
					}
					@include tablet {
						margin-bottom: 14px;
					}
				}
				&__desc {
					font-size: 30px;
					font-weight: 300;
					line-height: 1.3;
					flex: 1 1;
					@include word-wrap;
					@include tablet {
						font-size: clamp(14px, 2.5vw, 30px);
					}
					@include mobile {
						br {
							display: none;
						}
					}
				}
				&__links {
					ol {
						counter-reset: number 0;
						> li {
							display: flex;
							position: relative;
							gap: 50px;
							align-items: center;
							&:not(:first-child) {
								margin-top: 30px;
								@include until(860px) {
									margin-top: 12px;
								}
							}
							&:nth-child(even) {
								justify-content: flex-end;
							}
							> a {
								position: relative;
								&::before {
									position: absolute;
									top: -26px;
									left: 0;
									opacity: 0.3;
									font-size: 118px;
									font-weight: 700;
									color: #fff;
									counter-increment: number 1;
									content: counter(number);
									@include until(860px) {
										display: none;
									}
								}
							}
						}
					}
					&-item {
						display: flex;
						overflow: hidden;
						width: 580px;
						height: 253px;
						border-radius: 0 90px 0 0;
						background-color: #f1822d;
						box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.2);

						@include until(860px) {
							width: 100%;
							height: 112px;
							border-radius: 30px 0 30px 0;
							box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.2);
						}
						&::before {
							content: '';
						}
						.links-item-img {
							position: relative;
							width: 260px;
							height: 253px;
							flex: 0 0 253px;
							@include until(860px) {
								width: 165px;
								height: 112px;
								flex: 0 0 165px;
							}
							@include mobile {
								max-width: 164px;
								flex: 0 0 40%;
							}
							img {
								position: absolute;
								top: 50%;
								left: 50%;
								width: 100%;
								height: 100%;
								object-fit: cover;
								transform: translate(-50%, -50%);
							}
						}
						.links-item-text {
							display: flex;
							padding: 32px;
							color: #fff;
							font-size: 19px;
							font-weight: 300;
							line-height: 1.4;
							flex-direction: column;
							justify-content: center;
							@include word-wrap;
							@include until(860px) {
								font-size: 14px;
							}
							@include mobile {
								padding: 20px 10px 20px 25px;
							}
							strong {
								display: block;
								margin-bottom: 12px;
								font-size: 24px;
								font-weight: 700;
								line-height: 1.1;
								@include until(860px) {
									font-size: 16px;
								}
							}
						}
					}
					&-list {
						padding: 0 34px;
						border-left: 2px solid #424242;
						flex-direction: column;
						gap: 10px;
						@include until(860px) {
							display: none;
						}
						a {
							display: flex;
							width: 185px;
							padding: 0.4em 0;
							font-size: 24px;
							font-weight: 700;
							align-items: center;
							justify-content: space-between;
							&:hover {
								text-decoration: underline;
							}
							&:after {
								content: '';
								display: block;
								width: 12px;
								height: 18px;
								@include icon;
								background-image: url(~@/assets/img/onfan/arrow-right.svg);
							}
						}
					}
				}
				&__logo {
					display: flex;
					margin-bottom: 25px;
					@include tablet {
						margin-bottom: 10px;
					}
					gap: 40px;
					.flex-l {
						display: flex;
						padding: 30px;
						border: 1px solid #bfbfbf;
						border-radius: 0 80px 0 0;
						background-color: #fff;
						align-items: center;
						justify-content: center;
						flex: 0 0 45%;
						@include mobile {
							width: 100%;
							border-radius: 0 40px 0 0;
							flex: 0 0 100%;
						}
						img {
							width: 80%;
							min-width: 185px;
							max-width: 333px;
							height: auto;
							@include mobile {
								max-width: 200px;
							}
						}
					}
					.flex-r {
						font-size: 21px;
						font-weight: 300;
						line-height: 1.4;
						p:not(:last-child) {
							margin-bottom: 1em;
						}
						@include mobile {
							display: none;
						}
					}
					&-desc {
						display: flex;
						padding: 30px 80px;
						border-radius: 15px;
						background-color: #d6d6d6;
						align-items: center;
						justify-content: center;
						gap: 190px;
						@include tablet {
							gap: 8vw;
						}
						@include mobile {
							display: block;
							width: 100%;
							padding: 0px;
							background-color: transparent;
						}
						ul {
							display: flex;
							align-items: center;
							gap: 80px;
							@include mobile {
								margin-bottom: 10px;
								padding: 15px;
								border-radius: 15px;
								background-color: #d6d6d6;
								gap: 32px;
								justify-content: center;
							}
							li {
								display: flex;
								position: relative;
								flex-direction: column;
								align-items: center;
								gap: 10px;
								font-size: 16px;
								color: #535353;
								@include mobile {
									font-size: 14px;
									gap: 5px;
								}
								.icon {
									width: 85px;
									height: 80px;
									@include mobile {
										width: 47px;
										height: 48px;
									}
									img {
										width: 100%;
										height: 100%;
									}
								}
								@include mobile {
								}
								&:not(:first-child) {
									&::before {
										position: absolute;
										content: '+';
										top: 9px;
										left: -73%;
										font-size: 50px;
										color: #bbb;
										font-weight: 100;
										@include mobile {
											top: 7px;
											left: -59%;
											font-size: 30px;
										}
									}
								}
							}
						}
						.buttons {
							width: 100%;
							margin: 0;
							flex-wrap: nowrap;
							justify-content: center;
							.button-default {
								width: 210px;
							}
							@include until(1030px) {
								flex-wrap: wrap;
							}
							@include mobile {
								margin: 0 auto;
								flex-wrap: nowrap;
								justify-content: flex-end;
								.button-default {
									min-width: 118px;
									width: 118px;
									height: 32px;
									padding: 0 20px;
									font-size: 16px;
								}
							}
						}
					}
				}
			}
			&-footer {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-bottom: 200px;
				@include mobile {
					margin-bottom: 0;
				}
				&__image {
					position: relative;
					width: 80%;
					max-width: 1480px;
					height: auto;
					z-index: 1;
				}
			}
		}
	}
	.fp-partner-scroll {
		&__video {
			width: 96%;
			height: 0;
			padding-top: 36%;
			align-items: flex-end;
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 10;
				width: 2px;
				height: 100%;
				background-color: #fff;
			}
			&::before {
				left: -1px;
			}
			&::after {
				right: -1px;
			}
			.pc {
				width: 100%;
				@include until(1020px) {
					display: block;
				}
				@include mobile {
					display: none;
				}
			}
			.mo {
				@include until(1020px) {
					display: none;
				}
				@include mobile {
					display: block;
					width: 100%;
				}
			}
			@include mobile {
				width: 94%;
				padding-top: 74%;
			}
			@include until(630px) {
				padding-top: 83%;
			}
			@include until(430px) {
				padding-top: 93%;
			}
		}
		&__inner {
			@include mobile {
				height: 0;
				margin-top: 15%;
				padding-top: 65%;
			}
			@include until(630px) {
				margin-top: 5%;
				padding-top: 70%;
			}
			@include until(430px) {
				margin-top: 0;
			}
		}
		&__img {
			width: 100%;
			img {
				width: 100%;
				max-width: 1120px;
			}
			&-handle {
				width: 100%;
				.pc {
					@include until(1020px) {
						display: block;
					}
					@include mobile {
						display: none;
					}
				}
				.mo {
					@include until(1020px) {
						display: none;
					}
					@include mobile {
						display: block;
					}
				}
				@include mobile {
					top: 0px;
					transition-delay: 3.5s;
				}
			}
		}
	}
}
