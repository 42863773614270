.field {
	display: flex;
	align-items: center;
	gap: 8px;
	&:not(:last-child) {
		margin-bottom: 16px;
	}
	.is-grouped {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: space-between;
		.button-default {
			flex: 0 0;
		}
	}
	&.field-row {
		@include mobile {
			flex-direction: column;
			align-items: unset;
		}
		&.is-mobile-row {
			@include mobile {
				flex-direction: row;
				align-items: center;
			}
			strong {
				font-weight: 700;
			}
		}
		.field {
			&-label {
				height: auto;
				margin: 0;
				text-align: left;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.2;
				flex: 0 0;
				@include mobile {
					br {
						display: none;
					}
				}
				.required {
					margin-top: -3px;
				}
				&.w120 {
					flex-basis: 120px;
					@include mobile {
						flex-basis: auto;
						text-align: left;
					}
				}
				&.w160 {
					flex-basis: 160px;
					@include word-wrap;
					@include mobile {
						flex-basis: auto;
						text-align: left;
					}
				}
			}
			&-form {
				flex: 1 1;
			}
		}
	}
	.control {
		.dropdown .dropdown-trigger .button,
		.input {
			display: flex;
			width: 100%;
			min-width: 100px;
			height: 50px;
			padding: 2px 15px;
			border: 1px solid #bdbdbd;
			border-radius: 5px;
			background: #ffffff;
			color: #424242;
			font-size: 16px;
			font-weight: 400;
			align-items: center;
			line-height: 1;
			@include placeholder {
				color: #bdbdbd;
			}
			&-text {
				display: flex;
				width: 100%;
				min-width: 100px;
				height: 50px;
				font-size: 16px;
				font-weight: 400;
				align-items: center;
				line-height: 1;
			}
		}
		.input[readonly] {
			border-color: #e1e1e1;
			background-color: #f7f7f7;
		}
	}
}

.mbrp {
	&-section {
		.container {
			max-width: 1230px;
			padding: 0 40px;
			@include mobile {
				padding: 0 15px;
			}
		}
		.fp-biz-buttons {
			margin-bottom: 60px;
			.fp-biz-button {
				height: 50px;
				font-size: 21px;
				@include mobile {
					font-size: 16px;
				}
			}
		}

		.section {
			&:not(:last-child) {
				margin-bottom: 50px;
				@include mobile {
					margin-bottom: 20px;
				}
			}
			&-header {
				margin-bottom: 20px;
				.title {
					.form-checkbox label {
						font-size: 19px;
						.color-orange {
							font-weight: normal;
						}
						@include mobile {
							font-size: 17px;
						}
					}
				}
			}
			&-content {
				overflow-x: hidden;
				overflow-y: auto;
				width: 100%;
				height: 135px;
				padding: 20px;
				border-radius: 5px;
				border: 1px solid #bdbdbd;
				background-color: #fff;
			}
		}
	}
	&-header {
		margin-top: 100px;
		margin-bottom: 60px;
		text-align: center;
		@include mobile {
			margin-top: 60px;
			margin-bottom: 40px;
		}
		.title {
			font-size: 32px;
			font-weight: 700;
			&.is-large {
				font-size: 50px;
				font-weight: 900;
				@include mobile {
					font-size: 32px;
					font-weight: 700;
				}
			}
		}
	}
	&-body {
	}
	&-cert {
		&-wrap {
			margin-bottom: 60px;
			gap: 16px;
			@include mobile {
				flex-direction: column;
			}
			.flex-l {
				flex: 1 1;
				.field-label {
					font-size: 18px;
					font-weight: bold;
				}
			}
			.flex-r {
				flex: 0 0 217px;
				@include mobile {
					flex-basis: auto;
				}
				.button-default {
					width: 100%;
					height: 100%;
					border-radius: 15px;
					font-size: 16px;
					@include mobile {
						height: 75px;
					}
				}
			}
		}
		&-desc {
			margin-bottom: 60px;
			line-height: 1.3;
			p {
				margin-bottom: 16px;
			}
			&.is-padding {
				padding: 3em;
			}
		}
	}
	&-forms {
		.fieldset-content {
			.field-form {
				.input {
					width: calc(100% - 138px);
					@include mobile {
						width: 100%;
					}
				}
			}
		}
		&-help {
			font-size: 16px;
			font-weight: 400;
			line-height: 1.3;
			margin-top: 10px;
		}
	}
	&-complete {
		margin-bottom: 60px;
		&-header {
			margin-bottom: 50px;
			padding-bottom: 0.9em;
			border-bottom: 1px solid #000;
			font-size: 21px;
			font-weight: 700;
			@include mobile {
				text-align: center;
			}
		}
		&-content {
			text-align: center;
			&__title {
				display: block;
				font-size: 21px;
				font-weight: 700;
				margin-bottom: 1em;
			}
			&__desc {
				font-size: 16px;
				line-height: 1.3;
				strong.color-orange {
					font-weight: 700;
					color: #ff7300;
				}
			}
		}
	}
}
.join-intro {
	display: flex;
	padding-top: 25px;
	gap: 65px;
	align-items: center;
	justify-content: center;
	@include mobile {
		flex-direction: column;
		gap: 20px;
	}
	> li {
		display: flex;
		width: 350px;
		height: 350px;
		border-radius: 50%;
		background-color: #eee;
		align-items: center;
		justify-content: center;
		@include mobile {
			width: 260px;
			height: 260px;
		}
	}
	&-circle {
		text-align: center;
		.button-default.is-large.is-rounded {
			width: auto;
			min-width: inherit;
			height: 50px;
			padding: 0 20px;
			@include mobile {
				height: 30px;
				font-size: 16px;
				font-weight: normal;
			}
		}
		.icon-wrap {
			display: inline-flex;
			width: 65px;
			height: 65px;
			margin-bottom: 15px;
			align-items: center;
			justify-content: center;
		}
	}
	&-text {
		display: block;
		margin-bottom: 16px;
		text-align: center;
		font-weight: bold;
		line-height: 1.2;
		strong {
			display: block;
			font-size: 24px;
		}
		em {
			display: block;
			font-weight: bold;
			font-size: 16px;
			color: #757575;
		}
	}
}
.icon {
	&-join {
		&-integ {
			@include icon;
			width: 60px;
			height: 64px;
			background-image: url(~@/assets/img/common/icon_join_integ.svg);
		}
		&-general {
			@include icon;
			width: 54px;
			height: 61px;
			background-image: url(~@/assets/img/common/icon_join_general.svg);
		}
	}
}

.form-checkbox.is-largetext {
	margin-bottom: 20px;
	label {
		font-size: 21px;
		font-weight: 700;
		@include mobile {
			font-size: 19px;
		}
	}
}

// 이용약관
.terms {
	&-wrap {
	}
	&-section {
		line-height: 1.5;
		font-size: 0.9em;
		&:not(:last-child) {
			margin-bottom: 1em;
		}
		&-title {
			font-size: 1.2em;
			font-weight: bold;
		}
		p {
			&:not(:last-child) {
				margin-bottom: 1em;
			}
			&.text-indent {
				padding-left: 1em;
			}
		}
		.text-under {
			text-decoration: underline;
		}
	}
}

.required {
	position: relative;
	overflow: hidden;
	display: inline-flex;
	width: 18px;
	height: 18px;
	text-indent: -999em;
	vertical-align: middle;
	align-items: center;
	&::after {
		content: '*';
		display: inline-block;
		width: 100%;
		height: 100%;
		margin-left: 0.1em;
		font-size: 20px;
		font-weight: 400;
		text-indent: 0;
		color: #ff7300;
	}
}
.fieldset {
	&-legend {
		display: inline;
		margin: 0;
		font-size: 21px;
		font-weight: 700;
		line-height: 1.1;
	}
	&-header {
		display: flex;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #221a1a;
		align-items: center;
		justify-content: space-between;
	}
	&-section {
		display: block;
		margin-bottom: 60px;
	}
	&-reference {
		display: flex;
		align-items: center;
	}
}

.input-password-wrap {
	position: relative;
	@at-root .mbrp-forms .fieldset-content & {
		width: calc(100% - 138px);
		@include mobile {
			width: 100%;
		}
		.input {
			padding-right: 50px;
			width: 100%;
		}
	}
	.input-password-button {
		display: flex;
		position: absolute;
		overflow: hidden;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		align-items: center;
		justify-content: center;
		&.is-active {
			.input-password-icons {
				transform: translateY(-50%);
			}
		}
	}
	.input-password-icons {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
		width: 50px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		transform: translateY(0);
		@include transition(transform, 0.3s);
		span {
			display: flex;
			width: 50px;
			height: 50px;
			align-items: center;
			justify-content: center;
		}
	}
}
.input-password-container {
	display: flex;
	gap: 10px;
	justify-content: space-between;
	.button-default {
		@include word-wrap;
	}
}
.icon {
	&-eye {
		&-on {
			@include icon;
			width: 22px;
			height: 18px;
			background-image: url(~@/assets/img/common/eye-on.svg);
		}
		&-off {
			@include icon;
			width: 22px;
			height: 18px;
			background-image: url(~@/assets/img/common/eye-off.svg);
		}
	}
}
.button-link-text {
	position: relative;
	display: inline-flex;
	font-size: 14px;
	line-height: 1.1;
	align-items: center;
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		left: 0;
		right: 0;
		bottom: 0;
		border-bottom: 1px solid #bdbdbd;
		@include transition(all, 0.3s);
	}
	&::after {
		@include ico;
		position: absolute;
		height: 15px;
		top: -4px;
		right: -20px;
		font-size: 22px;
		line-height: 1;
		content: $ico-chevron-right;
	}
	&.is-noborder {
		&::before {
			display: none;
		}
		&::after {
			position: static;
			margin-top: -0.1em;
			height: auto;
		}
	}
	&:hover,
	&:active {
		&::before {
			width: 0;
			left: 50%;
			right: 50%;
		}
	}
}
ol.number-list {
	list-style: none;
	margin: 0;
	padding: 0;
	counter-reset: list-number;
	li {
		padding-left: 1em;
		counter-increment: list-number;
		line-height: 1.2;
		&::before {
			display: inline-block;
			width: 1em;
			margin-left: -1em;
			content: counter(list-number) '. ';
		}
		&:not(:last-child) {
			margin-bottom: 0.5em;
		}
	}
}

// success checkmark
.success-checkmark {
	width: 80px;
	height: 115px;
	margin: 0 auto;
	transform: scale(0.85);

	.check-icon {
		width: 80px;
		height: 80px;
		position: relative;
		border-radius: 50%;
		box-sizing: content-box;
		border: 4px solid #ff7300;

		&::before {
			top: 3px;
			left: -2px;
			width: 30px;
			transform-origin: 100% 50%;
			border-radius: 100px 0 0 100px;
		}

		&::after {
			top: 0;
			left: 30px;
			width: 60px;
			transform-origin: 0 50%;
			border-radius: 0 100px 100px 0;
			animation: rotate-circle 5.5s ease-in;
		}

		&::before,
		&::after {
			content: '';
			height: 100px;
			position: absolute;
			background: #ffffff;
			transform: rotate(-45deg);
		}

		.icon-line {
			height: 5px;
			background-color: #ff7300;
			display: block;
			border-radius: 2px;
			position: absolute;
			z-index: 10;

			&.line-tip {
				top: 46px;
				left: 14px;
				width: 25px;
				transform: rotate(45deg);
				animation: icon-line-tip 1.2s;
			}

			&.line-long {
				top: 38px;
				right: 8px;
				width: 47px;
				transform: rotate(-45deg);
				animation: icon-line-long 1.2s;
			}
		}

		.icon-circle {
			top: -4px;
			left: -4px;
			z-index: 10;
			width: 80px;
			height: 80px;
			border-radius: 50%;
			position: absolute;
			box-sizing: content-box;
			border: 4px solid rgba(255, 115, 00, 0.7);
		}

		.icon-fix {
			top: 8px;
			width: 5px;
			left: 26px;
			z-index: 1;
			height: 85px;
			position: absolute;
			transform: rotate(-45deg);
			background-color: #ffffff;
		}
	}
}

@keyframes rotate-circle {
	0% {
		transform: rotate(-45deg);
	}
	5% {
		transform: rotate(-45deg);
	}
	12% {
		transform: rotate(-405deg);
	}
	100% {
		transform: rotate(-405deg);
	}
}

@keyframes icon-line-tip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@keyframes icon-line-long {
	0% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}
.mbrp-loading {
	font-size: 24px;
	font-weight: 500;
	line-height: 1.2;
	text-align: center;
	strong {
		display: block;
		font-weight: 700;
	}
	p {
		color: #757575;
	}
	.loading-container {
		margin-top: 50px;
	}
}
.loading-container {
	position: relative;
	display: none;
	overflow: hidden;
	width: 100px;
	height: 100px;
	margin: 0 auto;
	z-index: 40;
	align-items: center;
	justify-content: center;
	padding: 10px;
	&.is-active {
		display: flex;
	}

	.loading {
		@include loader;
		display: flex;
		position: absolute;
		width: 100px;
		height: 100px;
		top: 50%;
		left: 50%;
		margin: -50px 0 0 -50px;
		border: none;
		background: conic-gradient(transparent, #ff8f34);
		align-items: center;
		justify-content: center;
		&::before {
			content: '';
			position: absolute;
			width: 85%;
			height: 85%;
			border-radius: 50%;
			background-color: #fff;
		}
	}
}

#join-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	@include tablet {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}
