.noDisplay {
	display: none !important;
}
.grid-list {
	display: flex;
	margin: 0;
	flex-wrap: wrap;
	gap: 40px;
	@include mobile {
		gap: 22px;
	}
}
.grid-item {
	flex: 0 0 calc(25% - 30px);
	padding: 0;
	@include until(1024px) {
		flex: 0 0 calc(25% - 30px);
	}
	@include mobile {
		flex: 0 0 calc(33.33% - 15px);
	}
	@include small {
		flex: 0 0 calc(50% - 11px);
	}
}
/* Product Component */
/* product */
.product-card {
	position: relative;
	&:hover {
		.product-image {
			.image-link {
				> img {
					transform: scale(1.06);
				}
			}
		}
	}

	.product-image {
		overflow: hidden;
		position: relative;
		border-radius: 15px;
		// border: 1px solid rgba(0, 0, 0, 0.05);
		.image-link {
			display: block;
			overflow: hidden;
			position: relative;
			width: 100%;
			height: 0;
			padding-top: 100%;
			border: 1px solid rgba(0, 0, 0, 0.05);
			background-size: auto 30px;
			border-radius: 15px;
			width: 100%;
			> img {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: scale(1);
				@include transition(transform, 0.5s);
			}
		}

		// &:hover {
		//     border-color: #ddd;
		// }

		.image-badge {
			position: absolute;
			left: 0;
			bottom: 0;
			display: flex;
			div {
				margin-right: 3px;
				img {
					width: 40px;
					height: 40px;
				}
			}
			.badge-item {
				color: #ffffff;
				display: flex;
				width: 40px;
				height: 40px;
				align-items: center;
				justify-content: center;
				font-family: var(--font-family-blacksans);
				font-size: 13px;
				&.primary {
					background-color: #015aa8;
				}
				&.secondary {
					background-color: #ff7300;
				}
				&.sky {
					background-color: #299cc3;
				}
				&.white {
					background-color: #ffffff;
					color: #ff7300;
				}
			}
		}
		.image-top-badge {
			display: flex;
			position: absolute;
			left: 0px;
			top: 0px;
			div {
				margin-right: 3px;
			}
			.badge-item {
				img {
					width: auto;
					height: clamp(25px, 2.57vw, 40px);
					border-radius: 5px;
				}
			}
		}
	}
	.product-info {
		position: relative;
		padding-top: 13px;
		.title {
		}
		.title-link {
			display: block;
			height: 2.6em;
			.text {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				font-size: clamp(15px, 1.8vw, 16px);
				line-height: 1.3;
				max-height: 3em;
				text-align: left;
				letter-spacing: -0.5px;
			}
			&:hover .text {
			}
		}
		.shop {
			padding-top: clamp(5px, 1.18vw, 15px);
			.text {
				font-size: clamp(11px, 1.3vw, 13px);
				font-weight: 700;
				color: #999;
			}
		}
		.subtext {
			font-size: 13px;
			font-weight: 700;
			padding-top: 5px;
		}
		.options {
			padding-top: 5px;
			.mobile {
				display: none;
			}
			&.is-inline {
				display: inline-block;
				padding-top: 0;
				line-height: 22px;
				vertical-align: top;
				font-size: 15px;
				position: relative;
				color: #999;
				&:before {
					content: '(';
					display: inline-block;
					transform: translateX(2px);
				}
				&:after {
					content: ')';
					display: inline-block;
					transform: translateX(-2px);
				}
			}
		}
		.option-actions {
			margin-top: 8px;
			.btn-option {
				font-weight: 700;
			}
		}
		.price {
			display: flex;
			padding-top: 10px;
			font-weight: 700;
			font-size: clamp(16px, 2vw, 19px);
			letter-spacing: 0;
			letter-spacing: -0.5px;
			align-items: flex-end;
			flex-wrap: wrap;
			.price-discount {
				color: #ff7300;
				padding-right: 5px;
				white-space: nowrap;
				@include small {
				}
			}
			.price-current {
				display: flex;
				align-items: flex-end;
				&-benefit {
					width: 100%;
					.tit {
						font-size: clamp(12px, 1.375vw, 14px);
						font-weight: 700;
						color: #ff7300;
						margin-right: 5px;
					}
					.price-current {
						text-decoration: line-through;
						color: #999999;
						font-size: clamp(12px, 1.375vw, 14px);
						font-weight: 400;
						white-space: nowrap;
						em {
							font-size: clamp(12px, 1.375vw, 14px);
						}
						// @include small {
						//     width: 100%;
						//     margin-top: 0.5em;
						//     margin-left: 0;
						// }
					}
				}
				em {
					font-size: 0.85em;
					font-weight: 400;
				}
				&.bfDcn {
					order: 2;
					margin-left: 5px;
					text-decoration: line-through;
					color: #999999;
					font-size: clamp(12px, 1.375vw, 14px);
					font-weight: 400;
					white-space: nowrap;
					@include small {
						width: 100%;
						margin-top: 0.5em;
						margin-left: 0;
					}
				}
				&.afDcn {
					white-space: nowrap;
					em {
						font-size: 16px;
						font-weight: var(--font-weight-base);
					}
				}
			}
		}
		.image-badge {
			padding-top: clamp(9.5px, 1.45vw, 18.5px);
		}
		.benefit {
			padding-top: clamp(9.5px, 1.45vw, 10px);
			.badge {
				font-weight: 700;
			}
			.badge-item {
				height: 23px;
				img {
					height: 23px;
				}
			}
		}
		.store {
			position: relative;
			padding-top: 10px;
		}
		.store-box {
			display: inline-flex;
			align-items: center;
			height: 23px;
			padding: 0 5px;
			border-radius: 5px;
			border: 1px solid #ebebeb;
			background-color: #f2f2f2;
		}
		.store-text {
			font-size: 12px;
			font-weight: 700;
			color: #555;
		}
		.subscription-info {
			position: relative;
			margin-top: 10px;
			.text {
				color: #999;
			}
			.btn-edit {
				display: inline-block;
				margin-left: 4px;
				.text {
					font-weight: 700;
				}
			}
		}
	}
	.product-title .title-link {
		.text {
			font-size: clamp(13px, 1.375vw, 16px);
			line-height: 1.35;
			font-weight: 700;
		}
	}

	.btn-delete {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(25%, -25%);
		.icon-delete {
			width: 30px;
			height: 30px;
			background-image: url(~@/assets/img/live/icon_delete_circle@24.svg);
			background-size: 30px;
			height: 30px;
		}
	}
	&.is-row {
		display: flex;
		.product-image {
			flex: 0 0 120px;
			width: 120px;
			height: 120px;
			padding-top: 0;
			overflow: hidden;
		}
		.product-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 30px;
		}
	}
	.mask-link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		cursor: pointer;
		@at-root #mypage-interest & {
			pointer-events: none;
		}
		@at-root .mypage-interest & {
			pointer-events: none;
		}
		// &:hover ~ .product-image img {
		//     transform: scale(1.06);
		// }
		&:hover ~ .product-info .title .text {
			text-decoration: underline;
		}
	}
}
.popup-livereport {
	max-width: 600px;
	.popup-body {
		padding: 0 20px;
	}
}
.popup-share {
	width: 320px;
	height: 200px;
	border-radius: 15px;
	.popup-body {
		min-height: inherit;
		padding: 0 20px !important;
	}
	.popup-header {
		height: 0;
	}
	.share-list {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		height: 180px;
		.btn-share-text {
			font-size: 16px;
			font-weight: 400;
			display: block;
			padding-top: 15px;
		}
	}
	.icon-share-kakao {
		width: 64px;
		height: 64px;
		background-image: url(~@/assets/img/common/icon_share_kakao.svg);
	}
	.icon-share-link {
		width: 64px;
		height: 64px;
		background-image: url(~@/assets/img/common/icon_share_link.svg);
	}
	.icon-share-qr {
		display: flex;
		overflow: hidden;
		width: 64px;
		height: 64px;
		margin-top: -3px;
		margin-bottom: 3px;
		border: 1px #ffffff solid;
		border-radius: 8px;
		text-indent: 0;
		align-items: center;
		justify-content: center;
		> div {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			> canvas {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}
	.btn-share-close {
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.icon-share-close {
		width: 24px;
		height: 24px;
		background-image: url(~@/assets/img/live/icon_share_close.svg);
	}
}
.live-card {
	// 라이브커머스
	&.is-onair {
		max-width: 280px;
		padding-top: 34px;
		@include tablet {
			padding-top: 22px;
		}

		.bottom-duration {
			display: none;
		}
		.live-video {
			overflow: hidden;
			border-radius: 18px;
			box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1);
			.video-player {
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 0;
				padding-top: calc(425 / 240 * 100%);
				// border: 1px solid rgba(0, 0, 0, 0.02);
				// padding-top: 176%;
				// border: 1px solid rgba(0, 0, 0, 0.02);
				background-color: #f1f1f1;
				// border-radius: 15px;
				img {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
					object-fit: cover;
					transform: scale(1);
					transition: transform 0.6s;
				}
				iframe,
				video {
					width: 100%;
					height: 100%;
					z-index: 0;
					object-fit: cover;
					transform: scale(1.01);
					transition: transform 0.6s;
				}
				&-mask {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: 100%;
					z-index: 1;
					cursor: pointer;
				}
			}
		}
		.video-content {
			display: flex;
			min-width: 0;
			height: auto;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 5;
			padding: 15px;
			background-color: rgba(17, 17, 17, 0.9);
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
	&.is-end {
		.video-onair {
			display: none;
		}
		.video-badge {
			display: none;
		}
	}
	.live-video {
		.video-player {
			border-radius: 15px;
		}
	}
	.video-content {
		display: flex;
		min-width: 0;
		height: 120px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #f4f4f4;
		padding: 15px;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	&:hover {
		.live-video {
			img {
				transform: scale(1.06);
			}
			.video-content-title .text {
				text-decoration: underline;
			}
			~ .live-title .text {
				text-decoration: underline;
			}
			.video-image-link {
				img {
					transform: none;
				}
			}
		}
	}

	.video-content-group {
		display: block;
		max-width: calc(100% - clamp(40px, 4vw, 51px));
		text-align: left;
	}
	.video-content-title {
		margin-bottom: 3px;
		.text {
			font-size: 13px;
			color: #fff;
			font-weight: 700;
		}
	}
	.video-content-price {
		font-size: 13px;
		font-weight: 700;
		.price-discount {
			color: #ff7300;
			display: inline-block;
			margin-right: 5px;
		}
		.price-current {
			color: #ffffff;
		}
	}
	// 하이라이트 영상
	&__highlight {
		padding-top: 0;
		.video-content {
			position: static;
			height: auto;
			padding: 12px 15px 0;
			background-color: transparent;
			&-title {
				font-size: 19px;
				font-weight: 500;
				line-height: 1.3;
				color: #424242;
			}
		}
		.video-player {
			&__src {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 50%;
				left: 50%;
				opacity: 0;
				object-fit: cover;
				transform: translate(-50%, -50%);
				// @include transition(opacity, 0.2s);
				&.is-vertical {
					width: 135%;
					height: 135%;
					&.is-large {
						width: 135%;
						height: 135%;
					}
				}
				&.is-large {
					// width: 135%;
					// height: 135%;
					width: 178%;
					height: 178%;
					@include mobile {
						width: 135%;
						height: 135%;
					}
				}
			}
			&__mask {
				position: absolute;
				display: flex;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				opacity: 1;
				background-color: rgba(0, 0, 0, 0.2);
				align-items: center;
				justify-content: center;
				@include transition(opacity, 0.1s);
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;
					width: 80px;
					height: 81px;
					margin: auto;
					background-position: center;
					background-size: contain;
					background-image: url(~@/assets/img/common/icon_vod.svg);
					text-indent: -999em;
					font-size: 0;
				}
			}
		}
	}
	&.is-playing {
		.video-player {
			.video-player {
				&__src {
					left: 50%;
					top: 50%;
					opacity: 1;
				}
				&__mask {
					opacity: 0;
				}
			}
		}
	}
}

/* list-tabs */
.list-tabs {
	position: relative;
	margin-bottom: 30px;
	.tab-group {
		display: flex;
		align-items: center;
		margin-bottom: 1em;
	}
	.group-header {
		padding-right: 30px;
		color: #999;
		font-size: 15px;
		white-space: nowrap;
		font-weight: 700;
	}
	.tab-list {
		display: block;
		white-space: nowrap;
	}
	.tab-item {
		display: inline-block;
		position: relative;
		cursor: pointer;
		~ .tab-item {
			margin-left: 10px;
		}
		&:last-child {
			margin-right: 25px;
		}
	}
	.tab-link {
		display: block;
		border-radius: 5px;
		padding: 11px 15px 10px;
		background-color: #ebebeb;
		white-space: nowrap;
	}
	.tab-text {
		font-size: 13px;
		color: #111;
		font-weight: 700;
	}
	.tab-count {
		font-size: 13px;
		color: #999;
		font-weight: 700;
	}
	.btn-delete {
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(30%) translateY(-30%);
		.icon-delete {
			width: 15px;
			height: 15px;
			background-image: url(~@/assets/img/live/icon_delete_circle.svg);
		}
	}
	.tab-item {
		&.is-selected {
			.tab-link {
				background-color: #015aa8;
			}
			.tab-text {
				color: #fff;
			}
			.tab-count {
				color: #bbb;
			}
		}
	}
	@include tablet {
		.tab-group {
			display: block;
		}
		.group-header {
			display: block;
			margin-bottom: 10px;
			font-size: 13px;
		}
		.tab-scroll-wrapper {
			overflow-x: auto;
			margin: 0 calc(25px * -1);
			padding-top: 5px;
			padding-bottom: 5px;
		}
		.tab-list {
			padding: 0 25px;
		}
	}
	@include mobile {
		.group-header {
			margin-bottom: 0;
		}
	}
}

/* list-top-container */
.list-top-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 15px;
	border-bottom: 1px solid #ebebeb;
	+ .grid-container {
		.notfound-container {
			border-top: none;
		}
	}
	.top-header {
		display: flex;
		align-items: center;
		.title {
			font-size: clamp(13px, 1.4vw, 15px);
			color: #242424;
			margin-right: 5px;
			font-weight: 700;
		}
		.count {
			font-size: clamp(13px, 1.4vw, 15px);
			color: #bbb;
			font-weight: 700;
		}
	}
	.top-options {
		.option-list {
			display: flex;
			gap: clamp(7px, 1.4vw, 15px);
			align-items: center;
			.boradcast-button-cal {
				margin-top: -1px;
				margin-left: 5px;
			}
		}
		.option-item {
			// margin-left: clamp(7px, 1.4vw, 15px);
			cursor: pointer;
		}
		.option-link {
		}
		.option-text {
			font-size: clamp(13px, 1.4vw, 15px);
			color: #bbb;
			font-weight: 700;
		}
		.is-selected {
			.option-text {
				color: #242424;
			}
		}
	}
	&.is-borderless {
		border-bottom: none;
	}
	.top-column {
		display: flex;
		align-items: center;
	}
	.top-date {
	}
	.top-date .text {
		font-weight: 700;
	}
	.top-actions {
		.detailed-link {
			display: inline-flex;
			align-items: center;
		}
		.detailed-link .icon-arrow {
			position: relative;
			top: -1px;
			width: 20px;
			height: 20px;
			background-image: url(~@/assets/img/live/icon_arrow_right.svg);
		}
		.detailed-link:hover {
			cursor: pointer;
			text-decoration: underline;
		}
		.text {
			font-weight: 700;
		}
	}
	.top-order .text {
		font-weight: 700 !important;
	}
	.subtext {
		display: flex;
		align-items: center;
	}
	.subtext.dot .icon-dot {
		display: inline-block;
		width: 3px;
		height: 3px;
		border-radius: 3px;
		background-color: #999;
		margin: 0 10px;
	}
	.subtext.notice {
		padding-left: 23px;
		font-size: 13px;
		font-weight: 700;
	}
	.subtext.notice .icon-warning {
		width: 20px;
		height: 20px;
		margin-right: 10px;
		background-image: url(~@/assets/img/live/icon_notice.svg);
		background-size: 20px auto;
	}
	.number {
		display: inline-block;
		margin-right: 10px;
	}
	.top-column {
		.header-link {
			display: inline-flex;
			align-items: center;
		}
		.text {
			font-weight: 700;
		}
		.icon-arrow {
			width: 20px;
			height: 20px;
			background-image: url(~@/assets/img/live/icon_arrow_right.svg);
		}
	}
}
#page-live-list {
	.page-component {
		padding: 0;
		width: auto;
	}
}
#page-live-list.page-container,
#page-live-bridge.page-container {
	padding-top: clamp(30px, 4vw, 50px);
}

body.prevent_overscroll {
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
	background-color: #000;
}

/* live-home */
.live-home-container {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100vh; /*height:var(--app-height);*/
	min-width: 280px;
	min-height: 640px;
	background-color: #000;
	display: flex;
	justify-content: center;
}
#page-live-bridge .live-home-container {
	height: var(--app-height);
}
.live-home-container .live-home-inner {
	min-width: 280px;
	min-height: 640px;
	width: calc(100vh * 0.5625);
	height: 100%;
	display: inline-block;
	text-align: left;
	vertical-align: top;
}
.live-home-container .live-container {
	position: relative;
	z-index: 0;
	min-height: 100%;
	width: 100%;
	touch-action: pan-y;
	user-select: none;
	-webkit-user-drag: none;
	max-height: 100vh; /*height:calc(100vw * 1000.0 / 575.0);*/
}
.live-home-container .live-header {
	z-index: 50;
	position: absolute;
	top: 0;
	left: 0;
	padding: 30px;
	width: 100%;
}
.live-home-container .live-header * {
	color: #ffffff;
}
.live-home-container .live-header-badge {
	margin-bottom: 10px;
}
.live-home-container .live-header-badge .video-badge {
	position: static;
	display: block;
	width: 60px;
	height: 44px;
}
// .live-home-container .live-header-badge .icon-comingsoon {
//     display: block;
//     width: 60px;
//     height: 44px;
//     background-image: url(../../images/common/badge_comingsoon.svg);
// }
// .live-home-container .live-header-badge .icon-vod {
//     display: block;
//     width: 60px;
//     height: 44px;
//     background-image: url(../../images/common/badge_vod.svg);
// }
.live-home-container .live-header-title {
	margin-bottom: 5px;
}
.live-home-container .live-header-title .title-link {
}
.live-home-container .live-header-title .title {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 20px;
	font-weight: 700;
}
.live-home-container .live-header-content {
	display: flex;
	align-items: center;
}
.live-home-container .live-header-content .text {
	font-weight: 700;
}
.live-home-container .live-header-content .live-seller {
}
.live-home-container .live-header-content .live-seller .text {
	font-size: 13px;
	color: var(--text-muted);
}
.live-home-container .live-header-content .live-watch {
	margin-left: 10px;
}
.live-home-container .live-header-content .live-watch .icon-watch {
	width: 14px;
	height: 12px;
	background-image: url(~@/assets/img/live/icon_watch.svg);
}
.live-home-container .live-header-content .live-watch .icon-liveLike {
	width: 14px;
	height: 12px;
	background-image: url(~@/assets/img/live/live_like.svg);
}
.live-home-container .live-header-content .live-watch .text {
	font-size: 12px;
}
.live-home-container .live-top-actions {
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: 50;
	@include mobile {
		top: -5px;
		right: 10px;
	}
}
.live-home-container .live-top-actions .btn {
	margin-left: calc(20px * 0.75);
	@include mobile {
		padding: 0 10px;
		margin-left: 5px;
	}
}
.live-home-container .live-top-actions i.icon-sound {
	width: 32px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_video_sound.svg);
}
.live-home-container .live-top-actions i.icon-noSound {
	width: 32px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_video_noSound.svg);
}
.live-home-container .live-top-actions i.icon-report {
	width: 30px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_video_report.svg);
}
.live-home-container .live-top-actions i.icon-alarm {
	width: 25px;
	height: 27px;
	background-image: url(~@/assets/img/live/icon_video_alram.svg);
}
.live-home-container .live-top-actions i.icon-share {
	width: 27px;
	height: 27px;
	background-image: url(~@/assets/img/live/icon_video_share.svg);
}
.live-home-container .live-top-actions i.icon-close {
	width: 26px;
	height: 26px;
	background-image: url(~@/assets/img/live/icon_video_close.svg);
}
.live-home-container .live-video-player {
	z-index: -1;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
}
.live-home-container .live-video-player .video-player-image {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-color: #000;
}
.live-home-container .live-video-player .video-player-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.live-home-container .live-video-player .video-player-image.is-visible {
	display: block;
}
.live-home-container .live-video-player video {
	width: 100%;
	height: 100%;
}
.live-home-container .live-video-player iframe {
	width: 100%;
	height: 100%;
	border: 0;
}
.live-home-container .live-video-player video {
}
.live-home-container .live-tag-item {
	z-index: 50;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	height: 70px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	backdrop-filter: blur(5px);
}
.live-home-container .live-tag-item .tag-item {
	position: relative;
	display: flex;
	align-items: center;
	flex: 1 1 auto;
}
.live-home-container .live-tag-item .tag-item-link {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.live-home-container .live-tag-item .tag-item-thumbnail {
	flex: 0 0 46px;
	width: 46px;
	height: 46px;
	border-radius: 0.3125rem;
	border: 2px solid #ebebeb;
	margin-right: 15px;
}
.live-home-container .live-tag-item .tag-item-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.live-home-container .live-tag-item .tag-item-content {
}
.live-home-container .live-tag-item .tag-item-title {
	margin-bottom: 4px;
}
.live-home-container .live-tag-item .tag-item-title .text {
	font-weight: 700;
	color: #ffffff;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.live-home-container .live-tag-item .tag-item-price {
	font-weight: 700;
}
.live-home-container .live-tag-item .tag-item-price .price-discount {
	color: #ff7300;
}
.live-home-container .live-tag-item .tag-item-price .price-current {
	color: #ffffff;
}
.live-home-container .live-tag-item .live-all {
	padding-left: 5px;
}
.live-home-container .live-tag-item .live-all-link {
	font-size: 14px;
	font-weight: 700;
	color: #ff7300;
	white-space: nowrap;
}
/*.live-home-container .commnets-container {z-index:50;overflow-y:scroll;position:absolute;bottom:70px;left:0;width:100%;max-width: 400px;-ms-scroll-chaining: none;overscroll-behavior: none;-webkit-mask-image: linear-gradient(transparent, #fff 30px);mask-image: linear-gradient(transparent, #fff 30px);will-change: bottom;-webkit-overflow-scrolling: touch;-ms-overflow-style: none;-ms-overflow-style: none; scrollbar-width: none;max-height:20vh;width:calc(100% - 90px);transition:bottom 0.5s cubic-bezier(0, 1, 0, 1) 0.1s,max-height 0.1s linear;}*/
/*.live-home-container .commnets-container::-webkit-scrollbar {display:none}*/
/*.live-home-container .commnets-container .comments-inner {position:relative;z-index:1;padding:30px 30px 20px}*/
/*.live-home-container .commnets-container .comment-list {}*/
/*.live-home-container .commnets-container .comment-item {font-weight:700}*/
/*.live-home-container .commnets-container .comment-item ~ .comment-item {margin-top:10px}*/
/*.live-home-container .commnets-container .comment-id {display:inline-block;margin-right:4px;color:#bbb}*/
/*.live-home-container .commnets-container .comment-text {color:#ffffff}*/
/*.live-home-container .commnets-container.is-active {max-height:50vh}*/
.live-home-container .commnets-container {
	z-index: 50;
	position: absolute;
	bottom: 70px;
	left: 0;
	width: 100%;
	max-width: 400px;
	-webkit-mask-image: linear-gradient(transparent, #fff 30px);
	mask-image: linear-gradient(transparent, #fff 30px);
	will-change: bottom;
	width: calc(100% - 90px);
	transition: bottom 0.5s cubic-bezier(0, 1, 0, 1) 0.1s, max-height 0.1s linear;
}
.live-home-container .commnets-container::-webkit-scrollbar {
	display: none;
}
.live-home-container .commnets-container .comments-scroll {
	overflow-y: scroll;
	max-height: 20vh;
	transition: bottom 0.5s cubic-bezier(0, 1, 0, 1) 0.1s, max-height 0.1s linear;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.live-home-container .commnets-container .comments-scroll::-webkit-scrollbar {
	display: none;
}
.live-home-container .commnets-container .comments-inner {
	position: relative;
	z-index: 1;
	padding: 30px 30px 0;
}
.live-home-container .commnets-container .comment-list {
}
.live-home-container .commnets-container .comment-item {
	font-weight: 400;
	line-height: 1.35;
}
.live-home-container .commnets-container .comment-item.myself span {
	color: cornflowerblue;
}
.live-home-container .commnets-container .comment-item ~ .comment-item {
	margin-top: 10px;
}
.live-home-container .commnets-container .comment-id {
	display: inline-block;
	margin-right: 4px;
	color: #bbb;
}
.live-home-container .commnets-container .comment-id.comment-reply {
	color: #d30000 !important;
}
.live-home-container .commnets-container .comment-text {
	color: #ffffff;
}
.live-home-container .commnets-container .comment-master {
	color: #ffd000;
}
.live-home-container .commnets-container .comments-master-fixed {
	position: sticky;
	top: 0;
	padding: 20px 30px 20px;
}
.live-home-container .commnets-container .comments-current {
	color: #ff7300;
	font-weight: 700;
	line-height: 1.24;
}
.live-home-container .commnets-container.is-active {
}
.live-home-container .commnets-container.is-active .comments-scroll {
	max-height: 50vh;
}

.live-home-container .comment-writeform {
	z-index: 50;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
	display: none;
}
.live-home-container .comment-writeform.is-visible {
	display: block;
}
.live-home-container .comment-writeform .form-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	padding: 0 10px;
}
.live-home-container .comment-writeform .form-comment-text {
	flex: auto;
	height: 50px;
	padding: 15px 10px;
	font-size: 15px;
	border-radius: 5px;
	border: 1px solid #ebebeb;
	background-color: #fff;
	resize: none;
	word-break: break-all;
}
.live-home-container .comment-writeform .form-comment-text:focus {
	outline: none;
}
.live-home-container .comment-writeform .btn-comment {
	margin-left: 10px;
	height: 50px;
	width: 88px;
	border-radius: 10px;
}
.live-home-container .comment-writeform .btn-comment .text {
	font-size: 15px;
	font-weight: 700;
}
.live-home-container .live-tab-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}
.live-home-container .live-tab-wrapper:after {
	z-index: -1;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 320px);
}
.live-home-container .live-tab-wrapper.is-dimmed:after {
	z-index: 50;
	background-color: rgba(0, 0, 0, 0.3);
}
.live-home-container .live-aside-actions {
	z-index: 50;
	position: absolute;
	right: 30px;
	bottom: 100px;
	display: flex;
	flex-direction: column;
}
.live-home-container .live-aside-actions .btn {
	width: 32px;
	height: 32px;
	padding: 0;
	border: none;
}
.live-home-container .live-aside-actions .btn ~ .btn {
	margin-top: 30px;
}
.live-home-container .live-aside-actions [class^='icon'] {
	background-position: 50%;
}
.live-home-container .live-aside-actions .icon-like {
	width: 32px;
	height: 32px;
	background-image: url(~@/assets/img/live/icon_actions_like.svg);
	background-position: 0 0;
}
.live-home-container .live-aside-actions .icon-comment {
	width: 32px;
	height: 32px;
	background-image: url(~@/assets/img/live/icon_actions_comment.svg);
}
.live-home-container .live-aside-actions .icon-cart {
	width: 32px;
	height: 32px;
	background-image: url(~@/assets/img/live/icon_actions_cart.svg);
}
.live-home-container .live-aside-actions .icon-coupon {
	width: 32px;
	height: 32px;
	background-image: url(~@/assets/img/live/icon_actions_coupon.svg);
}
.live-home-container .live-aside-actions .icon-subscriptions {
	width: 32px;
	height: 32px;
	background-image: url(~@/assets/img/live/icon_actions_subscriptions.svg);
}
.live-home-container .live-aside-actions .icon-notice {
	width: 30px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_actions_notice2.svg);
}
.live-home-container .video-controller {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	height: 70px;
}
.live-home-container .video-controller .video-play-progress {
	flex: 1;
}
.live-home-container .video-controller .progress-bar {
	position: relative;
	height: 2px;
	background-color: #ebebeb;
	border-radius: 2px;
	cursor: pointer;
	margin: 38px 0;
}
.live-home-container .video-controller .bar-buffered {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 2px;
}
.live-home-container .video-controller .bar-current {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 2px;
	background-color: #015aa8;
}
.live-home-container .video-controller .bar-slider {
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 21px;
	height: 21px;
	border-radius: 11px;
	background-color: #015aa8;
}
.live-home-container .video-controller .video-play-indicator {
	padding-left: 10px;
}
.live-home-container .video-controller .video-play-indicator .duration {
	font-size: 12px;
	font-weight: 700;
	color: #ffffff;
}
.live-home-container .modal-section {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 90;
}
.live-home-container .modal-section .modal-dimmed {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 90;
}
.live-home-container .modal-section .modal-header {
	position: relative;
	flex: 0 0 75px;
	height: 75px;
	border-bottom: 1px solid #262626;
	display: flex;
	align-items: center;
	padding: 0 20px;
}
.live-home-container .modal-section .modal-header-title {
	font-size: 20px;
	font-weight: 700;
}
.live-home-container .modal-section .btn-header {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
}
.live-home-container .modal-section .btn-header .icon-close {
	width: 24px;
	height: 24px;
	background-image: url(~@/assets/img/live/icon_close.svg);
}
.live-home-container .modal-section .modal-wrapper {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #111;
	max-height: 50%;
	display: flex;
	flex-direction: column;
	z-index: 91;
	animation: animModalShow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
}
.live-home-container .modal-section .modal-wrapper * {
	color: #fff;
}
.live-home-container .modal-section .product-list {
	padding: 15px 0;
	flex: 1 1 auto;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
}
.live-home-container .modal-section .product-list::-webkit-scrollbar {
	display: none;
}
.live-home-container .modal-section .product-item {
	position: relative;
	padding: 15px 20px;
}
.live-home-container .modal-section .product-card {
	display: flex;
	align-items: center;
}
.live-home-container .modal-section .product-card .card-image {
	flex: 0 0 64px;
	border: 2px solid #ebebeb;
	border-radius: 5px;
}
.live-home-container .modal-section .product-card .card-image img {
	width: 64px;
	height: 64px;
	object-fit: cover;
}
.live-home-container .modal-section .product-card .card-content {
	position: relative;
	flex: auto;
	padding-left: 20px;
	font-size: 15px;
}
.live-home-container .modal-section .product-item .product-link {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: block;
	z-index: 92;
}
.live-home-container .modal-section .product-card .product-title {
	font-weight: bold;
}
.live-home-container .modal-section .product-card .product-price {
	font-weight: 700;
	margin: 5px 0;
}
.live-home-container .modal-section .product-card .product-price .price-discount {
	color: #ff7300;
}
.live-home-container .modal-section .product-card .product-price .price-amount.bfDcn,
.live-home-container .price-current.bfDcn {
	text-decoration: line-through;
	color: #999999;
	font-size: clamp(10px, 1.375vw, 13px);
	margin-left: 5px;
}
.live-home-container .modal-section .product-card .product-price .price-amount.afDcn,
.live-home-container .price-current.afDcn {
	color: #ffffff !important;
	margin-left: 5px;
}
.live-home-container .modal-section .product-card .product-market {
	color: #999;
}
.live-home-container .modal-section .product-card .card-actions {
	display: flex;
	align-items: center;
}
.live-home-container .modal-section .product-card .card-actions .btn-cart {
	margin-left: 20px;
}
.live-home-container .modal-section .product-card .card-actions .icon-interest {
	width: 30px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_like_30.svg);
}
.live-home-container .modal-section .product-card .card-actions .icon-interest-active {
	width: 30px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_like_30_active.svg);
}
.live-home-container .modal-section .product-card .card-actions .icon-cart {
	width: 30px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_cart_30.svg);
}
.live-home-container .modal-section[aria-modal='true'] {
	display: block;
}
.live-home-container .modal-section[aria-modal='false'] {
	display: none;
}
.live-home-container .modal-section[aria-modal='false'] .modal-wrapper {
	animation: animModalHide 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
}
.live-home-container .popup-alert {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 90;
	transform: translate(-50%, -50%);
	width: 400px;
	border-radius: 20px;
	text-align: center;
	background-color: #111;
	overflow: hidden;
}
.live-home-container .popup-alert *:not(.form-field *) {
	color: #fff;
}
.live-home-container .popup-alert .alert-body {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 137px;
}
.live-home-container .popup-alert .alert-form {
	height: auto;
	flex-direction: column;
	padding: 40px;
}
.live-home-container .popup-alert .form-field {
	padding-top: 25px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.live-home-container .popup-alert .alert-actions {
	display: flex;
	border-top: 1px solid #262626;
}
.live-home-container .popup-alert .alert-actions .btn-alert {
	flex: 1;
	height: 65px;
}
.live-home-container .popup-alert .alert-actions .btn-alert .text {
	font-size: 15px;
	font-weight: 700;
}
.live-home-container .popup-alert .alert-actions .btn-alert:not(:first-child) {
	border-left: 1px solid #262626;
}
.live-home-container .popup-alert .alert-actions .btn-alert:hover {
	background-color: #151515;
}
.live-home-container .popup-alert .alert-actions .btn-alert:focus {
	outline: none;
}
// .live-home-container .popup-share {
// 	position: absolute;
// 	left: 50%;
// 	top: 50%;
// 	z-index: 90;
// 	transform: translate(-50%, -50%);
// 	width: 400px;
// 	border-radius: 20px;
// 	text-align: center;
// 	background: linear-gradient(135deg, rgba(20, 132, 232, 1) 0%, rgba(1, 90, 168, 1) 100%);
// 	overflow: hidden;
// }
// .live-home-container .popup-share * {
// 	color: #fff;
// }
// .live-home-container .popup-share .share-list {
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-evenly;
// 	height: 200px;
// }
// .live-home-container .popup-share .share-list {
// }
// .live-home-container .popup-share .share-list .btn-share {
// }
// .live-home-container .popup-share .share-list .btn-share-text {
// 	font-size: 20px;
// 	font-weight: 700;
// 	display: block;
// 	padding-top: 15px;
// }
// .live-home-container .popup-share .icon-share-kakao {
// 	width: 64px;
// 	height: 64px;
// 	background-image: url(~@/assets/img/live/icon_share_kakao.svg);
// }
// .live-home-container .popup-share .icon-share-link {
// 	width: 64px;
// 	height: 64px;
// 	background-image: url(~@/assets/img/live/icon_share_link.svg);
// }
// .live-home-container .popup-share .icon-share-qr {
// 	width: 64px;
// 	height: 64px;
// 	display: flex;
// 	border: #ffffff 2px solid;
// }
// .live-home-container .popup-share .btn-share-close {
// 	position: absolute;
// 	right: 15px;
// 	top: 15px;
// }
// .live-home-container .popup-share .icon-share-close {
// 	width: 24px;
// 	height: 24px;
// 	background-image: url(~@/assets/img/live/icon_share_close.svg);
// }
.live-home-container .popup-event {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 80;
	transform: translate(-50%, -50%);
	display: inline-block;
	text-align: center;
	overflow: hidden; /*box-shadow:0 0 30px rgb(0 0 0 / 20%);*/
	width: 400px;
}
.live-home-container .popup-event img {
	position: absolute;
	width: 100%;
	height: auto;
	object-fit: contain;
}
.live-home-container .popup-event .popup-event-coupon {
	width: 400px;
	min-height: 200px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(-45deg, #ff7300, #fa9c33);
}
.live-home-container .popup-event .popup-body {
	padding: 0 40px;
	line-height: 1.5;
}
// .live-home-container .popup-event .popup-event-coupon * {
// 	color: #fff;
// }
// .live-home-container .popup-event .popup-event-coupon .coupon-title {
// 	display: flex;
// 	justify-content: center;
// 	align-items: flex-end;
// 	line-height: 1.2;
// }
// .live-home-container .popup-event .popup-event-coupon .coupon-em {
// 	font-size: 60px;
// 	font-weight: 700;
// }
// .live-home-container .popup-event .popup-event-coupon .coupon-text {
// 	display: block;
// 	padding-bottom: 10px;
// 	font-size: 40px;
// 	font-weight: 700;
// }
// .live-home-container .popup-event .popup-event-coupon .coupon-description {
// 	font-size: 13px;
// 	margin-top: 20px;
// 	line-height: 1.35;
// }
// .live-home-container .popup-event .coupon-inner {
// 	position: relative;
// 	z-index: 1;
// 	padding: 40px 60px;
// }
// .live-home-container .popup-event .btn-close {
// 	position: absolute;
// 	right: 20px;
// 	top: 20px;
// }
// .live-home-container .popup-event .btn-close .icon-close {
// 	width: 24px;
// 	height: 24px;
// 	background-image: url(~@/assets/img/live/icon_close.svg);
// 	background-size: 24px auto;
// }
// .live-home-container .popup-event .btn-close .icon-close {
// 	width: 24px;
// 	height: 24px;
// 	background-image: url(~@/assets/img/live/icon_close.svg);
// 	background-size: 24px auto;
// }
.live-home-container .live-modal {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	left: -1px;
	right: -1px;
	bottom: 0;
	background-color: #111;
	z-index: 60;
	transform: translateY(100%);
	transition: all 0.4s;
}
.live-home-container .live-modal * {
	color: #fff;
}
.live-home-container .live-modal .modal-header {
	position: relative;
	display: flex;
	align-items: center;
	height: 75px;
	padding: 0 20px;
	border-bottom: 1px solid #262626;
}
.live-home-container .live-modal .modal-header .btn-modal-close {
	position: absolute;
	right: 20px;
}
.live-home-container .live-modal .modal-header .icon-modal-close {
	width: 25px;
	height: 25px;
	background-image: url(~@/assets/img/live/icon_modal_close.svg);
}
.live-home-container .live-modal .modal-header .header {
	font-size: 20px;
	font-weight: 900;
}
.live-home-container .live-modal .modal-body {
	position: relative;
	padding: 40px 30px;
}
.live-home-container .live-modal .notice-content {
}
.live-home-container .live-modal .notice-content .title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
}
.live-home-container .live-modal .notice-content .text {
	font-size: 15px;
	line-height: 25px;
}
.live-home-container .live-modal.is-visible {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
}

.live-home-container.is-horizontal {
	min-width: 640px;
	min-height: 280px;
}
.live-home-container.is-horizontal .live-home-inner {
	min-width: 640px;
	min-height: 280px;
	width: 100%;
}

@-webkit-keyframes animModalShow {
	from {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	to {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@-webkit-keyframes animModalHide {
	from {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	to {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
}

[id^='#page-live'].product-card .product-info .subtext {
	font-weight: 700;
}
[id^='#page-live'].product-card .product-title .title-link .text {
	font-size: clamp(15px, 1.375vw, 16px);
}

.comment-container {
	position: relative;
	padding-bottom: 100px;
	.comment-header {
		margin-bottom: 40px;
		.header-title {
			font-size: 20px;
			font-weight: 700;
		}
	}
	.comment-card {
		display: flex;
		width: 100%;
		align-items: flex-start;
		justify-content: space-between;
	}
	.comment-avatar {
		flex: 0 0 60px;
		.avatar {
			display: flex;
			overflow: hidden;
			width: 40px;
			height: 40px;
			text-align: center;
			border-radius: 20px;
			background-color: #ff7300;
			align-items: center;
			justify-content: center;
		}
		.avatar img {
			width: 40px;
			height: 40px;
			object-fit: cover;
		}
		.avatar-short {
			font-size: 26px;
			line-height: 1;
			font-family: var(--font-family-blacksans);
			color: #fff;
		}
	}
	.comment-write {
		flex: auto;
		.form-comment {
			border-radius: 5px;
			resize: none;
		}
	}
	.comment-content {
		flex: auto;
		padding-top: 5px;
		width: calc(100% - 60px);
	}
	.comment-metadata {
		font-weight: 700;
		margin-bottom: 10px;
		line-height: 18px;
		.metadata-name {
			font-size: 16px;
		}
		.metadata-date {
			color: #999;
			font-size: 14px;
		}
	}
	.comment-text {
	}
	.comment-text .text {
		font-size: 16px;
		line-height: 22px;
		word-break: break-all;
	}
	.comment-actions {
		position: relative;
		padding-top: 10px;
		display: flex;
		align-items: center;
		.actions-rating {
			display: flex;
			align-items: center;
		}
		.btn-rating {
			position: relative;
			display: inline-flex;
			align-items: center;
			.text {
				font-size: 14px;
				color: #999;
				display: inline-block;
				margin-left: 5px;
			}
			~ .btn-rating {
				margin-left: 10px;
			}
		}
		.icon-like {
			width: 15px;
			height: 15px;
			background-image: url(~@/assets/img/common/icon_comment_like.svg);
			background-position: 50%;
		}
		.icon-dislike {
			width: 15px;
			height: 15px;
			background-image: url(~@/assets/img/common/icon_comment_dislike.svg);
			background-position: 50%;
		}
		.actions-reply {
			display: flex;
			margin-left: 15px;
			align-items: center;
			.btn-reply {
				white-space: nowrap;
			}
			.text {
				font-size: 14px;
				color: #999;
				font-weight: bold;
			}
		}
	}
	.comment-reply {
		position: relative;
		padding-top: 15px;
		.btn-reply-view {
			.text {
				font-weight: 700;
				color: #015aa8;
			}
			.icon-arrow {
				width: 14px;
				height: 10px;
				background-image: url(~@/assets/img/common/icon_comment_arrow.svg);
				margin-right: 10px;
				transform: rotate(180deg);
			}
		}
		&.is-visible .icon-arrow {
			transform: rotate(0deg);
		}
	}
	.btn-comment {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		border-radius: 60px;
		border: 1px solid #bdbdbd;
		background-color: #fff;
		// box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
		margin-top: 80px;
		.text {
			font-weight: 700;
		}
		.icon-arrow {
			width: 20px;
			height: 20px;
			background-image: url(~@/assets/img/common/icon_arrow_down.svg);
			margin-left: 5px;
		}
	}
}
.comment-form-container {
	position: relative;
	margin-bottom: 40px;
}
.comment-list-container {
}
.comment-list-container .comment-list .comment-list {
	padding-top: 50px;
}
.comment-list-container .comment-item {
}
.comment-list-container .comment-item ~ .comment-item {
	margin-top: 50px;
}
.comment-btn {
	height: 60px;
	margin-left: 10px;
	border-radius: 10px;
	background-color: #645f5b;
}
.btn-reply {
	margin-right: 10px;
}

#page-live-bridge {
	.comment-container {
		padding-top: 5em;
		.comment-content {
			margin-top: 0;
		}
		.comment-write {
			top: 0;
			padding-top: 0;
		}
	}
	.bridge-title {
		position: relative;
		margin-bottom: clamp(25px, 4vw, 40px);
		text-align: center;
	}
	.bridge-title .date {
		font-size: clamp(13px, 1.5vw, 20px);
		color: #015aa8;
		font-weight: 700;
	}
	.bridge-title .title {
		font-size: clamp(20px, 3.2vw, 50px);
		font-weight: 900;
		padding-top: 15px;
	}
	.bridge-cover {
		position: relative;
		text-align: center;
		height: 0;
		padding-top: var(--ratio-2x1);
	}
	.bridge-cover img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.bridge-cover .cover-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: clamp(36px, 4.7vw, 80px);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #015aa8;
	}
	.bridge-cover .cover-content .text {
		font-size: clamp(13px, 1.4vw, 20px);
		font-weight: 700;
		color: #ffffff;
	}
	.bridge-cover .live-home-container.is-horizontal {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		min-width: 320px;
		min-height: 100%;
	}
	.bridge-cover .live-home-container.is-horizontal .live-home-inner {
		min-width: 320px;
		min-height: 100%;
	}
	.bridge-cover .live-home-container .live-container {
		height: 100%;
	}
	.bridge-body {
		position: relative;
		padding-top: clamp(15px, 2.4vw, 30px);
	}
	.live-info-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: clamp(15px, 2.4vw, 20px);
		border-bottom: 1px solid #ebebeb;
	}
	.live-info-wrapper {
	}
	.bridge-seller {
		flex: 1;
		font-size: 16px;
		font-weight: 700;
	}
	.bridge-seller .seller-link {
		display: inline-flex;
		align-items: center;
	}
	.bridge-seller .seller-thumbnail {
		width: 40px;
		height: 40px;
		border-radius: 0.3125rem;
		display: inline-block;
		background-color: #ccc;
		margin-right: 15px;
	}
	.bridge-seller .seller-thumbnail img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.bridge-seller .seller-name {
		font-size: clamp(13px, 1.6vw, 16px);
	}
	.bridge-notice {
		display: flex;
	}
	.bridge-notice .btn {
	}
	.bridge-notice .btn ~ .btn {
		margin-left: 10px;
	}
	.bridge-notice .icon-notify {
		width: 16px;
		height: 20px;
		background-image: url(~@/assets/img/live/icon_live_alarm.svg);
		background-size: 14px auto;
		background-position: 0 50%;
	}
	.bridge-actions {
		margin-left: 10px;
		.btn-share {
			display: flex;
			width: 50px;
			height: 30px;
			margin-left: 1em;
			padding: 5px 10px;
			border: 1px solid #645f5b;
			border-radius: 30px;
			background: #fff;
			font-size: 16px;
			color: #645f5b;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&:hover,
			&:focus {
				background-color: #ff7300;
				border-color: #ff7300;
				.icon-share {
					filter: brightness(10);
				}
			}
		}
		.icon-share {
			width: 18px;
			height: 18px;
			background-image: url(~@/assets/img/common/icon_share.svg);
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
		}
	}
	.bridge-notice .text {
		font-size: 12px;
	}
	.live-product {
		padding-top: 20px;
	}
	.live-product .section-header {
		position: relative;
		margin-bottom: 20px;
	}
	.live-product .section-header .title {
		font-size: clamp(13px, 2vw, 20px);
		font-weight: 700;
	}
}

#page-live-list {
	.live-list-container {
		border-top: 1px solid #ebebeb;
		padding-top: 30px;
		transition: all 0.2s;
		.grid-list {
			display: flex;
			margin: 0;
			flex-wrap: wrap;
			gap: 40px;
			@include mobile {
				gap: 22px;
			}
		}
	}
}
.gridPrev {
	position: absolute;
	z-index: 20;
	left: 0%;
	top: 43%;
	transform: rotate(180deg);
}
.gridNext {
	position: absolute;
	z-index: 20;
	right: 0%;
	top: 43%;
}

#live-calendar {
	padding-top: 0;
	.v-sm,
	.v-sm-inline,
	.v-md,
	.v-md-inline {
		display: none !important;
	}
	.live-card .live-video .video-player {
		border-radius: 12px;
	}
	.calendar {
		position: relative;
		background-color: #f2f0ed;
		padding: clamp(15px, 2.4vw, 30px) 0 0;
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 20px;
			height: 100%;
			top: 0;
			bottom: 0;
			z-index: 1;
			background: rgb(242, 240, 237);
		}
		&::before {
			left: 0;
			background: linear-gradient(90deg, rgba(242, 240, 237, 1) 30%, rgba(242, 240, 237, 0) 100%);
		}
		&::after {
			right: 0;
			background: linear-gradient(270deg, rgba(242, 240, 237, 1) 30%, rgba(242, 240, 237, 0) 100%);
		}
	}
	.calendar-component {
	}
	.calendar-container {
		overflow-x: auto;
		margin: 0 calc(25px * -1);
		padding: 0 25px 20px;
	}
	.calendar-row {
		display: flex;
		justify-content: space-between;
		.day {
			flex: 0 0 clamp(45px, 6vw, 80px);
			border-radius: 40px;
			overflow: hidden;
		}
		.day ~ .day {
			// margin-left: clamp(10px, 3vw, 20px);
			margin-left: 0;
		}
		.day:last-child {
			margin-right: 25px;
		}
		.day-link {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: clamp(45px, 6vw, 80px);
			height: clamp(45px, 6vw, 80px);
			color: #757575;
		}
		.date {
			font-size: clamp(20px, 2vw, 33px);
			font-weight: 700;
		}
		.text {
			font-size: 13px;
			display: inline-block;
			margin-top: 5px;
		}
		.day:not(.is-selected):not(.is-past) .day-link:hover {
			text-decoration: none;
			background-color: #ebebeb !important;
		}
		.day.is-today {
			* {
				color: #ff7300;
			}
		}
		.day.is-past {
			.day-link {
				background-color: #999;
				color: #ffffff;
			}
		}
		.day.is-selected {
			.day-link {
				// background-color: #015aa8;
				border-radius: 50%;
				border: 3px solid #ff7300;
				color: #ff7300;
			}
		}
	}

	.product-card {
		.product-info {
			.title-link {
				display: block;
				height: 2em;
			}
			.subtext {
				max-height: 4.2em;
				font-size: 13px;
				line-height: 1.2;
				color: #999;
				@include text-ellipsis-3;
			}
			.price {
				display: flex;
				padding-top: 10px;
				font-weight: 700;
				font-size: clamp(16px, 2vw, 19px);
				letter-spacing: 0;
				letter-spacing: -0.5px;
				align-items: flex-end;
				flex-wrap: wrap;
			}
		}
	}

	.schedule-component {
		.schedule-list {
			position: relative;
			padding-top: clamp(25px, 4vw, 50px);
		}
		.schedule-item {
			display: flex;
			~ .schedule-item {
				margin-top: clamp(20px, 4vw, 20px);
			}
		}
	}

	.item-badge {
		position: relative;
		height: 50px;
		margin-top: -20px;
		margin-bottom: 0.5em;
		@include tablet {
			height: 36px;
		}
		.video-badge {
			position: static;
			display: block;
			width: 71px;
			height: 50px;
			@include tablet {
				width: 53px;
				height: 36px;
			}
		}
	}

	.item-time {
		padding-right: 70px;
		margin-bottom: 30px;
		.text {
			font-size: clamp(20px, 4vw, 40px);
			color: var(--text-muted);
			font-weight: 700;
		}
	}
	.is-active .item-time .text {
		color: #015aa8;
	}

	.item-product {
		position: relative;
		width: 640px;
		.product-card {
			display: flex;
			flex-direction: row;
			.product-info {
			}
			.product-image {
				flex: 0 0 clamp(40px, 7.9vw, 90px);
				@include mobile {
					flex: 0 0 40px;
				}
				// padding-top: clamp(40px, 7.9vw, 90px);
				// height: 0;
				.image-link {
					display: block;
					width: clamp(40px, 7.9vw, 90px);
					height: clamp(40px, 7.9vw, 90px);
					border-radius: calc(0.3125rem * 2);
					overflow: hidden;
					@include mobile {
						width: 40px;
						height: 40px;
					}
				}
				img {
					width: 100%;
					height: 100%;
					background-color: #ccc;
				}
			}
			.product-info {
				display: flex;
				position: relative;
				padding: 10px 20px 5px;
				justify-content: space-between;
				flex-direction: column;
				.title {
					flex: 1 1 auto;
					margin-bottom: 1em;
					.text {
						font-size: 16px;
					}
				}
				.price {
					padding-top: 0;
					display: flex;
					align-items: center;
				}
				.info-summary {
				}
				.summary-link {
					.text {
						font-size: 16px;
					}
				}
				.info-name {
					padding-top: 10px;
					.name-link {
						.text {
							font-size: 13px;
						}
					}
				}
				.info-price {
					padding-top: 20px;
				}
			}
		}
		.shopping-bottom {
			position: relative;
			padding-top: 10px;
			margin-top: 10px;
			display: flex;
			align-items: center;
			.bottom-status {
				color: #999;
			}
			.status-text {
				font-size: 16px;
				font-weight: 700;
				color: #999;
				display: inline-block;
				margin-right: 7px;
			}
			.status-text-live {
				color: #cd0505;
			}
			.status-playtime {
			}
			.status-playtime .icon-playtime {
				width: 9px;
				height: 11px;
				background: url(~@/assets/img/live/icon_video_time.svg) no-repeat 0 0;
				margin-right: 5px;
			}
			.status-playtime .text {
				font-size: 13px;
				color: var(--text-muted);
				font-weight: 700;
			}
			.status-watch {
				display: inline-flex;
				align-items: center;
			}
			.status-watch .icon-watch {
				width: 13px;
				height: 11px;
				background: url(~@/assets/img/live/icon_watch_gray.svg) no-repeat 0 0;
				margin-right: 5px;
			}
			.status-watch .text {
				font-size: 13px;
				color: var(--text-muted);
				font-weight: 700;
			}
			.bottom-seller {
				position: relative;
			}
			.bottom-seller .text {
				font-size: clamp(13px, 1.375vw, 16px);
				font-weight: 700;
				color: #111;
			}
			.bottom-status:before {
				content: '·';
				color: #111;
				display: inline-block;
				width: 10px;
				text-align: center;
				margin-left: 2px;
			}
			.bottom-status .status-text {
				font-size: clamp(13px, 1.375vw, 16px);
			}
		}
		.btn-noti {
			padding: 0 20px;
			&:not(.btn-primary) .text {
				font-size: 13px;
				font-weight: 700;
				color: var(--text-muted);
			}
		}
	}

	.item-live {
		flex: 0 0 160px;
		transition: all 0.2s;
		max-width: 160px;
		.live-card {
			position: relative;
		}
		.link-live {
		}
		.live-video {
			position: relative;
			.video-watch {
				left: auto;
				right: 10px;
			}
			.video-playtime {
				z-index: 10;
				position: absolute;
				right: 15px;
				top: 15px;
				.text {
					font-size: 13px;
					color: #fff;
					font-weight: 700;
				}
			}
		}
		.live-card .live-video .video-badge {
			width: 400px;
			background-size: 40px;
		}
	}
	.item-content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 1 auto;
		padding-left: clamp(30px, 5vw, 40px);
	}

	.item-actions {
		position: absolute;
		right: 0;
		.btn-actions {
			width: 140px;
			height: 56px;
			.text {
				font-size: clamp(13px, 1.375vw, 16px);
				font-weight: 700;
			}
			&:not(.btn-primary) .text {
				color: #999;
			}
		}
	}
	.item-actions .btn-actions .icon-noti {
		width: 16px;
		height: 20px;
		margin-right: 5px;
		background: url(~@/assets/img/live/icon_bell.svg) no-repeat;
	}
	.item-actions .btn-actions.btn-primary .icon-noti {
		background-image: url(~@/assets/img/live/icon_bell_active_2.svg);
	}

	.schedule-notfound {
		padding: 150px 0 100px;
		.text {
			text-align: center;
			font-size: clamp(25px, 3vw, 40px);
			font-weight: 700;
			color: transparent;
			background-color: rgba(0, 0, 0, 0.05);
			text-shadow: 1px -1px 0 #e7e7e7;
			background-clip: text;
			-webkit-background-clip: text;
			-moz-background-clip: text;
		}
	}
}

/* [YYY] Add:220310 */
.live-home-container.is-horizontal {
	min-width: 320px;
	min-height: 180px;
}
.live-home-container.is-horizontal .live-home-inner {
	min-width: 320px;
	min-height: 180px;
}
.live-home-container .live-header-content .live-watch .icon-watch {
	margin-right: 4px;
}

@media (max-width: 1024px) {
	#live-calendar .item-product .product-card {
		flex-wrap: wrap;
	}
	#live-calendar .product-card .product-title {
		flex: 0 0 100%;
		margin-bottom: 20px;
	}
	#live-calendar .schedule-component .product-card .product-info .title-link {
		display: none;
	}
	#live-calendar .product-card .product-info .title-link .text {
		display: none;
	}
	#live-calendar .item-content {
		flex-direction: column;
		flex-wrap: wrap;
		padding-left: clamp(20px, 5vw, 74.5px);
		padding-top: 10px;
		padding-bottom: 10px;
	}
	#live-calendar .item-content .item-product {
		display: flex;
		flex-direction: column;
		padding-top: 10px;
	}
	#live-calendar .item-time {
		padding-left: 0;
		margin-bottom: 0;
	}
	#live-calendar .item-product .shopping-bottom {
		flex: 1;
		align-items: initial;
	}
	#live-calendar .item-product .btn-noti {
		position: absolute;
		left: 0;
		bottom: -10px;
	}

	#live-calendar .item-content .item-product {
		width: calc(70%);
	}
	#live-calendar .item-badge .icon-live {
		width: 50px;
		height: 36px;
		background-size: 50px auto;
	}
	#live-calendar .item-badge .icon-comingsoon {
		width: 50px;
		height: 36px;
		background-size: 50px auto;
	}
	#live-calendar .item-badge .icon-vod {
		width: 50px;
		height: 36px;
		background-size: 50px auto;
	}
	#live-calendar .item-actions {
		flex: 0 0 auto;
		margin-left: 30px;
	}
	#live-calendar .item-actions .btn-actions {
		width: 110px;
		height: 45px;
	}

	#live-calendar .product-card .product-info .subtext {
		padding-top: 0;
	}
	#live-calendar .item-product .product-card .product-info {
		justify-content: space-around;
	}
	#live-calendar .item-product .product-card .product-info .title {
		flex: 0 0 auto;
	}

	.comment-container .comment-header .header-title {
		font-size: 18px;
	}
	.comment-container .comment-avatar {
		flex: 0 0 55px;
	}
	.comment-container .comment-avatar .avatar {
		width: 30px;
		height: 30px;
	}
	.comment-container .comment-avatar .avatar-short {
		font-size: 20px;
		line-height: 32px;
	}
	.comment-container .comment-metadata .metadata-name {
		font-size: 14px;
	}
	.comment-container .comment-metadata .metadata-date {
		font-size: 12px;
	}
	.comment-container .comment-text .text {
		font-size: 14px;
	}
	.comment-container .comment-actions .btn-rating .text {
		font-size: 12px;
	}
	.comment-container .comment-actions .actions-reply .text {
		font-size: 12px;
	}
	.comment-container .comment-reply .btn-reply-view .text {
		font-size: 14px;
	}
	.comment-container .comment-reply {
		padding-top: 20px;
	}
	.comment-list-container .comment-list .comment-list {
		padding-top: 25px;
	}
	.comment-list-container .comment-item ~ .comment-item {
		margin-top: 40px;
	}
	.comment-container .btn-comment {
		height: 60px;
	}
	.comment-container .btn-comment .icon-arrow {
		width: 15px;
		height: 15px;
		background-size: 15px;
	}
}

@media (max-width: 768px) {
	#page-live-list .live-list-container {
		padding-top: 0px;
		border-top-width: 0px;
	}
	#page-live-bridge .bridge-seller .seller-thumbnail {
		width: 36px;
		height: 36px;
		margin-right: 8.5px;
	}
	/* #live-calendar .item-product .product-card .product-info {flex:0 0 100%;padding-left:0;padding-bottom:0} */
	#live-calendar .item-product .shopping-bottom {
		margin-top: 10px;
		padding-top: 10px;
	}
	#live-calendar .item-product .shopping-bottom .status-playtime .icon-playtime {
		width: 8px;
		height: 11px;
		background-size: 8px auto;
	}
	#live-calendar .item-product .shopping-bottom .status-watch .icon-watch {
		width: 11px;
		height: 9px;
		background-size: 11px auto;
	}

	#live-calendar .item-actions {
		position: relative;
	}
	#live-calendar .item-content .item-product {
		width: 100%;
	}
	#live-calendar .item-actions {
		margin-left: 0;
		margin-top: 40px;
	}
	#live-calendar .item-product .product-card .product-info {
		padding-left: 10px;
	}
	#live-calendar .item-badge .icon-live {
		width: 40px;
		height: 29px;
		background-size: 40px auto;
	}
	#live-calendar .item-badge .icon-comingsoon {
		width: 40px;
		height: 29px;
		background-size: 40px auto;
	}
	#live-calendar .item-badge .icon-vod {
		width: 40px;
		height: 29px;
		background-size: 40px auto;
	}

	#page-live-bridge .live-info-wrapper .btn {
		height: 36px;
	}
	#page-live-bridge .live-info-wrapper .btn .text {
		font-size: 11px;
		display: none;
	}
	#page-live-bridge .bridge-actions {
		margin-left: 5px;
	}
	#page-live-bridge .bridge-actions .icon-share {
		width: 13px;
		height: 11px;
		background-size: 13px auto;
	}
	#page-live-bridge .live-product {
		padding-top: 30px;
	}

	.comment-container .comment-header .header-title {
		font-size: 13px;
	}
	.comment-container .comment-avatar .avatar {
		width: 30px;
		height: 30px;
	}
	.comment-container .comment-avatar .avatar-short {
		font-size: 20px;
		line-height: 32px;
	}
	.comment-container .comment-metadata .metadata-name {
		font-size: 13px;
	}
	.comment-container .comment-metadata .metadata-date {
		font-size: 12px;
	}
	.comment-container .comment-text .text {
		font-size: 13px;
	}
	.comment-container .comment-actions .btn-rating .text {
		font-size: 12px;
	}
	.comment-container .comment-actions .actions-reply .text {
		font-size: 12px;
	}
	.comment-container .comment-reply .btn-reply-view .text {
		font-size: 13px;
	}
	.comment-container .comment-reply {
		padding-top: 20px;
	}
	.comment-list-container .comment-list .comment-list {
		padding-top: 25px;
	}
	.comment-list-container .comment-item ~ .comment-item {
		margin-top: 40px;
	}
	.comment-container .btn-comment {
		height: 60px;
	}
	.comment-container .btn-comment .icon-arrow {
		width: 15px;
		height: 15px;
		background-size: 15px;
	}

	/* [YYY] Add:220303 */
	#live-calendar .item-product .shopping-bottom .bottom-status {
		display: none;
	}
	#live-calendar .item-live {
		flex: 0 0 120px;
		max-width: 0 0 120px;
	}
	#live-calendar .item-content {
		padding-left: 20px;
	}
	#live-calendar .item-product .product-card .product-image {
		flex: 0 0 40px;
		width: 40px;
	}
	#live-calendar .item-product .product-card .product-image img {
		object-fit: contain;
		background-color: transparent;
	}
	#live-calendar .item-product .product-card .product-info {
		max-width: calc(100% - 40px);
	}
	#live-calendar .item-product .product-card .product-info .price {
		white-space: nowrap;
		font-size: 12px;
	}
}

@media (max-width: 700px) {
	/* 220614 modify */
	.live-home-container .live-header {
		padding: 30px 20px;
	}
	.live-home-container .live-header-title {
		width: 80%;
	}
	.live-home-container .live-header-title .title {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@include small {
	.v-sm {
		display: block !important;
	}
	[id^='page-live'] .product-card .product-title .title-link .text {
		font-size: 15px;
	}
	/* [YYY] Delete:220303 #live-calendar .item-live {flex:0 0 50%;} */
}

@media (min-width: 320px) and (max-width: 1024px) {
}

.sidetoside-animate {
	-webkit-animation-name: spaceman-bounce;
	-moz-animation-name: spaceman-bounce;
	-ms-animation-name: spaceman-bounce;
	-o-animation-name: spaceman-bounce;
	animation-name: spaceman-bounce;
	-webkit-animation-duration: 2.5s;
	-moz-animation-duration: 2.5s;
	-ms-animation-duration: 2.5s;
	-o-animation-duration: 2.5s;
	animation-duration: 2.5s;
	-webkit-animation-timing-function: ease-in-out;
	-moz-animation-timing-function: ease-in-out;
	-ms-animation-timing-function: ease-in-out;
	-o-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-delay: 0.5s;
	-moz-animation-delay: 0.5s;
	-ms-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
}
@-moz-keyframes spaceman-bounce {
	0% {
		margin-left: 0px;
	}
	50% {
		margin-left: -40px;
	}
	100% {
		margin-left: 0px;
	}
}
@-webkit-keyframes spaceman-bounce {
	0% {
		margin-left: 0px;
	}
	50% {
		margin-left: -40px;
	}
	100% {
		margin-left: 0px;
	}
}
@-ms-keyframes spaceman-bounce {
	0% {
		margin-left: 0px;
	}
	50% {
		margin-left: -40px;
	}
	100% {
		margin-left: 0px;
	}
}
@-o-keyframes spaceman-bounce {
	0% {
		margin-left: 0px;
	}
	50% {
		margin-left: -40px;
	}
	100% {
		margin-left: 0px;
	}
}

/***** YYY ******/
.actions-like {
	position: relative;
	width: 32px;
	height: 32px;
	margin-bottom: 30px;
}
.actions-like-anim-circle {
	opacity: 0;
	position: absolute;
	top: -35px;
	left: -32px;
	background-position: 0px;
	background-repeat: no-repeat;
	height: 100px;
	width: 100px;
	pointer-events: none;
	background: url(~@/assets/img/live/anim_like.heart.png);
}
.actions-like-btn {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 32px;
	height: 32px;
}

.actions-like-btn img {
	position: relative;
	width: 32px;
	height: 32px;
	top: 1px;
	left: 3px;
}
.actions-like-btn .icon-like {
	width: 32px;
	height: 30px;
	background-image: url(~@/assets/img/live/icon_like.svg);
}
.actions-like-anim-circle.active {
	opacity: 1;
	-webkit-animation: heart-animate 0.6s steps(28) forwards;
	animation: heart-animate 0.6s steps(28) forwards;
}
@-webkit-keyframes heart-animate {
	100% {
		background-position: -2800px;
	}
}
@keyframes heart-animate {
	100% {
		background-position: -2800px;
	}
}

.actions-like-anim-heart {
	animation: hideAndSeek 1.3s, bounceBounce 1.1s;
	width: 30px;
	height: 30px;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 50%;
}

@keyframes bounceBounce {
	0% {
		margin-left: 0px;
	}
	20% {
		margin-left: -7px;
	}
	40% {
		margin-left: 0px;
	}
	60% {
		margin-left: -6px;
	}
	80% {
		margin-left: 0px;
	}
	100% {
		margin-left: -5px;
	}
}

@keyframes ups {
	from {
		bottom: 0px;
	}
	to {
		bottom: 120px;
	}
}

@keyframes hideAndSeek {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}

.heart_img_0 {
	background-image: url(~@/assets/img/live/icon_like_heart_0.svg);
}

.heart_img_1 {
	background-image: url(~@/assets/img/live/icon_like_heart_1.svg);
}

.heart_img_2 {
	background-image: url(~@/assets/img/live/icon_like_heart_2.svg);
}

/* fold 기준 위치 수정 */
@media (max-width: 280px) {
	.live-home-container .live-top-actions {
		right: 40px;
	}
	.live-home-container .live-aside-actions {
		right: 40px;
	}
	/* 전체상품보기, 공지사항 클릭 */
	.live-home-container .live-tag-item,
	.live-home-container .live-modal .modal-header {
		padding: 0 40px 0 10px;
	}
	.live-home-container .modal-section .modal-header {
		padding-left: 10px;
	}
	.live-home-container .modal-section .btn-header,
	.live-home-container .live-modal .modal-header .btn-modal-close {
		right: 50px;
	}
	.live-home-container .modal-section .product-item {
		padding: 15px 45px 0 10px;
	}
	.live-home-container .live-modal .modal-body {
		padding: 40px 45px 40px 10px;
	}

	/* 220614 modify */
	.live-home-container .live-header {
		padding: 30px 10px;
	}
}
