.swiper-pagination-bullet {
	background-color: #fff;
	opacity: 0.6;
	text-indent: -9999em;
	overflow: hidden;
	width: 5px;
	height: 5px;
}
.swiper-pagination-bullet-active {
	background-color: #ff7300;
	opacity: 1;
}

.community-list {
}
.community-list-item {
	width: 100%;
}
.community-list-item:not(:last-child) {
	margin-bottom: 20px;
}
.community-list-item:last-child {
	margin-bottom: 40px;
}
.community-card {
	position: relative;
	padding: 20px;
	background-color: #fff;
	border-radius: 1px;
}

.community-card .community-content {
	padding-bottom: 15px;
}
.community-card .community-content .badge {
	margin-bottom: 12px;
}
.community-card .community-content .text {
	line-height: 22px;
	font-size: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	max-height: 66px;
}
.community-card .community-images {
	overflow: hidden;
	position: relative;
}
.community-card .community-image-wrapper {
}
.community-card .community-image {
	position: relative;
	width: 100%;
	height: 0;
	// padding-top: 61.5%;
	padding-top: 100%;
}
.community-card .community-image img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.community-card .community-info {
	padding: 20px 0;
}
.community-card .community-info .info-row {
	display: flex;
	min-width: 0;
}
.community-card .community-info .info-item {
	position: relative;
	font-size: 13px;
	color: #555;
}
.community-card .community-info .info-item.writer {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.community-card .community-info .info-item:not(.writer) {
	flex-shrink: 0;
}
.community-card .community-info .info-item:not(:first-child):before {
	content: '·';
	display: inline-block;
	margin-right: 2px;
	margin-left: 4px;
}
.community-card .community-actions {
	position: relative;
	border-top: 1px solid #ebebeb;
	display: flex;
	padding-top: 20px;
}
.community-card .community-actions .actions-btn {
	display: inline-flex;
	align-items: center;
}
.community-card .community-actions .actions-btn:not(:first-child) {
	margin-left: 15px;
}
.community-card .community-actions .icon-reply {
	width: 20px;
	height: 20px;
	background-image: url(../img/community/reply_icon.svg);
	margin-right: 10px;
}
.community-card .community-actions .icon-message {
	width: 20px;
	height: 20px;
	background-image: url(../img/community/message_icon.svg);
	margin-right: 10px;
}
.community-card .community-actions .actions-text {
	font-size: 13px;
	font-weight: 900;
	color: #015aa8;
}

#sme-community .page-buttons {
	padding: 20px 20px 60px;
}
#sme-community .page-buttons .btn {
	width: 100%;
}

/* #sme-community {background-color:#f3f5f7} */

#sme-community {
}
#sme-community .page-component {
	padding: 0px;
}
#sme-community .list-header {
	padding: 20px;
}
#sme-community .community-banner {
	position: relative;
}
#sme-community .community-banner .banner-content {
	z-index: 1;
	position: absolute;
	left: 0;
	bottom: 40px;
	width: 100%;
}
#sme-community .community-banner .banner-title {
	padding-left: 20px;
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	line-height: 30px;
}
#sme-community .community-banner .banner-subtitle {
	font-size: 1rem;
	color: #fff;
	line-height: 22px;
	margin-bottom: 8px;
}
#sme-community .community-banner .banner-image {
	margin-top: 55px;
	position: relative;
	height: 0;
	padding-top: 100%;
	line-height: 0;
}
#sme-community .community-banner .banner-image img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
#sme-community .community-banner .deem-banner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.35));
}
#sme-community .community-list-wrapper {
}

/* #sme-community .section-notice-arrow {position: absolute;right: 15px;top: 91px;display: flex;z-index: 100;}
#sme-community .btn-arrow {width:50px;height:50px;background-color:#f3f5f7;border:1px solid #ebebeb;line-height:0;display: flex;justify-content: center;align-items: center;}
#sme-community .btn-arrow {transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-community .btn-arrow:hover {background-color:#ebebeb;transition-property: background-color; transition-duration: 0.3s;transition-timing-function:ease-in-out;}
#sme-community .btn-arrow:disabled [class^="icon-arrow"] {opacity:.3;}
#sme-community .disabled {opacity:.3;}
#sme-community .btn-arrow:not(:first-child) {margin-left:5px;}
#sme-community [class^="icon-arrow"] {width:15px;height:15px;}
#sme-community .icon-arrow-prev {background-image:url(../img/main/notice_prev.svg)}
#sme-community .icon-arrow-next {background-image:url(../img/main/notice_next.svg)} */

#sme-community-view {
}
#sme-community-view .community-view-header {
	display: flex;
	padding-top: 40px;
	padding-bottom: 20px;
}
#sme-community-view .community-view-header .header-avatar {
	flex: 0 0 40px;
}
#sme-community-view .community-view-header .header-avatar .avatar {
	width: 40px;
	height: 40px;
}
#sme-community-view .community-view-header .header-avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
#sme-community-view .community-view-header .header-content {
	flex: 1 1 auto;
	padding-left: 15px;
	padding-top: 2px;
}
#sme-community-view .community-view-header .header-content .title {
	display: block;
	margin-bottom: 5px;
	font-size: 1rem;
	font-weight: bold;
}
#sme-community-view .community-view-header .header-content .date {
	display: block;
	margin-bottom: 5px;
	font-size: 13px;
	color: #999;
	font-weight: bold;
}
#sme-community-view .community-view-header .header-badge {
	display: flex;
}
#sme-community-view .community-view-actions {
	padding: 20px 0;
	display: flex;
	border-bottom: 1px solid #ebebeb;
}
#sme-community-view .community-view-actions .btn {
	padding: 0;
	height: 20px;
}
#sme-community-view .community-view-actions .icon-reply {
	margin-right: 10px;
	width: 20px;
	height: 20px;
	background-image: url(../img/community/reply_icon_60h.svg);
	background-size: 20px auto;
}
#sme-community-view .community-view-actions .icon-message {
	margin-right: 10px;
	width: 20px;
	height: 20px;
	background-image: url(../img/community/message_icon_60h.svg);
	background-size: 20px auto;
}
#sme-community-view .community-view-actions .text {
	font-weight: 900;
	font-size: 13px;
}
#sme-community-view .community-view-actions .btn:not(:first-child) {
	margin-left: 20px;
}

#sme-community .community-title {
	display: flex;
	justify-content: center;
	padding: 10px;
}
#sme-community .community-title .community-title-desc {
	word-break: keep-all;
	padding: 10px;
	font-weight: 700;
	border-radius: 2px;
	background-color: white;
	color: #111;
	text-align: center;
	font-size: 1.1rem;
	line-height: 1.5;
}
#sme-community .community-title .community-title-desc img {
	width: 100%;
	margin-top: 10px;
}

.comment-container {
	position: relative;
}
.comment-container .comment-list {
	text-align: left;
	padding: 20px 0 0;
}
.comment-container .comment-item {
	display: flex;
	align-items: top;
}
.comment-container .comment-item ~ .comment-item {
	padding-top: 30px;
}
.comment-container .comment-column.avatar {
	flex: 0 0 40px;
}
.comment-container .comment-column.avatar img {
	width: 40px;
	height: 40px;
	object-fit: cover;
}
.comment-container .comment-column.contents {
	flex: auto;
	padding-left: 15px;
	padding-top: 0;
}
.comment-container .comment-column.control {
	flex: 0 0 100px;
	align-self: center;
}
.comment-container .comment-metadata {
	margin-bottom: 20px;
}
.comment-container .comment-author {
	font-size: 1rem;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
}
.comment-container .comment-author .badge {
	margin-left: 13px;
	border: 1px solid #ebebeb;
	border-radius: 1px;
	background-color: #f3f5f7;
	height: 20px;
	padding: 0 5px;
}
.comment-container .comment-author .badge-text {
	font-size: 11px;
	color: #999 !important;
}
.comment-container .comment-date {
	display: block;
	font-size: 13px;
	font-weight: 600;
	color: #999;
	margin-top: 5px;
}
.comment-container .comment-content {
	margin-top: 11px;
}
.comment-container .comment-content .comment-text {
	font-size: 16px;
	line-height: 22px;
	color: #555;
}
.comment-container .comment-write {
	position: relative;
	background-color: #fff;
	top: -1px;
	padding-top: 30px;
}
.comment-container .comment-write .comment-column.contents {
	padding-top: 0;
}
.comment-container .comment-write .comment-author {
	padding-top: 10px;
	padding-bottom: 10px;
}
.comment-container .comment-input {
	margin-left: -55px;
	margin-top: 15px;
}
.comment-container .comment-input-textarea {
	height: 140px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	color: #555;
	resize: vertical;
	max-height: 200px;
	min-height: 40px;
	box-shadow: 0 0 0 0.5px #ebebeb inset;
	font-size: 13px;
	line-height: 20px;
	border: none;
}
.comment-container .comment-input-actions {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
}
.comment-container .comment-input-byte {
}
.comment-container .comment-input-byte .byte {
	font-size: 13px;
	letter-spacing: 0;
	color: #555;
}

/* 셀렉트 추가 S (20220721 - hib) */
#sme-community .box_select {
	width: 205px;
	position: absolute;
	top: 8px;
	right: 5px;
	z-index: 999;
}
#sme-community .box_select button {
	width: 100%;
	padding: 8px 12px;
	text-align: left;
	background: #999999;
	color: #fff;
	border-radius: 5px;
	position: relative;
	top: 3px;
}
#sme-community .box_select button::after {
	display: inline-block;
	width: 28px;
	height: 26px;
	content: '';
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	position: absolute;
	top: 3px;
	right: 2px;
}
#sme-community .box_select button.active::after {
	background: url('../img/common/list_arrow_btn_gray.png') center / 85% no-repeat;
	transform: rotate(180deg);
}
#sme-community .box_select ul {
	display: none;
	width: 100%;
	padding: 12px 10px 8px;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #999999;
}
#sme-community .box_select ul li {
	font-size: 14px;
	font-weight: 900;
}
#sme-community .box_select ul li + li {
	margin-top: 8px;
}
#sme-community .box_select .show_list {
	display: block;
	background: #fff;
}
/* 셀렉트 추가 E (20220721 - hib) */

/* 해당 영역에만 백그라운드 컬러 추가 (20220802 hib)*/
.community-wrap {
	background-color: #f3f5f7;
}
