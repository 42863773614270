.floating-buttons {
	display: flex;
	position: fixed;
	width: 76px;
	height: auto;
	right: 7.5%;
	bottom: 4%;
	z-index: 1;
	gap: 8px;
	flex-direction: column;
	align-items: center;

	@include until(1800px) {
		right: 15px;
		bottom: 10px;
	}
	@include tablet {
		width: 48px;
		gap: 6px;
	}
	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 26px;
		height: 26px;
		@include tablet {
			width: 21px;
			height: 21px;
		}
		svg {
			width: 100%;
			height: 100%;
		}
		&.icon-floating-onfan {
			@include tablet {
				width: 24px;
				height: 23px;
				margin-top: -3px;
			}
		}
		&.icon-floating-aichat {
			width: 31px;
			height: 31px;
			margin-top: -4px;
			margin-bottom: -3px;
			@include tablet {
				width: 26px;
				height: 26px;
			}
		}
		&.icon-floating {
			&-top {
				width: 9px;
				height: 8px;

				@include tablet {
					width: 8px;
					height: 7px;
				}
			}
		}
	}
	&-visible {
		position: relative;
		margin-bottom: -8px;
		padding: 8px;
		border-radius: 18px;
		overflow: hidden;
		user-select: none;
		&.is-active {
			.floating-buttons-visible__inner {
				margin-top: -350px;
				bottom: -350px;
				opacity: 0;
				visibility: hidden;
			}
		}
		&__inner {
			position: relative;
			display: flex;
			flex-direction: column;
			left: 0;
			bottom: 0;
			gap: 8px;
			opacity: 1;
			visibility: visible;
			// transform: translateY(0);
			@include transition(bottom, 0.3s);
			transition-property: bottom, margin-top, opacity, visibility;
		}
	}
	.floating-button {
		display: inline-flex;
		border: 1px solid #e0e0e0;
		border-radius: 8px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 60px;
		height: 60px;
		background-color: #fff;
		box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
		&.bg-kakao {
			border-color: #ffe31c;
			background-color: #ffe31c;
		}
		&.btn-pagetop {
			border-color: transparent;
			background-color: #645f5b;
			color: #fff;
		}
		&.color-orange {
			border-color: #ff7300;
			background-color: #ff7300;
			color: #fff;
		}

		.text {
			display: block;
			margin-top: 5px;
			font-size: 14px;
			font-weight: 700;
			line-height: 1;
			@include tablet {
				margin-top: 4px;
				font-size: 12px;
			}
		}
		@include tablet {
			width: 48px;
			height: 48px;
			border-radius: 6px;
		}
	}
}
.icon-open-floating {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	@include transition(transform, 0.3s);
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 2px;
		height: 19px;
		z-index: 1;
		background-color: #fff;
		transform: translate(-50%, -50%);
	}
	&::after {
		transform: translate(-50%, -50%) rotate(90deg);
	}
	&.is-close {
		transform: rotate(135deg);
	}
}
